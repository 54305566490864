module Bfs.Web.Shared.FSharp.Routing

let private tryRemoveLeft (toRemove: string) (input: string) =
    if input.StartsWith toRemove then input.Substring toRemove.Length else input

let private tryRemoveRight (toRemove: string) (input: string) =
    if input.EndsWith toRemove then
        input.Substring(0, (input.Length - toRemove.Length))
    else
        input

let private normalizeTypAndMethod (t: string) (m: string) =
    let t = t.ToLower() |> tryRemoveLeft "i" |> tryRemoveRight "service"
    let m = m.ToLower()
    (t, m)

let routeBuilder (t: string) (m: string) =
    let (t, m) = normalizeTypAndMethod t m

    sprintf "/api/%s/%s" t m

let routeBuilderPatienten (t: string) (m: string) =
    let (t, m) = normalizeTypAndMethod t m

    sprintf "/api/patienten/%s/%s" t m
