module Verrechnungen.Types

open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Bfs.Web.Data.Service.Contracts.Kundenportal.Verrechnungen
open Fetch.Types
open Types
open System

type Page = Verrechnungen of filiale: Guid option

type Msg =
    | LoadFilialen
    | FilialenLoaded of Result<Filiale[], exn>

    | LoadVerrechnungen of filiale: Guid
    | VerrechnungenLoaded of Result<Verrechnung[], exn>

    | VerschiebenStart of DateTime
    | VerschiebenDurchfuehrenTry
    | VerschiebenDurchfuehrenTried of Result<VerschiebungResult, exn>
    | VerschiebenDurchfuehren
    | VerschiebenDurchfuehrenDone of Result<VerschiebungResult, exn>
    | VerschiebenAbbrechen

    | DtaReportAnfordern
    | DtaReportAngefordert of Result<Response, exn>

    | PrintVereinbarung of string

    | SelectVerrechnung of Guid * bool

    | SwitchFiliale of Guid

    | HideInfoBox

    | Toggle of Guid

    | LoadEventSettings
    | LoadEventSettingsCompleted of Result<EventSettings, exn>

    | GlobalMsg of GlobalMsg

type Model =
    {
        Filialen: Remote<Filiale[]>
        SelectedFilialeId: Guid option

        ShowInfoBox: bool

        Verrechnungen: Remote<Verrechnung[]>
        ExpandedVerrechnungen: Guid list

        UserDarfVerschieben: bool
        FilialeKannVerschieben: bool

        VerrechnungAuswaehlenFuerMonat: DateTime option
        SelectedVerrechnugen: Guid list

        DtaReportAngefordert: bool

        IsSending: bool

        OstergewinnspielActive: bool
    }

    static member Init = {
        Filialen = Loading
        SelectedFilialeId = None
        ShowInfoBox = true
        Verrechnungen = Loading
        ExpandedVerrechnungen = []

        VerrechnungAuswaehlenFuerMonat = None
        SelectedVerrechnugen = []

        DtaReportAngefordert = false

        IsSending = false
        UserDarfVerschieben = false
        FilialeKannVerschieben = false

        OstergewinnspielActive = false
    }
