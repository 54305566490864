module AdminFile.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "Administration: Auswertungen und Dokumente"

    BuildUrl =
        function
        | Page.List(1, None, None) -> [ Urls.admin; Urls.files ]
        | Page.List(p, None, None) -> [
            Urls.admin
            Urls.files
            Urls.pageSeperator
            p.ToString()
          ]
        | Page.List(p, Some kdnr, Some kategorie) -> [
            Urls.admin
            Urls.files
            kdnr.ToString()
            kategorie
            Urls.pageSeperator
            p.ToString()
          ]
        | Page.List(p, Some kdnr, None) -> [
            Urls.admin
            Urls.files
            kdnr.ToString()
            Urls.pageSeperator
            p.ToString()
          ]
        | Page.List(p, None, Some kategorie) -> [
            Urls.admin
            Urls.files
            Urls.all
            kategorie
            Urls.pageSeperator
            p.ToString()
          ]

    ParseUrl =
        function
        | [ Urls.admin; Urls.files ] -> Some(Page.List(1, None, None))
        | [ Urls.admin; Urls.files; Urls.pageSeperator; page ] ->
            Some(Page.List((System.Int32.Parse(page)), None, None))
        | [ Urls.admin; Urls.files; kdnr; Urls.pageSeperator; page ] ->
            Some(Page.List((System.Int32.Parse(page)), Some(System.Int32.Parse(kdnr)), None))
        | [ Urls.admin; Urls.files; Urls.all; kategorie; Urls.pageSeperator; page ] ->
            Some(Page.List((System.Int32.Parse(page)), None, Some kategorie))
        | [ Urls.admin; Urls.files; kdnr; kategorie; Urls.pageSeperator; page ] ->
            Some(Page.List((System.Int32.Parse(page)), Some(System.Int32.Parse(kdnr)), Some kategorie))
        | _ -> None
}
