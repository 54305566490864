module Results

open System

type AuthenticationError = | InvalidOrExpiredToken

module AuthenticationError =
    let explain =
        function
        | InvalidOrExpiredToken -> "Session abgelaufen"

type ServerError =
    | Exception of string
    | Authentication of AuthenticationError
    | DatabaseItemNotFound of Guid

exception ServerException of ServerError

module ServerError =

    let failwith (er: ServerError) = raise (ServerException er)

    let ofOption err (v: Option<_>) = v |> Option.defaultWith (fun _ -> err |> failwith)

    let ofResult<'a> (v: Result<'a, ServerError>) =
        match v with
        | Ok v -> v
        | Error e -> e |> failwith

type ServerResult<'a> = Result<'a, ServerError>
