module Videos.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun model -> Header "Das interaktive BFS-Video"

    BuildUrl = fun _ -> [ Urls.videos ]

    ParseUrl =
        function
        | [ Urls.videos ] -> Some Page.Videos
        | _ -> None
}
