module Settings.Types

open System
open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.Settings
open Types
open Results

type Page = | Settings

type Msg =
    | LoadSettings
    | SettingsLoaded of ServerResult<Settings>

    | TryChangeDokumentenabruf of Guid * bool
    | ChangeDokumentenabrufTried of Guid * bool * ServerResult<Result<string, string>>

    | ChangeDokumentenabrufSetting of Guid * bool
    | DokumentenabrufSettingChanged of Guid * bool * ServerResult<Result<string, string>>

    | PrintVereinbarung of string

    | SwitchOffPostzustellung
    | SwitchOffPostzustellungConfirmed
    | SwitchOnPostzustellung
    | PostzustellungToggled of ServerResult<bool>

    | GlobalMsg of GlobalMsg

type Model =
    {
        Filialen: DokumentenabrufSettings[] option
        PostInsKundenportalZustellen: bool
        DokumentenAbrufAnzeigen: bool
    }
    static member Init() = {
        Filialen = None
        PostInsKundenportalZustellen = false
        DokumentenAbrufAnzeigen = false
    }

let dokumentenAbrufAnzeigen u =
    u.Roles |> List.contains Roles.KundeMitDokumentenabruf
    && u.Roles |> List.contains Roles.KundeKostenpflichtigBuchen
