namespace Files

open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.File
open Kundenportal.Types

module Http =
    let getKategorien () = fetchAs<string list> "/api/file/kategorien"

    let getFiles (page, filter) =
        let url =
            match filter with
            | Alle -> sprintf "/api/file?page=%i" page
            | NurNeue -> sprintf "/api/file?page=%i&onlyNew=true" page
            | Kategorie kategorie -> sprintf "/api/file?page=%i&kategorie=%s" page kategorie
        fetchAs<Paginated<FileListItem>> url

    let downloadUrl (f: FileListItem) = (sprintf "/api/file/%O" f.Id)
    let downloadMetadataUrl (f: FileListItem) = (sprintf "/api/file/%O/metadata" f.Id)


module State =
    let init (page: Page) user (lastModel: Model option) =
        match page with
        | Files(page, filter) ->
            let kategorienModel =
                match lastModel with
                | Some x ->
                    match x.Kategorien with
                    | Body y -> Body y
                    | _ -> Loading
                | None -> Loading
            let cmd1 = if kategorienModel = Loading then Cmd.ofMsg LoadKategorien else Cmd.none

            let newmodel = {
                PageNumber = Some page
                SelectedFilter = filter
                Kategorien = kategorienModel
                Files = Loading
                ExpandedBeschreibungen = []
                OstergewinnspielActive = false
            }
            let cmd2 = Cmd.ofMsg (LoadFiles(page, filter))
            (newmodel, Cmd.batch [ cmd1; cmd2; LoadEventSettings |> Cmd.ofMsg ])

    let private markFileRead id (files: Paginated<FileListItem>) =
        let items =
            files.Items
            |> List.map (fun (i: FileListItem) -> if (i.Id = id && i.IsNew) then { i with IsNew = false } else i)
        { files with Items = items }

    let update msg model =
        match msg with
        | LoadFiles(page, filter) ->
            let filesLoadedFactory result = FilesLoaded(filter, result)
            let cmd = filesLoadedFactory |> request Http.getFiles (page, filter)
            ({ model with Files = Loading }, cmd)
        | FilesLoaded(filter, x) ->
            ({ model with
                Files = Loaded x
                SelectedFilter = filter
             },
             Cmd.none)

        | LoadKategorien ->
            let cmd = KategorienLoaded |> request Http.getKategorien ()
            ({ model with Kategorien = Loading }, cmd)
        | KategorienLoaded x -> ({ model with Kategorien = Loaded x }, Cmd.none)

        | ToggleBeschreibung id ->
            ({ model with
                ExpandedBeschreibungen = model.ExpandedBeschreibungen |> toggle id
             },
             Cmd.none)

        | MarkRead id ->
            match model.Files with
            | Body files ->
                ({ model with
                    Files = Body(files |> markFileRead id)
                 },
                 Cmd.none)
            | _ -> (model, Cmd.none)

        | LoadEventSettings ->
            let request () = fetchAs<EventSettings> "/api/events/settings"
            model,
            (
                LoadEventSettingsCompleted
                |> Http.request request ()
            )
        | LoadEventSettingsCompleted (Error _) ->
            Logger.error "Die Einstellungen für das Ostergewinnspiel konnten nicht geladen werden"
            model, Cmd.none
        | LoadEventSettingsCompleted (Ok settings) ->
            { model with OstergewinnspielActive = settings.EasterEvent.IsSome}, Cmd.none

        | GlobalMsg _ -> (model, Cmd.none) // wird hier sowieso nicht behandelt
