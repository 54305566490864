module Files.WebPart

open WebPart
open Types
open Kundenportal.Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "Auswertungen & Dokumente"

    BuildUrl =
        function
        | Files.Types.Page.Files(1, Alle) -> [ Urls.files ]
        | Files.Types.Page.Files(p, Alle) -> [
            Urls.files
            Urls.pageSeperator
            p.ToString()
          ]
        | Files.Types.Page.Files(1, NurNeue) -> [ Urls.files; Urls.newId ]
        | Files.Types.Page.Files(p, NurNeue) -> [
            Urls.files
            Urls.newId
            Urls.pageSeperator
            p.ToString()
          ]
        | Files.Types.Page.Files(1, Kategorie kategorie) -> [ Urls.files; kategorie ]
        | Files.Types.Page.Files(p, Kategorie kategorie) -> [
            Urls.files
            kategorie
            Urls.pageSeperator
            p.ToString()
          ]

    ParseUrl =
        function
        | [ Urls.files ] -> Some(Page.Files(1, Alle))
        | [ Urls.files; Urls.pageSeperator; p ] -> Some(Page.Files(System.Int32.Parse(p), Alle))
        | [ Urls.files; Urls.newId ] -> Some(Page.Files(1, NurNeue))
        | [ Urls.files; Urls.newId; Urls.pageSeperator; p ] -> Some(Page.Files(System.Int32.Parse(p), NurNeue))
        | [ Urls.files; kategorie ] -> Some(Page.Files(1, Kategorie kategorie))
        | [ Urls.files; kategorie; Urls.pageSeperator; p ] ->
            Some(Page.Files(System.Int32.Parse(p), Kategorie kategorie))
        | _ -> None
}
