module ApiToken.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "API-Token"

    BuildUrl = fun _ -> [ Urls.apitoken ]

    ParseUrl =
        function
        | [ Urls.apitoken ] -> Some Page.ApiToken
        | _ -> None
}
