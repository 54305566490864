module Render

open Fulma
open Fable.React
open Fable.React.Props

let box body =
    Columns.columns [
        Columns.CustomClass "is-product-list"
        Columns.IsMultiline
    ] [
        Column.column [
            Column.Width(Screen.All, Column.Is12)
        ] [
            div [
                Class "flat-card profile-info-card is-auto"
            ] [
                div [ Class "card-body" ] [
                    div [ Class "content" ] [ body |> ofList ]
                ]
            ]
        ]
    ]

let pageNotFound =
    box [
        p [] [
            str "Die von Ihnen gesuchte Seite konnte leider nicht gefunden werden."
        ]
        p [] [
            a [ Href "/" ] [ str "Zurück zum Start" ]
        ]
    ]
