module Admin.View

open Fable.React

open Types
open Fable.React.Props

let backendVersionText version =
    match version with
    | Empty -> ""
    | Loading -> "..."
    | LoadError e -> e
    | Body v -> v

let version (model: Types.Model) =
    div [] [
        h2 [] [ str "Version" ]

        p [] [
            b [] [ str "Frontend: " ]
            str model.ClientVersion
        ]

        p [] [
            b [] [ str "Backend / API: " ]
            str (backendVersionText model.ServerVersion)
        ]
    ]

let view (model: Types.Model) dispatch navigateTo =
    div [ Class "columns is-multiline" ] [
        div [ Class "column is-12" ] [
            div [
                Class "flat-card profile-info-card is-auto"
            ] [
                div [ Class "card-body" ] [ version model ]
            ]
        ]
    ]
