module Profile.View

open System
open Fable.React

open Types
open Fable.React.Props

open ViewParts

let optBoxEnabeld state dispatch =
    let buttonStyle = if state then "is-danger" else "is-light"
    div [ Class "paragraphs" ] [
        p [] [
            b [] [
                str "Ihr Account ist durch Zwei-Faktor-Authentifizierung abgesichert! "
                span [] [ i [ Class "fas fa-check" ] [] ]
            ]
        ]
        p [] [
            label [ Class "checkbox" ] [
                input [
                    Type "checkbox"
                    Checked state
                    OnChange(fun ev -> ((DeactivateOtpCheckboxChanged ev.Checked) |> Otp |> dispatch))
                ]
                str " Ich möchte die Zwei-Faktor-Authentifizierung deaktivieren."
            ]
        ]
        p [ Class "is-pulled-right" ] [
            button [
                Class("bfsbutton")
                Disabled(not state)
                OnClick(fun _ ->
                    if state then
                        (DeactivateOtp |> Otp |> dispatch))
            ] [ str "Deaktivieren" ]
        ]
    ]
let optBoxDisabeld dispatch =
    div [ Class "paragraphs" ] [
        p [] [
            b [] [
                str "Sie können ihren Account durch die Verwendung von Zwei-Faktor-Authentifizierung absichern:"
            ]
        ]
        p [] [
            str
                "Sie benötigen eine Authenticator-App auf Ihrem Smartphone (z.B. Google Authenticator, Authy, 1Password, etc.), um für jede Anmeldung ein neues Einmalpasswort zu erzeugen."
        ]
        p [] [ img [ Src "BFS_2fa_prozess2x.jpg" ] ]
        p [ Class "is-pulled-right" ] [
            a [
                Class "bfsbutton "
                OnClick(fun _ -> ActivateOtp |> Otp |> dispatch)
            ] [ str "Jetzt aktivieren!" ]
        ]
    ]
let submitOtpOnEnter dispatch =
    OnKeyDown(fun (e) ->
        if (e.key = "Enter") then
            ConfirmOtp |> Otp |> dispatch)
let optBoxActivating model dispatch =
    div [ Class "paragraphs" ] [
        yield
            p [] [
                str
                    "Bitte scannen Sie den QR-Code mit Ihrer Authenticator-App und verifizieren Sie Ihre App durch die Eingabe des in der App angezeigten Einmalpassworts."
            ]
        yield p [] [ img [ Src model.QrCode ] ]
        yield
            validatedTextBoxWithoutErrorMsg2
                [
                    AutoComplete "off"
                    submitOtpOnEnter dispatch
                ]
                (dispatch << (Otp << OtpChanged))
                "Einmalpasswort"
                "Otp"
                "123456"
                model.OtpField

        if model.ErrorMsg.IsSome then
            yield div [ Class "is-pulled-left error-msg" ] [ str model.ErrorMsg.Value ]

        yield
            p [ Class "is-pulled-right" ] [
                button [
                    Type "submit"
                    Class "bfsbutton"
                    Disabled(not (model.CanSubmit))
                    Props.OnClick(fun _ -> ConfirmOtp |> Otp |> dispatch)
                ] [ str "Bestätigen" ]
            ]
    ]

let otpBox (model: OtpModel) dispatch =
    div [ Class "column is-6" ] [
        div [
            Class "flat-card profile-info-card is-auto"
        ] [
            div [ Class "card-title" ] [
                h3 [] [ b [] [ str "Account absichern" ] ]
            ]
            div [ Class "card-body" ] [
                yield
                    match model.Status with
                    | OtpDeactivated -> optBoxDisabeld dispatch
                    | OtpActivating -> optBoxActivating model dispatch
                    | OtpActivated should -> optBoxEnabeld should dispatch
            ]
        ]
    ]

let submitPasswordOnEnter dispatch =
    OnKeyDown(fun (e) ->
        if (e.key = "Enter") then
            SubmitPassword |> Password |> dispatch)
let passwordChangeForm model dispatch = [
    yield
        div [ Class "info-block" ] [
            validatedPasswordBoxWithoutErrorMsg
                "is-tooltip-right"
                [ submitPasswordOnEnter dispatch ]
                (dispatch << (Password << OldPasswordChanged))
                "Aktuelles Passwort"
                "OldPassword"
                ""
                model.OldPassword
        ]
    yield
        div [ Class "info-block" ] [
            validatedPasswordBoxWithoutErrorMsg
                "is-tooltip-right"
                [ submitPasswordOnEnter dispatch ]
                (dispatch << (Password << NewPassword1Changed))
                "Neues Passwort"
                "NewPassword1"
                ""
                model.NewPassword1
        ]
    yield
        div [ Class "info-block" ] [
            validatedPasswordBoxWithoutErrorMsg
                "is-tooltip-right"
                [ submitPasswordOnEnter dispatch ]
                (dispatch << (Password << NewPassword2Changed))
                "Neues Passwort (Wiederholung)"
                "NewPassword2"
                ""
                model.NewPassword2
        ]

    if model.ErrorMsg.IsSome then
        yield div [ Class "is-pulled-left error-msg" ] [ str model.ErrorMsg.Value ]

    yield
        p [ Class "is-pulled-right" ] [
            button [
                Type "submit"
                Class("bfsbutton")
                Disabled(not (model.CanSubmit))
                Props.OnClick(fun _ -> SubmitPassword |> Password |> dispatch)
            ] [ str "Ändern" ]
        ]
]

let passwordBox model dispatch =
    div [ Class "column is-6" ] [
        div [
            Class "flat-card profile-info-card is-auto with-overflowing-tooltips"
        ] [
            div [ Class "card-title" ] [
                h3 [] [ b [] [ str "Passwort ändern" ] ]
            ]
            div [ Class "card-body paragraphs" ] [
                match model.OkMsg with
                | None -> yield! passwordChangeForm model dispatch
                | Some msg -> yield str msg
            ]
        ]
    ]

let helloBox (model: Model) =
    div [ Class "column is-12" ] [
        div [ Class "flat-card is-auto" ] [
            div [ Class "" ] [
                yield
                    match model.UserInfo with
                    | Empty -> div [] []
                    | Loading -> spinner
                    | LoadError s -> errorMsg "Fehler beim Laden!"
                    | Body b ->
                        section [ Class "hero" ] [
                            div [ Class "hero-body" ] [
                                yield h1 [ Class "title" ] [ str model.Name ]
                                if (b.Kundennummer.IsSome) then
                                    yield
                                        p [ Class "subtitle" ] [
                                            str ("Kundennummer: " + b.Kundennummer.Value.ToString())
                                        ]
                            ]
                        ]
            ]
        ]
    ]

let notificationsBox (model: NotificationsModel) (email: string) dispatch =
    div [ Class "column is-12" ] [
        div [
            Class "flat-card is-auto  profile-info-card"
        ] [
            div [ Class "card-title" ] [
                h3 [] [ b [] [ str "Benachrichtigungen" ] ]
            ]

            div [ Class "card-body paragraphs" ] [
                if String.IsNullOrWhiteSpace email then
                    p [
                        Style [
                            FontSize "1rem"
                            FontWeight "bold"
                            Margin "0.8rem"
                        ]
                    ] [
                        icon "fas fa-exclamation-triangle"
                        str
                            "Sie haben noch keine E-Mail-Adresse hinterlegt. Bitte hinterlegen Sie eine E-Mail-Adresse, um Benachrichtigungen zu erhalten."
                    ]
                else
                    p [ Style [ Margin "0.8rem" ] ] [
                        str
                            "Ab sofort können Sie sich per E-Mail von uns benachrichtigen lassen. Wählen Sie einfach selbst aus bei welcher Information Sie eine Benachrichtigung erhalten möchten."
                    ]
                table [
                    Class "table table-striped responsive-table "
                ] [
                    tr [] [
                        td [] [
                            str "Neue Auswertungen und Dokumente"
                            br []
                            span [
                                Style [ FontSize "0.8rem"; Color "gray" ]
                            ] [
                                str
                                    "Sie erhalten eine E-Mail-Benachrichtigung, in der alle neuen Dokumente, Absetzungen und Auswertungen angegeben werden."
                            ]
                        ]
                        td [] [
                            checkbox
                                model.PostImPortal
                                (fun b -> b |> PostImPortalChanged |> Notification |> dispatch)
                                "1"
                        ]
                    ]
                    if model.PreisInfosAnzeigen then
                        tr [] [
                            td [] [
                                str "Preisinformationen erhalten"
                                br []
                                span [
                                    Style [ FontSize "0.8rem"; Color "gray" ]
                                ] [
                                    str
                                        "Sobald wir Informationen über neue Preisverhandlungen erhalten, werden Sie direkt per E-Mail über diese informiert."
                                ]
                            ]
                            td [] [
                                checkbox
                                    model.PreisInfos
                                    (fun b -> b |> PreisInfosChanged |> Notification |> dispatch)
                                    "2"
                            ]
                        ]
                    tr [] [
                        td [] [
                            str "Belegeingang"
                            br []
                            span [
                                Style [ FontSize "0.8rem"; Color "gray" ]
                            ] [
                                str
                                    "Sie möchten wissen wann Ihre Sendung bei uns eingegangen ist? Sobald der Sendungseingang erfasst wurde, erhalten Sie eine E-Mail-Benachrichtigung. "
                            ]
                        ]
                        td [] [
                            checkbox
                                model.Belegeingang
                                (fun b -> b |> BelegeingangChanged |> Notification |> dispatch)
                                "3"
                        ]
                    ]
                    tr [] [
                        td [] [
                            str "Belegprüfung abgeschlossen"
                            br []
                            span [
                                Style [ FontSize "0.8rem"; Color "gray" ]
                            ] [
                                str
                                    "Sobald unsere Belegprüfung die Prüfung Ihrer Sendung abgeschlossen hat, erhalten Sie eine E-Mail-Benachrichtigung. Die Sendung geht anschließend in die weitere Verarbeitung bis zum Versand an den Kostenträger."
                            ]
                        ]
                        td [] [
                            checkbox
                                model.BelegpruefungAbgeschlossen
                                (fun b ->
                                    b
                                    |> BelegpruefungAbgeschlossenChanged
                                    |> Notification
                                    |> dispatch)
                                "4"
                        ]
                    ]
                    tr [] [
                        td [] [
                            str "Auszahlung getätigt"
                            br []
                            span [
                                Style [ FontSize "0.8rem"; Color "gray" ]
                            ] [
                                str
                                    "Sie möchten direkt eine Benachrichtigung erhalten, wenn die Auszahlung getätigt wurde? Wurde Ihre Sendung geprüft, wird nach der vertraglich vereinbarten Frist die angekaufte Forderungssumme an Sie ausgezahlt. Sobald die Auszahlung getätigt wurde, erhalten Sie eine E-Mail-Benachrichtigung."
                            ]
                        ]
                        td [] [
                            checkbox
                                model.AuszahlungGetaetigt
                                (fun b -> b |> AuszahlungGetaetigtChanged |> Notification |> dispatch)
                                "5"
                        ]
                    ]
                    tr [] [
                        td [] [
                            str "Ticket beantwortet"
                            br []
                            span [
                                Style [ FontSize "0.8rem"; Color "gray" ]
                            ] [
                                str
                                    "Sobald unser Kundenservice Ihr Ticket beantwortet hat, werden Sie direkt per E-Mail über die Antwort informiert."
                            ]
                        ]
                        td [] [
                            checkbox
                                model.TicketAntwort
                                (fun b -> b |> TicketAntwortChanged |> Notification |> dispatch)
                                "5"
                        ]
                    ]
                ]


                let isLoading = (if model.Sending then " is-loading" else "")
                p [ Class "is-pulled-right" ] [
                    button [
                        Type "submit"
                        Class("bfsbutton" + isLoading)
                        Disabled(model.Sending || String.IsNullOrWhiteSpace email)
                        Props.OnClick(fun _ -> SubmitNotifications |> Notification |> dispatch)
                    ] [ str "Speichern" ]
                ]
            ]
        ]
    ]

let view model dispatch navigateTo =
    div [
        Class "columns is-account-grid is-multiline"
    ] [
        helloBox model

        if model.Password.IsSome then
            passwordBox model.Password.Value dispatch

        if model.Otp.IsSome then
            otpBox model.Otp.Value dispatch

        if model.Notifications.IsSome then
            notificationsBox model.Notifications.Value model.Email dispatch
    ]
