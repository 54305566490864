module Faq.Types

open Bfs.Web.Data.Service.Contracts.Kundenportal.Faq
open Types

type Page = | Faq

type Msg =
    | LoadKategorien
    | KategorienLoaded of Result<string list, exn>

    | LoadFaq of string
    | FaqLoaded of Result<Faq list, exn>

    | SearchForFaq of string

type FaqDisplayMode =
    | Unknown
    | Kategorie of string
    | Search of string

type Model =
    {
        Kategorien: Remote<string list>
        Faqs: Remote<Faq list>

        SelectedKategorie: FaqDisplayMode
    }
    static member Init = {
        SelectedKategorie = Unknown
        Faqs = Loading
        Kategorien = Loading
    }
