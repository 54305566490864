module LetzteSendung.View

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard.Lists
open Bfs.Web.Kundenportal.WebParts.User.DashBoardPage
open Fable.React

open Types
open Fable.React.Props

open ViewParts

open Bfs.Web.Shared.Formating


let mobileTable (data: SendungBelegeDetails list) =
    match data.IsEmpty with
    | true ->
        div [ Class "has-text-centered is-hidden-desktop" ]
            [ str "Hier werden Sie zukünftig Ihre aktuelle Sendung mit den angekauften Belegen sehen." ]
    | false ->
        div [
            Class "dta-list is-hidden-desktop"
        ] (
            data
            |> List.sortBy (fun row -> row.KlientPatient, row.LeistungsGrundlage)
            |> (List.map (fun row ->
            div [ Class "dta-item" ] [
                table
                    [  ]
                    [
                        thead [] [
                            tr [] [
                                td [ Class "pr-4" ] [ str "RechnungsNr" ]
                                td [] [ str row.RechnungsNrExtern ]
                            ]
                            tr [] [
                                td [ Class "pr-4" ] [ str "Patient/Klient" ]
                                td [] [ str row.KlientPatient ]
                            ]
                            tr [] [
                                td [ Class "pr-4" ] [ str "Leistungsgrundlage" ]
                                td [] [ str row.LeistungsGrundlage ]
                            ]
                            tr [] [
                                td [ Class "pr-4" ] [ str "Rechnungsbetrag" ]
                                td [] [ str (row.Rechnungsbetrag |> asMoney) ]
                            ]
                            tr [] [
                                td [ Class "pr-4" ] [ str "Meldung" ]
                                td [] [ str row.Meldung ]
                            ]
                        ]
                    ]
            ]))
    )

let desktopTable (data: SendungBelegeDetails list) =
    table [
        Class "table table-striped responsive-table is-hidden-touch is-bfscolored"
    ] [
        thead [] [
            tr [] [
                th [] [ str "RechnungsNr" ]
                th [] [ str "Patient/Klient" ]
                th [] [ str "Leistungsgrundlage" ]
                th [] [ str "Rechnungsbetrag" ]
                th [] [ str "Meldung" ]
            ]
        ]
        tbody [] (
            match data.IsEmpty with
            | true ->
                [
                    tr []
                       [
                           td [ ColSpan 5
                                Class "has-text-centered" ]
                              [ str "Hier werden Sie zukünftig Ihre aktuelle Sendung mit den angekauften Belegen sehen." ]
                       ]
                ]
            | false ->
                data
                |> List.sortBy (fun row -> row.KlientPatient, row.LeistungsGrundlage)
                |> List.map (fun row ->
                    tr [
                        Key row.RechnungsNrExtern
                    ] [
                        td [ ] [ str row.RechnungsNrExtern ]
                        td [ ] [ str row.KlientPatient ]
                        td [ ] [ str row.LeistungsGrundlage ]
                        td [ ] [ str (row.Rechnungsbetrag |> asMoney) ]
                        td [ ] [ str row.Meldung ]
                    ]
                )
        )
    ]

let private SendungList =
    FunctionComponent.Of(fun (sendungsNr: int, data: SendungBelegeDetails list) ->
        let showTable = Hooks.useState(false)
        div [ Class "mb-4" ]
            [
                a [
                    OnClick (fun _ -> showTable.update (not showTable.current))
                ]
                  [
                    (match showTable.current with
                     | true -> linkicon "fas fa-chevron-down"
                     | false -> linkicon "fas fa-chevron-right")

                    b [ Class "is-size-6" ] [str $"Sendung {sendungsNr}"]
                  ]
                if showTable.current then
                    div [ Class "ml-6 mr-4 mt-4 mb-6" ]
                        [
                            mobileTable data
                            desktopTable data
                        ]
            ]
    )

let view (model: Model) _ (navigateTo: AnyPage -> unit) =

    match model.Loading with
    | true ->
        spinner
    | false ->
        div [ Class "flat-card profile-info-card is-auto overflow-initial" ]
            [
                div [ Class "card-title" ] [
                        a [
                            OnClick(fun _ -> navigateTo DashboardPage.Dashboard)
                        ] [
                            i [ Class "fas fa-angle-left mr-2" ] []
                            str "Zurück zum Dashboard"
                        ]
                    ]
                div [ Class "card-body" ]
                    (match model.Data.IsEmpty with
                     | true -> [ str "Wir freuen uns auf Ihre erste Sendung!" ]
                     | false ->
                        model.Data
                                |> List.sortByDescending (fun x -> x.SendungNr)
                                |> List.map (fun data -> SendungList (data.SendungNr, data.Rechnungen)))
            ]
