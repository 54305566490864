namespace News

open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.News


module Http =
    let getNewsPage (page) = fetchAs<Paginated<NewsListItem>> (sprintf "/api/news?page=%i" page)
    let getNews (id) = fetchAs<News> (sprintf "/api/news/%s" id)
    let imageUrl (id) = (sprintf "/api/news/%s/image" id)
    let markRead (id) = postEmpty (sprintf "/api/news/%s/isshown" id)


module State =
    let init page user (lastModel: News.Types.Model option) =
        match page with
        | AllNews pagenumber ->
            let initialModel =
                AllNewsModel
                    {
                        PageNumber = Some pagenumber
                        NewsList = Loading
                    }
            (initialModel, Cmd.ofMsg (LoadNewsPage pagenumber))
        | NewsItem id ->
            let backToPage =
                match lastModel with
                | Some(AllNewsModel m) when m.PageNumber.IsSome -> m.PageNumber.Value
                | _ -> 1

            let initialModel =
                NewsItemModel
                    {
                        Id = id
                        NewsItem = Loading
                        BackToPage = backToPage
                    }
            (initialModel, Cmd.ofMsg (LoadNews id))

    let update msg model =
        match msg, model with
        | LoadNewsPage page, AllNewsModel submodel ->
            let loadNewsPageCmd = NewsPageLoaded |> request Http.getNewsPage page
            ({ submodel with NewsList = Loading } |> AllNewsModel, loadNewsPageCmd)

        | LoadNews id, model ->
            let loadNewsCmd = NewsLoaded |> request Http.getNews id

            let backToPage =
                match model with
                | AllNewsModel m when m.PageNumber.IsSome -> m.PageNumber.Value
                | NewsItemModel m -> m.BackToPage
                | _ -> 1
            (NewsItemModel
                {
                    Id = id
                    NewsItem = Loading
                    BackToPage = backToPage
                },
             loadNewsCmd)

        | NewsPageLoaded x, AllNewsModel submodel -> ({ submodel with NewsList = Loaded x } |> AllNewsModel, Cmd.none)

        | NewsLoaded x, NewsItemModel submodel ->
            let cmd =
                match x with
                | Ok _ -> Cmd.ofMsg (MarkNewsRead submodel.Id)
                | _ -> Cmd.none
            ({ submodel with NewsItem = Loaded x } |> NewsItemModel, cmd)

        | MarkNewsRead id, _ ->
            let cmd = MarkNewsReadDone |> request Http.markRead id
            (model, cmd)
        | MarkNewsReadDone _, _ -> (model, Cmd.none)

        | _, _ ->
            Logger.log "NewsMessage konnt nicht dispatched werden"
            Logger.log (model, msg)
            (model, Cmd.none)
