module MenuDef

open Types

open Fable.React

type menuItemDefinition =
    {
        Title: string
        Icon: ReactElement option
        Role: string
        Page: AnyPage
        Subitems: menuItemDefinition list
        IsActive: Model -> bool
    }
    member def.isVisible roles =
        match def.Role, def.Subitems with
        | "", [] -> true
        | "", subitems -> subitems |> List.exists (fun s -> s.isVisible roles)
        | role, _ -> roles |> List.contains role

let inline menuItem4<'Model1, 'Model2, 'Model3> title icon role (page: AnyPage) sub = {
    Title = title
    Icon = icon
    Role = role
    Page = page
    Subitems = sub
    IsActive =
        (fun m ->
            (m.PageModel :? 'Model1)
            || (m.PageModel :? 'Model2)
            || (m.PageModel :? 'Model3))
}

let inline menuItem3<'Model1, 'Model2, 'Model3> title role (page: AnyPage) sub =
    menuItem4<'Model1, 'Model2, 'Model3> title None role page sub

let inline menuItem2<'Model1, 'Model2> title role (page: AnyPage) sub =
    menuItem3<'Model1, 'Model2, 'Model2> title role page sub

let inline menuItem<'Model1> title role (page: AnyPage) sub = menuItem3<'Model1, 'Model1, 'Model1> title role page sub

let inline menuItemWithIcon<'Model1> title iconClass role (page: AnyPage) sub =
    menuItem4<'Model1, 'Model1, 'Model1> title (Some iconClass) role page sub
let inline menuItemWithIcon2<'Model1, 'Model2> title iconClass role (page: AnyPage) sub =
    menuItem4<'Model1, 'Model2, 'Model1> title (Some iconClass) role page sub
let inline menuItemWithIcon3<'Model1, 'Model2, 'Model3> title iconClass role (page: AnyPage) sub =
    menuItem4<'Model1, 'Model2, 'Model3> title (Some iconClass) role page sub
