module Dashboard.AnstehendeZahlungen

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard
open Bfs.Web.Kundenportal.WebParts.User.Shared.Tile
open Fable.React
open Fable.React.Props
open Elmish

open Types
open Http
open ViewParts

open Dashboard.Shared

open Bfs.Web.Shared.Formating

type Model = {
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
    Loading: bool
    Data: AnstehendeZahlung
    SavingToggle: bool
}

type Msg =
    | LoadData
    | LoadDataCompleted of Result<AnstehendeZahlung, exn>
    | ToggleBenachrichtigung
    | NotificationToggled of Result<Fetch.Types.Response, exn>
    | ShowHint
    | NavigateToList

module Http =
    let loadAnstehendeZahlungen () = fetchAs<AnstehendeZahlung> "/api/dashboard/anstehende-zahlung"
    let setNotificationForAnstehendeZahlung enabled =
        let payload = {
            Enabled = enabled
        }
        postRecord<ToggleNotificationRequest> "/api/dashboard/set-notification-for-anstehende-zahlung" payload
module State =
    let init (props: DashboardProps) =
        {
            GlobalDispatch = props.GlobalDispatch
            NavigateTo = props.NavigateTo
            Loading = true
            Data = {
                Betrag = None
                Datum = None
                Benachrichtigung = false
            }
            SavingToggle = true
        }, Cmd.ofMsg LoadData

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadData ->
            let cmd =
                LoadDataCompleted
                |> request Http.loadAnstehendeZahlungen ()
            {model with Loading = false
                        SavingToggle = true}, cmd
        | LoadDataCompleted (Ok data) ->
            { model with Loading = false
                         Data = data
                         SavingToggle = false }, Cmd.none
        | LoadDataCompleted (Error _) ->
            let msg = GlobalMessageBox.Error "Die Daten für Ihre anstehenden Zahlungen können zur Zeit leider nicht geladen werden." |> ShowMessageBox
            model.GlobalDispatch msg
            { model with Loading = false }, Cmd.none
        | ToggleBenachrichtigung ->
            let newValue = not model.Data.Benachrichtigung
            let cmd =
                NotificationToggled
                |> request Http.setNotificationForAnstehendeZahlung newValue
            { model with SavingToggle = false
                         Data = { model.Data with Benachrichtigung = newValue}}, cmd
        | NotificationToggled (Ok _) ->
            { model with SavingToggle = false }, Cmd.none
        | NotificationToggled (Error _) ->
            let msg = GlobalMessageBox.Error "Ihre neuen Benachrichtigungseinstellungen können zur Zeit leider nicht gespeichert werden." |> ShowMessageBox
            model.GlobalDispatch msg
            { model with SavingToggle = false
                         Data = { model.Data with Benachrichtigung = not model.Data.Benachrichtigung}}, Cmd.none
        | ShowHint ->
            let msg = GlobalMessageBox.HtmlInfoWithTitle
                          "Anstehende Zahlungen"
                          (div [ ]
                               [
                                  span []
                                       [ str "Bitte beachten Sie, dass dies der voraussichtliche Zahlbetrag ist. Verrechnungen, Vorzugsgebühren o.ä. können ggf. noch in Abzug gebracht werden. Unter " ]
                                  a [ Href "/#verrechnungen"
                                      OnClick (fun _ -> ClearMessageBox |> model.GlobalDispatch) ]
                                    [ str "Verrechnungen" ]
                                  span []
                                       [ str " können Sie diese hier im Portal prüfen." ]
                               ])
                      |> ShowMessageBox
            model.GlobalDispatch msg
            model, Cmd.none
        | NavigateToList ->
            model.NavigateTo Zahlungen.Types.Page.List
            model, Cmd.none

let View =
    FunctionComponent.Of(fun (props: DashboardProps) ->
        let model, dispatch = React.useElmish ((State.init props), State.update)
        let title = str "Nächste anstehende Zahlung"
        let titleAction =
            Some (a [ OnClick (fun _ -> NavigateToList |> dispatch) ]
              [ navigationArrow ])
        let footer =
            match model.Loading with
            | true -> None
            | false -> Some (notificationBox "Benachrichtigung" model.Data.Benachrichtigung model.SavingToggle (fun _ -> ToggleBenachrichtigung |> dispatch) "Anstehende-Zahlungen-Toggle")

        let icon =
            Some (img [
                    Class "dashboard-icon"
                    Src (match model.Data.Datum, model.Data.Betrag with
                         | Some _, Some _ -> "dashboard/zahlung.svg"
                         | _ -> "dashboard/zahlung_waiting_96x96.svg")
                ])

        let body =
            match model.Loading with
            | true -> spinner
            | false ->
                match model.Data.Datum, model.Data.Betrag with
                | Some datum, Some betrag ->
                   [
                       div []
                           [
                               b [ Class "is-size-4" ]
                                 [ str (betrag |> asMoney) ]
                               a [ Class "is-size-4"
                                   OnClick (fun _ -> ShowHint |> dispatch) ]
                                 [ str "*" ]
                           ]
                       div [ Class "is-size-7 has-text-grey-light" ]
                           [ str $"am {datum |> asString}" ]
                   ] |> ofList
                | _ -> str "Keine anstehenden Zahlungen."

        bfsTile
            title
            titleAction
            icon
            body
            footer
    )
