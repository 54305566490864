module Home.View

open Fable.React
open Fulma

open Types
open Fable.React.Props

let view (model: Model) dispatch (navigateTo: AnyPage -> unit) =

    [
        div [ Class "homepage" ] [
             div [ Class "columns help-center" ] [
                 Help.View.wideTicketBox navigateTo
                 Help.View.wideVideoBox navigateTo
             ]

             Quickfinder.View.quickfinderBox navigateTo (GlobalMsg >> dispatch) "" false false

             News.View.newsListView model.NewsList navigateTo false

             div [ Class "view-more" ] [
                 a [
                     OnClick(fun _ -> navigateTo (News.Types.AllNews 1))
                 ] [ str "weitere News" ]
             ]
        ]
    ]
    |> ofList
