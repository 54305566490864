namespace Faq

open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts.Kundenportal.Faq

module Http =
    let getKategorien () = fetchAs<string list> "/api/faq/kategorien"
    let getFaqs kategorie = fetchAs<Faq list> (sprintf "/api/faq/kategorien/%s" kategorie)
    let searchFaqs query = fetchAs<Faq list> (sprintf "/api/faq/search?q=%s" query)

module State =
    let init page user lastModel =
        match page with
        | Faq -> (Model.Init, Cmd.ofMsg LoadKategorien)

    let update msg model =
        match msg with

        | LoadKategorien ->
            let cmd = KategorienLoaded |> request Http.getKategorien ()
            ({ model with Kategorien = Loading }, cmd)
        | KategorienLoaded x ->
            let cmd =
                match x with
                | Ok [] -> Cmd.none
                | Ok(first :: _) -> Cmd.ofMsg (LoadFaq first)
                | _ -> Cmd.none
            ({ model with Kategorien = Loaded x }, cmd)

        | LoadFaq kategorie ->
            let cmd = FaqLoaded |> request Http.getFaqs kategorie
            ({ model with
                Faqs = Loading
                SelectedKategorie = Kategorie kategorie
             },
             cmd)
        | FaqLoaded x ->
            ({ model with
                Faqs = Loaded x
             },
             Cmd.none)

        | SearchForFaq query ->
            let cmd = FaqLoaded |> request Http.searchFaqs query
            ({ model with
                Faqs = Loading
                SelectedKategorie = Search query
             },
             cmd)
