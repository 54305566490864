﻿module AdminNotification.Types

open Bfs.Web.Data.Service.Contracts
open Fetch.Types
open Types
open Bfs.Web.Data.Service.Contracts.Kundenportal.Admin
open Validation

type Page =
    | NofiticationList of int
    | AddNotification

type Msg =
    | LoadNotifications of int
    | NotificationsLoaded of Result<Paginated<NotificationListItem>, exn>
    | NewNotification
    | TitleChanged of string
    | MessageChanged of string
    | KundenportalBenutzerChanged of int
    | KundenportalBenutzerAllChanged of bool
    | Save
    | Saved of Result<Response, exn>
    | LoadKundenWithNotificationEnabled
    | KundenWithNotificationEnabledLoaded of Result<KundeWithNotificationEnabled list, exn>
    | GlobalMsg of GlobalMsg
type Model =
    | ListModel of NotificationList
    | ItemModel of NewNotificationItem

and NotificationList =
    {
        PageNumber: int
        Notifications: Remote<Paginated<NotificationListItem>>
    }
    static member Init pagenumber = {
        PageNumber = pagenumber
        Notifications = Loading
    }

and NewNotificationItem =
    {
        Id: string option // guid?
        Title: FormField
        Message: FormField
        KundenportalBenutzerIds: int list // who should receive a notification
        AllNotificationKundenportalBenutzer: KundeWithNotificationEnabled list
        BackToPage: int
        IsSending: bool
    }
    static member Init backToPage = {
        Id = None
        Title = FormField.Init(Some(MandatoryWithMaxLength 200))
        Message = FormField.Init(Some Mandatory)
        KundenportalBenutzerIds = []
        BackToPage = backToPage
        IsSending = false
        AllNotificationKundenportalBenutzer = []
    }

    static member Reset model =
        { model with
            Title = FormField.Init(Some(MandatoryWithMaxLength 200))
            Message = FormField.Init(Some Mandatory)
            KundenportalBenutzerIds = []
        }
        : NewNotificationItem

    static member StartSending model = { model with IsSending = true }

    static member StopSending model = { model with IsSending = false }

    static member SelectAllKundenportalBenutzer(model: NewNotificationItem) =
        { model with
            KundenportalBenutzerIds =
                model.AllNotificationKundenportalBenutzer
                |> List.map (fun a -> a.KundenportalBenutzerId)
        }
    static member IsSelected (model: NewNotificationItem) id = model.KundenportalBenutzerIds |> List.contains id

    static member AreAllSelected(model: NewNotificationItem) =
        model.KundenportalBenutzerIds.Length = model.AllNotificationKundenportalBenutzer.Length

    member x.CanSubmit =
        not x.IsSending
        && x.Title.IsValid
        && x.Message.IsValid
        && x.KundenportalBenutzerIds.Length > 0
