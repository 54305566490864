module AdminNews.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "Administration: News"

    BuildUrl =
        function
        | Page.NewsList 1 -> [ Urls.admin; Urls.news ]
        | Page.NewsList p -> [
            Urls.admin
            Urls.news
            Urls.pageSeperator
            p.ToString()
          ]
        | Page.NewsEdit id -> [ Urls.admin; Urls.news; id ]
        | Page.NewsNew -> [ Urls.admin; Urls.news; Urls.newId ]

    ParseUrl =
        function
        | [ Urls.admin; Urls.news ] -> Some(Page.NewsList 1)
        | [ Urls.admin; Urls.news; Urls.pageSeperator; page ] -> Some(Page.NewsList(System.Int32.Parse(page)))
        | [ Urls.admin; Urls.news; Urls.newId ] -> Some(Page.NewsNew)
        | [ Urls.admin; Urls.news; id ] -> Some(Page.NewsEdit id)
        | _ -> None
}
