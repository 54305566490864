﻿namespace State

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard.Lists
open Elmish
open DokumentenDepot.Types
open Http
open Types

module Http =
    let getData _ = fetchAs<DokumentenDepot list> "/api/dashboard/dokumenten-depot-list"

module State =
    let init _ _ _ =
        {
            Loading = true
            Data = List.Empty
        },
        Cmd.ofMsg LoadData

    let update (msg: DokumentenDepot.Types.Msg) (model: DokumentenDepot.Types.Model) : DokumentenDepot.Types.Model * Cmd<DokumentenDepot.Types.Msg> =
        match msg with
        | LoadData ->
            { model with Loading = true
                         Data = [ ] },
            (DataLoaded |> request Http.getData ())
        | DataLoaded (Ok data) ->
            {
                Loading = false
                Data = data
            },
            Cmd.none
        | DataLoaded (Error ex) ->
            {
                Loading = false
                Data = [  ]
            },
            Cmd.ofMsg (
                DokumentenDepot.Types.Msg.GlobalMsg(
                    ShowMessageBox(
                        GlobalMessageBox.Error "Die Daten können zur Zeit leider nicht geladen werden."
                    )
                )
            )
        | DokumentenDepot.Types.Msg.GlobalMsg(_) ->
            model, Cmd.none
