namespace Admin

open Elmish
open Types
open Http


module Http =
    let getServerVersion () = getText "/api/info/version"


module State =
    let init page user lastModel =
        match page with
        | VersionInfo ->
            let initialModel = Model.Init
            let cmd = Cmd.ofMsg GetServerVersion
            (initialModel, cmd)

    let update msg model =
        match msg with
        | GetServerVersion ->
            let cmd = ServerVersionLoaded |> request Http.getServerVersion ()
            (model, cmd)

        | ServerVersionLoaded x -> ({ model with ServerVersion = Loaded x }, Cmd.none)
