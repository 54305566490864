module AdminText.View

open Fable.React
open Fable.React.Props
open Types
open ViewParts
open Fulma


// EditView
let maintenanceActiveSwitch (model: TextEditModel) dispatch =
    div [ Class "field" ] [
        label [ Class "label" ] [ str "Wartungsmodus aktiv" ]
        switch model.MaintenanceActive (fun b -> dispatch (MaintenanceActiveChanged b)) ""
    ]

let editBox (model: TextEditModel) dispatch navigateTo =
    let isLoading = (if model.Sending then " is-loading" else "")

    Column.column [ Column.Width(Screen.All, Column.Is6) ] [
        div [
            Class "flat-card profile-info-card is-auto"
        ] [
            div [ Class "card-title" ] [
                h3 [] [
                    b [] [
                        str (sprintf "%s bearbeiten" model.Key)
                    ]
                ]
            ]
            div [ Class "card-body" ] [
                if (model.Key = Bfs.Web.Data.Service.Contracts.TextTableKeys.WartungsMeldung) then
                    div [ Class "columns" ] [
                        div [ Class "column is-12" ] [
                            maintenanceActiveSwitch model dispatch
                        ]
                    ]

                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block" ] [
                            validatedTextarea (dispatch << ContentChanged) "Text" "Text" "" "is-big" model.Content
                        ]
                    ]
                ]

                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block is-pulled-right" ] [
                            button [
                                Class("button is-link" + isLoading)
                                Disabled(not (model.CanSubmit))
                                Props.OnClick(fun _ -> dispatch (Save))
                            ] [ str "Speichern" ]
                        ]
                        div [ Class "info-block" ] [
                            button [
                                Class("button is-danger is-link")
                                Props.OnClick(fun _ -> navigateTo Page.TextList)
                            ] [
                                str (if model.IsDirty then "Abbrechen" else "Zurück")
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let previewBox (model: TextEditModel) =
    Column.column [ Column.Width(Screen.All, Column.Is6) ] [
        div [
            Class "flat-card profile-info-card is-auto"
        ] [
            [
                div [ Class "card-title" ] [ h3 [] [ b [] [ str "Vorschau" ] ] ]
                div [ Class "card-body" ] [
                    div [
                        Class "content"
                        DangerouslySetInnerHTML { __html = model.TextAsHtml }
                    ] []
                ]
            ]
            |> ofList
        ]
    ]

let editPage (model: TextEditModel) dispatch navigateTo =
    div [ Class "columns is-multiline" ] [
        editBox model dispatch navigateTo
        previewBox model
    ]



// ListView
let tableRow navigateTo textKey =
    tr [ Class "" ] [
        td [] [ str textKey ]
        td [] [
            a [
                OnClick(fun _ -> navigateTo (Page.TextEdit textKey))
                Title "bearbeiten"
            ] [ icon "fas fa-edit" ]
        ]
    ]

let table textInfoList navigateTo =
    table [
        Class "table table-striped responsive-table"
    ] [
        thead [] [
            tr [] [
                th [] [ str "Text-Schlüssel" ]
                th [] [ str "Aktionen" ]
            ]
        ]
        tbody [] (textInfoList |> List.map (tableRow navigateTo))
    ]

let listPage (model: TextListModel) navigateTo =
    div [ Class "columns is-multiline" ] [
        div [ Class "column is-12" ] [
            yield
                div [
                    Class "flat-card profile-info-card is-auto"
                ] [
                    div [ Class "card-body" ] [
                        yield
                            match model.TextInfoList with
                            | Empty -> div [] []
                            | Loading -> spinner
                            | LoadError _ -> errorMsg "Fehler beim Laden der Texte!"
                            | Body x when x = [] -> div [] [ str "Keine Texte vorhanden" ]
                            | Body x -> table x navigateTo
                    ]
                ]
        ]
    ]

// Auswahl, welche View gerendert wird
let view (model: Model) dispatch (navigateTo: AnyPage -> unit) =
    match model with
    | ListModel m -> listPage m navigateTo
    | ItemModel m -> editPage m dispatch navigateTo
