namespace Login

open Fable.React
open Fable.React.Props
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.Account
open Http
open Elmish
open Types
open Validation
open Kundenportal.Types


module Http =
    let postLogin (name, password, otp, akzeptiert) =
        postRecord2<LoginData, Account.LoginResult>
            "/api/account/login"
            ({
                Username = name
                Password = password
                OneTimePassword = otp
                NutzungsbedingungenAkzeptiert = akzeptiert
            })
    let postLogoff () = postEmpty "/api/account/logoff"
    let checkLogin () = fetchAs<UserStatus> "/api/account/user"


module State =
    let init page user lastModel =
        match page with
        | Login gotoAfter -> (Model.Init(gotoAfter), Cmd.ofMsg CheckBrowser)

    let update msg (model: Model) =
        match msg with
        | CheckBrowser ->
            let cmd =
                if supportsES2015 then
                    Cmd.ofMsg CheckLogin
                else
                    Cmd.batch [
                        Cmd.ofMsg (
                            GlobalMsg(
                                ShowMessageBox(
                                    GlobalMessageBox.Errors [
                                        "Die von Ihnen verwendete Browser-Version wird nicht unterstützt."
                                        "Um alle Funktionen des BFS Kundenportals nutzen zu können, aktualisieren Sie bitte auf eine neuere Browser-Version. Das BFS Kundenportal gewährleistet einen vollen Funktionsumfang ab den Versionen: Mozilla Firefox 54, Google Chrome 51, Safari 10, Opera 38"
                                    ]
                                )
                            )
                        )
                        Cmd.ofMsg CheckLogin
                    ] // good luck
            (model, cmd)

        | CheckLogin ->
            let cmd = LoginChecked |> request Http.checkLogin ()
            (model, cmd)
        | LoginChecked(Ok user) ->
            let sessiondata =
                Map [
                    SessionDataKeys.UserId, user.Id
                    SessionDataKeys.FirstName, user.FirstName
                    SessionDataKeys.LastName, user.LastName
                    SessionDataKeys.SprinklrChatHash, user.SprinklrChatHash
                ]
            let sessiondata =
                match user.Kundennummer with
                | Some k ->
                    sessiondata
                    |> Map.add SessionDataKeys.Kundennummer (string k)
                | None -> sessiondata

            let usersession = {
                Name = user.Name
                Email = user.Email
                Roles = user.Roles
                IsNewCustomer = user.IsNewCustomer
                SessionData = sessiondata
            }

            (model, Cmd.ofMsg (GlobalMsg(LoginValidated(usersession, model.GotoAfterLogin))))
        | LoginChecked(Error _) -> // wir sind wohl nicht eingeloggt, also loginform anzeigen
            ({ model with Loading = false }, Cmd.none)

        | NameChanged value ->
            ({ model with
                Name = update model.Name value
             },
             Cmd.none)
        | PasswordChanged value ->
            ({ model with
                Password = update model.Password value
             },
             Cmd.none)
        | OtpChanged value ->
            ({ model with
                Otp = update model.Otp value
             },
             Cmd.none)

        | Submit nutzungsbedingungenAkzeptiert ->
            if model.CanSubmit then
                let cmd =
                    Submitted
                    |> request
                        Http.postLogin
                        (model.Name.Value, model.Password.Value, model.Otp.Value, nutzungsbedingungenAkzeptiert)
                ({ model with Sending = true }, cmd)
            else
                (model, Cmd.none)

        | Submitted(Ok(Account.LoginOk)) -> (model, Cmd.ofMsg CheckLogin)
        | Submitted(Ok(Account.NutzungsbedingungenAkzeptieren html)) ->
            let cmd =
                {
                    Type = InfoBox
                    Title = "Nutzungsbedingungen"
                    BodyElement =
                        div [
                            Class "content"
                            DangerouslySetInnerHTML { __html = html }
                        ] []
                    Buttons = [
                        {
                            Caption = "Abbrechen"
                            Type = GlobalMessageBoxButtonType.OkButton
                            Message = None
                            ClosesDialog = true
                        }
                        {
                            Caption = "Drucken"
                            Type = GlobalMessageBoxButtonType.YesButton
                            Message = Some(PrintNutzungsbedingungen html :> AnyWebPartMsg)
                            ClosesDialog = false
                        }
                        {
                            Caption = "Akzeptieren"
                            Type = GlobalMessageBoxButtonType.CancelButton
                            Message = Some(NutzungsbedingungenAkzeptiert :> AnyWebPartMsg)
                            ClosesDialog = true
                        }
                    ]
                }
                |> ShowMessageBox
                |> GlobalMsg
                |> Cmd.ofMsg
            ({ model with
                Sending = false
                OtpRequired = false
             },
             cmd)

        | Submitted(Ok(Account.UserTemporaryLocked)) ->
            ({ model with
                Sending = false
                ErrorMsg =
                    Some
                        "Anmeldung fehlgeschlagen! Benutzername oder Passwort ist nicht korrekt, Ihr Zugang ist für 5 Minuten gesperrt. Bitte probieren Sie es anschließend erneut."
             },
             Cmd.none)

        | Submitted(Ok(Account.Unauthorized)) -> // every other error status code
            ({ model with
                Sending = false
                ErrorMsg =
                    Some(
                        if not model.OtpRequired then
                            "Ungültige Benutzerkennung und/oder Passwort."
                        else
                            "Das Einmalpasswort ist falsch."
                    )
             },
             Cmd.none)
        | Submitted(Ok(Account.OneTimePasswordMissing)) ->
            ({ model with
                OtpRequired = true
                Sending = false
                ErrorMsg = None
             },
             Cmd.none)
        | Submitted(Ok(Account.Wartung msg)) ->
            ({ model with
                Sending = false
                WartungMsg = Some msg
             },
             Cmd.none)
        | Submitted(Error _) -> // irgendein anderer fehler
            ({ model with
                Sending = false
                ErrorMsg = Some "Es ist ein Fehler aufgetreten."
             },
             Cmd.none)

        | NutzungsbedingungenAkzeptiert -> (model, (Submit(Some true) |> Cmd.ofMsg))

        | PrintNutzungsbedingungen s ->
            Preview.webPrint "BFS-Kundenportal Nutzungsbedingungen" s
            (model, Cmd.none)

        | GlobalMsg(_) -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!
