module Bfs.Web.Data.Service.Client.App.View

open System
open Elmish
open Elmish.Debug
open Elmish.React
#if DEBUG
open Elmish.HMR
#endif

open Browser

let urlSubscription _ : (SubId * Subscribe<Types.Msg>) list =
    let sub dispatch =
        let onChange _ =
            match MainDispatch.parseUrl window.location.hash with
            | None -> ()
            | Some page -> dispatch (Types.Msg.UrlUpdated page)

        // listen to manual hash changes or page refresh
        window.addEventListener ("hashchange", unbox onChange)
        {new IDisposable with member _.Dispose() = ()}
    [ ["url-changed"], sub ]

Http.Encoders <-
    (Bfs.Web.Data.Service.Contracts.Encoders.extra
     |> Bfs.Web.Data.Service.Contracts.KundenportalEncoders.addPortalExtra)
WebPart.Registry <- WebPartRegistry.AllParts

Program.mkProgram Kundenportal.MainDispatch.init Kundenportal.MainDispatch.update Kundenportal.View.view
|> Program.withSubscription urlSubscription
#if DEBUG
|> Program.withConsoleTrace
#endif
|> Program.withReactSynchronous "elmish-app"
#if DEBUG
|> Program.withDebugger
#endif
|> Program.run
