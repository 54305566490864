module AdminFaq.View

open System
open Fable.React

open Types
open Bfs.Web.Data.Service.Contracts.Kundenportal.Faq
open Fable.React.Props

open ViewParts
open Fulma


let tableRow dispatch (faq: AdminFaqListItem) =
    let aktiv = if faq.Aktiv then "" else "deaktiviert"

    tr [ Class aktiv ] [
        td [] [ str (sprintf "%i." faq.Position) ]
        td [] [ str faq.Frage ]
        td [] [
            if faq.SichtbarFuerPflege then
                span [
                    Class "tag is-small is-info is-light"
                ] [ str "Pflege" ]
            if faq.SichtbarFuerPflegeDtaPlus then
                span [
                    Class "tag is-small is-info is-light"
                ] [ str "DTA-Plus" ]
            if faq.SichtbarFuerTherapie then
                span [
                    Class "tag is-small is-info is-light"
                ] [ str "Therapie" ]
            if faq.SichtbarFuerJugendhilfe then
                span [
                    Class "tag is-small is-info is-light"
                ] [ str "Jugendhilfe" ]
        ]
        td [] [
            a [
                OnClick(fun _ -> faq.Id |> LoadFaq |> dispatch)
                Title "bearbeiten"
            ] [ icon "fas fa-edit" ]
            (if faq.Aktiv then
                 a [
                     OnClick(fun _ -> (DeactivateFaq(faq.Id.ToString())) |> dispatch)
                     Title "deaktivieren"
                 ] [ icon "fas fa-eye-slash" ]
             else
                 a [
                     OnClick(fun _ -> (ActivateFaq(faq.Id.ToString())) |> dispatch)
                     Title "aktivieren"
                 ] [ icon "fas fa-eye" ])
        ]
    ]

let table faqs dispatch =
    table [
        Class "table table-striped responsive-table"
    ] [
        thead [] [
            tr [] [
                th [] [ str "Pos." ]
                th [] [ str "Frage" ]
                th [] [ str "Sichtbar für" ]
                th [] [ str "Aktionen" ]
            ]
        ]
        tbody [] (faqs |> List.map (tableRow dispatch))
    ]

let faqList model dispatch =
    div [ Class "column is-8" ] [
        yield
            div [
                Class "flat-card profile-info-card is-auto"
            ] [
                div [ Class "card-body" ] [
                    yield
                        button [
                            Class "button is-link is-pulled-right is-small"
                            OnClick(fun _ ->
                                if model.SelectedKategorie.IsSome then
                                    model.SelectedKategorie.Value |> NewFaq |> dispatch)
                        ] [ str "Neu" ]
                    yield
                        match model.Faqs with
                        | Empty -> div [] []
                        | Loading -> spinner
                        | LoadError s -> errorMsg "Fehler beim Laden der FAQs!"
                        | Body x when x = [] -> div [] [ str "Keine FAQs vorhanden" ]
                        | Body x -> table x dispatch
                ]
            ]
    ]

let editBox (kategorien: AdminFaqKategorieListItem list) (model: EditModel) dispatch =
    let isLoading = (if model.Sending then " is-loading" else "")
    Column.column [ Column.Width(Screen.All, Column.Is6) ] [
        div [
            Class "flat-card profile-info-card is-auto"
        ] [
            div [ Class "card-title" ] [
                h3 [] [ b [] [ str "FAQ bearbeiten" ] ]
            ]
            div [ Class "card-body" ] [
                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block" ] [
                            validatedTextBox (dispatch << FrageChanged) "Frage" "Frage" "" model.Frage
                        ]
                    ]
                ]

                div [ Class "columns" ] [
                    div [ Class "column is-8" ] [
                        div [ Class "info-block" ] [
                            validatedTextBox (dispatch << PositionChanged) "Position" "Position" "" model.Position
                        ]
                    ]

                    div [ Class "column is-4" ] [
                        div [ Class "info-block" ] [
                            div [ Class "field" ] [
                                label [ Class "label" ] [ str "Aktiv" ]
                                checkbox model.Aktiv (fun b -> b |> AktivChanged |> dispatch) ""
                            ]
                        ]
                    ]
                ]

                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block" ] [
                            validatedTextarea (dispatch << AntwortChanged) "Antwort" "Antwort" "" "is-big" model.Antwort
                        ]
                    ]
                ]

                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block" ] [
                            div [ Class "field" ] [
                                label [ Class "label" ] [ str "Kategorie" ]
                                Select.select [ Select.Option.CustomClass "" ] [
                                    select
                                        [
                                            Class "select input is-multiline is-multiple"
                                            OnChange(fun ev ->
                                                match ev.Value |> Guid.TryParse with
                                                | true, id -> id |> KategorieChanged |> dispatch
                                                | false, _ -> ())
                                        ]
                                        (kategorien
                                         |> List.map (fun k ->
                                             option [
                                                 Value k.Id
                                                 Selected(k.Id = model.KategorieId)
                                             ] [ str k.Anzeigename ]))
                                ]
                            ]
                        ]
                    ]
                ]

                div [ Class "columns" ] [
                    div [ Class "column is-3" ] [
                        div [ Class "info-block" ] [
                            div [ Class "field" ] [
                                label [ Class "label" ] [ str "für Pflege" ]
                                checkbox
                                    model.SichtbarFuerPflege
                                    (fun b -> b |> SichtbarFuerPflegeChanged |> dispatch)
                                    "a"
                            ]
                        ]
                    ]

                    div [ Class "column is-3" ] [
                        div [ Class "info-block" ] [
                            div [ Class "field" ] [
                                label [ Class "label" ] [ str "für DTA-Plus" ]
                                checkbox
                                    model.SichtbarFuerPflegeDtaPlus
                                    (fun b -> b |> SichtbarFuerPflegeDtaPlusChanged |> dispatch)
                                    "b"
                            ]
                        ]
                    ]

                    div [ Class "column is-3" ] [
                        div [ Class "info-block" ] [
                            div [ Class "field" ] [
                                label [ Class "label" ] [ str "für Therapie" ]
                                checkbox
                                    model.SichtbarFuerTherapie
                                    (fun b -> b |> SichtbarFuerTherapieChanged |> dispatch)
                                    "c"
                            ]
                        ]
                    ]

                    div [ Class "column is-3" ] [
                        div [ Class "info-block" ] [
                            div [ Class "field" ] [
                                label [ Class "label" ] [ str "für Jugendhilfe" ]
                                checkbox
                                    model.SichtbarFuerJugendhilfe
                                    (fun b -> b |> SichtbarFuerJugendhilfeChanged |> dispatch)
                                    "d"
                            ]
                        ]
                    ]
                ]

                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block is-pulled-right" ] [
                            button [
                                Class("button is-link" + isLoading)
                                Disabled(not (model.CanSubmit))
                                Props.OnClick(fun _ -> Save |> dispatch)
                            ] [ str "Speichern" ]
                        ]
                        div [ Class "info-block" ] [
                            button [
                                Class("button is-danger is-link")
                                Props.OnClick(fun _ -> model.KategorieId |> LoadFaqList |> dispatch)
                            ] [
                                str (if model.IsDirty then "Abbrechen" else "Zurück")
                            ]
                        ]
                    ]
                ]

            ]
        ]
    ]


let previewBox (model: EditModel) =
    Column.column [ Column.Width(Screen.All, Column.Is6) ] [
        div [
            Class "flat-card profile-info-card is-auto"
        ] [
            [
                div [ Class "card-title" ] [
                    h3 [] [ b [] [ str model.Frage.Value ] ]
                ]
                div [ Class "card-body" ] [
                    div [
                        Class "content"
                        DangerouslySetInnerHTML { __html = model.TextAsHtml }
                    ] []
                ]
            ]
            |> ofList
        ]
    ]

let kategorienList kategorien selectedKategorie dispatch =
    let kategorie (k: AdminFaqKategorieListItem) =
        let active = if k.Id = selectedKategorie then " is-active" else ""

        li [
            Class("wishlist-item " + active)
            OnClick(fun _ -> k.Id |> LoadFaqList |> dispatch)
        ] [
            div [ Class "item-wrapper" ] [ yield a [] [ str k.Anzeigename ] ]
        ]

    div [ Class "column is-4" ] [
        div [ Class "flat-card is-auto menu-card" ] [
            ul [ Class "wishlists" ] [
                kategorien |> List.map kategorie |> ofList
            ]
        ]
    ]

let view (model: Types.Model) dispatch (navigateTo: AnyPage -> unit) =
    match model.EditModel with
    | Some editModel ->
        div [ Class "columns is-multiline" ] [
            editBox model.Kategorien editModel dispatch
            previewBox editModel
        ]
    | None ->
        div [
            Class "columns is-product-list is-multiline faq"
        ] [
            kategorienList model.Kategorien (model.SelectedKategorie |> Option.defaultValue Guid.Empty) dispatch
            faqList model dispatch
        ]
