namespace Videos

open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Elmish
open Types

// Thumbnail aus Videos ziehen geht zB mit
// ffmpeg -ss 00:00:30 -i "Video.mp4" -vframes 1 -q:v 2 -s 350x196 "Thumbnail.jpg"
// von https://stackoverflow.com/questions/27568254/how-to-extract-1-screenshot-for-a-video-with-ffmpeg-at-a-given-time

module State =
    let init page (user: UserSession option) lastModel =
        let model = {
            InteractiveVideoIsPlaying = false
            OstergewinnspielActive = false
        }
        model, LoadEventSettings |> Cmd.ofMsg

    let update msg (model: Model) =
        match msg with
        | PlayInteractiveVideo ->
            { model with
                InteractiveVideoIsPlaying = true
            },
            Cmd.none
        | LoadEventSettings ->
            let request () = Http.fetchAs<EventSettings> "/api/events/settings"
            model,
            (
                LoadEventSettingsCompleted
                |> Http.request request ()
            )
        | LoadEventSettingsCompleted (Error _) ->
            Logger.error "Die Einstellungen für das Ostergewinnspiel konnten nicht geladen werden"
            model, Cmd.none
        | LoadEventSettingsCompleted (Ok settings) ->
            { model with OstergewinnspielActive = settings.EasterEvent.IsSome}, Cmd.none
        | GlobalMsg(_) -> (model, Cmd.none)
