﻿module Melibo.View

open Fable.Core.JS
open Types
open Fable.React

type MeliboMode =
    | [<CompiledName("embed")>] Embed

type MeliboHeight =
    | [<CompiledName("100%")>] Full
    | [<CompiledName("max(calc(100vh - 350px),350px)")>] Limited

type MeliboProp =
    | [<CompiledName("api-key")>] ApiKey of string
    | [<CompiledName("mode")>] Mode of MeliboMode
    | [<CompiledName("height")>] Height of MeliboHeight
    | [<CompiledName("variables")>] Variables of string
    interface IHTMLProp

let inline meliboChat (props: MeliboProp seq) =
    domEl
        "melibo-webchat"
        (props |> Seq.map (fun item -> item :> IHTMLProp))
        [||]

let view (model: Model) dispatch (navigateTo: AnyPage -> unit) =
    div [] [
        match model.Token with
        | Remote.Empty
        | Remote.Loading ->
            ViewParts.spinner
        | Remote.Body tokenData ->
            meliboChat [
                ApiKey tokenData.ApiKey
                Mode MeliboMode.Embed
                Height MeliboHeight.Limited
                Variables (JSON.stringify {| jwtToken = tokenData.Token |})
            ]
        | Remote.LoadError msg ->
            ViewParts.errorMsg msg
    ]
