module News.View

open Fable.React
open Fulma

open Types
open Http
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.News
open Fable.React.Props

open ViewParts

let newsListItemView (news: NewsListItem) navigateTo =
    li [
        Props.Class "flat-card is-auto is-list-item"
        Props.OnClick(fun _ -> navigateTo (Page.NewsItem(news.Id.ToString())))
    ] [
        if (news.IsNew) then
            yield newIndicator

        yield
            span [ Props.Class "image is-hidden-mobile" ] [
                if news.HasImage then
                    yield
                        img [
                            Props.Class "image"
                            Props.Src(imageUrl (news.Id.ToString()))
                        ]
            ]
        yield
            span [ Props.Class "product-info" ] [
                a [] [
                    span [ Props.Class "product-name" ] [ a [] [ str news.Titel ] ]
                ]
                span [ Props.Class "product-description" ] [ str news.Textanfang ]
            ]

        yield
            span [
                Props.Class "product-abstract is-hidden-mobile"
            ] [
                span [ Props.Class "date" ] [
                    str (sprintf "%s" (news.Datum.ToString("dd.MM.yyyy")))
                ]
                span [ Props.Class "view-more" ] [
                    a [] [
                        str "Weiterlesen"
                        i [ Class "fas fa-angle-right fa-lg" ] []
                    ]
                ]
            ]
    ]


let newsListView (newsList: Remote<Paginated<NewsListItem>>) navigateTo withPagination =
    match newsList with
    | Empty -> div [] []
    | Loading -> spinner
    | LoadError s -> errorMsg "Fehler beim Laden der News!"
    | Body news when news.Items = [] -> div [] [ str "Keine News Vorhanden" ]
    | Body news ->
        [
            Columns.columns [
                Columns.CustomClass "is-product-list newslist"
                Columns.IsMultiline
            ] [
                Column.column [
                    Column.Width(Screen.All, Column.Is12)
                ] [
                    ul [] [
                        news.Items
                        |> List.map (fun i -> newsListItemView i navigateTo)
                        |> ofList
                    ]
                ]
            ]
            if withPagination then
                pagination news (fun page -> (navigateTo (Page.AllNews page)))
        ]
        |> ofList

let newsItemDetails id backToPage (news: News) navigateTo =
    [
        Columns.columns [
            Columns.CustomClass "is-product-list newsarticle"
            Columns.IsMultiline
        ] [
            Column.column [
                Column.Width(Screen.All, Column.Is12)
            ] [
                div [
                    Class "flat-card profile-info-card is-auto"
                ] [
                    div [ Class "card-title" ] [
                        a [
                            OnClick(fun _ -> Browser.Dom.history.back ())
                        ] [
                            i [ Class "fas fa-angle-left" ] []
                            str "Zurück zur Übersicht"
                        ]
                    ]

                    div [ Class "card-body" ] [
                        div [ Class "columns" ] [
                            if news.HasImage then
                                div [ Class "column is-4" ] [
                                    div [] [
                                        img [
                                            Class "news-image"
                                            Props.Src(imageUrl (id.ToString()))
                                        ]
                                    ]
                                ]

                            div [ Class "column" ] [
                                div [
                                    Class "content"
                                    DangerouslySetInnerHTML { __html = news.Html }
                                ] []
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]
    |> ofList
let newsItemView id backToPage (news: Remote<News>) navigateTo =
    match news with
    | Empty -> div [] []
    | Loading -> spinner
    | LoadError s -> errorMsg "Fehler beim Laden der News!"
    | Body body -> newsItemDetails id backToPage body navigateTo

let view (model: Types.Model) dispatch (navigateTo: AnyPage -> unit) =
    match model with
    | AllNewsModel m -> newsListView m.NewsList navigateTo true
    | NewsItemModel m -> newsItemView m.Id m.BackToPage m.NewsItem navigateTo
