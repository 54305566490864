module Dashboard.VertragsBestaetigung

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard

open Fable.React
open Fable.React.Props
open Elmish

open System
open Feliz
open Http

open Dashboard.Shared
open Types
open Validation
open ViewParts

type ShowVertragsDialog =
    | ShowContractAndTile
    | ShowTileOnly
    | ShowConfirmation
    | DoNotShowAnything

type Model = {
    GlobalDispatch: GlobalMsg -> unit
    Loading: bool
    AlreadySeen: bool
    Data: VertragBestaetigung
    ShowVertragsDialog: ShowVertragsDialog
    Name: FormField
}

type Msg =
    | LoadData
    | LoadDataCompleted of Result<VertragBestaetigung, exn>
    | NameChanged of string
    | VertragAktualisieren
    | CloseModal
    | VertragAkzeptiert
    | SetDataCompleted of Result<Fetch.Types.Response, exn>
    | ConfirmVertrag

module Http =
    let loadData () = fetchAs<VertragBestaetigung> "/api/dashboard/vertragsbestaetigung"
    let setNewVertragsbestaetigung payload =
        postRecord<VertragBestaetigungRequest> "/api/dashboard/vertragsbestaetigung" payload

module State =
    let SESSION_DATA_KEY_INFO_BOX_ALREADYSEEN = "VertragsBestaetigungInfoBoxAlreadySeen"

    let init (props: DashboardProps) userName user =
        let alreadySeen =
            user
            |> Option.map (fun u ->
                u.SessionData
                |> Map.containsKey SESSION_DATA_KEY_INFO_BOX_ALREADYSEEN)
            |> Option.defaultValue false
        {
            GlobalDispatch = props.GlobalDispatch
            Loading = true
            AlreadySeen = alreadySeen
            ShowVertragsDialog = DoNotShowAnything
            Name = FormField.InitWithDefault(Some ValidationRule.Mandatory, userName)
            Data = {
                VertragsNummer = ""
                VertragsBeschreibung = ""
                VertragsTeaser = ""
                VertragsText = ""
            }
        },
        Cmd.ofMsg LoadData

    let update msg model : Model * Cmd<Msg> =
        match msg with
        | LoadData ->
            let cmd = LoadDataCompleted |> request Http.loadData ()
            { model with Loading = true }, cmd
        | LoadDataCompleted(Ok data) ->
            { model with
                Loading = false
                Data = data
                ShowVertragsDialog =
                    match data.VertragsNummer with
                    | "" -> DoNotShowAnything
                    | _ -> if model.AlreadySeen then DoNotShowAnything else ShowTileOnly
            },
            Cmd.none

        | LoadDataCompleted(Error _) ->
            let msg =
                GlobalMessageBox.Error "Es kann derzeit nicht geprüft werden, ob ein Vertrag bestätigt werden muss."
                |> ShowMessageBox
            model.GlobalDispatch msg
            { model with Loading = false }, Cmd.none

        | VertragAktualisieren ->
            { model with
                ShowVertragsDialog = ShowContractAndTile
            },
            Cmd.none
        | CloseModal ->
            { model with
                ShowVertragsDialog = ShowTileOnly
            },
            Cmd.none
        | VertragAkzeptiert ->
            let cmd =
                SetDataCompleted
                |> request
                    Http.setNewVertragsbestaetigung
                    {
                        Name = model.Name.Value
                        VertragsNummer = model.Data.VertragsNummer
                    }
            { model with
                ShowVertragsDialog = ShowConfirmation
            },
            cmd
        | SetDataCompleted(Ok _) ->
            { model with
                ShowVertragsDialog = ShowConfirmation
            },
            Cmd.none
        | SetDataCompleted(Error _) ->
            let msg =
                GlobalMessageBox.Error "Die Vertragsbestätigung kann momentan nicht gespeichert werden."
                |> ShowMessageBox
            model.GlobalDispatch msg
            { model with
                ShowVertragsDialog = ShowTileOnly
            },
            Cmd.none
        | ConfirmVertrag ->
            { model with
                ShowVertragsDialog = DoNotShowAnything
            },
            Cmd.none
        | NameChanged s ->
            { model with
                Name = update model.Name s
            },
            Cmd.none

let newNavigationTile model dispatch userSession (props: DashboardProps) =
    let body =
        div [ Class "columns" ] [
            div [ Class "column" ] [ str model.Data.VertragsTeaser ]
            div [
                Class "column is-narrow vertrags-buttons"
            ] [
                a [
                    Class "show-more"
                    OnClick(fun _ -> VertragAktualisieren |> dispatch)
                ] [ str "Mehr anzeigen" ]
                button [
                    Class "button is-bfsorange"
                    OnClick(fun _ -> VertragAktualisieren |> dispatch)
                ] [ str "Jetzt aktualisieren" ]
            ]
        ]

    (NotificationTile.view
        {
            GlobalDispatch = props.GlobalDispatch
            UniqueSessionKey = State.SESSION_DATA_KEY_INFO_BOX_ALREADYSEEN
            Flag = "Wichtig"
            Title = "Wir aktualisieren unsere Vereinbarung."
            Body = body
            Session = userSession
        })

let view =
    FunctionComponent.Of(fun (props: DashboardProps, userName, userSession) ->
        let model, dispatch =
            React.useElmish ((State.init props userName userSession), State.update)
        div [] [
            match model.ShowVertragsDialog with
            | ShowTileOnly ->
                newNavigationTile model dispatch userSession props
            | ShowContractAndTile ->
                newNavigationTile model dispatch userSession props

                let usePdfViewer = String.IsNullOrEmpty model.Data.VertragsText
                Template.messageBoxComponent
                    GlobalMessageType.InfoBox
                    usePdfViewer
                    "Wir aktualisieren unsere Vereinbarung"
                    (div [] [
                        if usePdfViewer then
                            Html.object [
                                prop.className "pdf"
                                prop.custom ("data", $"/api/dashboard/%s{model.Data.VertragsNummer}.pdf")
                                prop.children [
                                    Html.p (str "Der Vertragstext kann in Ihrem Browser leider nicht angezeigt werden. Bitte speichern Sie ihn auf Ihrem Gerät und öffnen ihn anschließend mit der entsprechenden Anwendung.")
                                    Html.br [ ]
                                    Html.a [
                                        prop.href $"/api/dashboard/%s{model.Data.VertragsNummer}.pdf"
                                        prop.text "Vertragstext speichern"
                                    ]
                                ]
                    ]
                        else
                        div [
                            Class "content"
                            DangerouslySetInnerHTML
                                {
                                    __html =
                                        model.Data.VertragsText
                                        |> Fable.Formatting.Markdown.Markdown.ToHtml
                                }
                        ] []
                        p [] [ str "\n" ]
                        validatedTextBox
                            (fun s -> s |> NameChanged |> dispatch)
                            "Name:"
                            "Name"
                            "Vorname Nachname"
                            model.Name

                    ])
                    (fun () -> dispatch CloseModal)
                    [
                        button [
                            Class "button is-bfscolored"
                            OnClick(fun _ -> dispatch CloseModal)
                        ] [ str "Abbrechen" ]
                        button [
                            Class "button is-bfscolored"
                            Disabled(not model.Name.IsValid)
                            OnClick(fun _ -> dispatch VertragAkzeptiert)
                        ] [ str "Neuen Vertrag bestätigen" ]
                    ]
            | ShowConfirmation ->
                Template.messageBoxComponent
                    GlobalMessageType.InfoBox
                    false
                    "Wir aktualisieren unsere Vereinbarung"
                    (div [] [
                        p [] [
                            str
                                "Vielen Dank für Ihre Zustimmung.\nEin PDF mit dem Text der Vereinbarung erhalten Sie in Kürze in Ihrem Kundenportal als Dokument zugestellt."
                        ]
                    ])
                    (fun () -> ConfirmVertrag |> dispatch)
                    [
                        button [
                            Class "button is-bfscolored"
                            OnClick(fun _ -> ConfirmVertrag |> dispatch)
                        ] [ str "Ok" ]
                    ]
            | DoNotShowAnything -> div [] []
        ])
