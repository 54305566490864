module Password.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> NoHeader

    BuildUrl =
        function
        | LostPassword -> [ Urls.passwordlost ]
        | ResetPassword token -> [ Urls.passwordreset; token ]
        | MessagePage _ -> []

    ParseUrl =
        function
        | [ Urls.passwordlost ] -> Some(Page.LostPassword)
        | [ Urls.passwordreset; token ] -> Some(Page.ResetPassword token)
        | _ -> None
}
