﻿module AdminNotification.View

open AdminNotification.Types
open Bfs.Web.Data.Service.Contracts.Kundenportal.Admin
open Types
open Fable.React.Standard
open Fable.React.Props
open Fable.React.Helpers
open ViewParts
open Fulma
open Fable.React

let tableRow dispatch navigateTo (notification: NotificationListItem) =
    tr [] [
        td [] [ str notification.Title ]
        td [] [ str notification.Message ]
        td [] [
            str (notification.Created.ToString())
        ]
        td [] [ str notification.Receiver ]
    ]

let tableNotification notifications dispatch navigateTo =
    table [
        Class "table table-striped responsive-table"
    ] [
        thead [] [
            tr [] [
                th [] [ str "Titel" ]
                th [] [ str "Nachricht" ]
                th [] [ str "Erstellt am" ]
                th [] [ str "Kundenportalbenutzer" ]
            ]
        ]
        tbody [] (notifications |> List.map (tableRow dispatch navigateTo))
    ]

let listPage (model: NotificationList) (dispatch: Msg -> unit) navigateTo =
    div [ Class "columns is-multiline" ] [
        div [ Class "column is-12" ] [
            yield
                div [
                    Class "flat-card profile-info-card is-auto"
                ] [
                    div [ Class "card-body" ] [
                        yield
                            button [
                                Class "button is-link is-pulled is-small"
                                OnClick(fun _ -> navigateTo Page.AddNotification)
                            ] [ str "Neu" ]
                        yield
                            match model.Notifications with
                            | Remote.Empty -> div [] []
                            | Loading -> spinner
                            | LoadError s -> errorMsg "Fehler beim Laden der Benachrichtigungen!"
                            | Body x when x.Items = [] ->
                                div [] [
                                    str "Keine Benachrichtigungen vorhanden"
                                ]
                            | Body x -> tableNotification x.Items dispatch navigateTo
                    ]
                ]
            match model.Notifications with
            | Body x -> yield pagination x (fun page -> navigateTo (Page.NofiticationList page))
            | _ -> ()
        ]
    ]

let rowSubscriber (dispatch: Msg -> unit) model entry =
    tr [] [
        td [] [checkbox (NewNotificationItem.IsSelected model entry.KundenportalBenutzerId)
                   (fun b -> entry.KundenportalBenutzerId |> KundenportalBenutzerChanged |> dispatch) ""]
        td [] [
            str (string entry.KundenportalBenutzerId)
        ]
        td [] [ str entry.Name ]
    ]

let tableSubscriber (model: NewNotificationItem) (dispatch: Msg -> unit) =

    table [
        Class "table table-striped responsive-table"
    ] [
        thead [] [
            tr [] [
                th [] [ checkbox (model |> NewNotificationItem.AreAllSelected ) (fun b -> b |> KundenportalBenutzerAllChanged |> dispatch) ""]
                th [] [ str "KundenportalBenutzerId" ]
                th [] [ str "Name" ]
            ]
        ]
        tbody
            []
            (model.AllNotificationKundenportalBenutzer
             |> List.map (rowSubscriber dispatch model))
    ]

let addPage (model: NewNotificationItem) dispatch navigateTo =
    let isLoading = (if model.IsSending then " is-loading" else "")
    Column.column [ Column.Width(Screen.All, Column.Is6) ] [
        div [
            Class "flat-card profile-info-card is-auto"
        ] [
            div [ Class "card-title" ] [
                h3 [] [
                    b [] [ str "Benachrichtigung erstellen" ]
                ]
            ]

            div [ Class "card-body" ] [
                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block" ] [
                            validatedTextBox (dispatch << TitleChanged) "Titel" "Title" "" model.Title
                        ]
                    ]
                ]

                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block" ] [
                            validatedTextarea (dispatch << MessageChanged) "Message" "Message" "" "is-big" model.Message
                        ]
                    ]
                ]

                div [ Class "columns is-multiline" ] [
                    div [ Class "column is-12" ] [
                        yield
                            div [
                                Class "flat-card profile-info-card is-auto"
                            ] [
                                div [ Class "card-body" ] [ yield tableSubscriber model dispatch ]
                            ]
                    ]
                ]
                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block is-pulled-right" ] [
                            button [
                                Class("button is-link" + isLoading)
                                Disabled(not (model.CanSubmit))
                                Props.OnClick(fun _ -> dispatch Save)
                            ] [ str "Speichern" ]
                        ]
                        div [ Class "info-block" ] [
                            button [
                                Class("button is-danger is-link")
                                Props.OnClick(fun _ -> navigateTo (Page.NofiticationList model.BackToPage))
                            ] [
                                str "Abbrechen"
                            ]
                        ]
                    ]
                ]
            ]
        ]
    ]

let view (model: Model) dispatch (navigateTo: AnyPage -> unit) =
    match model with
    | ListModel m -> listPage m dispatch navigateTo
    | ItemModel m -> addPage m dispatch navigateTo
