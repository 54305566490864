module News.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg = fun _ -> None

    GetHeader = fun model -> Header model.Headline

    BuildUrl =
        function
        | Page.AllNews 1 -> [ Urls.news ]
        | Page.AllNews p -> [
            Urls.news
            Urls.pageSeperator
            p.ToString()
          ]
        | Page.NewsItem id -> [ Urls.news; id ]

    ParseUrl =
        function
        | [ Urls.news ] -> Some(Page.AllNews 1)
        | [ Urls.news; Urls.pageSeperator; page ] -> Some(Page.AllNews(System.Int32.Parse(page)))
        | [ Urls.news; id ] -> Some(Page.NewsItem id)
        | _ -> None
}
