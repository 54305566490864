module StaticPages.State

open Elmish

open Types

let init page user lastModel =
    let initialModel = { Page = page }
    (initialModel, Cmd.none)

let update msg model =
    match msg with
    | Load page ->
        let model = { Page = page }
        (model, Cmd.none)
