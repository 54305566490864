module Dta.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "Datenübergabe"

    BuildUrl =
        function
        | Dta.Types.DtaList 1 -> [ Urls.dta ]
        | Dta.Types.DtaList p -> [
            Urls.dta
            Urls.pageSeperator
            p.ToString()
          ]

    ParseUrl =
        function
        | [ Urls.dta ] -> Some(Page.DtaList 1)
        | [ Urls.dta; Urls.pageSeperator; page ] -> Some(Page.DtaList(System.Int32.Parse(page)))
        | _ -> None
}
