module AdminFile.Types

open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.File
open Types
open Fetch

type Page = List of page: int * kundennummer: int option * kategorie: string option

type Msg =
    | LoadList of page: int * kundennummer: int option * kategorie: string option
    | ListLoaded of Result<Paginated<AdminFileListItem>, exn>

    | KundennummernFilterTextChanged of string
    | FilterForKundennummerClear
    | FilterForKundennummer
    | FilterForKategorie of string option

    | ActivateFile of id: string * filetype: FileType
    | DeactivateFile of id: string * filetype: FileType
    | ActiveChanged of Result<Response, exn>

    | LoadAllKundennummern
    | AllKundennummernLoaded of Result<int list, exn>

    | LoadAllKategorien
    | AllKategorienLoaded of Result<string list, exn>

    | GlobalMsg of GlobalMsg

type Model =
    {
        PageNumber: int
        Kundennummer: int option
        Kategorie: string option

        Files: Remote<Paginated<AdminFileListItem>>

        AllKategorien: string list
        AllKundennummern: int list

        KundennummernFilterText: string
        KundennummernFilterEnabeld: bool
    }
    static member Init pagenumber kundennummer kategorie allKundennummern allKategorien = {
        PageNumber = pagenumber
        Kundennummer = kundennummer
        Kategorie = kategorie

        Files = Loading
        AllKundennummern = allKundennummern
        AllKategorien = allKategorien

        KundennummernFilterText = ""
        KundennummernFilterEnabeld = false
    }
