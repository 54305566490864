module News.Types

open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.News
open Types
open Fetch

type Page =
    | AllNews of page: int
    | NewsItem of id: string

type Msg =
    | LoadNewsPage of page: int
    | LoadNews of id: string
    | NewsPageLoaded of Result<Paginated<NewsListItem>, exn>
    | NewsLoaded of Result<News, exn>
    | MarkNewsRead of id: string
    | MarkNewsReadDone of Result<Response, exn>

type Model =
    | AllNewsModel of AllNewsModel
    | NewsItemModel of NewsItemModel
    member model.Headline =
        match model with
        | AllNewsModel _ -> "News"
        | NewsItemModel model ->
            match model.NewsItem with
            | Remote.Body news -> news.Titel
            | _ -> "News"
and AllNewsModel = {
    PageNumber: int option
    NewsList: Remote<Paginated<NewsListItem>>
}
and NewsItemModel = {
    Id: string
    NewsItem: Remote<News>
    BackToPage: int
}
