module Dashboard.View

open Bfs.Web.Data.Service.Contracts.Events
open Bfs.Web.Kundenportal.WebParts.User.Dashboard
open Fable.React

open Dashboard.Shared
open Bfs.Web.Kundenportal.WebParts.User.Events.Easter

open Types
open Fable.React.Props

let view (model: Model) dispatch (navigateTo: AnyPage -> unit) =

    let dashboardProps: DashboardProps = {
        GlobalDispatch = (GlobalMsg >> dispatch)
        NavigateTo = navigateTo
        MostRecentCallback = model.MostRecentCallback
    }

    let gridClasses =
        match model.MeliboChatActive with
        | true -> "with-melibo"
        | false -> System.String.Empty

    div [ Class "dashboard" ] [
        h1 [ Class "is-size-2 mb-6 mt-6" ] [
            str (
                match model.UserName.Length with
                | 0 -> System.String.Empty
                | _ -> $"Hallo, {model.UserName}!"
            )
        ]

        div [ Class $"dashboard-grid {gridClasses}" ] [
            div [ Class "letzte-sendung-grid" ] [
                (MeineLetzteSendung.View dashboardProps)

                if model.OstergewinnspielActive then
                    (Egg Color.Yellow Variant.Dotted [ Top "60%" ])
            ]

            div [ Class "anstehende-zahlung-grid" ] [
                AnstehendeZahlungen.View dashboardProps
            ]

            div [ Class "letzter-belegeingang-grid" ] [
                LetzterBelegeingang.View dashboardProps

                if model.OstergewinnspielActive then
                    (Egg Color.Green Variant.Stripped [ Top "100%"; Left "20%" ])
            ]

            div [ Class "quickfinder-grid" ] [
                Quickfinder.View.quickfinderBoxDashboard navigateTo (GlobalMsg >> dispatch) ""
            ]

            div [ Class "meine-zahlen-grid" ] [
                MeineZahlungen.View dashboardProps

                if model.OstergewinnspielActive then
                    (Egg Color.Orange Variant.Dotted [ Left "40%" ])
            ]

            div [ Class "digitale-kundenrechnung-grid" ] [
                if model.OstergewinnspielActive then
                    EventsEaster.View(dashboardProps, model.OstergewinnspielUpdateTrigger)
                else
                    DigitaleKundenrechnung.View dashboardProps
            ]

            div [ Class "chats-grid" ] [ Chats.View dashboardProps ]
            div [ Class "tickets-grid" ] [
                Tickets.View dashboardProps
                if model.OstergewinnspielActive then
                    (clickableEgg
                        {
                            OnCLick =
                                (fun _ ->
                                    (EasterEgg.Dashboard, Some(EggFound :> AnyWebPartMsg))
                                    |> EasterEggFound
                                    |> EventMsg
                                    |> GlobalMsg
                                    |> dispatch)
                            Found = model.EasterEggDashboardFound
                            Css = [ Top "100%"; Left "70%" ]
                        })
            ]
            div [ Class "neuste-dokumente-grid" ] [
                NeusteDokumente.View dashboardProps

                if model.OstergewinnspielActive then
                    (Egg Color.Blue Variant.Stripped [ Top "100%"; Left "10%" ])
            ]

            div [ Class "dokumenten-depot-grid" ] [
                DokumentenDepot.View dashboardProps

                if model.OstergewinnspielActive then
                    (Egg Color.Pink Variant.Stripped [ Left "60%" ])
            ]

            div [ Class "vertrag-bestaetigen-grid" ] [
                VertragsBestaetigung.view (dashboardProps, model.UserName, model.UserSession)
            ]
            div [ Class "events-grid" ] [
                EventBannerEaster.view (dashboardProps, model.UserSession, model.OstergewinnspielActive)
            ]
            div [ Class "news-grid" ] [ News.View dashboardProps ]

            div [ Class "notification-grid" ] [Notification.View dashboardProps]
            if model.MeliboChatActive then
                div [ Class "melibo-grid" ] [ Melibo.View dashboardProps ]
        ]
    ]
