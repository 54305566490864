module AdminText.Types

open Bfs.Web.Data.Service.Contracts
open Types
open Fetch
open Validation
open Preview


type Page =
    | TextList
    | TextEdit of key: string

type Msg =
    | LoadTextList
    | TextListLoaded of Result<string list, exn>

    | LoadText of key: string
    | TextLoaded of Result<string, exn>

    | LoadMaintenanceState
    | MaintenanceStateLoaded of Result<BoolResult, exn>

    | ContentChanged of string

    | MaintenanceActiveChanged of bool
    | MaintenanceActiveSaved of Result<Response, exn>

    | Save
    | Saved of Result<Response, exn>

    | GlobalMsg of GlobalMsg


type Model =
    | ListModel of TextListModel
    | ItemModel of TextEditModel

and TextListModel =
    {
        TextInfoList: Remote<string list>
    }

    static member Init = { TextInfoList = Loading }

and TextEditModel =
    {
        Key: string
        Content: FormField
        Sending: bool
        IsDirty: bool
        TextAsHtml: string
        MaintenanceActive: bool
    }

    static member Init key = {
        Key = key
        Content = FormField.Init(Some Mandatory)
        Sending = false
        IsDirty = false
        TextAsHtml = ""
        MaintenanceActive = false
    }

    member x.CanSubmit = not x.Sending && x.Content.IsValid
