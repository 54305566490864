module ApiToken.Types

open Types
open System
open Bfs.Web.Data.Service.Contracts.Kundenportal.ApiToken

type Page = | ApiToken

type Model =
    {
        Filialen: Remote<Filiale list>
        SelectedFiliale: Guid option
    }

    static member Init = {
        Filialen = Loading
        SelectedFiliale = None
    }

type Msg =
    | LoadFilialen
    | FilialenLoaded of Result<Filiale list, exn>
    | SwitchFiliale of Guid
    | DeleteToken of int
    | DeleteTokenConfirmed of int
    | TokenDeleted of Result<int, exn>
    | CreateToken of Guid
    | TokenCreated of Result<CreateTokenResult, exn>
    | GlobalMsg of GlobalMsg
