module Usermanagement.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "Benutzerverwaltung"

    BuildUrl = fun _ -> [ Urls.usermanagement ]

    ParseUrl =
        function
        | [ Urls.usermanagement ] -> Some Page.UserList
        | _ -> None
}
