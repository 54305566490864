[<RequireQualifiedAccessAttribute>]
module Storage

open Fable.Import

let private removeValue key = Browser.WebStorage.localStorage.removeItem key

let private setValue key value = Browser.WebStorage.localStorage.setItem (key, value)

let private getValue key = Browser.WebStorage.localStorage.getItem key

let clearAll () = Browser.WebStorage.localStorage.clear ()

[<Literal>]
let private storageKeyQuickFinderSortierung = "quickfindersortierung"

[<RequireQualifiedAccess>]
module IsQuickFinderSortierungAufsteigend =
    let set (value: bool) = setValue storageKeyQuickFinderSortierung (value.ToString())

    let get () =
        match getValue storageKeyQuickFinderSortierung with
        | null -> false
        | str when str.ToUpper() = "TRUE" -> true
        | _ -> false
