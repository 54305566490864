module AdminText.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader =
        fun model ->
            match model with
            | Model.ListModel _ -> Header "Administration: Texte"
            | Model.ItemModel m -> Header(sprintf "Administration: %s" m.Key)

    BuildUrl =
        function
        | Page.TextList -> [ Urls.admin; Urls.text ]
        | Page.TextEdit key -> [ Urls.admin; Urls.text; key ]

    ParseUrl =
        function
        | [ Urls.admin; Urls.text; key ] -> Some(Page.TextEdit key)
        | [ Urls.admin; Urls.text ] -> Some(Page.TextList)
        | _ -> None
}
