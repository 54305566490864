module MainDispatch

open Elmish

open System

open Types
open Http
open Browser

let pageHash (page: AnyPage) =
    WebPart.Registry.TryBuildUrl page
    |> Option.map UrlCombine
    |> Option.defaultValue ""

let parseUrl (urlHash: string) =
    let segments =
        if urlHash.StartsWith "#" then
            urlHash.Substring(1, urlHash.Length - 1) // remove the hash sign
        else
            urlHash
        |> fun hash -> hash.Split '/' // split the url segments
        |> List.ofArray
        |> List.filter (String.IsNullOrWhiteSpace >> not)

    WebPart.Registry.TryParseUrl segments

let handleUpdateUrl (nextPage: AnyPage) (model: Model) =
    match (WebPart.Registry.TryInit nextPage model.User model) with
    | Some(pageModel, cmd) ->
        let newModel =
            { model with
                Page = nextPage
                PageModel = pageModel
            }
        (newModel, cmd)
    | None -> (model, Cmd.none)

let init (page: AnyPage) =
    let model = {
        Page = null
        PageModel = null
        User = None
        MessageBox = None
        VideoOverlayVimeoId = None
        IsBurgerMenuOpen = false
    }

    handleUpdateUrl page model


let handleGlobalMsg msg model =
    match msg with
    | UpdateUserSessionData(key, value) ->
        match model.User with
        | None -> (model, Cmd.none)
        | Some user ->
            let nextSessionData = user.SessionData |> Map.add key value
            let nextUser =
                { user with
                    SessionData = nextSessionData
                }
            let nextModel = { model with User = Some nextUser }
            (nextModel, Cmd.none)

    | ShowMessageBox box -> ({ model with MessageBox = Some box }, Cmd.none)
    | ClearMessageBox -> ({ model with MessageBox = None }, Cmd.none)

    | ShowVideo vimeoId ->
        ({ model with
            VideoOverlayVimeoId = Some vimeoId
         },
         Cmd.none)
    | ClearVideo ->
        ({ model with
            VideoOverlayVimeoId = None
         },
         Cmd.none)

    | LoginValidated _
    | Logoff
    | LogoffValidated _ -> failwith "must be handeld bevor calling this update func"


let update (msg: Types.Msg) (model: Types.Model) =
    try
        match msg with

        | GlobalMsg msg -> handleGlobalMsg msg model

        | WebPartMsg msg ->
            let globalmsg = WebPart.Registry.TryGetGlobalMsg msg

            match globalmsg with
            | Some m ->
                let cmd = Cmd.map GlobalMsg (Cmd.ofMsg m)
                model, cmd
            | None ->
                let next = WebPart.Registry.TryUpdate msg model

                match next with
                | Some(nextModel, nextCmd) -> (nextModel, nextCmd)
                | None ->
                    Logger.error (sprintf "Message konnte nicht dispatched werden: %O" msg)
                    (model, Cmd.none)

        // generic and navigation msgs
        | NavigateTo page ->
            let nextUrl = UrlHashPrefix(pageHash page)
            history.pushState ((), "", nextUrl)

            let (newModel, cmd) = handleUpdateUrl page model
            ({ newModel with
                IsBurgerMenuOpen = false
             },
             cmd)

        | UrlUpdated page ->
            let (newModel, cmd) = handleUpdateUrl page model
            ({ newModel with
                IsBurgerMenuOpen = false
             },
             cmd)

        | ToggleBurgerMenu ->
            ({ model with
                IsBurgerMenuOpen = not model.IsBurgerMenuOpen
             },
             Cmd.none)

        | NavigateHome -> failwith "must be handeld bevor calling this update func"

    with UnauthorizedException _ ->
        Logger.log "will logoff"
        (model, Cmd.ofMsg (GlobalMsg Logoff))
