module Bfs.Web.Data.Service.Contracts.Encoders

#if FABLE_COMPILER
open Thoth.Json
#else
open Thoth.Json.Net
#endif

open System

let inline enumDecoder< ^T when ^T: enum<int32>> =
    Decode.int |> Decode.map (fun x -> enum< ^T> x)

let extra =
    Extra.empty
    |> Extra.withCustom (fun (x: DateTime) -> x.ToString("s") |> Encode.string) (fun path json ->
        Ok(DateTime.Parse(json.ToString())))
    |> Extra.withCustom (Encode.decimal) (Decode.decimal)
    |> Extra.withCustom (fun (x: byte array) -> Convert.ToBase64String(x) |> Encode.string) (fun path json ->
        Ok(Convert.FromBase64String(json.ToString())))
