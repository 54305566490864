namespace AdminText

open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts
open Validation


module Http =
    let getText (key) = fetchAsString (sprintf "/api/admin/text/%s" key)
    let postText (key, content: string) = postRecord<string> (sprintf "/api/admin/text/%s" key) (content)
    let getMaintenanceState () = fetchAs<BoolResult> "api/admin/maintenancestatus"
    let postMaintenanceState (state) = postRecord<bool> "api/admin/maintenancestatus" state
    let getTextList () = fetchAs<string list> "/api/admin/text/"

module State =
    let init page user lastModel =
        match page with
        | TextEdit key ->
            let initialModel = ItemModel(TextEditModel.Init key)
            let cmd1 = Cmd.ofMsg (LoadText key)
            let cmd2 = Cmd.ofMsg (LoadMaintenanceState)
            (initialModel, Cmd.batch [ cmd1; cmd2 ])
        | TextList ->
            let initialModel = ListModel(TextListModel.Init)
            let cmd = Cmd.ofMsg (LoadTextList)
            (initialModel, cmd)


    let update msg model =
        match msg, model with
        | LoadTextList, _ ->
            let cmd = TextListLoaded |> request Http.getTextList ()
            (model, cmd)
        | TextListLoaded result, ListModel model ->
            let model =
                { model with
                    TextInfoList = Loaded result
                }
                |> ListModel
            (model, Cmd.none)

        | LoadText key, ItemModel model ->
            let cmd = TextLoaded |> request Http.getText key
            let model = { model with Key = key } |> ItemModel
            (model, cmd)
        | TextLoaded(Ok content), ItemModel model ->
            let model =
                { model with
                    Content = content |> update model.Content
                    TextAsHtml = Fable.Formatting.Markdown.Markdown.ToHtml content
                    IsDirty = false
                }
                |> ItemModel
            (model, Cmd.none)
        | TextLoaded(Error exn), _ -> (model, exn |> errorCmd GlobalMsg "Laden")

        | LoadMaintenanceState, _ ->
            let cmd =
                MaintenanceStateLoaded
                |> request Http.getMaintenanceState ()
            (model, cmd)
        | MaintenanceStateLoaded(Ok state), ItemModel model ->
            let model =
                { model with
                    MaintenanceActive = state.Value
                }
                |> ItemModel
            (model, Cmd.none)
        | MaintenanceStateLoaded(Error exn), _ -> (model, exn |> errorCmd GlobalMsg "Laden")

        | ContentChanged value, ItemModel model ->
            let model =
                { model with
                    Content = value |> update model.Content
                    TextAsHtml = value |> Fable.Formatting.Markdown.Markdown.ToHtml
                    IsDirty = true
                }
                |> ItemModel
            (model, Cmd.none)

        | MaintenanceActiveChanged value, ItemModel model ->
            let cmd =
                MaintenanceActiveSaved
                |> request Http.postMaintenanceState value
            let model = { model with MaintenanceActive = value } |> ItemModel
            (model, cmd)
        | MaintenanceActiveSaved(Ok _), _ -> (model, Cmd.none)
        | MaintenanceActiveSaved(Error exn), _ -> (model, exn |> errorCmd GlobalMsg "Speichern")

        | Save, ItemModel model ->
            let cmd =
                Saved
                |> request Http.postText (model.Key, model.Content.Value)
            let model = { model with Sending = true } |> ItemModel
            (model, cmd)
        | Saved(Ok _), ItemModel model ->
            let model =
                { model with
                    Sending = false
                    IsDirty = false
                }
                |> ItemModel
            (model, Cmd.none)
        | Saved(Error e), ItemModel model ->
            let model = { model with Sending = false } |> ItemModel
            (model, e |> errorCmd GlobalMsg "Speichern")

        | GlobalMsg(_), _ -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!

        | _, _ ->
            Logger.log "AdminText konnt nicht dispatched werden"
            Logger.log (model, msg)
            (model, Cmd.none)
