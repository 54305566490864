module Preview

open System

open Types
open Http

let webPrint title body =
    let popup = Browser.Dom.window.``open`` ("", "_blank", "")
    popup.document.``open`` () |> ignore
    // siehe artifacts/print für die source zu diesem html
    popup.document.write (
        sprintf
            "<html><head><title>%s</title><style>body{margin-left:3em;margin-right:3em}.logoblock{margin:0 1.5rem;margin-bottom:140px;display:flex}.bg{position:absolute;width:339px;height:118px;content:url(orange-triangle.svg)}.logo{position:absolute;content:url(logo-white.svg);width:185px;margin-top:28px;margin-left:40px}.text{white-space:pre-wrap}</style></head><body onload=\"window.print();\" onafterprint=\"setTimeout(function(){ window.close(); },500); window.close();\"><div class=\"logoblock\"><div class=\"bg\"></div><div class=\"logo\"></div></div><h1>%s</h1><div class=\"text\">%s</div></body></html>"
            title
            title
            body
    )
    popup.document.close () |> ignore
