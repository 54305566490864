module Password.Types

open Types
open Validation
open Bfs.Web.Data.Service.Contracts

type Page =
    | LostPassword
    | ResetPassword of string
    | MessagePage of string

type Msg =
    | EmailChanged of string
    | PasswordChanged of string
    | Password2Changed of string

    | CheckToken of string
    | TokenChecked of Result<BoolResult, exn>

    | Submit
    | Submitted of Result<Result<string, string>, exn>

    | ShowMsg of string
    | GlobalMsg of GlobalMsg

type LostPasswordModel =
    {
        Email: FormField
        Sending: bool
        IsFinished: bool
    }
    static member Init() = {
        Email = FormField.Init(Some Mandatory)
        Sending = false
        IsFinished = false
    }
    member x.CanSubmit = not x.Sending && x.Email.IsValid

type ResetPasswordModel =
    {
        Password: FormField
        Password2: FormField
        Token: string
        Message: string option
        Sending: bool
        IsFinished: bool
    }
    static member Init(token) = {
        Password = FormField.Init(Some Mandatory)
        Password2 = FormField.Init(Some Mandatory)
        Message = None
        Sending = false
        IsFinished = false
        Token = token
    }
    member x.CanSubmit = not x.Sending && x.Password.IsValid && x.Password2.IsValid

type MessageModel =
    {
        Text: string
    }
    static member Init(text) = { Text = text }

type Model =
    | LostPasswordModel of LostPasswordModel
    | ResetPasswordModel of ResetPasswordModel
    | MessageModel of MessageModel
    member x.CanSubmit =
        match x with
        | LostPasswordModel m -> m.CanSubmit
        | ResetPasswordModel m -> m.CanSubmit
        | _ -> false
