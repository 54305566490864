module Tickets.View

open Bfs.Web.Data.Service.Contracts.Events
open Bfs.Web.Kundenportal.WebParts.User.Events.Easter
open Bfs.Web.Portale.Collapsable
open Bfs.Web.Portale.UploadBox
open Fable.React
open Browser
open Bfs.Web.Data.Service.Contracts.Kundenportal.Help
open Bfs.Web.Shared.Formating
open Types
open Fable.React.Props
open ViewParts
open Fulma

let ticketInfoBox ostergewinnspielActive =
    div [ Class "is-relative" ] [
        div [
            Class "flat-card upload-card is-auto"
        ] [
            div [ Class "card-body" ] [
                div [ Class "image-wrapper" ] [
                    img [ Src "help/bfs_ticketsystem_4.png" ]
                ]
                div [ Class "username has-text-centered" ] [
                    str "Erstellen Sie hier ein Ticket an unseren Kundenservice."
                ]
            ]
        ]

        if ostergewinnspielActive then
            Egg Color.Green Variant.Lines [ Top "20%" ]
    ]

let ticketRow =
    FunctionComponent.Of( fun (ticket: UserTicketListItem) ->

        let isExpanded = Hooks.useState(false)
        let chevronClass = if isExpanded.current then "upsidedown" else System.String.Empty

        fragment
            [  ]
            [
                tr [
                    Class "has-additional-data-row"
                    OnClick (fun _ -> isExpanded.update (isExpanded.current |> not))
                ] [
                    td [] [ str (ticket.Datum |> asString) ]
                    td [] [ str (ticket.Nummer |> Option.map (fun n -> $"#{n}") |> Option.defaultValue "") ]
                    td [ Class "nowrap" ] [
                        let iconclass =
                            match ticket.IstErledigt, ticket.Antworten with
                            | false, _ -> "fas fa-business-time"
                            | true, [] -> "fas fa-check-square"
                            | true, _ -> "fas fa-envelope-open-text"

                        span [ Class "icon" ] [ i [ Class iconclass ] [] ]

                        span [ Class "is-hidden-mobile" ] [ str (ticket |> ticketStatus) ]

                    ]

                    td [ Class "is-hidden-mobile ticket-title" ] [
                        let content =
                            div [ Style [ WhiteSpace WhiteSpaceOptions.PreWrap ] ]
                                [ str ticket.Betreff ]
                        CollapsableElement (content, isExpanded.current)
                    ]

                    td [] [ linkicon $"fas fa-chevron-down {chevronClass}" ]


                ]
                tr [ Class "additional-data" ] [
                    td [ ColSpan 5 ] [
                        let content =
                            div [ Class "ticket-body" ] [
                                div [ Class "is-hidden-tablet" ] [ b [] [ str ticket.Betreff ] ]
                                div [ Style [ WhiteSpace WhiteSpaceOptions.PreWrap ]  ] [ str ticket.Text ]
                                if Option.isSome ticket.Attachments then
                                    div [] [
                                        str $"Anhänge: {ticket.Attachments.Value}"
                                    ]
                                if not ticket.Antworten.IsEmpty then
                                    div [] [
                                        br []
                                        b [] [ str "Antwort BFS:" ]
                                        br []
                                        for antwort in ticket.Antworten do
                                            p [ DangerouslySetInnerHTML { __html = antwort } ] [ ]
                                            hr   []
                                    ]
                            ]
                        CollapsableElement (content, isExpanded.current)
                    ]
                ]
            ]
    )

let ticketTable (model: Model) dispatch =
    div [] [
        div [
            Class "flat-card profile-info-card is-auto"
        ] [
            div [ Class "card-title-with-tabs" ] [
                Tabs.tabs [ Tabs.CustomClass "account-tabs" ] [
                    Tabs.tab [
                        Tabs.Tab.CustomClass "submenu-item"
                        Tabs.Tab.IsActive model.ShowOnlyMyTickets
                    ] [
                        a [
                            OnClick(fun _ -> LoadUserTicketList true |> dispatch)
                        ] [ str "Meine Tickets" ]
                    ]
                    Tabs.tab [
                        Tabs.Tab.CustomClass "submenu-item"
                        Tabs.Tab.IsActive(not model.ShowOnlyMyTickets)
                    ] [
                        a [
                            OnClick(fun _ -> LoadUserTicketList false |> dispatch)
                        ] [ str "Alle Tickets" ]
                    ]
                ]
            ]
            div [ Class "card-body" ] [
                match model.UserTickets with
                | Empty -> str "Keine Tickets vorhanden"
                | Body x when x.IsEmpty -> str "Keine Tickets vorhanden"
                | Loading -> spinner
                | LoadError e -> errorMsg e
                | Body tickets ->
                    table [
                        Class "table table-striped responsive-table "
                    ] [
                        thead [] [
                            tr [] [
                                th [] [ str "Datum" ]
                                th [] [ str "Ticket" ]
                                th [] [ str "Status" ]
                                th [ Class "is-hidden-mobile" ] [ str "Betreff" ]
                                th [] [] // klappicon
                            ]
                        ]
                        tbody [] (tickets |> List.map ticketRow)
                    ]
            ]
        ]
    ]

let ticketUploadBox model dispatch =
    UploadBox (
        (Accept.Only [ UploadFileType.Pdf; UploadFileType.Image ]),
        (fun filelist -> filelist |> UploadFiles |> dispatch),
        (fun file -> file |> DeleteUploadedFile |> dispatch),
        model.CurrentUploadList,
        model.CurrentUploadCount,
        None)

let form model dispatch =
    let isLoading = (if model.Sending then " is-loading" else "")

    div [ Class "paragraphs datenuebergabe" ] [
        p [] [
            str (
                sprintf
                    "Hier können Sie als %s ein neues Ticket anlegen. Eine Rückmeldung erhalten Sie von uns per E-Mail. Wir werden Sie unter der E-Mail-Adresse %s kontaktieren."
                    model.Name
                    model.Mail
            )
        ]

        validatedTextBox
            (dispatch << BetreffChanged)
            "Betreff:"
            "Betreff"
            "Bitte geben Sie einen Betreff ein"
            model.Betreff

        div [ Class "columns" ] [
            div [ Class "column is-two-third" ] [
                validatedTextarea
                    (dispatch << TextChanged)
                    "Ihre Nachricht:"
                    "Text"
                    "Bitte schildern Sie hier genau Ihren Sachverhalt. Alle Details und Informationen helfen unserem Kundenservice-Team bei der Recherche und Lösung Ihres Anliegens."
                    "is-big"
                    model.Text
            ]
            div [ Class "column is-one-third field" ] [
                label [ Class "label" ] [ str "Ihre Dateianhänge:" ]
                ticketUploadBox model dispatch
            ]
        ]

        p [ Class "is-pulled-right" ] [
            button [
                Class("bfsbutton wide " + isLoading)
                Disabled(not (model.CanSubmit))
                Props.OnClick(fun _ -> Submit |> dispatch)
            ] [ str "Absenden" ]
        ]
    ]

let showMsg result =
    match result with
    | Success ->
        div [ Class "paragraphs" ] [
            p [] [ str "Vielen Dank für Ihre Anfrage!" ]
            p [] [
                str
                    "Bei der Bearbeitung Ihres Anliegens ist uns nicht nur Schnelligkeit, sondern auch eine gewissenhaft recherchierte Antwort wichtig. Diese Vorbereitungen und Recherche benötigen Zeit. Bitte haben Sie daher Verständnis dafür, dass für jede Anfrage ein individueller Zeitaufwand entsteht, der die Antwortzeit beeinflusst."
            ]
            p [] [
                str "Unser Kundenservice wird Sie, über Ihren bevorzugten Kontaktweg, benachrichtigen."
            ]
        ]
    | TooManyRequests ->
        div [ Class "paragraphs" ] [
            p [ Class "error-msg" ] [
                b [] [
                    str
                        $"Das Limit der erstellen Tickets wurde erreicht. In wenigen Augenblicken können Sie uns jedoch weitere Tickets senden."
                ]
            ]
            p [] [
                str "Vielen Dank für Ihr Verständnis."
            ]
        ]
    | PayloadTooLarge ->
        div [ Class "paragraphs" ] [
            p [ Class "error-msg" ] [
                b [] [
                    str
                        $"Leider haben Sie das Limit von {sizeLimitMB} MB Anhängen pro Ticket überschritten und Ihr Ticket wurde nicht versendet."
                ]
            ]
            p [] [
                str "Bitte reduzieren Sie die Größe Ihrer Anhänge oder nutzen Sie einen anderen Kontaktweg."
            ]
            p [] [ str "Vielen Dank." ]
        ]
    | Error ->
        div [ Class "paragraphs" ] [
            p [ Class "error-msg" ] [
                str "Leider ist ein Fehler aufgetreten und Ihr Ticket wurde nicht versendet."
            ]
            p [] [
                str "Bitte geben Sie Ihr Ticket erneut ein oder nutzen Sie einen anderen Kontaktweg."
            ]
            p [] [ str "Vielen Dank." ]
        ]

let main ostergewinnspielActive model dispatch =
    div [ Class "column is-9" ] [
        div [
            Class "is-relative"
        ] [
            div [
                Class "flat-card profile-info-card is-auto"
            ] [
                div [ Class "card-title" ] [
                    h3 [] [
                        b [] [
                            str (
                                match model.SendingResult with
                                | Some _ -> "Ticket angelegt"
                                | None -> "Neues Ticket anlegen"
                            )
                        ]
                    ]
                ]
                div [ Class "card-body" ] [
                    match model.SendingResult with
                    | Some msg -> yield showMsg msg
                    | None -> yield form model dispatch
                ]
            ]

            if ostergewinnspielActive then
                Egg Color.Blue Variant.Lines [ Left "30%" ]
                Egg Color.Pink Variant.Stripped [ Left "100%"; Top "30%" ]
                Egg Color.Green Variant.Dotted [ Top "80%" ]
                clickableEgg
                    {
                        OnCLick = (fun _ -> (EasterEgg.Tickets, None) |> EasterEggFound |> EventMsg |> GlobalMsg |> dispatch)
                        Found = model.EggFound
                        Css = [ Top "100%"; Left "15%" ]
                    }
        ]

        ticketTable model dispatch
    ]



let view model dispatch (navigateTo: AnyPage -> unit) =
    div [
        Class "ticketsystem columns is-account-grid is-multiline"
    ] [
        div [ Class "column is-3" ] [ ticketInfoBox model.OstergewinnspielActive ]
        main model.OstergewinnspielActive model dispatch
    ]
