﻿module DokumentenDepot.WebPart

open WebPart
open Types
open State

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "Dokumenten Depot"

    BuildUrl = fun _ -> [ Urls.dokumentenDepot ]

    ParseUrl =
        function
        | [ Urls.dokumentenDepot ] -> Some Page.List
        | _ -> None
}
