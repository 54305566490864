module Settings.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun model -> Header "Einstellungen"

    BuildUrl = fun _ -> [ Urls.settings ]

    ParseUrl =
        function
        | [ Urls.settings ] -> Some Page.Settings
        | _ -> None
}
