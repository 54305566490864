module Usermanagement.Types

open Bfs.Web.Data.Service.Contracts.Kundenportal.UserManagement
open Types
open Fetch
open Validation

type Page = | UserList


type EditUserDialogMsg =
    | InitDialog
    | LoadUserManagedRoles
    | UserManagedRolesLoaded of Result<UserManagedRole list, exn>
    | LoginChanged of string
    | EmailChanged of string
    | NameChanged of string
    | ToggleRole of UserManagedRole
    | Cancel
    | Save
    | Saved of Result<Result<unit, string>, exn>
    | Updated of Result<Result<unit, string>, exn>

type Msg =
    | LoadUserList
    | UserListLoaded of Result<UserInfo list, exn>

    | EnableUser of id: int
    | DisableUser of id: int
    | UserEnabledChanged of Result<Response, exn> // sperren und freigeben

    | ResetPassword of userInfo: UserInfo
    | ResetPasswordConfirmed of userInfo: UserInfo
    | ResetPasswordSubmitted of Result<Result<unit, string>, exn>

    | DeleteUser of UserInfo
    | DeleteUserConfirmed of id: int
    | UserDeleted of Result<Result<unit, string>, exn>

    | DialogMsg of EditUserDialogMsg
    | CreateNewUser
    | EditUserRoles of userInfo: UserInfo

    | GlobalMsg of GlobalMsg

type UserEditModel =
    {
        Id: int option
        Login: FormField
        Email: FormField
        Name: FormField
        IsDirty: bool
        Sending: bool
        UserRoles: UserManagedRole list
        AllUsermanagedRoles: Remote<UserManagedRole list>
        IsCurrentUser: bool
    }
    static member InitNew() = {
        Id = None
        Login = FormField.Init(Some Mandatory)
        Email = FormField.Init(Some ValidateEmailAddress)
        Name = FormField.Init(Some Mandatory)
        Sending = false
        IsDirty = false
        UserRoles = []
        AllUsermanagedRoles = Loading
        IsCurrentUser = false
    }

    static member InitExisting(userInfo: UserInfo) = {
        Id = Some userInfo.KundenportalBenutzerId
        Login = FormField.InitWithDefault(Some Mandatory, userInfo.Login)
        Email = FormField.InitWithDefault(None, userInfo.Email)
        Name = FormField.InitWithDefault(Some Mandatory, userInfo.Name)
        Sending = false
        IsDirty = false
        UserRoles = userInfo.BenutzerverwalteteRollen
        AllUsermanagedRoles = Loading
        IsCurrentUser = userInfo.IsCurrentUser
    }

    member x.CanSubmit = x.Email.IsValid && x.Name.IsValid

type Model =
    {
        Users: Remote<UserInfo list>
        ActiveUserDialog: UserEditModel option
    }

    static member Init = {
        Users = Loading
        ActiveUserDialog = None
    }
