module AdminFile.View

open Fable.React

open Types
open Http
open Bfs.Web.Data.Service.Contracts.Kundenportal.File
open Fable.React.Props
open ViewParts

let tableRow dispatch (file: AdminFileListItem) =
    let aktiv = if file.IsHidden then "deaktiviert" else ""

    tr [ Class aktiv ] [
        td [] [ str (file.Kundennummer.ToString()) ]
        td [] [ str file.Kategorie ]
        td [] [
            str file.Titel
            br []
            div [ Class "is-size-7" ] [ str file.Filename ]
        ]
        td [] [
            str (file.Datum.ToString("dd.MM.yyyy"))
        ]

        td [] [
            if file.IsHidden then
                yield
                    a [
                        OnClick(fun _ -> (ActivateFile(file.Id.ToString(), file.Type)) |> dispatch)
                        Title "aktivieren"
                    ] [ icon "fas fa-eye" ]
            else
                yield
                    a [
                        OnClick(fun _ -> (DeactivateFile(file.Id.ToString(), file.Type)) |> dispatch)
                        Title "deaktivieren"
                    ] [ icon "fas fa-eye-slash" ]

            yield
                a [
                    Class "download-icon fas fa-file-download"
                    Title "herunterladen"
                    Href(downloadUrl file)
                ] []
            if (file.HasMetadata) then
                yield
                    a [
                        Class "download-icon fas fa-file-csv"
                        Title "Inhaltsverzeichnis herunterladen"
                        Href(downloadMetadataUrl file)
                    ] []


        ]
    ]

let table files dispatch =
    table [
        Class "table table-striped responsive-table"
    ] [
        thead [] [
            tr [] [
                th [] [ str "Kunde" ]
                th [] [ str "Kategorie" ]
                th [] [ str "Titel & Dateiname" ]
                th [] [ str "Datum" ]
                th [] [ str "Aktionen" ]
            ]
        ]
        tbody [] (files |> List.map (tableRow dispatch))
    ]

let listPage (model: Model) dispatch navigateTo =
    let filterAlle, filterKunde =
        match model.Kundennummer with
        | Some _ -> "is-outlined", ""
        | None -> "", "is-outlined"

    div [ Class "columns is-multiline" ] [
        div [ Class "column is-12" ] [
            yield
                div [
                    Class "flat-card profile-info-card is-auto"
                ] [
                    div [ Class "card-body" ] [
                        yield
                            div [ Class "field is-grouped" ] [

                                div [ Class "field has-addons" ] [
                                    p [ Class "control" ] [
                                        button [
                                            Class("button is-link is-small is-rounded is-info " + filterAlle)
                                            OnClick(fun _ -> FilterForKundennummerClear |> dispatch)
                                        ] [ str "alle Kunden" ]
                                    ]
                                    div [ Class "control" ] [
                                        input [
                                            Class "input is-info is-small"
                                            Type "text"
                                            Placeholder "Kundennummer"
                                            Value model.KundennummernFilterText
                                            OnChange(fun ev -> KundennummernFilterTextChanged ev.Value |> dispatch)
                                            OnKeyDown(fun (e) ->
                                                if (e.key = "Enter") then
                                                    FilterForKundennummer |> dispatch)
                                        ]
                                    ]
                                    div [ Class "control" ] [
                                        a [
                                            Class("button is-info is-small is-rounded " + filterKunde)
                                            OnClick(fun _ -> FilterForKundennummer |> dispatch)
                                            Disabled(not model.KundennummernFilterEnabeld)
                                        ] [ str "nur eine Kundennummer" ]
                                    ]
                                ]

                                div [ Class "hspacer" ] []

                                div [ Class "field has-addons" ] [
                                    div [ Class "control" ] [
                                        select [
                                            Class "input has-background-info has-text-white is-info is-small is-rounded"
                                            OnChange(fun ev ->
                                                match ev.Value with
                                                | "" -> FilterForKategorie None |> dispatch
                                                | k -> FilterForKategorie(Some k) |> dispatch)
                                        ] [
                                            yield
                                                option [
                                                    Value ""
                                                    Selected(model.Kategorie = None)
                                                ] [ str "alle Kategorien" ]
                                            yield!
                                                model.AllKategorien
                                                |> List.sort
                                                |> List.map (fun s ->
                                                    option [
                                                        Value s
                                                        Selected(model.Kategorie = Some s)
                                                    ] [ str s ])
                                        ]
                                    ]

                                ]
                            ]

                        yield
                            match model.Files with
                            | Empty -> div [] []
                            | Loading -> spinner
                            | LoadError s -> errorMsg "Fehler beim Laden der Dateien!"
                            | Body x when x.Items = [] -> div [] [ str "Keine Dateien vorhanden" ]
                            | Body x -> table x.Items dispatch
                    ]
                ]
            match model.Files with
            | Body x ->
                yield pagination x (fun page -> navigateTo (Page.List(page, model.Kundennummer, model.Kategorie)))
            | _ -> ()
        ]
    ]

let view (model: Types.Model) dispatch (navigateTo: AnyPage -> unit) = listPage model dispatch navigateTo
