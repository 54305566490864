namespace AdminNews

open System
open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.News
open Bfs.Web.Data.Service.Contracts.Kundenportal.Admin
open Validation


module Http =
    let getNewsList (page, filter) =
        let url =
            match filter with
            | Alle -> (sprintf "/api/admin/news?page=%i" page)
            | Pflege -> (sprintf "/api/admin/news/pflege?page=%i" page)
            | Therapie -> (sprintf "/api/admin/news/therapie?page=%i" page)
            | BfsPlus -> (sprintf "/api/admin/news/bfsplus?page=%i" page)
            | Jugendhilfe -> (sprintf "/api/admin/news/jugendhilfe?page=%i" page)
            | Kundennummer kdnr -> (sprintf "/api/admin/news/kunde/%i/?page=%i" kdnr page)

        fetchAs<Paginated<AdminNewsListItem>> url
    let getNews (id) = fetchAs<AdminInfos<AdminNews>> (sprintf "/api/admin/news/%s" id)

    let getAllKundennummern () = fetchAs<int list> "/api/admin/kundennummern"

    let postNews (id, news) = postRecord<AdminNews> (sprintf "/api/admin/news/%s" id) (news)
    let inline putNews (news) = promise {
        let! response = putRecord "/api/admin/news" (news)
        let! id = response.json<Guid> ()
        return id
    }

    let postActivate (id) = postEmpty (sprintf "/api/admin/news/%s/active" id)
    let postDeactivate (id) = postEmpty (sprintf "/api/admin/news/%s/inactive" id)

    let postImage (id, file) =
        file
        |> uploadFile<bool> (sprintf "/api/admin/news/%s/image" id)

    let imageUrl (id) =
        // der Ticks-tag verhindert, dass der browser gecached image zieht
        // Poor-Mans-Cache-Invalidation
        (sprintf "/api/admin/news/%s/image?t=%i" id DateTime.Now.Ticks)


module State =
    let init page user (lastModel: AdminNews.Types.Model option) =
        match page with
        | NewsList pagenumber ->
            let kdnr =
                match lastModel with
                | Some(ListModel m) -> m.AllKundennummern
                | Some(ItemModel m) -> m.AllKundennummern
                | None -> []

            let initialModel = ListModel(NewsListModel.Init pagenumber kdnr)
            let cmd1 = Cmd.ofMsg (LoadNewsPage pagenumber)
            let cmd2 = if (kdnr = []) then Cmd.ofMsg LoadAllKundennummern else Cmd.none
            (initialModel, Cmd.batch [ cmd1; cmd2 ])

        | NewsEdit id ->
            let backToPage, kdnr =
                match lastModel with
                | Some(ListModel m) -> m.PageNumber, m.AllKundennummern
                | _ -> 1, []

            let initialModel = ItemModel(NewsModel.Init (Some id) backToPage kdnr)
            let cmd1 = Cmd.ofMsg (LoadNews id)
            let cmd2 = if (kdnr = []) then Cmd.ofMsg LoadAllKundennummern else Cmd.none
            (initialModel, Cmd.batch [ cmd1; cmd2 ])

        | NewsNew ->
            let backToPage, kdnr =
                match lastModel with
                | Some(ListModel m) -> m.PageNumber, m.AllKundennummern
                | _ -> 1, []

            let initialModel = ItemModel(NewsModel.Init None backToPage kdnr)
            let cmd1 = Cmd.ofMsg NewNews
            let cmd2 = if (kdnr = []) then Cmd.ofMsg LoadAllKundennummern else Cmd.none
            (initialModel, Cmd.batch [ cmd1; cmd2 ])

    let update msg model =
        match msg, model with
        | LoadNewsPage pagenumber, ListModel model ->
            let cmd =
                NewsPageLoaded
                |> request Http.getNewsList (pagenumber, model.Filter)
            ({ model with News = Loading } |> ListModel, cmd)
        | NewsPageLoaded x, ListModel model -> ({ model with News = Loaded x } |> ListModel, Cmd.none)

        | FilterChanged filter, ListModel model ->
            ({ model with Filter = filter } |> ListModel, Cmd.ofMsg (LoadNewsPage 1))
        | FilterForKundennummer, ListModel model ->
            let kdnr = tryInt model.KundennummernFilterText
            let cmd =
                match kdnr with
                | Some kdnr ->
                    if model.AllKundennummern |> Seq.contains kdnr then
                        Cmd.ofMsg (FilterChanged(Kundennummer kdnr))
                    else
                        Cmd.none
                | None -> Cmd.none
            (model |> ListModel, cmd)

        | ActivateNews id, _ -> (model, ActiveChanged |> request Http.postActivate id)
        | DeactivateNews id, _ -> (model, ActiveChanged |> request Http.postDeactivate id)
        | ActiveChanged _, ListModel model -> (model |> ListModel, Cmd.ofMsg (LoadNewsPage model.PageNumber))

        | LoadNews id, ItemModel jobmodel ->
            let cmd = NewsLoaded |> request Http.getNews id
            let model = { jobmodel with Id = Some id }
            (ItemModel model, cmd)

        | NewsLoaded(Ok news), ItemModel model ->
            ({ model with
                Titel = update model.Titel news.Item.Titel
                Text = update model.Text news.Item.Text
                TextAsHtml = Fable.Formatting.Markdown.Markdown.ToHtml news.Item.Text
                GueltigAb =
                    dateUpdate
                        model.GueltigAb
                        model.GueltigAb.DatePickerState
                        (Some news.Item.GueltigAb)
                        (Some news.Item.GueltigAb)
                Aktiv = news.Item.Aktiv
                Kundennummern = news.Item.Kundennummern
                KundenTyp = news.Item.KundenTyp
                HasImage = news.HasImage
                IsDirty = false
             }
             |> ItemModel,
             Cmd.none)
        | NewsLoaded(Error exn), _ -> (model, exn |> errorCmd GlobalMsg "Laden")

        | NewNews, ItemModel model ->
            ({ model with
                Titel = update model.Titel "Neue news"
                GueltigAb =
                    dateUpdate
                        model.GueltigAb
                        model.GueltigAb.DatePickerState
                        (Some DateTime.Today)
                        (Some DateTime.Today)
                Aktiv = true
                IsDirty = true
             }
             |> ItemModel,
             Cmd.none)

        | LoadAllKundennummern, model ->
            let cmd =
                AllKundennummernLoaded
                |> request Http.getAllKundennummern ()
            (model, cmd)
        | AllKundennummernLoaded(Ok lst), ItemModel model ->
            ({ model with AllKundennummern = lst } |> ItemModel, Cmd.none)
        | AllKundennummernLoaded(Ok lst), ListModel model ->
            ({ model with AllKundennummern = lst } |> ListModel, Cmd.none)
        | AllKundennummernLoaded(Error exn), model -> (model, exn |> errorCmd GlobalMsg "Laden aller Kundennummern")

        | TitelChanged value, ItemModel model ->
            ({ model with
                Titel = update model.Titel value
                IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | TextChanged value, ItemModel model ->
            ({ model with
                Text = update model.Text value
                TextAsHtml = Fable.Formatting.Markdown.Markdown.ToHtml value
                IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | GueltigAbChanged(state, date), ItemModel model ->
            ({ model with
                GueltigAb = dateUpdate model.GueltigAb state date None
                IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | AktivChanged value, ItemModel model ->
            ({ model with
                Aktiv = value
                IsDirty = true
             }
             |> ItemModel,
             Cmd.none)
        | KundenTypChanged value, ItemModel model ->
            ({ model with
                KundenTyp = value
                IsDirty = true
             }
             |> ItemModel,
             Cmd.none)

        | DeleteImage, ItemModel model ->
            (model |> ItemModel,
             ImageUploaded
             |> request Http.postImage (model.Id.Value, null))
        | UploadImage file, ItemModel model ->
            (model |> ItemModel,
             ImageUploaded
             |> request Http.postImage (model.Id.Value, file))
        | ImageUploaded(Ok hasImage), ItemModel model -> ({ model with HasImage = hasImage } |> ItemModel, Cmd.none)
        | ImageUploaded(Error exn), model -> (model, exn |> errorCmd GlobalMsg "Speichern des Bilds")

        | KundennummernFilterTextChanged value, ListModel model ->
            let kdnr = tryInt value
            let ok =
                match kdnr with
                | Some kdnr -> model.AllKundennummern |> Seq.contains kdnr
                | None -> false
            ({ model with
                KundennummernFilterText = value
                KundennummernFilterEnabeld = ok
             }
             |> ListModel,
             Cmd.none)

        | KundennummernAddTextChanged value, ItemModel model ->
            let kdnr = tryInt value
            let ok =
                match kdnr with
                | Some kdnr -> model.AllKundennummern |> Seq.contains kdnr
                | None -> false
            ({ model with
                KundennummernAddText = value
                KundennummernAddEnabeld = ok
             }
             |> ItemModel,
             Cmd.none)
        | AddKundennummer, ItemModel model ->
            let kdnr = tryInt model.KundennummernAddText
            let newModel =
                match model.KundennummernAddEnabeld, kdnr with
                | true, Some kdnr ->
                    let lst =
                        model.Kundennummern
                        |> List.append [ kdnr ]
                        |> List.distinct
                        |> List.sort
                    { model with
                        Kundennummern = lst
                        KundennummernAddText = ""
                        KundennummernAddEnabeld = false
                        IsDirty = true
                    }
                | _ -> model
            (newModel |> ItemModel, Cmd.none)
        | RemoveKundennummer kdnr, ItemModel model ->
            let lst = model.Kundennummern |> List.except [ kdnr ] |> List.sort
            ({ model with
                Kundennummern = lst
                IsDirty = true
             }
             |> ItemModel,
             Cmd.none)

        | Save, ItemModel model ->
            let news = {
                Titel = model.Titel.Value
                Text = model.Text.Value
                Aktiv = model.Aktiv
                GueltigAb = model.GueltigAb.Value.Value
                KundenTyp = model.KundenTyp
                Kundennummern = model.Kundennummern
            }

            let cmd =
                match model.Id with
                | Some id -> Saved |> request Http.postNews (id, news)
                | None -> SavedNew |> request Http.putNews (news)

            ({ model with Sending = true } |> ItemModel, cmd)
        | Saved(Ok _), ItemModel model ->
            ({ model with
                Sending = false
                IsDirty = false
             }
             |> ItemModel,
             Cmd.none)
        | Saved(Error e), ItemModel model ->
            ({ model with Sending = false } |> ItemModel, e |> errorCmd GlobalMsg "Speichern")
        | SavedNew(Ok id), ItemModel model ->
            ({ model with
                Sending = false
                IsDirty = false
                Id = Some(id.ToString())
             }
             |> ItemModel,
             Cmd.none)
        | SavedNew(Error e), ItemModel model ->
            ({ model with Sending = false } |> ItemModel, e |> errorCmd GlobalMsg "Speichern")

        | GlobalMsg(_), _ -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!

        | _, _ ->
            Logger.log "AdminNewsMessage konnt nicht dispatched werden"
            Logger.log (model, msg)
            (model, Cmd.none)
