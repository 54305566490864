module Dashboard.DigitaleKundenrechnung

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard
open Bfs.Web.Kundenportal.WebParts.User.Shared.Tile

open Fable.React
open Fable.React.Props
open Elmish


open Types
open Http
open ViewParts

open Dashboard.Shared

type Model = {
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
    Loading: bool
    Data: DigitaleKundenrechnung
    SavingToggle: bool
}

type Msg =
    | LoadData
    | LoadDataCompleted of Result<DigitaleKundenrechnung, exn>
    | ToggleBenachrichtigung
    | NotificationToggled of Result<Fetch.Types.Response, exn>
    | NavigateToVideo

module Http =
    let loadAnstehendeZahlungen () = fetchAs<DigitaleKundenrechnung> "/api/dashboard/digitale-kundenrechnung"
    let setNotificationForBelegpruefungAbgeschlossen enabled =
        let payload = {
            Enabled = enabled
        }
        postRecord<ToggleNotificationRequest> "/api/dashboard/set-notification-for-digitale-kundenrechnung" payload
module State =
    let init (props: DashboardProps) =
        {
            GlobalDispatch = props.GlobalDispatch
            NavigateTo = props.NavigateTo
            Loading = true
            Data = {
                DigitaleKundenrechnungAktiviert = false
            }
            SavingToggle = true
        }, Cmd.ofMsg LoadData

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadData ->
            let cmd =
                LoadDataCompleted
                |> request Http.loadAnstehendeZahlungen ()
            {model with Loading = true
                        SavingToggle = true}, cmd
        | LoadDataCompleted (Ok data) ->
            { model with Loading = false
                         SavingToggle = false
                         Data = data }, Cmd.none
        | LoadDataCompleted (Error _) ->
            let msg = GlobalMessageBox.Error "Ihre Daten zur digitalen Kundenrechnung können zur Zeit leider nicht geladen werden." |> ShowMessageBox
            model.GlobalDispatch msg
            { model with Loading = false }, Cmd.none
        | NavigateToVideo ->
            model.NavigateTo Videos.Types.Page.Videos
            model, Cmd.none
        | ToggleBenachrichtigung ->
            let newValue = not model.Data.DigitaleKundenrechnungAktiviert
            let cmd =
                NotificationToggled
                |> request Http.setNotificationForBelegpruefungAbgeschlossen newValue
            { model with SavingToggle = false
                         Data = { model.Data with DigitaleKundenrechnungAktiviert = newValue}}, cmd
        | NotificationToggled (Ok _) ->
            let msg = GlobalMessageBox.InfoWithTitle "Postzustellung im Kundenportal eingeschaltet" "Zukünftig werden alle Briefe an Sie hier ins Kundenportal zugestellt." |> ShowMessageBox
            model.GlobalDispatch msg
            { model with SavingToggle = false }, Cmd.none
        | NotificationToggled (Error _) ->
            let msg = GlobalMessageBox.Error "Ihre neuen Benachrichtigungseinstellungen können zur Zeit leider nicht gespeichert werden." |> ShowMessageBox
            model.GlobalDispatch msg
            { model with SavingToggle = false
                         Data = { model.Data with DigitaleKundenrechnungAktiviert = not model.Data.DigitaleKundenrechnungAktiviert}}, Cmd.none

let View =
    FunctionComponent.Of(fun (props: DashboardProps) ->
        let model, dispatch = React.useElmish ((State.init props), State.update)
        let title =
            match model.Data.DigitaleKundenrechnungAktiviert with
            | true -> str "Interaktives Video"
            | false -> str "Digitale Kundenrechnung"
        let footer =
            match model.Loading || model.Data.DigitaleKundenrechnungAktiviert with
            | true -> None
            | false -> Some (notificationBox "Digitale Kundenrechnung" false false (fun _ -> ToggleBenachrichtigung |> dispatch) "Digitale-Kundenrechnung-Toggle")
        let body =
            match model.Loading, model.Data.DigitaleKundenrechnungAktiviert with
            | true, _ -> spinner
            | false, true ->
                a [] [
                            video [
                                Style [ CSSProp.Width "100%" ]
                                Src "video/InteraktivesVideoPreview.mp4"
                                AutoPlay true
                                Loop true
                                OnClick(fun _ -> NavigateToVideo |> dispatch)
                            ] []
                            ]
            | false, false ->
                div
                             [ Class "digitale-kundenrechnung" ]
                             [
                               div [ Class "is-hidden-mobile is-hidden-tablet-only is-hidden-desktop-only dashboard-hero" ]
                                   [
                                        img [
                                            Class "dashboard-icon"
                                            Src "dashboard/Kundenrechnung.svg"
                                        ]
                                   ]
                               div [ Class "" ]
                                   [
                                       div []
                                           [
                                               b [ ]
                                                 [ str "Umweltfreundlich & schnell" ]
                                           ]
                                       div [ Class "is-size-7" ]
                                           [ str "Erhalten Sie Ihre Kundenrechnung und Benachrichtigungen via E-Mail." ]
                                       a [ Class "is-size-7"
                                           Href "/#settings" ]
                                         [ str "Mehr erfahren!" ]
                                   ]
                             ]
        bfsTile
            title
            None
            None
            body
            footer
    )
