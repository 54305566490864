module Kundenportal.Types

open Types

type Filter =
    | Alle
    | NurNeue
    | Kategorie of string

module VimeoIds =
    let Quickfinder = VimeoId "594551227"

module SessionDataKeys =

    [<Literal>]
    let Kundennummer = "Kundennummer"

    [<Literal>]
    let UserId = "UserId"

    [<Literal>]
    let SprinklrChatHash = "SprinklrChatHash"

    [<Literal>]
    let FirstName = "FirstName"

    [<Literal>]
    let LastName = "LastName"

module SessionDataDefaultValue =
    [<Literal>]
    let Default = ""
