﻿namespace LetzteSendung


open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard.Lists
open Elmish
open Types
open Http

module Http =
    let getData _ = fetchAs<SendungBelegeDetailsBlock list> "/api/dashboard/meine-letzte-sendung-list"

module State =
    let init _ _ _ =
        {
            Loading = true
            Data = List.Empty
        },
        Cmd.ofMsg LoadData

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadData ->
            { model with Loading = true
                         Data = [ ] },
            (DataLoaded |> request Http.getData ())
        | DataLoaded (Ok data) ->
            { model with
                Loading = false
                Data = data
            },
            Cmd.none
        | DataLoaded (Error ex) ->
            { model with
                Loading = false
                Data = [  ]
            },
            Cmd.ofMsg (
                GlobalMsg(
                    ShowMessageBox(
                        GlobalMessageBox.Error "Die Daten können zur Zeit leider nicht geladen werden."
                    )
                )
            )
        | GlobalMsg(_) ->
            model, Cmd.none
