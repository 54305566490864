﻿module Melibo.WebPart

open Types
open WebPart

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "KI-Assistent"

    BuildUrl = fun _ -> [ Urls.melibo ]

    ParseUrl =
        function
        | [ Urls.melibo ] -> Some Page.Chat
        | _ -> None
}
