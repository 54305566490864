﻿module Dashboard.MeineZahlungen

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard
open Bfs.Web.Kundenportal.WebParts.User.Shared.Tile

open Fable.React
open Fable.React.Props
open Elmish


open Feliz.Recharts
open Fulma
open Types
open Http
open ViewParts

open Dashboard.Shared

open Bfs.Web.Shared.Formating

type DataToDisplay =
    | Volumen
    | Zahlungen

type Model = {
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
    Loading: bool
    Data: MeineZahlen list
    DataToDisplay: DataToDisplay
    VolumenTotal: decimal
    ZahlungenTotal: decimal
}

type Msg =
    | LoadData
    | LoadDataCompleted of Result<MeineZahlen list, exn>
    | SwitchDataToDisplay of DataToDisplay

module Http =
    let loadAnstehendeZahlungen () = fetchAs<MeineZahlen list> "/api/dashboard/meine-zahlen"

module State =
    let init (props: DashboardProps) =
        {
            GlobalDispatch = props.GlobalDispatch
            NavigateTo = props.NavigateTo
            Loading = true
            Data = []
            DataToDisplay = DataToDisplay.Volumen
            VolumenTotal = 0M
            ZahlungenTotal = 0M
        }, Cmd.ofMsg LoadData

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadData ->
            let cmd =
                LoadDataCompleted
                |> request Http.loadAnstehendeZahlungen ()
            {model with Loading = false}, cmd
        | LoadDataCompleted (Ok data) ->
            { model with Loading = false
                         Data = data
                         VolumenTotal = data |> List.map (fun x -> x.Volumen) |> List.sum
                         ZahlungenTotal = data |> List.map (fun x -> x.Zahlungen) |> List.sum }, Cmd.none
        | LoadDataCompleted (Error _) ->
            let msg = GlobalMessageBox.Error "Ihre Zahlen können zur Zeit leider nicht geladen werden." |> ShowMessageBox
            model.GlobalDispatch msg
            { model with Loading = false }, Cmd.none
        | SwitchDataToDisplay toDisplay ->
            { model with DataToDisplay = toDisplay }, Cmd.none

let View =
    FunctionComponent.Of(fun (props: DashboardProps) ->
        let model, dispatch = React.useElmish ((State.init props), State.update)
        let title = str "Meine Zahlen"
        let titleActions =
            match model.Loading with
            | true -> None
            | false ->
                Some (
                    div [ Class "block" ]
                        [
                            Button.button [ Button.Color (if model.DataToDisplay = DataToDisplay.Volumen then CustomColors.bfsOrange else IsWhite)
                                            Button.Size Size.IsSmall
                                            Button.OnClick (fun _ -> (SwitchDataToDisplay DataToDisplay.Volumen) |> dispatch)
                                            Button.CustomClass "is-rounded" ]
                                          [ str "Sendungswert" ]
                            Button.button [ Button.Color (if model.DataToDisplay = DataToDisplay.Zahlungen then CustomColors.bfsOrange else IsWhite)
                                            Button.Size Size.IsSmall
                                            Button.OnClick (fun _ -> (SwitchDataToDisplay DataToDisplay.Zahlungen) |> dispatch)
                                            Button.CustomClass "is-rounded" ]
                                          [ str "Zahlungen" ]
                        ])
        let body =
            match model.Loading with
            | true -> spinner
            | false ->
                div [ Class "meine-zahlen" ]
                    [
                        div [ Class "meine-zahlen-description"
                              Key "meine-zahlen-description" ]
                           (
                                match model.DataToDisplay with
                                | Volumen ->
                                    [
                                        div [ Class "large" ] [ str "Sendungswert" ]
                                        div [ Class "subtle" ]
                                            [ str $"In den vergangenen {model.Data.Length} Monaten haben Sie Sendungen im Wert von {model.VolumenTotal |> asMoney} eingereicht." ]
                                    ]
                                | Zahlungen ->
                                    [
                                        div [ Class "large" ] [ str "Erhaltene Zahlungen" ]
                                        div [ Class "subtle" ]
                                            [ str $"In den vergangenen {model.Data.Length} Monaten haben Sie Gesamtzahlungen in Höhe von {model.ZahlungenTotal |> asMoney} erhalten." ]
                                    ]
                           )
                        div [
                                   Class "meine-zahlen-chart"
                                   Key "meine-zahlen-chart"
                               ]
                               [
                                   Recharts.responsiveContainer [
                                     responsiveContainer.width (Feliz.length.percent 100)
                                     responsiveContainer.height 190
                                     responsiveContainer.chart
                                           (Recharts.barChart [
                                               barChart.margin (0,0,0,0)
                                               barChart.data model.Data
                                               barChart.width 500
                                               barChart.height 190
                                               barChart.children [
                                                    Recharts.xAxis [
                                                        xAxis.dataKey (fun (point: MeineZahlen) -> (point.Monat.Chars 0).ToString ())
                                                        xAxis.tick {| fontSize = 12; fill = "lightgrey" |}
                                                        RechartExtensions.xAxis.stroke "transparent"
                                                    ]
                                                    Recharts.yAxis [
                                                        yAxis.tickCount 4
                                                        RechartExtensions.yAxis.stroke "transparent"
                                                        RechartExtensions.yAxis.tick {| fontSize = 12; fill = "lightgrey" |}
                                                    ]
                                                    Recharts.tooltip [
                                                        tooltip.formatter (fun (value: float) _ (props: {|payload: MeineZahlen|}) ->
                                                            [ (value |> asMoneyFloat) :> obj; props.payload.Monat; props ] |> List.toArray)
                                                        RechartExtensions.tooltip.cursor {| fill= "#EEE" |}
                                                        RechartExtensions.tooltip.labelFormatter (fun _ -> "")
                                                    ]
                                                    Recharts.bar [
                                                        bar.dataKey (fun (point: MeineZahlen) ->
                                                            float (match model.DataToDisplay with
                                                                   | Volumen -> point.Volumen
                                                                   | Zahlungen -> point.Zahlungen))
                                                        RechartExtensions.bar.radius ([ 10; 10; 0; 0 ] |> List.toArray)
                                                        bar.barSize 15
                                                        bar.children
                                                            (model.Data
                                                             |> List.map (fun data -> Recharts.cell [
                                                                 cell.fill (if data.IstLetztesJahr then "lightgray" else "gray")
                                                                 cell.key data.Monat
                                                             ]))
                                                    ]
                                               ]
                                           ])
                                    ]
                               ]
                    ]

        bfsTile
            title
            titleActions
            None
            body
            None
    )
