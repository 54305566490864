module Faq.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg = fun _ -> None

    GetHeader = fun _ -> NoHeader // da es hier auch noch das Suchfeld geben soll, machen wir hier KEINE Header sondern dies passiert direkt im view

    BuildUrl = fun _ -> [ Urls.faq ]

    ParseUrl =
        function
        | [ Urls.faq ] -> Some Page.Faq
        | _ -> None
}
