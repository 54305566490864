﻿namespace Melibo

open Bfs.Web.Data.Service.Contracts.Kundenportal.ChatBot

open Types
open Elmish
open Http

module Http =
    let getToken () = postEmpty2<TokenResult> "api/chatbot/token"

module State =
    let init _ _ _  : Model * Cmd<Msg> =
        {
            Token = Remote.Loading
        }, Cmd.ofMsg LoadToken

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadToken ->
            { model with Token = Remote.Loading },
            (LoadTokenCompleted |> request Http.getToken ())
        | LoadTokenCompleted (Ok token) ->
            { model with Token = Remote.Body token },
            Cmd.none
        | LoadTokenCompleted (Result.Error ex) ->
            Logger.error ex
            { Token = Remote.LoadError "Der Chat kann aktuell leider nicht geladen werden" }, Cmd.none
        | GlobalMsg(_) ->
            model, Cmd.none
