﻿module Bfs.Web.Kundenportal.WebParts.User.Events.Easter

open Fable.React
open Fable.React.Props

type Color =
    | Blue
    | Yellow
    | Green
    | Orange
    | Pink

type Variant =
    | Dotted
    | Stripped
    | Lines

let Egg (color: Color) (variant: Variant) (css: CSSProp list) =
    let colorName =
        match color with
        | Blue -> "blau"
        | Yellow -> "gelb"
        | Green -> "gruen"
        | Orange -> "orange"
        | Pink -> "rosa"

    let variantName =
        match variant with
        | Dotted -> "1"
        | Stripped -> "2"
        | Lines -> "3"

    div
        [
            Class "easteregg"
            Style [
                BackgroundImage $"url(events/easter/egg_deko_{variantName}_{colorName}.svg)"
                yield! css
            ]
        ]
        [ ]

type ClickableEggProps = {
    OnCLick: unit -> unit
    Found: bool
    Css: CSSProp list
}

let clickableEgg =
    FunctionComponent.Of(fun (props: ClickableEggProps) ->

        let found = Hooks.useState props.Found
        let foundClass = if found.current then "found" else ""

        a
            [
                OnClick (fun _ ->
                    if found.current |> not then
                        props.OnCLick ()
                        found.update true
                )
                Class $"easteregg clickable {foundClass}"
                Style [
                    yield! props.Css
                ]
                if found.current then
                    Title "Dieses Ei haben Sie schon gefunden."
            ]
            [ ])
