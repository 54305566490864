module Logger

open Browser

let inline error msg = console.error msg

let inline errorfn fn msg = printfn fn msg

let inline log (msg: 'a) =
#if DEBUG
    console.log msg
#else
    ()
#endif

let inline debug (info: obj) = log ("[Debug]", info)

let inline debugfn fn info = log ("[Debug] " + sprintf fn info)
