module Tickets.Types

open System
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Validation
open Types
open Browser
open Bfs.Web.Data.Service.Contracts.Kundenportal.Help

type Page =
    | Ticket
    | PrefilledTicket of string

type Msg =
    | LoadUserTicketList of onlyMyTickets: bool
    | UserTicketListLoaded of Result<UserTicketListItem list, exn>
    | BetreffChanged of string
    | TextChanged of string
    | Submit
    | Submitted of Result<Fetch.Types.Response, exn>
    | UploadFiles of Types.File list // the JS File object
    | FileUploaded of Result<UploadResult, exn>
    | DeleteUploadedFile of UploadResult
    | UploadedFileDeleted of Result<Guid, exn>

    | LoadEventSettings
    | LoadEventSettingsCompleted of Result<EventSettings, exn>

    | GlobalMsg of GlobalMsg

type ResultText =
    | Success
    | TooManyRequests
    | PayloadTooLarge
    | Error

type Model =
    {
        Name: string
        Mail: string

        Betreff: FormField
        Text: FormField

        Sending: bool
        SendingResult: ResultText option

        CurrentUploadCount: int
        Uploading: bool
        CurrentUploadList: UploadResult list
        CurrentUploadSizeSum: int

        ShowOnlyMyTickets: bool
        UserTickets: Remote<UserTicketListItem list>

        OstergewinnspielActive: bool
        EggFound: bool
    }
    static member Init name email betreff =
        let mutable model = {
            Name = name
            Mail = email

            Betreff = FormField.Init(Some(MandatoryWithMaxLength 150))
            Text = FormField.Init(Some(MandatoryWithMaxLength 1000))

            Sending = false
            SendingResult = None

            CurrentUploadCount = 0
            Uploading = false
            CurrentUploadList = List.empty
            CurrentUploadSizeSum = 0

            ShowOnlyMyTickets = true
            UserTickets = Loading

            OstergewinnspielActive = false
            EggFound = false
        }

        if not (String.IsNullOrWhiteSpace betreff) then
            model <-
                { model with
                    Betreff = (update model.Betreff betreff)
                }

        model

    member x.CanSubmit =
        not x.Sending
        && not x.Uploading
        && x.Text.IsValid
        && x.Betreff.IsValid

let ticketStatus (ticket: UserTicketListItem) =
    match ticket.IstErledigt, ticket.Antworten with
    | false, _ -> "in Arbeit"
    | true, [] -> "erledigt"
    | true, _ -> "beantwortet"
