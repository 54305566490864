module Dashboard.Types

open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Elmish
open Types

type Msg =
    | LoadEventSettings
    | LoadEventSettingsCompleted of Result<EventSettings, exn>
    | EggFound
    | Callback of AnyWebPartMsg
    | GlobalMsg of GlobalMsg

type Model =
    {
        UserName: string
        UserSession: UserSession option
        OstergewinnspielActive: bool
        EasterEggDashboardFound: bool
        OstergewinnspielUpdateTrigger: int
        MostRecentCallback: AnyWebPartMsg option
        MeliboChatActive: bool
    }
    static member Init(session: UserSession option) =
        let meliboChatActive =
            session
            |> Option.map (fun s -> s.Roles |> List.contains Roles.KundeMitMeliboChat)
            |> Option.defaultValue false

        {
            UserName =
                (match session with
                 | Some session -> session.Name
                 | None -> System.String.Empty)
            UserSession = session
            OstergewinnspielActive = false
            EasterEggDashboardFound = false
            OstergewinnspielUpdateTrigger = 0
            MostRecentCallback = None
            MeliboChatActive = meliboChatActive
        },
        LoadEventSettings |> Cmd.ofMsg

    static member Update msg (model: Model) =
        match msg with
        | LoadEventSettings ->
            let request () = Http.fetchAs<EventSettings> "/api/events/settings"
            model, (LoadEventSettingsCompleted |> Http.request request ())
        | LoadEventSettingsCompleted(Error _) ->
            Logger.error "Die Event-Einstellungen für das Dashboard konnten nicht geladen werden"
            model, Cmd.none
        | LoadEventSettingsCompleted(Ok settings) ->
            { model with
                OstergewinnspielActive = settings.EasterEvent.IsSome
                EasterEggDashboardFound =
                    settings.EasterEvent
                    |> Option.map (fun x -> x.DashboardFound)
                    |> Option.defaultValue false
            },
            Cmd.none
        | EggFound ->
            { model with
                OstergewinnspielUpdateTrigger = model.OstergewinnspielUpdateTrigger + 1
            },
            Cmd.none
        | Callback callback ->
            { model with
                MostRecentCallback = Some callback
            },
            Cmd.none
        | GlobalMsg _ -> model, Cmd.none
