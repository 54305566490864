﻿module Bfs.Web.Portale.Collapsable

open System
open Browser.Types
open Fable.React
open Fable.React.Props

let private attachWindowListener (f: Event->unit) (node: Window) (eventType: string) =
    node.addEventListener(eventType, f)
    { new IDisposable with
        member __.Dispose() = node.removeEventListener(eventType, f) }

let CollapsableElement =
    FunctionComponent.Of( fun (childElement: ReactElement, expanded: bool) ->

            let isExpanded = Hooks.useState(expanded)
            let height = Hooks.useState<double> 0
            let childRef = Hooks.useRef<Element option>(None)

            let computeHeight () =
                height.update (if isExpanded.current then childRef.current.Value.scrollHeight else 0)

            Hooks.useEffect ((fun _ -> isExpanded.update expanded), [| box expanded |])
            Hooks.useEffect (computeHeight, [| box isExpanded; box childElement |])
            Hooks.useEffectDisposable((fun () ->
                (Browser.Dom.window, "resize")
                ||> attachWindowListener (fun _ -> computeHeight ())
            ), [||])

            div [
                Style [
                    CSSProp.Height $"{height.current}px"
                ]
                Class "collapsable"
            ] [
                div [
                    RefValue childRef
                ] [
                    childElement
                ]
            ]
        )
