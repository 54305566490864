﻿module AdminNotification.WebPart

open WebPart
open Types
open AdminNotification.State

let Part = {
    Init = State.init
    Update = State.update
    View = View.view
    GetHeader = fun _ -> Header "Administration: Benachrichtigungen"
    ParseUrl =
        function
            | [ Urls.admin; Urls.notifications ] -> Page.NofiticationList 1 |> Some
            | [ Urls.admin; Urls.notifications; Urls.pageSeperator; page ] ->
                Some(Page.NofiticationList(System.Int32.Parse(page)))
            | [ Urls.admin; Urls.notifications; Urls.newId ] -> Page.AddNotification |> Some
            | _ -> None
    BuildUrl =
        function
        | Page.NofiticationList 1 -> [ Urls.admin; Urls.notifications ]
        | Page.NofiticationList p -> [
            Urls.admin
            Urls.notifications
            Urls.pageSeperator
            string p ]
        | Page.AddNotification -> [ Urls.admin; Urls.notifications; Urls.newId ]
    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None
}
