namespace Usermanagement

open Elmish
open Types
open Http
open Validation
open Helpers

open Bfs.Web.Data.Service.Contracts.Kundenportal.UserManagement


module Http =
    let loadUserlist () = fetchAs<UserInfo list> "api/usermanagement"
    let loadUserManagedRoles () = fetchAs<UserManagedRole list> "api/usermanagement/managedroles"

    let disableUser userId = putRecord<int> "api/usermanagement/disable" userId
    let enableUser userId = putRecord<int> "api/usermanagement/enable" userId
    let deleteUser userId = delete<Result<unit, string>> (sprintf "api/usermanagement/%i" userId)

    let createNewUser userCreationData =
        postRecord2<UserCreationData, Result<unit, string>> "api/usermanagement" userCreationData
    let updateUser updateUserData = putRecord2<UpdateUserData, Result<unit, string>> "api/usermanagement/updateuser" updateUserData
    let resetPassword passwordResetData =
        postRecord2<PasswordResetData, Result<unit, string>> "api/usermanagement/resetpassword" passwordResetData

module State =
    let init page user lastModel =
        match page with
        | UserList ->
            let initialModel = Model.Init
            (initialModel, Cmd.ofMsg LoadUserList)

    let updateUserDialog msg model =
        match msg with
        | InitDialog -> (Some model, Cmd.ofMsg (LoadUserManagedRoles |> DialogMsg))
        | LoadUserManagedRoles ->
            let cmd =
                (UserManagedRolesLoaded >> DialogMsg)
                |> request Http.loadUserManagedRoles ()
            (Some model, cmd)
        | UserManagedRolesLoaded roles ->
            (Some
                { model with
                    AllUsermanagedRoles = Loaded roles
                },
             Cmd.none)

        | LoginChanged value ->
            (Some
                { model with
                    Login = update model.Login value
                    IsDirty = true
                },
             Cmd.none)
        | EmailChanged value ->
            (Some
                { model with
                    Login = if model.Login.Value = model.Email.Value then update model.Login value else model.Login
                    Email = update model.Email value
                    IsDirty = true
                },
             Cmd.none)
        | NameChanged value ->
            (Some
                { model with
                    Name = update model.Name value
                    IsDirty = true
                },
             Cmd.none)
        | ToggleRole role ->
            let newRoles = toggle role model.UserRoles
            (Some { model with UserRoles = newRoles }, Cmd.none)

        | Save ->
            match model.Id with
            | None ->
                let userCreationData = {
                    Email = model.Email.Value
                    Name = model.Name.Value
                    ManagedRoles = model.UserRoles
                }
                let modelNew = { model with Sending = true }
                let cmd =
                    (Saved >> DialogMsg)
                    |> request Http.createNewUser userCreationData
                (Some modelNew, cmd)
            | Some userId ->
                let rolesData = {
                    KundenportalBenutzerId = userId
                    Roles = model.UserRoles
                    Login = model.Login.Value
                    Name = model.Name.Value
                    Email = model.Email.Value
                }
                let cmd = (Updated >> DialogMsg) |> request Http.updateUser rolesData
                (Some model, cmd)
        | Saved(Ok(Ok _)) -> (None, Cmd.ofMsg LoadUserList)
        | Saved(Ok(Error errText)) ->
            let model = { model with Sending = false }
            let errBox = GlobalMessageBox.Error errText
            let msg = errBox |> ShowMessageBox |> GlobalMsg
            (Some model, Cmd.ofMsg msg)
        | Saved(Error e) ->
            let model = { model with Sending = false }
            let cmd = e |> errorCmd GlobalMsg "Speichern"
            (Some model, cmd)
        | Updated(Ok (Ok _)) -> (None, Cmd.ofMsg LoadUserList)
        | Updated(Ok (Error errText)) ->
            let model = { model with Sending = false }
            let errBox = GlobalMessageBox.Error errText
            let msg = errBox |> ShowMessageBox |> GlobalMsg
            (Some model, Cmd.ofMsg msg)
        | Updated(Error e) ->
            let model = { model with Sending = false }
            let cmd = e |> errorCmd GlobalMsg "Speichern"
            (Some model, cmd)

        | Cancel -> (None, Cmd.none)

    let update msg model =
        match msg with
        | LoadUserList ->
            let cmd = UserListLoaded |> request Http.loadUserlist ()
            (model, cmd)
        | UserListLoaded lst -> ({ model with Users = Loaded lst }, Cmd.none)

        | DisableUser id -> (model, UserEnabledChanged |> request Http.disableUser id)
        | EnableUser id -> (model, UserEnabledChanged |> request Http.enableUser id)
        | UserEnabledChanged _ -> (model, Cmd.ofMsg (LoadUserList))

        | ResetPassword user ->
            let pwResetMsg =
                sprintf
                    "Soll das Passwort des Benutzers '%s' wirklich zurückgesetzt werden? Er kann sich dann nicht mehr mit seinem aktuellen Passwort anmelden."
                    user.Email
            let box =
                GlobalMessageBox.ConfirmationBox
                    "Benutzer-Passwort zurücksetzen"
                    pwResetMsg
                    (Some((ResetPasswordConfirmed user) :> AnyWebPartMsg))
            let msg = box |> ShowMessageBox |> GlobalMsg
            (model, Cmd.ofMsg msg)
        | ResetPasswordConfirmed user ->
            let resetData = {
                KundenportalBenutzerId = user.KundenportalBenutzerId
                Email = user.Email
            }
            (model,
             ResetPasswordSubmitted
             |> request Http.resetPassword resetData)
        | ResetPasswordSubmitted(Ok(Ok _)) ->
            let infoBox =
                GlobalMessageBox.Info "Der Benutzer wurde per E-Mail gebeten, sein Passwort zu erneuern."
            let msg = infoBox |> ShowMessageBox |> GlobalMsg
            (model, Cmd.ofMsg msg)
        | ResetPasswordSubmitted(Ok(Error errText)) ->
            let errBox = GlobalMessageBox.Error errText
            let msg = errBox |> ShowMessageBox |> GlobalMsg
            (model, Cmd.ofMsg msg)
        | ResetPasswordSubmitted(Error e) -> (model, e |> errorCmd GlobalMsg "Senden")

        | DeleteUser user ->
            let delMsg = sprintf "Soll der Benutzer '%s' wirklich gelöscht werden?" user.Email
            let box =
                GlobalMessageBox.ConfirmationBox
                    "Benutzer löschen"
                    delMsg
                    (Some((DeleteUserConfirmed user.KundenportalBenutzerId) :> AnyWebPartMsg))
            let msg = box |> ShowMessageBox |> GlobalMsg
            (model, Cmd.ofMsg msg)
        | DeleteUserConfirmed id -> (model, UserDeleted |> request Http.deleteUser id)
        | UserDeleted(Ok _) -> (model, Cmd.ofMsg (LoadUserList))
        | UserDeleted(Error e) ->
            let cmd = e |> errorCmd GlobalMsg "Benutzer löschen"
            (model, cmd)

        | CreateNewUser ->
            let newUser = UserEditModel.InitNew()
            let newModel =
                { model with
                    ActiveUserDialog = Some newUser
                }
            (newModel, Cmd.ofMsg (DialogMsg(InitDialog)))
        | EditUserRoles userInfo ->
            let user = UserEditModel.InitExisting userInfo
            ({ model with
                ActiveUserDialog = Some user
             },
             Cmd.ofMsg (DialogMsg(InitDialog)))

        | DialogMsg m when model.ActiveUserDialog.IsSome ->
            let (newUserModel, newMsg) = updateUserDialog m model.ActiveUserDialog.Value
            ({ model with
                ActiveUserDialog = newUserModel
             },
             newMsg)

        | _ ->
            Logger.log "UsermanagementMessage konnte nicht dispatched werden"
            Logger.log (model, msg)
            (model, Cmd.none)
