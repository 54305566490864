module Sendungen.Types

open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.Sendungen
open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Types
open System
open Browser

type Page = Sendungen of filiale: Guid option

type Msg =
    | LoadFilialen
    | FilialenLoaded of Result<Filiale[], exn>

    | LoadSendungen of filiale: Guid
    | SendungenLoaded of Result<Sendung[], exn>

    | SwitchFiliale of Guid

    | ShowUpload of Sendung
    | CancelUpload
    | SelectSendungsOption of SendungsOption
    | UploadFiles of (Guid * Types.File list) // the JS File object
    | FileUploaded of Result<UploadResult, exn>
    | DeleteUploadedFile of UploadResult
    | UploadedFileDeleted of Result<Guid, exn>

    | UseDokumentenDepot of Sendung
    | CancelUseDokumentenDepot

    | GetVereinbarungForUploads
    | GotVereinbarungForUploads of Result<string, exn>
    | PrintLegal of Title: string * Content: string
    | AuftragErteilen
    | AuftragErteilt of Result<Fetch.Types.Response, exn>

    | GetFlexitarif of Sendung
    | GotFlexitarif of Result<FlexitarifFuerSendung, exn>
    | CancelFlexitarif
    | SelectAuszahlungstag of DateTime
    | ToggleBlitzueberweisung
    | ToggleAgb
    | AcceptAgb
    | ShowAgb of string
    | RequestFlexitarif
    | RequestedFlexitarif of Result<FlexitarifResult, exn>

    | HideInfoBox

    | LoadEventSettings
    | LoadEventSettingsCompleted of Result<EventSettings, exn>

    | GlobalMsg of GlobalMsg

type Model =
    {
        Filialen: Remote<Filiale[]>
        SelectedFilialeId: Guid option

        ShowInfoBox: bool

        Sendungen: Remote<Sendung[]>

        SelectedSendung: Sendung option
        SelectedSendungsOption: SendungsOption option
        CurrentUploadCount: int
        Sending: bool
        CurrentUploadList: UploadResult list

        Flexitarif: FlexitarifFuerSendung option
        FlexitarifAuswahl: FlexitarifAuswahl option
        AgbAkzeptiert: bool

        OstergewinnspielActive: bool
    }
    static member Init = {
        Filialen = Loading
        SelectedFilialeId = None

        ShowInfoBox = true

        Sendungen = Loading

        SelectedSendung = None
        SelectedSendungsOption = None
        CurrentUploadCount = 0
        Sending = false
        CurrentUploadList = List.empty

        Flexitarif = None
        FlexitarifAuswahl = None
        AgbAkzeptiert = false

        OstergewinnspielActive = false
    }
