﻿module DokumentenDepot.View

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard.Lists
open Bfs.Web.Kundenportal.WebParts.User.DashBoardPage
open Fable.React

open Types
open Fable.React.Props

open ViewParts

open Bfs.Web.Shared.Formating


let mobileTable (data: DokumentenDepot list) =
    match data.IsEmpty with
    | true ->
        div [ Class "has-text-centered is-hidden-desktop" ]
            [ str "Keine angekaufte Belege." ]
    | false ->
        div [
            Class "dta-list is-hidden-desktop"
        ] (
            data
            |> List.sortBy (fun x -> x.KlientPatient)
            |> List.sortByDescending (fun x -> x.SendungNr)
            |> (List.map (fun row ->
            div [ Class "dta-item" ] [
                table
                    [  ]
                    [
                        tbody [] [
                            tr [] [
                                td [ Class "pr-4" ] [ str "SendungsNr" ]
                                td [] [ str (row.SendungNr.ToString ()) ]
                            ]
                            tr [] [
                                td [ Class "pr-4" ] [ str "Rechnungsnummer" ]
                                td [] [ str row.RechnungsNrExtern ]
                            ]
                            tr [] [
                                td [ Class "pr-4" ] [ str "Klient/Patient" ]
                                td [] [ str row.KlientPatient ]
                            ]
                            tr [] [
                                td [ Class "pr-4" ] [ str "Eingelagert" ]
                                td [] [ str (row.EingelagertAm |> asString) ]
                            ]
                            tr [] [
                                td [ Class "pr-4" ] [ str "Art" ]
                                td [] [ str row.DokumentArt ]
                            ]
                            tr [] [
                                td [ Class "pr-4" ] [ str "Grund" ]
                                td [] [ str row.Grund ]
                            ]
                            tr [] [
                                td [ Class "pr-4" ] [ str "Rücksendung" ]
                                td [] [ str (match row.RuecksendungAm with
                                             | Some date -> date |> asString
                                             | None -> System.String.Empty) ]
                            ]
                        ]
                    ]
            ]))
    )

let desktopTable (data: DokumentenDepot list) =
    table [
        Class "table table-striped responsive-table is-hidden-touch is-bfscolored"
    ] [
        thead [] [
            tr [] [
                th [] [ str "SendungsNr" ]
                th [] [ str "Rechnungsnummer" ]
                th [] [ str "Klient/Patient" ]
                th [] [ str "Eingelagert" ]
                th [] [ str "Art" ]
                th [] [ str "Grund" ]
                th [] [ str "Rücksendung" ]
            ]
        ]
        tbody [] (
            match data.IsEmpty with
            | true ->
                [
                    tr []
                       [
                           td [ ColSpan 5
                                Class "has-text-centered" ]
                              [ str "Keine eingegangenen Belege." ]
                       ]
                ]
            | false ->
                data
                |> List.sortBy (fun x -> x.KlientPatient)
                |> List.sortByDescending (fun x -> x.SendungNr)
                |> List.map (fun row ->
                    tr [
                        Key (row.SendungNr.ToString ())
                    ] [
                        td [ ] [ str (row.SendungNr.ToString ()) ]
                        td [ ] [ str row.RechnungsNrExtern ]
                        td [ ] [ str row.KlientPatient ]
                        td [ ] [ str (row.EingelagertAm |> asString) ]
                        td [ ] [ str row.DokumentArt ]
                        td [ ] [ str row.Grund ]
                        td [ ] [ str (match row.RuecksendungAm with
                                             | Some date -> date |> asString
                                             | None -> System.String.Empty) ]
                    ]
                )
        )
    ]

let view (model: Model) _ (navigateTo: AnyPage -> unit) =

    match model.Loading with
    | true ->
        spinner
    | false ->
        div [ Class "flat-card profile-info-card is-auto overflow-initial" ]
            [
                div [ Class "card-title" ] [
                        a [
                            OnClick(fun _ -> navigateTo DashboardPage.Dashboard)
                        ] [
                            i [ Class "fas fa-angle-left mr-2" ] []
                            str "Zurück zum Dashboard"
                        ]
                    ]
                div [ Class "card-body" ]
                    [
                        mobileTable model.Data
                        desktopTable model.Data
                ]
            ]
