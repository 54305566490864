﻿module Dashboard.Notification

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard.Lists
open Bfs.Web.Kundenportal.WebParts.User.Shared.Tile
open Fable.React
open Fable.React.Props
open Elmish

open Types
open Http
open ViewParts

open Dashboard.Shared

type Model = {
    GlobalDispatch: GlobalMsg -> unit
    NavigateTo: AnyPage -> unit
    Loading: bool
    Data: PersonalNotification list
}

type Msg =
    | LoadData
    | DataLoaded of Result<PersonalNotification list, exn>

module Http =
    let loadPersonalNotifications () = fetchAs<PersonalNotification list> "/api/notification/personel"

module State =
    let init (props: DashboardProps) =
        {
            GlobalDispatch = props.GlobalDispatch
            NavigateTo = props.NavigateTo
            Loading = true
            Data = []
        }, Cmd.ofMsg LoadData

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with
        | LoadData ->
            let cmd = DataLoaded |> request Http.loadPersonalNotifications ()
            model, cmd

        | DataLoaded (Ok personalNotificationsResult) ->
            { model with
                Loading = false
                Data = personalNotificationsResult
            }, Cmd.none

        | DataLoaded (Error _ ) ->
            let msg = GlobalMessageBox.Error "Die Anfrage zu Benachrichtigungen erfolgte mit einen Fehler." |> ShowMessageBox
            model.GlobalDispatch msg
            { model with Loading = false }, Cmd.none

let rowSubscriber (entry: PersonalNotification) =
    tr [] [
        td [] [
            str entry.Title
        ]
        td [] [
            str entry.Message
        ]
        td [] [ str (entry.IsEnabled |> (fun b -> if b then "aktiv" else "inaktiv")) ]
        td [] [ str entry.ZugestelltAm ]
    ]

let tableSubscriber (model: Model) =

    table [
        Class "table table-striped responsive-table"
    ] [
        thead [] [
            tr [] [
                th [] [ str "Titel"]
                th [] [ str "Nachricht" ]
                th [] [ str "Gerät-Aktiv" ]
                th [] [ str "Zugestellt am" ]
            ]
        ]
        tbody
            []
            (model.Data
             |> List.map rowSubscriber)
    ]

let View =
    FunctionComponent.Of(fun (props: DashboardProps) ->
        let model, _ = React.useElmish ((State.init props), State.update)
        let title = str "Benachrichtigungen"
        let body =
            match model.Loading with
            | true -> spinner
            | false -> tableSubscriber model

        bfsTile
            title
            None
            None
            body
            None
    )
