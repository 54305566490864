module Profile.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader =
        fun model ->
            if model.Password.IsSome then
                Header "Benutzerprofil"
            else
                Header "Benachrichtigungen"

    BuildUrl =
        function
        | PasswordChange -> [ Urls.profile ]
        | Notifications -> [ Urls.notifications ]

    ParseUrl =
        function
        | [ Urls.profile ] -> Some Page.PasswordChange
        | [ Urls.notifications ] -> Some Page.Notifications
        | _ -> None
}
