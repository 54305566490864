module Validation

open System
open System.Text.RegularExpressions
open Fulma.Elmish

// FormField and validation
type ValidationRule =
    | Mandatory
    | MandatoryNumber
    | MandatoryWithMaxLength of int
    | OptionalWithMaxLength of int
    | MatchesRegex of regex: string * errorMsg: string
    | ValidateEmailAddress

type FormField =
    {
        Value: string
        Rule: ValidationRule option
        Error: string option
        Touched: bool
    }
    static member Init(rule) = {
        Value = ""
        Rule = rule
        Error = None
        Touched = false
    }

    static member InitWithDefault(rule, defaultValue) =
        let field = FormField.Init rule
        { field with
            Value = defaultValue
            Touched = true
        }

    member x.IsValid =
        match (x.Rule, x.Touched, x.Error) with
        | (None, _, _) -> true
        | (Some Mandatory, true, None) -> true
        | (Some(MandatoryWithMaxLength _), true, None) -> true
        | (Some(OptionalWithMaxLength _), _, None) -> true
        | (Some(MandatoryNumber), _, None) -> true
        | (Some(MatchesRegex _), true, None) -> true
        | (Some(ValidateEmailAddress), true, None) -> true
        | (_, _, _) -> false

let validationMsg field (newValue: string) =
    match field.Rule with
    | None -> None
    | Some(Mandatory) ->
        if newValue.Length = 0 then
            Some "Bitte füllen Sie das Pflichtfeld aus"
        else
            None
    | Some(MandatoryWithMaxLength len) ->
        if newValue.Length = 0 then
            Some "Bitte füllen Sie das Pflichtfeld aus"
        else if newValue.Length > len then
            Some(sprintf "Bitte verwenden Sie maximal %i Zeichen" len)
        else
            None
    | Some(OptionalWithMaxLength len) ->
        if newValue.Length > len then
            Some(sprintf "Bitte verwenden Sie maximal %i Zeichen" len)
        else
            None
    | Some(MandatoryNumber) ->
        if newValue.Length = 0 then
            Some "Bitte füllen Sie das Pflichtfeld aus"
        else
            match tryInt newValue with
            | None -> Some "Bitte geben Sie nur einen Zahl ein"
            | _ -> None
    | Some(MatchesRegex(regex, msg)) -> if Regex.IsMatch(newValue, regex) then None else Some msg
    | Some(ValidateEmailAddress) ->
        if Regex.IsMatch(newValue, Bfs.Web.Shared.FSharp.Regex.Email) then
            None
        else
            Some "Bitte geben Sie eine E-Mail-Adresse ein"



let update field (newValue: string) =
    { field with
        Value = newValue
        Error = validationMsg field newValue
        Touched = true
    }

// DateFormField and validation
type DateValidationRule =
    | MandatoryDate
    | ValidationFunc of ((DateTime option -> bool) * string)

type DateFormField =
    {
        Value: DateTime option
        Rule: DateValidationRule option
        Error: string option
        DatePickerState: DatePicker.Types.State
        Touched: bool
    }
    static member Init(rule) =
        let deleteButton =
            match rule with
            | Some MandatoryDate -> false
            | Some(ValidationFunc _) -> false
            | _ -> true

        {
            Value = None
            Rule = rule
            Error = None
            DatePickerState =
                { DatePicker.Types.defaultState with
                    AutoClose = true
                    ShowDeleteButton = deleteButton
                }
            Touched = false
        }
    member x.IsValid =
        match (x.Rule, x.Touched, x.Error) with
        | (None, _, _) -> true
        | (Some MandatoryDate, true, None) -> true
        | (Some(ValidationFunc(f, _)), _, None) -> f x.Value
        | (_, _, _) -> false

let dateValidationMsg (field: DateFormField) (newValue: DateTime option) =
    match field.Rule with
    | None -> None
    | Some(MandatoryDate) -> if newValue.IsNone then Some "Bitte füllen Sie das Pflichtfeld aus" else None
    | Some(ValidationFunc(f, e)) -> if (f newValue) then None else Some e

let dateUpdate
    (field: DateFormField)
    (newState: DatePicker.Types.State)
    (newValue: DateTime option)
    (referenceData: DateTime option)
    =
    let valueWithoutTimezone =
        newValue
        |> Option.map (fun d -> DateTime(d.Year, d.Month, d.Day))

    let newState =
        match referenceData with
        | None -> newState
        | Some date -> { newState with ReferenceDate = date }

    { field with
        Value = valueWithoutTimezone
        DatePickerState = newState
        Error = dateValidationMsg field valueWithoutTimezone
        Touched = true
    }
