﻿module AdminNotification.State

open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.Admin
open Elmish
open Types
open Http
open AdminNotification.Types
open Validation

module Http =
    let getNotifications (pagenumber: int) =
        $"/api/notification?pagenumber={pagenumber}"
        |> fetchAs<Paginated<NotificationListItem>>

    let getKundenWithNotificationEnabled () =
        "api/notification/kundenportalbenutzer"
        |> fetchAs<KundeWithNotificationEnabled list>

    let createNotification (notification: NotificationRequest) =
        notification
        |> postRecord<NotificationRequest> $"/api/notification/send"

module State =
    let init (page: Page) user lastModel =
        match page with
        | NofiticationList pageNumber ->
            let initialModel = ListModel(NotificationList.Init pageNumber)
            let cmd = Cmd.ofMsg (LoadNotifications pageNumber)
            (initialModel, cmd)
        | AddNotification ->
            let backToPage =
                match lastModel with
                | Some(ListModel lm) -> lm.PageNumber
                | _ -> 1

            let initialModel = ItemModel(NewNotificationItem.Init backToPage)
            let cmd1 = Cmd.ofMsg NewNotification
            let cmd2 = Cmd.ofMsg LoadKundenWithNotificationEnabled

            (initialModel, Cmd.batch [ cmd1; cmd2 ])

    let update msg model =
        match msg, model with
        | LoadNotifications pagenumber, ListModel model ->
            let cmd =
                NotificationsLoaded
                |> request Http.getNotifications pagenumber
            ({ model with Notifications = Loading } |> ListModel, cmd)

        | NotificationsLoaded response, ListModel model ->
            ({ model with
                Notifications = Loaded response
             }
             |> ListModel,
             Cmd.none)

        | LoadKundenWithNotificationEnabled, ItemModel model ->
            let cmd =
                KundenWithNotificationEnabledLoaded
                |> request Http.getKundenWithNotificationEnabled ()
            model |> ItemModel, cmd

        | KundenWithNotificationEnabledLoaded(Ok result), ItemModel model ->
            ({ model with
                AllNotificationKundenportalBenutzer = result
             }
             |> ItemModel,
             Cmd.none)

        | NewNotification, ItemModel model ->
            ({ model with
                Title = update model.Title "Neuer Titel"
             }
             |> ItemModel,
             Cmd.none)

        | TitleChanged value, ItemModel model ->
            ({ model with
                Title = update model.Title value
             }
             |> ItemModel,
             Cmd.none)

        | MessageChanged value, ItemModel model ->
            ({ model with
                Message = update model.Message value
             }
             |> ItemModel,
             Cmd.none)

        | KundenportalBenutzerAllChanged enabled, ItemModel model ->
            ({ model with
                KundenportalBenutzerIds =
                    if enabled then
                        (NewNotificationItem.SelectAllKundenportalBenutzer model).KundenportalBenutzerIds
                    else List.Empty
                 } |> ItemModel, Cmd.none)
        | KundenportalBenutzerChanged id, ItemModel model ->
            let newIds =
                if NewNotificationItem.IsSelected model id then
                    model.KundenportalBenutzerIds
                    |> List.filter(fun k -> k <> id)
                else model.KundenportalBenutzerIds @ [id]
            ({ model with
                KundenportalBenutzerIds = newIds
             }
             |> ItemModel,
             Cmd.none)

        | Save, ItemModel model ->
            let request =
                {
                    Title = model.Title.Value
                    Message = model.Message.Value
                    KundenportalBenutzerIds = model.KundenportalBenutzerIds
                }
                : NotificationRequest
            let cmd = Saved |> Http.request Http.createNotification request
            (model |> NewNotificationItem.StartSending |> ItemModel, cmd)
        | Saved(Ok _), ItemModel model ->
             model
             |> NewNotificationItem.Reset
             |> NewNotificationItem.StopSending
             |> ItemModel, Cmd.none

        | GlobalMsg _, _ -> model, Cmd.none
        | _, _ -> model, Cmd.none
