[<RequireQualifiedAccess>]
module Service

open Fable.Remoting.Client
open Bfs.Web.Data.Service.Contracts.Kundenportal.Settings

// so wie es hier gebaut ist, werden beim App-Start im Client alle Services
// einmal erzeugt. Wenn das irgendwann zu Prolemen mit der Startup-Time führt
// müssen wir hier etwas Layz machen

let inline private build<'T> () =
    Remoting.createApi ()
    |> Remoting.withRouteBuilder Bfs.Web.Shared.FSharp.Routing.routeBuilder
    |> Remoting.buildProxy<'T>

let Settings = build<ISettingsService> ()
