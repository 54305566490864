module ApiToken.View

open Fable.Core
open Fable.React
open Browser
open Bfs.Web.Data.Service.Contracts.Kundenportal.ApiToken
open Bfs.Web.Shared.Formating
open Types
open Fable.React.Props
open ViewParts
open Fulma
open System

let asTimeStampString (date: DateTime) =
    sprintf "%02i.%02i.%04i - %02i:%02i" date.Day date.Month date.Year date.Hour date.Minute

let actionHoverMenu dispatch (token: Token) =
    let menuName = sprintf "dropdown-%O" token.Id
    div [
        Class "dropdown is-hoverable is-right"
    ] [
        div [ Class "dropdown-trigger" ] [
            div [
                AriaHasPopup true
                AriaControls menuName
            ] [ icon "fas fa-ellipsis-v" ]
        ]
        div [
            Class "dropdown-menu"
            Id menuName
            Role "menu"
        ] [
            div [ Class "dropdown-content" ] [
                a [
                    Class "dropdown-item"
                    OnClick(fun _ -> DeleteToken token.Id |> dispatch)
                ] [ str "API-Token löschen" ]
            ]
        ]
    ]

let tokenRow dispatch (token: Token) = [
    tr [] [
        td [] [
            str (token.Erstellt |> asTimeStampString)
        ]
        td [] [
            str (
                Option.map (asString) token.LetzteNutzung
                |> Option.defaultValue ""
            )
        ]
        td [] [ str "***" ]
        td [ Class "pull-right" ] [ actionHoverMenu dispatch token ]
    ]
]

let filialenRow selectedFilialeId dispatch (filialen: Filiale list) =
    let selectedFilialeId = selectedFilialeId |> Option.defaultValue Guid.Empty

    Tabs.tabs [
        Tabs.CustomClass "is-hidden-touch account-tabs main-menu with-width-constraint"
    ] [
        filialen
        |> List.map (fun f ->
            Tabs.tab [
                Tabs.Tab.CustomClass "submenu-item"
                Tabs.Tab.IsActive(f.Id = selectedFilialeId)
            ] [
                a [
                    OnClick(fun _ -> (SwitchFiliale f.Id) |> dispatch)
                ] [ str f.Name ]
            ])
        |> ofList
    ]

let filialenMobile selectedFilialeId dispatch (filialen: Filiale list) =
    let selectedFilialeId = selectedFilialeId |> Option.defaultValue Guid.Empty

    div [ Class "is-hidden-desktop" ] [
        div [ Class "flat-card is-auto menu-card" ] [
            ul [ Class "wishlists" ] [
                filialen
                |> List.map (fun f ->
                    li [
                        Class(
                            "wishlist-item "
                            + (if f.Id = selectedFilialeId then "is-active" else "")
                        )
                        OnClick(fun _ -> (SwitchFiliale f.Id) |> dispatch)
                    ] [
                        div [ Class "item-wrapper" ] [ a [] [ str f.Name ] ]
                    ])
                |> ofList
            ]
        ]
    ]

let tokenCardBody dispatch model =
    match model.Filialen with
    | Empty -> str "Keine Filialen vorhanden"
    | Body x when x.IsEmpty -> str "Keine Filiale mit Token vorhanden"
    | Loading -> spinner
    | LoadError e -> errorMsg e
    | Body filialen ->
        match model.SelectedFiliale with
        | None -> str "Keine Filiale ausgewählt"
        | Some id ->
            let filiale = filialen |> List.find (fun x -> x.Id = id)
            div [] [
                table [
                    Class "table table-striped responsive-table"
                ] [
                    thead [] [
                        tr [] [
                            th [] [ str "Erstellt" ]
                            th [] [ str "Letzte Nutzung" ]
                            th [] [ str "Token" ]
                            th [] []
                        ]
                    ]
                    tbody
                        []
                        (filiale
                         |> fun sf -> sf.Tokens |> List.collect (tokenRow dispatch))
                ]

                div [ Class "center-text" ] [
                    if filiale.Tokens.IsEmpty then
                        str "Es existiert noch kein API-Token für diese Filiale. "
                    a [
                        OnClick(fun _ ->
                            match model.SelectedFiliale with
                            | Some f -> CreateToken f |> dispatch
                            | _ -> ())
                    ] [ str "Neues API-Token erzeugen" ]
                    str "."
                ]
            ]

let content (model: Model) dispatch =
    div [ Class "columns" ] [
        div [ Class "column is-12" ] [
            div [
                Class "flat-card profile-info-card is-auto overflow-initial"
            ] [
                div [ Class "card-body" ] [
                    p [ Style [ Margin "0.8rem" ] ] [
                        str
                            "Um anderen Anwendungen Zugriff auf Ihr BFS-Konto zu gestatten, können Sie hier API-Token erzeugen. Diese API-Token werden von der anderen Anwendung zur Authentifizierung genutzt."
                        br []
                        str
                            "Sobald Sie ein API-Token hier löschen, kann diese Anwendung nicht mehr auf Ihr BFS-Konto zugreifen."
                    ]

                    tokenCardBody dispatch model
                ]
            ]
        ]
    ]

let view model dispatch (navigateTo: AnyPage -> unit) =
    [
        match model.Filialen with
        | Body f when f.Length > 1 ->
            yield filialenRow model.SelectedFiliale dispatch f
            yield filialenMobile model.SelectedFiliale dispatch f
        | _ -> ()

        yield content model dispatch
    ]
    |> ofList
