module Tickets.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "BFS Ticket-System"

    BuildUrl = fun _ -> [ Urls.tickets ]

    ParseUrl =
        function
        | [ Urls.tickets ] -> Some Page.Ticket
        | _ -> None
}
