module ViewParts

open Fable.React
open Fable.React.Props
open Types
open Fulma.Elmish
open Validation
open Fulma.Extensions.Wikiki
open Fable.Core.JsInterop
open Browser.Dom
open Bfs.Web.Data.Service.Contracts

let spinner =
    div [ Props.Class "waiting" ] [
        img [
            Props.Src "spinner.gif"
            Props.Alt "loading"
        ]
    ]

let spinnerwhite =
    div [ Props.Class "waiting" ] [
        img [
            Props.Src "spinner-white.gif"
            Props.Alt "loading"
        ]
    ]

let nothing = div [] []

let errorMsg text = div [ Props.Class "center-msg" ] [ str text ]

let optionalErrorMsg text =
    match text with
    | Some msg -> errorMsg msg
    | None -> div [] []

let private capslocklWarningAttribuntes id attribute : IHTMLProp list = [
    Class(
        "control "
        + "is-tooltip-active"
        + " "
        + "is-tooltip-warning"
        + " "
        + attribute
    )
    Data("tooltip", "Bitte beachten Sie, dass die   Feststelltaste gedrückt ist.")

    OnBlur(fun _ -> (document.getElementById id).classList.remove "tooltip")
    OnKeyUp(fun ev ->
        if (ev.getModifierState "CapsLock") then
            (document.getElementById id).classList.add "tooltip"
        else
            (document.getElementById id).classList.remove "tooltip")
]

let private validatedInputBox
    controlType
    (capslockWarning: string option)
    withErrorText
    attributes
    (onChange: string -> unit)
    caption
    key
    placeholder
    (field: FormField)
    =
    let isDanger =
        match field.Error with
        | Some _ -> " is-danger "
        | None -> ""
    div [ Class "field" ] [
        label [ Class "label" ] [ str caption ]
        div [
            yield Id("capslock" + key)
            if capslockWarning.IsSome then
                yield! capslocklWarningAttribuntes ("capslock" + key) capslockWarning.Value
            else
                yield Class "control"
        ] [
            yield
                input [
                    yield Class("input" + isDanger)
                    yield Value field.Value
                    yield Type controlType
                    yield Key key
                    yield Name key
                    yield Placeholder placeholder
                    yield OnChange(fun ev -> onChange ev.Value)
                    yield! attributes
                ]
            match (withErrorText, field.Error) with
            | true, Some error -> yield span [ ClassName "help is-danger" ] [ str error ]
            | _ -> ()
        ]
    ]
let validatedTextBox = validatedInputBox "text" None true []
let validatedTextBoxWithAttributes attributes = validatedInputBox "text" None true attributes
let validatedTextBoxWithoutErrorMsg = validatedInputBox "text" None false []
let validatedTextBoxWithoutErrorMsg2 attributes = validatedInputBox "text" None false attributes
let validatedPasswordBoxWithoutErrorMsg capslockTooltipAttribute attributes =
    validatedInputBox "password" (Some capslockTooltipAttribute) false attributes


let validatedTextarea (onChange: string -> unit) caption key placeholder additonalClasses (field: FormField) =
    let isDanger =
        match field.Error with
        | Some _ -> " is-danger"
        | None -> ""
    div [ Class "field" ] [
        label [ Class "label" ] [ str caption ]
        div [ Class "control" ] [
            yield
                textarea [
                    Class("textarea " + additonalClasses + isDanger)
                    Type "text"
                    Key key
                    Name key
                    Placeholder placeholder
                    Value field.Value
                    OnChange(fun ev -> onChange ev.Value)
                ] []
            match field.Error with
            | Some error -> yield span [ ClassName "help is-danger" ] [ str error ]
            | _ -> ()
        ]
    ]

let validatedDatePicker<'a> onChange caption field dispatch =
    let isDanger =
        match field.Error with
        | Some _ -> " is-danger"
        | None -> ""
    div [ Class "field" ] [
        label [ Class "label" ] [ str caption ]
        div [ Class "control" ] [
            let pickerConfig: DatePicker.Types.Config<'a> = {
                OnChange = onChange
                Local = Date.Local.german
                DatePickerStyle = [
                    Position PositionOptions.Absolute
                    MaxWidth "450px"
                ]
            }

            yield DatePicker.View.root pickerConfig field.DatePickerState field.Value dispatch

            match field.Error with
            | Some error -> yield span [ ClassName "help is-danger" ] [ str error ]
            | _ -> ()
        ]
    ]

// Angezeigt werden sollen: p1, p2, (p3), ... n-1 , n , n+1 ... letzten 2 oder 3
// natürlich keine doppelt und die ... nur wenn es nötig ist
let private cleanPages count pages =
    pages
    |> Seq.filter (fun x -> x >= 0 && x <= count)
    |> Seq.distinct
    |> Seq.sort
let private paginationAlways (page: Paginated<'a>) onClick =
    let n = page.Page
    let count = page.PageCount

    let pages =
        if isLargeScreen () then
            let pagesmall =
                [
                    0
                    1
                    2
                    n - 1
                    n
                    n + 1
                    count - 1
                    count
                ] // 0 explizit als dummy, damit unten pairwise gut geht
                |> cleanPages count
            if Seq.length pagesmall <= 6 then
                Seq.append pagesmall [ 3; count - 2 ] |> cleanPages count
            else
                pagesmall
        else
            [ 0; 1; n - 1; n; n + 1; count ] // 0 explizit als dummy, damit unten pairwise gut geht
            |> cleanPages count


    div [ Props.Class "wishlist-pagination" ] [
        ul [] [
            pages
            |> Seq.pairwise
            |> Seq.map (fun (last, current) ->
                let active: IHTMLProp list =
                    if current = page.Page then [ Props.Class "is-active" ] else []
                [
                    if (current - last > 1) then
                        yield span [ Class "pagination-ellipsis" ] [ str "..." ]
                    yield
                        li (Seq.ofList active) [
                            a [
                                Props.OnClick(fun _ -> onClick (current))
                            ] [
                                span [] [ str (sprintf "%i" current) ]
                            ]
                        ]
                ])
            |> Seq.concat
            |> Seq.toList
            |> ofList
        ]
    ]
let pagination (page: Paginated<'a>) onClick = if (page.PageCount > 1) then paginationAlways page onClick else div [] []
let paginationR (page: Remote<Paginated<'a>>) onClick =
    match page with
    | Empty -> div [] []
    | Loading -> div [] []
    | LoadError e -> div [] []
    | Body list -> pagination list onClick

let private icon2 spanclass cssclass = span [ Class spanclass ] [ i [ Class(cssclass) ] [] ]
let icon = icon2 "icon"
let linkicon = icon2 "linkicon"

// komplexe teile
let newIndicator = div [ Class "new-item-wrapper" ] []

let adminImageUploadBox id hasImage imageUrl deleteMsg uploadMsg dispatch =
    div [
        Class "flat-card profile-info-card is-auto"
    ] [
        div [ Class "card-title" ] [ h3 [] [ b [] [ str "Bild" ] ] ]
        div [ Class "card-body" ] [
            match id, hasImage with
            | Some id, true ->
                yield
                    div [] [
                        div [ Class "info-block" ] [
                            img [ Src(imageUrl (id)) ]
                            button [
                                Class("button is-danger is-small is-link")
                                Props.OnClick(fun _ -> deleteMsg |> dispatch)
                            ] [ str "Bild löschen" ]
                        ]
                    ]
            | Some id, false ->
                yield
                    div [ Class "file is-boxed" ] [
                        label [ Class "file-label" ] [
                            input [
                                Class "file-input"
                                Type "file"
                                Accept "image/jpeg"
                                // damit die Notation bei OnChange funktioniert (um auf die JS properties zuzugreifen) muss "open Fable.Core.JsInterop" oben stehen
                                OnChange(fun ev -> uploadMsg !!(ev.target?files?(0)) |> dispatch)
                            ]
                            span [ Class "file-cta" ] [
                                span [ Class "file-icon" ] [ i [ Class "fas fa-upload" ] [] ]
                                span [ Class "file-label" ] [ str "Datei auswählen…" ]
                            ]
                        ]
                    ]
                yield
                    div [ Class "" ] [
                        i [] [
                            str
                                "Es dürfen nur jpg-Dateien hochgeladen werden, die die passende Auflösung und Größe für die Website haben! Eine Prüfung oder Konvertierung findet nicht statt."
                        ]
                    ]
            | None, _ ->
                yield
                    div [] [
                        str "Bitte speichern Sie die neue News bevor Sie ein Bild hochladen."
                    ]
        ]
    ]

[<RequireQualifiedAccess>]
module Icons =
    // war mal bfs_home.svg
    let Home =
        svg [
            HTMLAttr.Custom("viewBox", "0 0 20 20")
        ] [
            path [
                HTMLAttr.Custom(
                    "d",
                    "M18.81,19.7H1.11a.83.83,0,0,1-.83-.83V7.44a.82.82,0,0,1,.35-.67L9.48.5a.83.83,0,0,1,1,0l8.85,6.27a.82.82,0,0,1,.35.67V18.88A.82.82,0,0,1,18.81,19.7ZM1.93,18H18V7.86L10,2.18l-8,5.68Z"
                )
            ] []
        ]

    // war mal bfs_settings.svg
    let Settings =
        svg [
            HTMLAttr.Custom("viewBox", "0 0 20 20")
        ] [
            g [] [
                path [
                    HTMLAttr.Custom(
                        "d",
                        "M10,13.49A3.49,3.49,0,1,1,13.49,10,3.49,3.49,0,0,1,10,13.49ZM10,8a2,2,0,1,0,2,2A2,2,0,0,0,10,8Z"
                    )
                ] []
                path [
                    HTMLAttr.Custom(
                        "d",
                        "M10,19.65a2.53,2.53,0,0,1-2.5-2.2,7.83,7.83,0,0,1-1-.41A2.51,2.51,0,0,1,5,17.56H5A2.52,2.52,0,0,1,3,13.5a7.69,7.69,0,0,1-.41-1,2.52,2.52,0,0,1,0-5A7.87,7.87,0,0,1,3,6.5a2.52,2.52,0,0,1,.22-3.32A2.58,2.58,0,0,1,6.5,3a7.85,7.85,0,0,1,1-.41,2.52,2.52,0,0,1,5,0,7.7,7.7,0,0,1,1,.41,2.58,2.58,0,0,1,3.32.22A2.52,2.52,0,0,1,17,6.5a7.93,7.93,0,0,1,.41,1,2.52,2.52,0,0,1,0,5,7.78,7.78,0,0,1-.41,1,2.52,2.52,0,0,1-2,4.06h0A2.5,2.5,0,0,1,13.5,17a7.7,7.7,0,0,1-1,.41A2.53,2.53,0,0,1,10,19.65ZM6.35,15.37a.74.74,0,0,1,.38.11,6.34,6.34,0,0,0,1.7.71A.74.74,0,0,1,9,17l0,.14a1,1,0,0,0,2.08,0L11,17a.74.74,0,0,1,.55-.8,6.33,6.33,0,0,0,1.7-.71.74.74,0,0,1,1,.18l.12.18a1,1,0,0,0,.69.25h0a1,1,0,0,0,.74-1.78l-.12-.07a.74.74,0,0,1-.18-1,6.4,6.4,0,0,0,.71-1.7A.75.75,0,0,1,17,11l.13,0a1,1,0,0,0,1-1A1,1,0,0,0,17,9a.78.78,0,0,1-.86-.55,6.36,6.36,0,0,0-.71-1.7.74.74,0,0,1,.18-1l.18-.11a1.06,1.06,0,0,0-.06-1.42,1.07,1.07,0,0,0-1.47,0,1.1,1.1,0,0,1-.06.12.74.74,0,0,1-1,.18,6.3,6.3,0,0,0-1.69-.71A.74.74,0,0,1,11,3l0-.15A1,1,0,0,0,9,2.87L9,3a.74.74,0,0,1-.55.81,6.29,6.29,0,0,0-1.7.71.74.74,0,0,1-1-.18l-.11-.18a1.13,1.13,0,0,0-1.42.05,1,1,0,0,0,0,1.47l.12.06a.74.74,0,0,1,.18,1,6.35,6.35,0,0,0-.71,1.7A.74.74,0,0,1,3,9L2.87,9a1,1,0,1,0,0,2.08L3,11a.75.75,0,0,1,.81.55,6.39,6.39,0,0,0,.71,1.7.74.74,0,0,1-.18,1l-.18.11a1,1,0,0,0-.25.69,1,1,0,0,0,1,1H5a1,1,0,0,0,.74-.31,1.18,1.18,0,0,1,.07-.12A.74.74,0,0,1,6.35,15.37Z"
                    )
                ] []
            ]
        ]

    // war mal bfs_ticket.svg
    let TicketSvg customStyle =
        svg [
            HTMLAttr.Custom("viewBox", "0 0 20 20")
            Style customStyle
        ] [
            path [
                HTMLAttr.Custom(
                    "d",
                    "M18.57,16.86H1.44a.74.74,0,0,1-.74-.74V12.58a.74.74,0,0,1,.74-.74,1.84,1.84,0,1,0,0-3.69A.74.74,0,0,1,.7,7.42V3.88a.74.74,0,0,1,.74-.73H18.57a.74.74,0,0,1,.74.73V7.42a.74.74,0,0,1-.74.73,1.84,1.84,0,1,0,0,3.69.74.74,0,0,1,.74.74v3.55A.74.74,0,0,1,18.57,16.86ZM2.17,15.39H17.83V13.23a3.31,3.31,0,0,1,0-6.46V4.61H2.17V6.77a3.31,3.31,0,0,1,0,6.46Z"
                )
            ] []
        ]
    let TicketErstellenLink onClick =
        a [ OnClick onClick ] [
            TicketSvg [
                Height "1.3em"
                VerticalAlign "sub"
                MarginRight "5px"
            ]
            str "Ticket erstellen"
        ]

    // von https://fontawesome.com/ - check-square-regular.svg
    let CheckboxChecked customStyle =
        svg [
            HTMLAttr.Custom("viewBox", "0 0 448 512")
            Style customStyle
        ] [
            path [
                HTMLAttr.Custom(
                    "d",
                    "M400 480H48c-26.51 0-48-21.49-48-48V80c0-26.51 21.49-48 48-48h352c26.51 0 48 21.49 48 48v352c0 26.51-21.49 48-48 48zm-204.686-98.059l184-184c6.248-6.248 6.248-16.379 0-22.627l-22.627-22.627c-6.248-6.248-16.379-6.249-22.628 0L184 302.745l-70.059-70.059c-6.248-6.248-16.379-6.248-22.628 0l-22.627 22.627c-6.248 6.248-6.248 16.379 0 22.627l104 104c6.249 6.25 16.379 6.25 22.628.001z"
                )
            ] []
        ]
    // von https://fontawesome.com/ - check-square-solid.svg
    let CheckboxUnchecked customStyle =
        svg [
            HTMLAttr.Custom("viewBox", "0 0 448 512")
            Style customStyle
        ] [
            path [
                HTMLAttr.Custom(
                    "d",
                    "M400 32H48C21.5 32 0 53.5 0 80v352c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48V80c0-26.5-21.5-48-48-48zm-6 400H54c-3.3 0-6-2.7-6-6V86c0-3.3 2.7-6 6-6h340c3.3 0 6 2.7 6 6v340c0 3.3-2.7 6-6 6z"
                )
            ] []
        ]

    // von https://fontawesome.com/ - download-solid.svg
    let Download customStyle =
        svg [
            HTMLAttr.Custom("viewBox", "0 0 512 512")
            Style customStyle
        ] [
            path [
                HTMLAttr.Custom(
                    "d",
                    "M216 0h80c13.3 0 24 10.7 24 24v168h87.7c17.8 0 26.7 21.5 14.1 34.1L269.7 378.3c-7.5 7.5-19.8 7.5-27.3 0L90.1 226.1c-12.6-12.6-3.7-34.1 14.1-34.1H192V24c0-13.3 10.7-24 24-24zm296 376v112c0 13.3-10.7 24-24 24H24c-13.3 0-24-10.7-24-24V376c0-13.3 10.7-24 24-24h146.7l49 49c20.1 20.1 52.5 20.1 72.6 0l49-49H488c13.3 0 24 10.7 24 24zm-124 88c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20zm64 0c0-11-9-20-20-20s-20 9-20 20 9 20 20 20 20-9 20-20z"
                )
            ] []
        ]
    let DownloadLink text href =
        a [ Href href ] [
            Download [
                Height "1.3em"
                VerticalAlign "sub"
                MarginRight "5px"
            ]
            str (sprintf "%s herunterladen" text)
        ]
    let DownloadLink2 text onClick =
        a [ OnClick onClick ] [
            Download [
                Height "1.3em"
                VerticalAlign "sub"
                MarginRight "5px"
            ]
            str (sprintf "%s herunterladen" text)
        ]



let orangeCheckbox ischecked onclick =
    let active = if ischecked then "is-active" else ""

    div [
        Class("orangeCheckbox " + active)
        OnClick onclick
    ] [
        if ischecked then
            Icons.CheckboxChecked [
                Width "1em"
                MarginTop "5px"
                MarginRight "5px"
            ]
        else
            Icons.CheckboxUnchecked [
                Width "1em"
                MarginTop "5px"
                MarginRight "5px"
            ]
    ]

let disabeldCheckboxWithTooltip text onclick =
    div [
        Class("orangeCheckbox is-disabeld tooltip is-tooltip-multiline")
        OnClick onclick
        Data("tooltip", text)
    ] [
        Icons.CheckboxUnchecked [
            Width "1em"
            MarginTop "5px"
            MarginRight "5px"
        ]
    ]

let switch isChecked (onChange: bool -> unit) nr =
    Switch.switch [
        Switch.CustomClass "is-info"
        Switch.Checked isChecked
        Switch.OnChange(fun ev -> onChange (ev.Checked))
        Switch.Id("switch" + nr)
    ] []

let regularSwitch isChecked isDisabled (onChange: bool -> unit) nr =
    Switch.switch [
        Switch.IsRounded
        Switch.Checked isChecked
        Switch.Disabled isDisabled
        Switch.Color CustomColors.bfsOrange
        Switch.OnChange(fun ev -> onChange (ev.Checked))
        Switch.Id("switch" + nr)
    ] []

let checkbox isChecked (onChange: bool -> unit) nr = orangeCheckbox isChecked (fun _ -> onChange (not isChecked))
