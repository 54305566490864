module Settings.View

open Fable.React

open Types
open Fable.React.Props
open Bfs.Web.Data.Service.Contracts.Kundenportal.Settings

open ViewParts
open Bfs.Web.Shared.Formating

let postImPortalBox (model: Types.Model) dispatch =
    let postZustellungMsg =
        if model.PostInsKundenportalZustellen then
            SwitchOffPostzustellung
        else
            SwitchOnPostzustellung

    div [ Class "column is-12" ] [
        div [
            Class "flat-card is-auto  profile-info-card"
        ] [
            div [ Class "card-title" ] [
                h3 [] [ b [] [ str "Postzustellung" ] ]
            ]

            div [ Class "card-body paragraphs" ] [
                p [] [
                    str
                        "Statt auf den Postboten zu warten, kann die BFS alle Briefe an Sie auch elektronisch und umweltfreundlich hier ins Kundenportal zustellen."
                    br []
                    str "Darüber können Sie sich in den "
                    a [ Props.Href "/#notifications" ] [ str "Einstellungen" ]
                    str
                        " per E-Mail informieren lassen. Die Briefe laden Sie dann ganz sicher im Kundenportal herunter."
                ]
                table [ Class "table table-striped" ] [
                    tr [] [
                        td [] [
                            str "Briefe an mich nicht per Post verschicken, sondern im Kundenportal hinterlegen."
                        ]
                        td [ Class "pull-right" ] [
                            checkbox model.PostInsKundenportalZustellen (fun _ -> postZustellungMsg |> dispatch) ()
                        ]
                    ]
                ]
            ]
        ]
    ]

let dokumentenabrufBox (model: Types.Model) dispatch =
    div [ Class "column is-12" ] [
        div [
            Class "flat-card is-auto  profile-info-card"
        ] [
            div [ Class "card-title" ] [
                h3 [] [
                    b [] [ str "Dokumentenabruf Flatrate" ]
                ]
            ]

            div [ Class "card-body paragraphs" ] [
                p [] [
                    str
                        "Mit dem Abschluss des Dokumentenabruf-Abonnements haben Sie die Möglichkeit, sich über unseren Quick Finder die gescannten Dokumente, wie zum Beispiel Ihre eingereichten Belege, herunterzuladen."
                    br []
                    str
                        "Alle Details und Gebühren zur Flatrate entnehmen Sie bitte dem Hinweistext. Dieser erscheint, sobald Sie den Haken in der Checkbox setzen."
                ]

                table [ Class "table table-striped" ] [
                    match model.Filialen with
                    | None -> spinner
                    | Some [||] -> nothing
                    | Some filialen ->
                        filialen
                        |> Array.sortBy (fun f -> f.Name1)
                        |> Array.map (fun f ->
                            let isChecked =
                                match f.DokumentenabrufStatus with
                                | Probezeit -> true
                                | Gebuehrenpflichtig -> true
                                | _ -> false

                            tr [] [
                                td [] [
                                    str (sprintf "%s %s" f.Name1 f.Name2)
                                ]
                                td [ Class "pull-right" ] [
                                    match f.GueltigBis with
                                    | None ->
                                        checkbox
                                            isChecked
                                            (fun b -> (TryChangeDokumentenabruf(f.FilialeId, b)) |> dispatch)
                                            (f.FilialeId.ToString())
                                    | Some date -> str (sprintf "bis %s" (date |> asString))
                                ]
                            ])
                        |> ofArray
                ]
            ]
        ]
    ]

let view model dispatch navigateTo =
    div [
        Class "columns is-account-grid is-multiline einstellungen"
    ] [
        postImPortalBox model dispatch

        if model.DokumentenAbrufAnzeigen then
            dokumentenabrufBox model dispatch
    ]
