module Verrechnungen.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "Anstehende Verrechnungen"

    BuildUrl =
        function
        | Page.Verrechnungen _ -> [ Urls.verrechnungen ]

    ParseUrl =
        function
        | [ Urls.verrechnungen ] -> Some(Page.Verrechnungen None)
        | _ -> None
}
