// This files has been generated using a script do not edit it

namespace Fable.FontAwesome

open Fable.Core

[<AutoOpen>]
module Free =

    [<RequireQualifiedAccess>]
    module Fa =

        module Solid =
            let inline Ad<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ad"
            let inline AddressBook<'a> = Fable.FontAwesome.Fa.Icon "fas fa-address-book"
            let inline AddressCard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-address-card"
            let inline Adjust<'a> = Fable.FontAwesome.Fa.Icon "fas fa-adjust"
            let inline AirFreshener<'a> = Fable.FontAwesome.Fa.Icon "fas fa-air-freshener"
            let inline AlignCenter<'a> = Fable.FontAwesome.Fa.Icon "fas fa-align-center"
            let inline AlignJustify<'a> = Fable.FontAwesome.Fa.Icon "fas fa-align-justify"
            let inline AlignLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-align-left"
            let inline AlignRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-align-right"
            let inline Allergies<'a> = Fable.FontAwesome.Fa.Icon "fas fa-allergies"
            let inline Ambulance<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ambulance"
            let inline AmericanSignLanguageInterpreting<'a> = Fable.FontAwesome.Fa.Icon "fas fa-american-sign-language-interpreting"
            let inline Anchor<'a> = Fable.FontAwesome.Fa.Icon "fas fa-anchor"
            let inline AngleDoubleDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-angle-double-down"
            let inline AngleDoubleLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-angle-double-left"
            let inline AngleDoubleRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-angle-double-right"
            let inline AngleDoubleUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-angle-double-up"
            let inline AngleDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-angle-down"
            let inline AngleLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-angle-left"
            let inline AngleRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-angle-right"
            let inline AngleUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-angle-up"
            let inline Angry<'a> = Fable.FontAwesome.Fa.Icon "fas fa-angry"
            let inline Ankh<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ankh"
            let inline AppleAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-apple-alt"
            let inline Archive<'a> = Fable.FontAwesome.Fa.Icon "fas fa-archive"
            let inline Archway<'a> = Fable.FontAwesome.Fa.Icon "fas fa-archway"
            let inline ArrowAltCircleDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrow-alt-circle-down"
            let inline ArrowAltCircleLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrow-alt-circle-left"
            let inline ArrowAltCircleRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrow-alt-circle-right"
            let inline ArrowAltCircleUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrow-alt-circle-up"
            let inline ArrowCircleDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrow-circle-down"
            let inline ArrowCircleLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrow-circle-left"
            let inline ArrowCircleRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrow-circle-right"
            let inline ArrowCircleUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrow-circle-up"
            let inline ArrowDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrow-down"
            let inline ArrowLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrow-left"
            let inline ArrowRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrow-right"
            let inline ArrowUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrow-up"
            let inline ArrowsAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrows-alt"
            let inline ArrowsAltH<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrows-alt-h"
            let inline ArrowsAltV<'a> = Fable.FontAwesome.Fa.Icon "fas fa-arrows-alt-v"
            let inline AssistiveListeningSystems<'a> = Fable.FontAwesome.Fa.Icon "fas fa-assistive-listening-systems"
            let inline Asterisk<'a> = Fable.FontAwesome.Fa.Icon "fas fa-asterisk"
            let inline At<'a> = Fable.FontAwesome.Fa.Icon "fas fa-at"
            let inline Atlas<'a> = Fable.FontAwesome.Fa.Icon "fas fa-atlas"
            let inline Atom<'a> = Fable.FontAwesome.Fa.Icon "fas fa-atom"
            let inline AudioDescription<'a> = Fable.FontAwesome.Fa.Icon "fas fa-audio-description"
            let inline Award<'a> = Fable.FontAwesome.Fa.Icon "fas fa-award"
            let inline Baby<'a> = Fable.FontAwesome.Fa.Icon "fas fa-baby"
            let inline BabyCarriage<'a> = Fable.FontAwesome.Fa.Icon "fas fa-baby-carriage"
            let inline Backspace<'a> = Fable.FontAwesome.Fa.Icon "fas fa-backspace"
            let inline Backward<'a> = Fable.FontAwesome.Fa.Icon "fas fa-backward"
            let inline Bacon<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bacon"
            let inline Bahai<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bahai"
            let inline BalanceScale<'a> = Fable.FontAwesome.Fa.Icon "fas fa-balance-scale"
            let inline BalanceScaleLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-balance-scale-left"
            let inline BalanceScaleRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-balance-scale-right"
            let inline Ban<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ban"
            let inline BandAid<'a> = Fable.FontAwesome.Fa.Icon "fas fa-band-aid"
            let inline Barcode<'a> = Fable.FontAwesome.Fa.Icon "fas fa-barcode"
            let inline Bars<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bars"
            let inline BaseballBall<'a> = Fable.FontAwesome.Fa.Icon "fas fa-baseball-ball"
            let inline BasketballBall<'a> = Fable.FontAwesome.Fa.Icon "fas fa-basketball-ball"
            let inline Bath<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bath"
            let inline BatteryEmpty<'a> = Fable.FontAwesome.Fa.Icon "fas fa-battery-empty"
            let inline BatteryFull<'a> = Fable.FontAwesome.Fa.Icon "fas fa-battery-full"
            let inline BatteryHalf<'a> = Fable.FontAwesome.Fa.Icon "fas fa-battery-half"
            let inline BatteryQuarter<'a> = Fable.FontAwesome.Fa.Icon "fas fa-battery-quarter"
            let inline BatteryThreeQuarters<'a> = Fable.FontAwesome.Fa.Icon "fas fa-battery-three-quarters"
            let inline Bed<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bed"
            let inline Beer<'a> = Fable.FontAwesome.Fa.Icon "fas fa-beer"
            let inline Bell<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bell"
            let inline BellSlash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bell-slash"
            let inline BezierCurve<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bezier-curve"
            let inline Bible<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bible"
            let inline Bicycle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bicycle"
            let inline Biking<'a> = Fable.FontAwesome.Fa.Icon "fas fa-biking"
            let inline Binoculars<'a> = Fable.FontAwesome.Fa.Icon "fas fa-binoculars"
            let inline Biohazard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-biohazard"
            let inline BirthdayCake<'a> = Fable.FontAwesome.Fa.Icon "fas fa-birthday-cake"
            let inline Blender<'a> = Fable.FontAwesome.Fa.Icon "fas fa-blender"
            let inline BlenderPhone<'a> = Fable.FontAwesome.Fa.Icon "fas fa-blender-phone"
            let inline Blind<'a> = Fable.FontAwesome.Fa.Icon "fas fa-blind"
            let inline Blog<'a> = Fable.FontAwesome.Fa.Icon "fas fa-blog"
            let inline Bold<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bold"
            let inline Bolt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bolt"
            let inline Bomb<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bomb"
            let inline Bone<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bone"
            let inline Bong<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bong"
            let inline Book<'a> = Fable.FontAwesome.Fa.Icon "fas fa-book"
            let inline BookDead<'a> = Fable.FontAwesome.Fa.Icon "fas fa-book-dead"
            let inline BookMedical<'a> = Fable.FontAwesome.Fa.Icon "fas fa-book-medical"
            let inline BookOpen<'a> = Fable.FontAwesome.Fa.Icon "fas fa-book-open"
            let inline BookReader<'a> = Fable.FontAwesome.Fa.Icon "fas fa-book-reader"
            let inline Bookmark<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bookmark"
            let inline BorderAll<'a> = Fable.FontAwesome.Fa.Icon "fas fa-border-all"
            let inline BorderNone<'a> = Fable.FontAwesome.Fa.Icon "fas fa-border-none"
            let inline BorderStyle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-border-style"
            let inline BowlingBall<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bowling-ball"
            let inline Box<'a> = Fable.FontAwesome.Fa.Icon "fas fa-box"
            let inline BoxOpen<'a> = Fable.FontAwesome.Fa.Icon "fas fa-box-open"
            let inline Boxes<'a> = Fable.FontAwesome.Fa.Icon "fas fa-boxes"
            let inline Braille<'a> = Fable.FontAwesome.Fa.Icon "fas fa-braille"
            let inline Brain<'a> = Fable.FontAwesome.Fa.Icon "fas fa-brain"
            let inline BreadSlice<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bread-slice"
            let inline Briefcase<'a> = Fable.FontAwesome.Fa.Icon "fas fa-briefcase"
            let inline BriefcaseMedical<'a> = Fable.FontAwesome.Fa.Icon "fas fa-briefcase-medical"
            let inline BroadcastTower<'a> = Fable.FontAwesome.Fa.Icon "fas fa-broadcast-tower"
            let inline Broom<'a> = Fable.FontAwesome.Fa.Icon "fas fa-broom"
            let inline Brush<'a> = Fable.FontAwesome.Fa.Icon "fas fa-brush"
            let inline Bug<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bug"
            let inline Building<'a> = Fable.FontAwesome.Fa.Icon "fas fa-building"
            let inline Bullhorn<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bullhorn"
            let inline Bullseye<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bullseye"
            let inline Burn<'a> = Fable.FontAwesome.Fa.Icon "fas fa-burn"
            let inline Bus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bus"
            let inline BusAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-bus-alt"
            let inline BusinessTime<'a> = Fable.FontAwesome.Fa.Icon "fas fa-business-time"
            let inline Calculator<'a> = Fable.FontAwesome.Fa.Icon "fas fa-calculator"
            let inline Calendar<'a> = Fable.FontAwesome.Fa.Icon "fas fa-calendar"
            let inline CalendarAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-calendar-alt"
            let inline CalendarCheck<'a> = Fable.FontAwesome.Fa.Icon "fas fa-calendar-check"
            let inline CalendarDay<'a> = Fable.FontAwesome.Fa.Icon "fas fa-calendar-day"
            let inline CalendarMinus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-calendar-minus"
            let inline CalendarPlus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-calendar-plus"
            let inline CalendarTimes<'a> = Fable.FontAwesome.Fa.Icon "fas fa-calendar-times"
            let inline CalendarWeek<'a> = Fable.FontAwesome.Fa.Icon "fas fa-calendar-week"
            let inline Camera<'a> = Fable.FontAwesome.Fa.Icon "fas fa-camera"
            let inline CameraRetro<'a> = Fable.FontAwesome.Fa.Icon "fas fa-camera-retro"
            let inline Campground<'a> = Fable.FontAwesome.Fa.Icon "fas fa-campground"
            let inline CandyCane<'a> = Fable.FontAwesome.Fa.Icon "fas fa-candy-cane"
            let inline Cannabis<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cannabis"
            let inline Capsules<'a> = Fable.FontAwesome.Fa.Icon "fas fa-capsules"
            let inline Car<'a> = Fable.FontAwesome.Fa.Icon "fas fa-car"
            let inline CarAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-car-alt"
            let inline CarBattery<'a> = Fable.FontAwesome.Fa.Icon "fas fa-car-battery"
            let inline CarCrash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-car-crash"
            let inline CarSide<'a> = Fable.FontAwesome.Fa.Icon "fas fa-car-side"
            let inline Caravan<'a> = Fable.FontAwesome.Fa.Icon "fas fa-caravan"
            let inline CaretDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-caret-down"
            let inline CaretLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-caret-left"
            let inline CaretRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-caret-right"
            let inline CaretSquareDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-caret-square-down"
            let inline CaretSquareLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-caret-square-left"
            let inline CaretSquareRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-caret-square-right"
            let inline CaretSquareUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-caret-square-up"
            let inline CaretUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-caret-up"
            let inline Carrot<'a> = Fable.FontAwesome.Fa.Icon "fas fa-carrot"
            let inline CartArrowDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cart-arrow-down"
            let inline CartPlus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cart-plus"
            let inline CashRegister<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cash-register"
            let inline Cat<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cat"
            let inline Certificate<'a> = Fable.FontAwesome.Fa.Icon "fas fa-certificate"
            let inline Chair<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chair"
            let inline Chalkboard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chalkboard"
            let inline ChalkboardTeacher<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chalkboard-teacher"
            let inline ChargingStation<'a> = Fable.FontAwesome.Fa.Icon "fas fa-charging-station"
            let inline ChartArea<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chart-area"
            let inline ChartBar<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chart-bar"
            let inline ChartLine<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chart-line"
            let inline ChartPie<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chart-pie"
            let inline Check<'a> = Fable.FontAwesome.Fa.Icon "fas fa-check"
            let inline CheckCircle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-check-circle"
            let inline CheckDouble<'a> = Fable.FontAwesome.Fa.Icon "fas fa-check-double"
            let inline CheckSquare<'a> = Fable.FontAwesome.Fa.Icon "fas fa-check-square"
            let inline Cheese<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cheese"
            let inline Chess<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chess"
            let inline ChessBishop<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chess-bishop"
            let inline ChessBoard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chess-board"
            let inline ChessKing<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chess-king"
            let inline ChessKnight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chess-knight"
            let inline ChessPawn<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chess-pawn"
            let inline ChessQueen<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chess-queen"
            let inline ChessRook<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chess-rook"
            let inline ChevronCircleDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chevron-circle-down"
            let inline ChevronCircleLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chevron-circle-left"
            let inline ChevronCircleRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chevron-circle-right"
            let inline ChevronCircleUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chevron-circle-up"
            let inline ChevronDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chevron-down"
            let inline ChevronLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chevron-left"
            let inline ChevronRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chevron-right"
            let inline ChevronUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-chevron-up"
            let inline Child<'a> = Fable.FontAwesome.Fa.Icon "fas fa-child"
            let inline Church<'a> = Fable.FontAwesome.Fa.Icon "fas fa-church"
            let inline Circle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-circle"
            let inline CircleNotch<'a> = Fable.FontAwesome.Fa.Icon "fas fa-circle-notch"
            let inline City<'a> = Fable.FontAwesome.Fa.Icon "fas fa-city"
            let inline ClinicMedical<'a> = Fable.FontAwesome.Fa.Icon "fas fa-clinic-medical"
            let inline Clipboard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-clipboard"
            let inline ClipboardCheck<'a> = Fable.FontAwesome.Fa.Icon "fas fa-clipboard-check"
            let inline ClipboardList<'a> = Fable.FontAwesome.Fa.Icon "fas fa-clipboard-list"
            let inline Clock<'a> = Fable.FontAwesome.Fa.Icon "fas fa-clock"
            let inline Clone<'a> = Fable.FontAwesome.Fa.Icon "fas fa-clone"
            let inline ClosedCaptioning<'a> = Fable.FontAwesome.Fa.Icon "fas fa-closed-captioning"
            let inline Cloud<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cloud"
            let inline CloudDownloadAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cloud-download-alt"
            let inline CloudMeatball<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cloud-meatball"
            let inline CloudMoon<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cloud-moon"
            let inline CloudMoonRain<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cloud-moon-rain"
            let inline CloudRain<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cloud-rain"
            let inline CloudShowersHeavy<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cloud-showers-heavy"
            let inline CloudSun<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cloud-sun"
            let inline CloudSunRain<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cloud-sun-rain"
            let inline CloudUploadAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cloud-upload-alt"
            let inline Cocktail<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cocktail"
            let inline Code<'a> = Fable.FontAwesome.Fa.Icon "fas fa-code"
            let inline CodeBranch<'a> = Fable.FontAwesome.Fa.Icon "fas fa-code-branch"
            let inline Coffee<'a> = Fable.FontAwesome.Fa.Icon "fas fa-coffee"
            let inline Cog<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cog"
            let inline Cogs<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cogs"
            let inline Coins<'a> = Fable.FontAwesome.Fa.Icon "fas fa-coins"
            let inline Columns<'a> = Fable.FontAwesome.Fa.Icon "fas fa-columns"
            let inline Comment<'a> = Fable.FontAwesome.Fa.Icon "fas fa-comment"
            let inline CommentAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-comment-alt"
            let inline CommentDollar<'a> = Fable.FontAwesome.Fa.Icon "fas fa-comment-dollar"
            let inline CommentDots<'a> = Fable.FontAwesome.Fa.Icon "fas fa-comment-dots"
            let inline CommentMedical<'a> = Fable.FontAwesome.Fa.Icon "fas fa-comment-medical"
            let inline CommentSlash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-comment-slash"
            let inline Comments<'a> = Fable.FontAwesome.Fa.Icon "fas fa-comments"
            let inline CommentsDollar<'a> = Fable.FontAwesome.Fa.Icon "fas fa-comments-dollar"
            let inline CompactDisc<'a> = Fable.FontAwesome.Fa.Icon "fas fa-compact-disc"
            let inline Compass<'a> = Fable.FontAwesome.Fa.Icon "fas fa-compass"
            let inline Compress<'a> = Fable.FontAwesome.Fa.Icon "fas fa-compress"
            let inline CompressAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-compress-alt"
            let inline CompressArrowsAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-compress-arrows-alt"
            let inline ConciergeBell<'a> = Fable.FontAwesome.Fa.Icon "fas fa-concierge-bell"
            let inline Cookie<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cookie"
            let inline CookieBite<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cookie-bite"
            let inline Copy<'a> = Fable.FontAwesome.Fa.Icon "fas fa-copy"
            let inline Copyright<'a> = Fable.FontAwesome.Fa.Icon "fas fa-copyright"
            let inline Couch<'a> = Fable.FontAwesome.Fa.Icon "fas fa-couch"
            let inline CreditCard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-credit-card"
            let inline Crop<'a> = Fable.FontAwesome.Fa.Icon "fas fa-crop"
            let inline CropAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-crop-alt"
            let inline Cross<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cross"
            let inline Crosshairs<'a> = Fable.FontAwesome.Fa.Icon "fas fa-crosshairs"
            let inline Crow<'a> = Fable.FontAwesome.Fa.Icon "fas fa-crow"
            let inline Crown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-crown"
            let inline Crutch<'a> = Fable.FontAwesome.Fa.Icon "fas fa-crutch"
            let inline Cube<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cube"
            let inline Cubes<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cubes"
            let inline Cut<'a> = Fable.FontAwesome.Fa.Icon "fas fa-cut"
            let inline Database<'a> = Fable.FontAwesome.Fa.Icon "fas fa-database"
            let inline Deaf<'a> = Fable.FontAwesome.Fa.Icon "fas fa-deaf"
            let inline Democrat<'a> = Fable.FontAwesome.Fa.Icon "fas fa-democrat"
            let inline Desktop<'a> = Fable.FontAwesome.Fa.Icon "fas fa-desktop"
            let inline Dharmachakra<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dharmachakra"
            let inline Diagnoses<'a> = Fable.FontAwesome.Fa.Icon "fas fa-diagnoses"
            let inline Dice<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dice"
            let inline DiceD20<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dice-d20"
            let inline DiceD6<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dice-d6"
            let inline DiceFive<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dice-five"
            let inline DiceFour<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dice-four"
            let inline DiceOne<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dice-one"
            let inline DiceSix<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dice-six"
            let inline DiceThree<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dice-three"
            let inline DiceTwo<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dice-two"
            let inline DigitalTachograph<'a> = Fable.FontAwesome.Fa.Icon "fas fa-digital-tachograph"
            let inline Directions<'a> = Fable.FontAwesome.Fa.Icon "fas fa-directions"
            let inline Divide<'a> = Fable.FontAwesome.Fa.Icon "fas fa-divide"
            let inline Dizzy<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dizzy"
            let inline Dna<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dna"
            let inline Dog<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dog"
            let inline DollarSign<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dollar-sign"
            let inline Dolly<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dolly"
            let inline DollyFlatbed<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dolly-flatbed"
            let inline Donate<'a> = Fable.FontAwesome.Fa.Icon "fas fa-donate"
            let inline DoorClosed<'a> = Fable.FontAwesome.Fa.Icon "fas fa-door-closed"
            let inline DoorOpen<'a> = Fable.FontAwesome.Fa.Icon "fas fa-door-open"
            let inline DotCircle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dot-circle"
            let inline Dove<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dove"
            let inline Download<'a> = Fable.FontAwesome.Fa.Icon "fas fa-download"
            let inline DraftingCompass<'a> = Fable.FontAwesome.Fa.Icon "fas fa-drafting-compass"
            let inline Dragon<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dragon"
            let inline DrawPolygon<'a> = Fable.FontAwesome.Fa.Icon "fas fa-draw-polygon"
            let inline Drum<'a> = Fable.FontAwesome.Fa.Icon "fas fa-drum"
            let inline DrumSteelpan<'a> = Fable.FontAwesome.Fa.Icon "fas fa-drum-steelpan"
            let inline DrumstickBite<'a> = Fable.FontAwesome.Fa.Icon "fas fa-drumstick-bite"
            let inline Dumbbell<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dumbbell"
            let inline Dumpster<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dumpster"
            let inline DumpsterFire<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dumpster-fire"
            let inline Dungeon<'a> = Fable.FontAwesome.Fa.Icon "fas fa-dungeon"
            let inline Edit<'a> = Fable.FontAwesome.Fa.Icon "fas fa-edit"
            let inline Egg<'a> = Fable.FontAwesome.Fa.Icon "fas fa-egg"
            let inline Eject<'a> = Fable.FontAwesome.Fa.Icon "fas fa-eject"
            let inline EllipsisH<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ellipsis-h"
            let inline EllipsisV<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ellipsis-v"
            let inline Envelope<'a> = Fable.FontAwesome.Fa.Icon "fas fa-envelope"
            let inline EnvelopeOpen<'a> = Fable.FontAwesome.Fa.Icon "fas fa-envelope-open"
            let inline EnvelopeOpenText<'a> = Fable.FontAwesome.Fa.Icon "fas fa-envelope-open-text"
            let inline EnvelopeSquare<'a> = Fable.FontAwesome.Fa.Icon "fas fa-envelope-square"
            let inline Equals<'a> = Fable.FontAwesome.Fa.Icon "fas fa-equals"
            let inline Eraser<'a> = Fable.FontAwesome.Fa.Icon "fas fa-eraser"
            let inline Ethernet<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ethernet"
            let inline EuroSign<'a> = Fable.FontAwesome.Fa.Icon "fas fa-euro-sign"
            let inline ExchangeAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-exchange-alt"
            let inline Exclamation<'a> = Fable.FontAwesome.Fa.Icon "fas fa-exclamation"
            let inline ExclamationCircle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-exclamation-circle"
            let inline ExclamationTriangle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-exclamation-triangle"
            let inline Expand<'a> = Fable.FontAwesome.Fa.Icon "fas fa-expand"
            let inline ExpandAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-expand-alt"
            let inline ExpandArrowsAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-expand-arrows-alt"
            let inline ExternalLinkAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-external-link-alt"
            let inline ExternalLinkSquareAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-external-link-square-alt"
            let inline Eye<'a> = Fable.FontAwesome.Fa.Icon "fas fa-eye"
            let inline EyeDropper<'a> = Fable.FontAwesome.Fa.Icon "fas fa-eye-dropper"
            let inline EyeSlash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-eye-slash"
            let inline Fan<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fan"
            let inline FastBackward<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fast-backward"
            let inline FastForward<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fast-forward"
            let inline Fax<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fax"
            let inline Feather<'a> = Fable.FontAwesome.Fa.Icon "fas fa-feather"
            let inline FeatherAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-feather-alt"
            let inline Female<'a> = Fable.FontAwesome.Fa.Icon "fas fa-female"
            let inline FighterJet<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fighter-jet"
            let inline File<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file"
            let inline FileAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-alt"
            let inline FileArchive<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-archive"
            let inline FileAudio<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-audio"
            let inline FileCode<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-code"
            let inline FileContract<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-contract"
            let inline FileCsv<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-csv"
            let inline FileDownload<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-download"
            let inline FileExcel<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-excel"
            let inline FileExport<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-export"
            let inline FileImage<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-image"
            let inline FileImport<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-import"
            let inline FileInvoice<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-invoice"
            let inline FileInvoiceDollar<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-invoice-dollar"
            let inline FileMedical<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-medical"
            let inline FileMedicalAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-medical-alt"
            let inline FilePdf<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-pdf"
            let inline FilePowerpoint<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-powerpoint"
            let inline FilePrescription<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-prescription"
            let inline FileSignature<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-signature"
            let inline FileUpload<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-upload"
            let inline FileVideo<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-video"
            let inline FileWord<'a> = Fable.FontAwesome.Fa.Icon "fas fa-file-word"
            let inline Fill<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fill"
            let inline FillDrip<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fill-drip"
            let inline Film<'a> = Fable.FontAwesome.Fa.Icon "fas fa-film"
            let inline Filter<'a> = Fable.FontAwesome.Fa.Icon "fas fa-filter"
            let inline Fingerprint<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fingerprint"
            let inline Fire<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fire"
            let inline FireAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fire-alt"
            let inline FireExtinguisher<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fire-extinguisher"
            let inline FirstAid<'a> = Fable.FontAwesome.Fa.Icon "fas fa-first-aid"
            let inline Fish<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fish"
            let inline FistRaised<'a> = Fable.FontAwesome.Fa.Icon "fas fa-fist-raised"
            let inline Flag<'a> = Fable.FontAwesome.Fa.Icon "fas fa-flag"
            let inline FlagCheckered<'a> = Fable.FontAwesome.Fa.Icon "fas fa-flag-checkered"
            let inline FlagUsa<'a> = Fable.FontAwesome.Fa.Icon "fas fa-flag-usa"
            let inline Flask<'a> = Fable.FontAwesome.Fa.Icon "fas fa-flask"
            let inline Flushed<'a> = Fable.FontAwesome.Fa.Icon "fas fa-flushed"
            let inline Folder<'a> = Fable.FontAwesome.Fa.Icon "fas fa-folder"
            let inline FolderMinus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-folder-minus"
            let inline FolderOpen<'a> = Fable.FontAwesome.Fa.Icon "fas fa-folder-open"
            let inline FolderPlus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-folder-plus"
            let inline Font<'a> = Fable.FontAwesome.Fa.Icon "fas fa-font"
            let inline FootballBall<'a> = Fable.FontAwesome.Fa.Icon "fas fa-football-ball"
            let inline Forward<'a> = Fable.FontAwesome.Fa.Icon "fas fa-forward"
            let inline Frog<'a> = Fable.FontAwesome.Fa.Icon "fas fa-frog"
            let inline Frown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-frown"
            let inline FrownOpen<'a> = Fable.FontAwesome.Fa.Icon "fas fa-frown-open"
            let inline FunnelDollar<'a> = Fable.FontAwesome.Fa.Icon "fas fa-funnel-dollar"
            let inline Futbol<'a> = Fable.FontAwesome.Fa.Icon "fas fa-futbol"
            let inline Gamepad<'a> = Fable.FontAwesome.Fa.Icon "fas fa-gamepad"
            let inline GasPump<'a> = Fable.FontAwesome.Fa.Icon "fas fa-gas-pump"
            let inline Gavel<'a> = Fable.FontAwesome.Fa.Icon "fas fa-gavel"
            let inline Gem<'a> = Fable.FontAwesome.Fa.Icon "fas fa-gem"
            let inline Genderless<'a> = Fable.FontAwesome.Fa.Icon "fas fa-genderless"
            let inline Ghost<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ghost"
            let inline Gift<'a> = Fable.FontAwesome.Fa.Icon "fas fa-gift"
            let inline Gifts<'a> = Fable.FontAwesome.Fa.Icon "fas fa-gifts"
            let inline GlassCheers<'a> = Fable.FontAwesome.Fa.Icon "fas fa-glass-cheers"
            let inline GlassMartini<'a> = Fable.FontAwesome.Fa.Icon "fas fa-glass-martini"
            let inline GlassMartiniAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-glass-martini-alt"
            let inline GlassWhiskey<'a> = Fable.FontAwesome.Fa.Icon "fas fa-glass-whiskey"
            let inline Glasses<'a> = Fable.FontAwesome.Fa.Icon "fas fa-glasses"
            let inline Globe<'a> = Fable.FontAwesome.Fa.Icon "fas fa-globe"
            let inline GlobeAfrica<'a> = Fable.FontAwesome.Fa.Icon "fas fa-globe-africa"
            let inline GlobeAmericas<'a> = Fable.FontAwesome.Fa.Icon "fas fa-globe-americas"
            let inline GlobeAsia<'a> = Fable.FontAwesome.Fa.Icon "fas fa-globe-asia"
            let inline GlobeEurope<'a> = Fable.FontAwesome.Fa.Icon "fas fa-globe-europe"
            let inline GolfBall<'a> = Fable.FontAwesome.Fa.Icon "fas fa-golf-ball"
            let inline Gopuram<'a> = Fable.FontAwesome.Fa.Icon "fas fa-gopuram"
            let inline GraduationCap<'a> = Fable.FontAwesome.Fa.Icon "fas fa-graduation-cap"
            let inline GreaterThan<'a> = Fable.FontAwesome.Fa.Icon "fas fa-greater-than"
            let inline GreaterThanEqual<'a> = Fable.FontAwesome.Fa.Icon "fas fa-greater-than-equal"
            let inline Grimace<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grimace"
            let inline Grin<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin"
            let inline GrinAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin-alt"
            let inline GrinBeam<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin-beam"
            let inline GrinBeamSweat<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin-beam-sweat"
            let inline GrinHearts<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin-hearts"
            let inline GrinSquint<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin-squint"
            let inline GrinSquintTears<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin-squint-tears"
            let inline GrinStars<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin-stars"
            let inline GrinTears<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin-tears"
            let inline GrinTongue<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin-tongue"
            let inline GrinTongueSquint<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin-tongue-squint"
            let inline GrinTongueWink<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin-tongue-wink"
            let inline GrinWink<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grin-wink"
            let inline GripHorizontal<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grip-horizontal"
            let inline GripLines<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grip-lines"
            let inline GripLinesVertical<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grip-lines-vertical"
            let inline GripVertical<'a> = Fable.FontAwesome.Fa.Icon "fas fa-grip-vertical"
            let inline Guitar<'a> = Fable.FontAwesome.Fa.Icon "fas fa-guitar"
            let inline HSquare<'a> = Fable.FontAwesome.Fa.Icon "fas fa-h-square"
            let inline Hamburger<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hamburger"
            let inline Hammer<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hammer"
            let inline Hamsa<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hamsa"
            let inline HandHolding<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-holding"
            let inline HandHoldingHeart<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-holding-heart"
            let inline HandHoldingUsd<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-holding-usd"
            let inline HandLizard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-lizard"
            let inline HandMiddleFinger<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-middle-finger"
            let inline HandPaper<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-paper"
            let inline HandPeace<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-peace"
            let inline HandPointDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-point-down"
            let inline HandPointLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-point-left"
            let inline HandPointRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-point-right"
            let inline HandPointUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-point-up"
            let inline HandPointer<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-pointer"
            let inline HandRock<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-rock"
            let inline HandScissors<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-scissors"
            let inline HandSpock<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hand-spock"
            let inline Hands<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hands"
            let inline HandsHelping<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hands-helping"
            let inline Handshake<'a> = Fable.FontAwesome.Fa.Icon "fas fa-handshake"
            let inline Hanukiah<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hanukiah"
            let inline HardHat<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hard-hat"
            let inline Hashtag<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hashtag"
            let inline HatCowboy<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hat-cowboy"
            let inline HatCowboySide<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hat-cowboy-side"
            let inline HatWizard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hat-wizard"
            let inline Hdd<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hdd"
            let inline Heading<'a> = Fable.FontAwesome.Fa.Icon "fas fa-heading"
            let inline Headphones<'a> = Fable.FontAwesome.Fa.Icon "fas fa-headphones"
            let inline HeadphonesAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-headphones-alt"
            let inline Headset<'a> = Fable.FontAwesome.Fa.Icon "fas fa-headset"
            let inline Heart<'a> = Fable.FontAwesome.Fa.Icon "fas fa-heart"
            let inline HeartBroken<'a> = Fable.FontAwesome.Fa.Icon "fas fa-heart-broken"
            let inline Heartbeat<'a> = Fable.FontAwesome.Fa.Icon "fas fa-heartbeat"
            let inline Helicopter<'a> = Fable.FontAwesome.Fa.Icon "fas fa-helicopter"
            let inline Highlighter<'a> = Fable.FontAwesome.Fa.Icon "fas fa-highlighter"
            let inline Hiking<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hiking"
            let inline Hippo<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hippo"
            let inline History<'a> = Fable.FontAwesome.Fa.Icon "fas fa-history"
            let inline HockeyPuck<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hockey-puck"
            let inline HollyBerry<'a> = Fable.FontAwesome.Fa.Icon "fas fa-holly-berry"
            let inline Home<'a> = Fable.FontAwesome.Fa.Icon "fas fa-home"
            let inline Horse<'a> = Fable.FontAwesome.Fa.Icon "fas fa-horse"
            let inline HorseHead<'a> = Fable.FontAwesome.Fa.Icon "fas fa-horse-head"
            let inline Hospital<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hospital"
            let inline HospitalAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hospital-alt"
            let inline HospitalSymbol<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hospital-symbol"
            let inline HotTub<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hot-tub"
            let inline Hotdog<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hotdog"
            let inline Hotel<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hotel"
            let inline Hourglass<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hourglass"
            let inline HourglassEnd<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hourglass-end"
            let inline HourglassHalf<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hourglass-half"
            let inline HourglassStart<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hourglass-start"
            let inline HouseDamage<'a> = Fable.FontAwesome.Fa.Icon "fas fa-house-damage"
            let inline Hryvnia<'a> = Fable.FontAwesome.Fa.Icon "fas fa-hryvnia"
            let inline ICursor<'a> = Fable.FontAwesome.Fa.Icon "fas fa-i-cursor"
            let inline IceCream<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ice-cream"
            let inline Icicles<'a> = Fable.FontAwesome.Fa.Icon "fas fa-icicles"
            let inline Icons<'a> = Fable.FontAwesome.Fa.Icon "fas fa-icons"
            let inline IdBadge<'a> = Fable.FontAwesome.Fa.Icon "fas fa-id-badge"
            let inline IdCard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-id-card"
            let inline IdCardAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-id-card-alt"
            let inline Igloo<'a> = Fable.FontAwesome.Fa.Icon "fas fa-igloo"
            let inline Image<'a> = Fable.FontAwesome.Fa.Icon "fas fa-image"
            let inline Images<'a> = Fable.FontAwesome.Fa.Icon "fas fa-images"
            let inline Inbox<'a> = Fable.FontAwesome.Fa.Icon "fas fa-inbox"
            let inline Indent<'a> = Fable.FontAwesome.Fa.Icon "fas fa-indent"
            let inline Industry<'a> = Fable.FontAwesome.Fa.Icon "fas fa-industry"
            let inline Infinity<'a> = Fable.FontAwesome.Fa.Icon "fas fa-infinity"
            let inline Info<'a> = Fable.FontAwesome.Fa.Icon "fas fa-info"
            let inline InfoCircle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-info-circle"
            let inline Italic<'a> = Fable.FontAwesome.Fa.Icon "fas fa-italic"
            let inline Jedi<'a> = Fable.FontAwesome.Fa.Icon "fas fa-jedi"
            let inline Joint<'a> = Fable.FontAwesome.Fa.Icon "fas fa-joint"
            let inline JournalWhills<'a> = Fable.FontAwesome.Fa.Icon "fas fa-journal-whills"
            let inline Kaaba<'a> = Fable.FontAwesome.Fa.Icon "fas fa-kaaba"
            let inline Key<'a> = Fable.FontAwesome.Fa.Icon "fas fa-key"
            let inline Keyboard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-keyboard"
            let inline Khanda<'a> = Fable.FontAwesome.Fa.Icon "fas fa-khanda"
            let inline Kiss<'a> = Fable.FontAwesome.Fa.Icon "fas fa-kiss"
            let inline KissBeam<'a> = Fable.FontAwesome.Fa.Icon "fas fa-kiss-beam"
            let inline KissWinkHeart<'a> = Fable.FontAwesome.Fa.Icon "fas fa-kiss-wink-heart"
            let inline KiwiBird<'a> = Fable.FontAwesome.Fa.Icon "fas fa-kiwi-bird"
            let inline Landmark<'a> = Fable.FontAwesome.Fa.Icon "fas fa-landmark"
            let inline Language<'a> = Fable.FontAwesome.Fa.Icon "fas fa-language"
            let inline Laptop<'a> = Fable.FontAwesome.Fa.Icon "fas fa-laptop"
            let inline LaptopCode<'a> = Fable.FontAwesome.Fa.Icon "fas fa-laptop-code"
            let inline LaptopMedical<'a> = Fable.FontAwesome.Fa.Icon "fas fa-laptop-medical"
            let inline Laugh<'a> = Fable.FontAwesome.Fa.Icon "fas fa-laugh"
            let inline LaughBeam<'a> = Fable.FontAwesome.Fa.Icon "fas fa-laugh-beam"
            let inline LaughSquint<'a> = Fable.FontAwesome.Fa.Icon "fas fa-laugh-squint"
            let inline LaughWink<'a> = Fable.FontAwesome.Fa.Icon "fas fa-laugh-wink"
            let inline LayerGroup<'a> = Fable.FontAwesome.Fa.Icon "fas fa-layer-group"
            let inline Leaf<'a> = Fable.FontAwesome.Fa.Icon "fas fa-leaf"
            let inline Lemon<'a> = Fable.FontAwesome.Fa.Icon "fas fa-lemon"
            let inline LessThan<'a> = Fable.FontAwesome.Fa.Icon "fas fa-less-than"
            let inline LessThanEqual<'a> = Fable.FontAwesome.Fa.Icon "fas fa-less-than-equal"
            let inline LevelDownAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-level-down-alt"
            let inline LevelUpAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-level-up-alt"
            let inline LifeRing<'a> = Fable.FontAwesome.Fa.Icon "fas fa-life-ring"
            let inline Lightbulb<'a> = Fable.FontAwesome.Fa.Icon "fas fa-lightbulb"
            let inline Link<'a> = Fable.FontAwesome.Fa.Icon "fas fa-link"
            let inline LiraSign<'a> = Fable.FontAwesome.Fa.Icon "fas fa-lira-sign"
            let inline List<'a> = Fable.FontAwesome.Fa.Icon "fas fa-list"
            let inline ListAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-list-alt"
            let inline ListOl<'a> = Fable.FontAwesome.Fa.Icon "fas fa-list-ol"
            let inline ListUl<'a> = Fable.FontAwesome.Fa.Icon "fas fa-list-ul"
            let inline LocationArrow<'a> = Fable.FontAwesome.Fa.Icon "fas fa-location-arrow"
            let inline Lock<'a> = Fable.FontAwesome.Fa.Icon "fas fa-lock"
            let inline LockOpen<'a> = Fable.FontAwesome.Fa.Icon "fas fa-lock-open"
            let inline LongArrowAltDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-long-arrow-alt-down"
            let inline LongArrowAltLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-long-arrow-alt-left"
            let inline LongArrowAltRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-long-arrow-alt-right"
            let inline LongArrowAltUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-long-arrow-alt-up"
            let inline LowVision<'a> = Fable.FontAwesome.Fa.Icon "fas fa-low-vision"
            let inline LuggageCart<'a> = Fable.FontAwesome.Fa.Icon "fas fa-luggage-cart"
            let inline Magic<'a> = Fable.FontAwesome.Fa.Icon "fas fa-magic"
            let inline Magnet<'a> = Fable.FontAwesome.Fa.Icon "fas fa-magnet"
            let inline MailBulk<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mail-bulk"
            let inline Male<'a> = Fable.FontAwesome.Fa.Icon "fas fa-male"
            let inline Map<'a> = Fable.FontAwesome.Fa.Icon "fas fa-map"
            let inline MapMarked<'a> = Fable.FontAwesome.Fa.Icon "fas fa-map-marked"
            let inline MapMarkedAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-map-marked-alt"
            let inline MapMarker<'a> = Fable.FontAwesome.Fa.Icon "fas fa-map-marker"
            let inline MapMarkerAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-map-marker-alt"
            let inline MapPin<'a> = Fable.FontAwesome.Fa.Icon "fas fa-map-pin"
            let inline MapSigns<'a> = Fable.FontAwesome.Fa.Icon "fas fa-map-signs"
            let inline Marker<'a> = Fable.FontAwesome.Fa.Icon "fas fa-marker"
            let inline Mars<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mars"
            let inline MarsDouble<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mars-double"
            let inline MarsStroke<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mars-stroke"
            let inline MarsStrokeH<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mars-stroke-h"
            let inline MarsStrokeV<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mars-stroke-v"
            let inline Mask<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mask"
            let inline Medal<'a> = Fable.FontAwesome.Fa.Icon "fas fa-medal"
            let inline Medkit<'a> = Fable.FontAwesome.Fa.Icon "fas fa-medkit"
            let inline Meh<'a> = Fable.FontAwesome.Fa.Icon "fas fa-meh"
            let inline MehBlank<'a> = Fable.FontAwesome.Fa.Icon "fas fa-meh-blank"
            let inline MehRollingEyes<'a> = Fable.FontAwesome.Fa.Icon "fas fa-meh-rolling-eyes"
            let inline Memory<'a> = Fable.FontAwesome.Fa.Icon "fas fa-memory"
            let inline Menorah<'a> = Fable.FontAwesome.Fa.Icon "fas fa-menorah"
            let inline Mercury<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mercury"
            let inline Meteor<'a> = Fable.FontAwesome.Fa.Icon "fas fa-meteor"
            let inline Microchip<'a> = Fable.FontAwesome.Fa.Icon "fas fa-microchip"
            let inline Microphone<'a> = Fable.FontAwesome.Fa.Icon "fas fa-microphone"
            let inline MicrophoneAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-microphone-alt"
            let inline MicrophoneAltSlash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-microphone-alt-slash"
            let inline MicrophoneSlash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-microphone-slash"
            let inline Microscope<'a> = Fable.FontAwesome.Fa.Icon "fas fa-microscope"
            let inline Minus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-minus"
            let inline MinusCircle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-minus-circle"
            let inline MinusSquare<'a> = Fable.FontAwesome.Fa.Icon "fas fa-minus-square"
            let inline Mitten<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mitten"
            let inline Mobile<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mobile"
            let inline MobileAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mobile-alt"
            let inline MoneyBill<'a> = Fable.FontAwesome.Fa.Icon "fas fa-money-bill"
            let inline MoneyBillAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-money-bill-alt"
            let inline MoneyBillWave<'a> = Fable.FontAwesome.Fa.Icon "fas fa-money-bill-wave"
            let inline MoneyBillWaveAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-money-bill-wave-alt"
            let inline MoneyCheck<'a> = Fable.FontAwesome.Fa.Icon "fas fa-money-check"
            let inline MoneyCheckAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-money-check-alt"
            let inline Monument<'a> = Fable.FontAwesome.Fa.Icon "fas fa-monument"
            let inline Moon<'a> = Fable.FontAwesome.Fa.Icon "fas fa-moon"
            let inline MortarPestle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mortar-pestle"
            let inline Mosque<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mosque"
            let inline Motorcycle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-motorcycle"
            let inline Mountain<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mountain"
            let inline Mouse<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mouse"
            let inline MousePointer<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mouse-pointer"
            let inline MugHot<'a> = Fable.FontAwesome.Fa.Icon "fas fa-mug-hot"
            let inline Music<'a> = Fable.FontAwesome.Fa.Icon "fas fa-music"
            let inline NetworkWired<'a> = Fable.FontAwesome.Fa.Icon "fas fa-network-wired"
            let inline Neuter<'a> = Fable.FontAwesome.Fa.Icon "fas fa-neuter"
            let inline Newspaper<'a> = Fable.FontAwesome.Fa.Icon "fas fa-newspaper"
            let inline NotEqual<'a> = Fable.FontAwesome.Fa.Icon "fas fa-not-equal"
            let inline NotesMedical<'a> = Fable.FontAwesome.Fa.Icon "fas fa-notes-medical"
            let inline ObjectGroup<'a> = Fable.FontAwesome.Fa.Icon "fas fa-object-group"
            let inline ObjectUngroup<'a> = Fable.FontAwesome.Fa.Icon "fas fa-object-ungroup"
            let inline OilCan<'a> = Fable.FontAwesome.Fa.Icon "fas fa-oil-can"
            let inline Om<'a> = Fable.FontAwesome.Fa.Icon "fas fa-om"
            let inline Otter<'a> = Fable.FontAwesome.Fa.Icon "fas fa-otter"
            let inline Outdent<'a> = Fable.FontAwesome.Fa.Icon "fas fa-outdent"
            let inline Pager<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pager"
            let inline PaintBrush<'a> = Fable.FontAwesome.Fa.Icon "fas fa-paint-brush"
            let inline PaintRoller<'a> = Fable.FontAwesome.Fa.Icon "fas fa-paint-roller"
            let inline Palette<'a> = Fable.FontAwesome.Fa.Icon "fas fa-palette"
            let inline Pallet<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pallet"
            let inline PaperPlane<'a> = Fable.FontAwesome.Fa.Icon "fas fa-paper-plane"
            let inline Paperclip<'a> = Fable.FontAwesome.Fa.Icon "fas fa-paperclip"
            let inline ParachuteBox<'a> = Fable.FontAwesome.Fa.Icon "fas fa-parachute-box"
            let inline Paragraph<'a> = Fable.FontAwesome.Fa.Icon "fas fa-paragraph"
            let inline Parking<'a> = Fable.FontAwesome.Fa.Icon "fas fa-parking"
            let inline Passport<'a> = Fable.FontAwesome.Fa.Icon "fas fa-passport"
            let inline Pastafarianism<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pastafarianism"
            let inline Paste<'a> = Fable.FontAwesome.Fa.Icon "fas fa-paste"
            let inline Pause<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pause"
            let inline PauseCircle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pause-circle"
            let inline Paw<'a> = Fable.FontAwesome.Fa.Icon "fas fa-paw"
            let inline Peace<'a> = Fable.FontAwesome.Fa.Icon "fas fa-peace"
            let inline Pen<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pen"
            let inline PenAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pen-alt"
            let inline PenFancy<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pen-fancy"
            let inline PenNib<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pen-nib"
            let inline PenSquare<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pen-square"
            let inline PencilAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pencil-alt"
            let inline PencilRuler<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pencil-ruler"
            let inline PeopleCarry<'a> = Fable.FontAwesome.Fa.Icon "fas fa-people-carry"
            let inline PepperHot<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pepper-hot"
            let inline Percent<'a> = Fable.FontAwesome.Fa.Icon "fas fa-percent"
            let inline Percentage<'a> = Fable.FontAwesome.Fa.Icon "fas fa-percentage"
            let inline PersonBooth<'a> = Fable.FontAwesome.Fa.Icon "fas fa-person-booth"
            let inline Phone<'a> = Fable.FontAwesome.Fa.Icon "fas fa-phone"
            let inline PhoneAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-phone-alt"
            let inline PhoneSlash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-phone-slash"
            let inline PhoneSquare<'a> = Fable.FontAwesome.Fa.Icon "fas fa-phone-square"
            let inline PhoneSquareAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-phone-square-alt"
            let inline PhoneVolume<'a> = Fable.FontAwesome.Fa.Icon "fas fa-phone-volume"
            let inline PhotoVideo<'a> = Fable.FontAwesome.Fa.Icon "fas fa-photo-video"
            let inline PiggyBank<'a> = Fable.FontAwesome.Fa.Icon "fas fa-piggy-bank"
            let inline Pills<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pills"
            let inline PizzaSlice<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pizza-slice"
            let inline PlaceOfWorship<'a> = Fable.FontAwesome.Fa.Icon "fas fa-place-of-worship"
            let inline Plane<'a> = Fable.FontAwesome.Fa.Icon "fas fa-plane"
            let inline PlaneArrival<'a> = Fable.FontAwesome.Fa.Icon "fas fa-plane-arrival"
            let inline PlaneDeparture<'a> = Fable.FontAwesome.Fa.Icon "fas fa-plane-departure"
            let inline Play<'a> = Fable.FontAwesome.Fa.Icon "fas fa-play"
            let inline PlayCircle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-play-circle"
            let inline Plug<'a> = Fable.FontAwesome.Fa.Icon "fas fa-plug"
            let inline Plus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-plus"
            let inline PlusCircle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-plus-circle"
            let inline PlusSquare<'a> = Fable.FontAwesome.Fa.Icon "fas fa-plus-square"
            let inline Podcast<'a> = Fable.FontAwesome.Fa.Icon "fas fa-podcast"
            let inline Poll<'a> = Fable.FontAwesome.Fa.Icon "fas fa-poll"
            let inline PollH<'a> = Fable.FontAwesome.Fa.Icon "fas fa-poll-h"
            let inline Poo<'a> = Fable.FontAwesome.Fa.Icon "fas fa-poo"
            let inline PooStorm<'a> = Fable.FontAwesome.Fa.Icon "fas fa-poo-storm"
            let inline Poop<'a> = Fable.FontAwesome.Fa.Icon "fas fa-poop"
            let inline Portrait<'a> = Fable.FontAwesome.Fa.Icon "fas fa-portrait"
            let inline PoundSign<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pound-sign"
            let inline PowerOff<'a> = Fable.FontAwesome.Fa.Icon "fas fa-power-off"
            let inline Pray<'a> = Fable.FontAwesome.Fa.Icon "fas fa-pray"
            let inline PrayingHands<'a> = Fable.FontAwesome.Fa.Icon "fas fa-praying-hands"
            let inline Prescription<'a> = Fable.FontAwesome.Fa.Icon "fas fa-prescription"
            let inline PrescriptionBottle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-prescription-bottle"
            let inline PrescriptionBottleAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-prescription-bottle-alt"
            let inline Print<'a> = Fable.FontAwesome.Fa.Icon "fas fa-print"
            let inline Procedures<'a> = Fable.FontAwesome.Fa.Icon "fas fa-procedures"
            let inline ProjectDiagram<'a> = Fable.FontAwesome.Fa.Icon "fas fa-project-diagram"
            let inline PuzzlePiece<'a> = Fable.FontAwesome.Fa.Icon "fas fa-puzzle-piece"
            let inline Qrcode<'a> = Fable.FontAwesome.Fa.Icon "fas fa-qrcode"
            let inline Question<'a> = Fable.FontAwesome.Fa.Icon "fas fa-question"
            let inline QuestionCircle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-question-circle"
            let inline Quidditch<'a> = Fable.FontAwesome.Fa.Icon "fas fa-quidditch"
            let inline QuoteLeft<'a> = Fable.FontAwesome.Fa.Icon "fas fa-quote-left"
            let inline QuoteRight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-quote-right"
            let inline Quran<'a> = Fable.FontAwesome.Fa.Icon "fas fa-quran"
            let inline Radiation<'a> = Fable.FontAwesome.Fa.Icon "fas fa-radiation"
            let inline RadiationAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-radiation-alt"
            let inline Rainbow<'a> = Fable.FontAwesome.Fa.Icon "fas fa-rainbow"
            let inline Random<'a> = Fable.FontAwesome.Fa.Icon "fas fa-random"
            let inline Receipt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-receipt"
            let inline RecordVinyl<'a> = Fable.FontAwesome.Fa.Icon "fas fa-record-vinyl"
            let inline Recycle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-recycle"
            let inline Redo<'a> = Fable.FontAwesome.Fa.Icon "fas fa-redo"
            let inline RedoAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-redo-alt"
            let inline Registered<'a> = Fable.FontAwesome.Fa.Icon "fas fa-registered"
            let inline RemoveFormat<'a> = Fable.FontAwesome.Fa.Icon "fas fa-remove-format"
            let inline Reply<'a> = Fable.FontAwesome.Fa.Icon "fas fa-reply"
            let inline ReplyAll<'a> = Fable.FontAwesome.Fa.Icon "fas fa-reply-all"
            let inline Republican<'a> = Fable.FontAwesome.Fa.Icon "fas fa-republican"
            let inline Restroom<'a> = Fable.FontAwesome.Fa.Icon "fas fa-restroom"
            let inline Retweet<'a> = Fable.FontAwesome.Fa.Icon "fas fa-retweet"
            let inline Ribbon<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ribbon"
            let inline Ring<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ring"
            let inline Road<'a> = Fable.FontAwesome.Fa.Icon "fas fa-road"
            let inline Robot<'a> = Fable.FontAwesome.Fa.Icon "fas fa-robot"
            let inline Rocket<'a> = Fable.FontAwesome.Fa.Icon "fas fa-rocket"
            let inline Route<'a> = Fable.FontAwesome.Fa.Icon "fas fa-route"
            let inline Rss<'a> = Fable.FontAwesome.Fa.Icon "fas fa-rss"
            let inline RssSquare<'a> = Fable.FontAwesome.Fa.Icon "fas fa-rss-square"
            let inline RubleSign<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ruble-sign"
            let inline Ruler<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ruler"
            let inline RulerCombined<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ruler-combined"
            let inline RulerHorizontal<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ruler-horizontal"
            let inline RulerVertical<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ruler-vertical"
            let inline Running<'a> = Fable.FontAwesome.Fa.Icon "fas fa-running"
            let inline RupeeSign<'a> = Fable.FontAwesome.Fa.Icon "fas fa-rupee-sign"
            let inline SadCry<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sad-cry"
            let inline SadTear<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sad-tear"
            let inline Satellite<'a> = Fable.FontAwesome.Fa.Icon "fas fa-satellite"
            let inline SatelliteDish<'a> = Fable.FontAwesome.Fa.Icon "fas fa-satellite-dish"
            let inline Save<'a> = Fable.FontAwesome.Fa.Icon "fas fa-save"
            let inline School<'a> = Fable.FontAwesome.Fa.Icon "fas fa-school"
            let inline Screwdriver<'a> = Fable.FontAwesome.Fa.Icon "fas fa-screwdriver"
            let inline Scroll<'a> = Fable.FontAwesome.Fa.Icon "fas fa-scroll"
            let inline SdCard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sd-card"
            let inline Search<'a> = Fable.FontAwesome.Fa.Icon "fas fa-search"
            let inline SearchDollar<'a> = Fable.FontAwesome.Fa.Icon "fas fa-search-dollar"
            let inline SearchLocation<'a> = Fable.FontAwesome.Fa.Icon "fas fa-search-location"
            let inline SearchMinus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-search-minus"
            let inline SearchPlus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-search-plus"
            let inline Seedling<'a> = Fable.FontAwesome.Fa.Icon "fas fa-seedling"
            let inline Server<'a> = Fable.FontAwesome.Fa.Icon "fas fa-server"
            let inline Shapes<'a> = Fable.FontAwesome.Fa.Icon "fas fa-shapes"
            let inline Share<'a> = Fable.FontAwesome.Fa.Icon "fas fa-share"
            let inline ShareAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-share-alt"
            let inline ShareAltSquare<'a> = Fable.FontAwesome.Fa.Icon "fas fa-share-alt-square"
            let inline ShareSquare<'a> = Fable.FontAwesome.Fa.Icon "fas fa-share-square"
            let inline ShekelSign<'a> = Fable.FontAwesome.Fa.Icon "fas fa-shekel-sign"
            let inline ShieldAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-shield-alt"
            let inline Ship<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ship"
            let inline ShippingFast<'a> = Fable.FontAwesome.Fa.Icon "fas fa-shipping-fast"
            let inline ShoePrints<'a> = Fable.FontAwesome.Fa.Icon "fas fa-shoe-prints"
            let inline ShoppingBag<'a> = Fable.FontAwesome.Fa.Icon "fas fa-shopping-bag"
            let inline ShoppingBasket<'a> = Fable.FontAwesome.Fa.Icon "fas fa-shopping-basket"
            let inline ShoppingCart<'a> = Fable.FontAwesome.Fa.Icon "fas fa-shopping-cart"
            let inline Shower<'a> = Fable.FontAwesome.Fa.Icon "fas fa-shower"
            let inline ShuttleVan<'a> = Fable.FontAwesome.Fa.Icon "fas fa-shuttle-van"
            let inline Sign<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sign"
            let inline SignInAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sign-in-alt"
            let inline SignLanguage<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sign-language"
            let inline SignOutAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sign-out-alt"
            let inline Signal<'a> = Fable.FontAwesome.Fa.Icon "fas fa-signal"
            let inline Signature<'a> = Fable.FontAwesome.Fa.Icon "fas fa-signature"
            let inline SimCard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sim-card"
            let inline Sitemap<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sitemap"
            let inline Skating<'a> = Fable.FontAwesome.Fa.Icon "fas fa-skating"
            let inline Skiing<'a> = Fable.FontAwesome.Fa.Icon "fas fa-skiing"
            let inline SkiingNordic<'a> = Fable.FontAwesome.Fa.Icon "fas fa-skiing-nordic"
            let inline Skull<'a> = Fable.FontAwesome.Fa.Icon "fas fa-skull"
            let inline SkullCrossbones<'a> = Fable.FontAwesome.Fa.Icon "fas fa-skull-crossbones"
            let inline Slash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-slash"
            let inline Sleigh<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sleigh"
            let inline SlidersH<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sliders-h"
            let inline Smile<'a> = Fable.FontAwesome.Fa.Icon "fas fa-smile"
            let inline SmileBeam<'a> = Fable.FontAwesome.Fa.Icon "fas fa-smile-beam"
            let inline SmileWink<'a> = Fable.FontAwesome.Fa.Icon "fas fa-smile-wink"
            let inline Smog<'a> = Fable.FontAwesome.Fa.Icon "fas fa-smog"
            let inline Smoking<'a> = Fable.FontAwesome.Fa.Icon "fas fa-smoking"
            let inline SmokingBan<'a> = Fable.FontAwesome.Fa.Icon "fas fa-smoking-ban"
            let inline Sms<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sms"
            let inline Snowboarding<'a> = Fable.FontAwesome.Fa.Icon "fas fa-snowboarding"
            let inline Snowflake<'a> = Fable.FontAwesome.Fa.Icon "fas fa-snowflake"
            let inline Snowman<'a> = Fable.FontAwesome.Fa.Icon "fas fa-snowman"
            let inline Snowplow<'a> = Fable.FontAwesome.Fa.Icon "fas fa-snowplow"
            let inline Socks<'a> = Fable.FontAwesome.Fa.Icon "fas fa-socks"
            let inline SolarPanel<'a> = Fable.FontAwesome.Fa.Icon "fas fa-solar-panel"
            let inline Sort<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort"
            let inline SortAlphaDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-alpha-down"
            let inline SortAlphaDownAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-alpha-down-alt"
            let inline SortAlphaUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-alpha-up"
            let inline SortAlphaUpAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-alpha-up-alt"
            let inline SortAmountDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-amount-down"
            let inline SortAmountDownAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-amount-down-alt"
            let inline SortAmountUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-amount-up"
            let inline SortAmountUpAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-amount-up-alt"
            let inline SortDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-down"
            let inline SortNumericDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-numeric-down"
            let inline SortNumericDownAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-numeric-down-alt"
            let inline SortNumericUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-numeric-up"
            let inline SortNumericUpAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-numeric-up-alt"
            let inline SortUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sort-up"
            let inline Spa<'a> = Fable.FontAwesome.Fa.Icon "fas fa-spa"
            let inline SpaceShuttle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-space-shuttle"
            let inline SpellCheck<'a> = Fable.FontAwesome.Fa.Icon "fas fa-spell-check"
            let inline Spider<'a> = Fable.FontAwesome.Fa.Icon "fas fa-spider"
            let inline Spinner<'a> = Fable.FontAwesome.Fa.Icon "fas fa-spinner"
            let inline Splotch<'a> = Fable.FontAwesome.Fa.Icon "fas fa-splotch"
            let inline SprayCan<'a> = Fable.FontAwesome.Fa.Icon "fas fa-spray-can"
            let inline Square<'a> = Fable.FontAwesome.Fa.Icon "fas fa-square"
            let inline SquareFull<'a> = Fable.FontAwesome.Fa.Icon "fas fa-square-full"
            let inline SquareRootAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-square-root-alt"
            let inline Stamp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-stamp"
            let inline Star<'a> = Fable.FontAwesome.Fa.Icon "fas fa-star"
            let inline StarAndCrescent<'a> = Fable.FontAwesome.Fa.Icon "fas fa-star-and-crescent"
            let inline StarHalf<'a> = Fable.FontAwesome.Fa.Icon "fas fa-star-half"
            let inline StarHalfAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-star-half-alt"
            let inline StarOfDavid<'a> = Fable.FontAwesome.Fa.Icon "fas fa-star-of-david"
            let inline StarOfLife<'a> = Fable.FontAwesome.Fa.Icon "fas fa-star-of-life"
            let inline StepBackward<'a> = Fable.FontAwesome.Fa.Icon "fas fa-step-backward"
            let inline StepForward<'a> = Fable.FontAwesome.Fa.Icon "fas fa-step-forward"
            let inline Stethoscope<'a> = Fable.FontAwesome.Fa.Icon "fas fa-stethoscope"
            let inline StickyNote<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sticky-note"
            let inline Stop<'a> = Fable.FontAwesome.Fa.Icon "fas fa-stop"
            let inline StopCircle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-stop-circle"
            let inline Stopwatch<'a> = Fable.FontAwesome.Fa.Icon "fas fa-stopwatch"
            let inline Store<'a> = Fable.FontAwesome.Fa.Icon "fas fa-store"
            let inline StoreAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-store-alt"
            let inline Stream<'a> = Fable.FontAwesome.Fa.Icon "fas fa-stream"
            let inline StreetView<'a> = Fable.FontAwesome.Fa.Icon "fas fa-street-view"
            let inline Strikethrough<'a> = Fable.FontAwesome.Fa.Icon "fas fa-strikethrough"
            let inline Stroopwafel<'a> = Fable.FontAwesome.Fa.Icon "fas fa-stroopwafel"
            let inline Subscript<'a> = Fable.FontAwesome.Fa.Icon "fas fa-subscript"
            let inline Subway<'a> = Fable.FontAwesome.Fa.Icon "fas fa-subway"
            let inline Suitcase<'a> = Fable.FontAwesome.Fa.Icon "fas fa-suitcase"
            let inline SuitcaseRolling<'a> = Fable.FontAwesome.Fa.Icon "fas fa-suitcase-rolling"
            let inline Sun<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sun"
            let inline Superscript<'a> = Fable.FontAwesome.Fa.Icon "fas fa-superscript"
            let inline Surprise<'a> = Fable.FontAwesome.Fa.Icon "fas fa-surprise"
            let inline Swatchbook<'a> = Fable.FontAwesome.Fa.Icon "fas fa-swatchbook"
            let inline Swimmer<'a> = Fable.FontAwesome.Fa.Icon "fas fa-swimmer"
            let inline SwimmingPool<'a> = Fable.FontAwesome.Fa.Icon "fas fa-swimming-pool"
            let inline Synagogue<'a> = Fable.FontAwesome.Fa.Icon "fas fa-synagogue"
            let inline Sync<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sync"
            let inline SyncAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-sync-alt"
            let inline Syringe<'a> = Fable.FontAwesome.Fa.Icon "fas fa-syringe"
            let inline Table<'a> = Fable.FontAwesome.Fa.Icon "fas fa-table"
            let inline TableTennis<'a> = Fable.FontAwesome.Fa.Icon "fas fa-table-tennis"
            let inline Tablet<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tablet"
            let inline TabletAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tablet-alt"
            let inline Tablets<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tablets"
            let inline TachometerAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tachometer-alt"
            let inline Tag<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tag"
            let inline Tags<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tags"
            let inline Tape<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tape"
            let inline Tasks<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tasks"
            let inline Taxi<'a> = Fable.FontAwesome.Fa.Icon "fas fa-taxi"
            let inline Teeth<'a> = Fable.FontAwesome.Fa.Icon "fas fa-teeth"
            let inline TeethOpen<'a> = Fable.FontAwesome.Fa.Icon "fas fa-teeth-open"
            let inline TemperatureHigh<'a> = Fable.FontAwesome.Fa.Icon "fas fa-temperature-high"
            let inline TemperatureLow<'a> = Fable.FontAwesome.Fa.Icon "fas fa-temperature-low"
            let inline Tenge<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tenge"
            let inline Terminal<'a> = Fable.FontAwesome.Fa.Icon "fas fa-terminal"
            let inline TextHeight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-text-height"
            let inline TextWidth<'a> = Fable.FontAwesome.Fa.Icon "fas fa-text-width"
            let inline Th<'a> = Fable.FontAwesome.Fa.Icon "fas fa-th"
            let inline ThLarge<'a> = Fable.FontAwesome.Fa.Icon "fas fa-th-large"
            let inline ThList<'a> = Fable.FontAwesome.Fa.Icon "fas fa-th-list"
            let inline TheaterMasks<'a> = Fable.FontAwesome.Fa.Icon "fas fa-theater-masks"
            let inline Thermometer<'a> = Fable.FontAwesome.Fa.Icon "fas fa-thermometer"
            let inline ThermometerEmpty<'a> = Fable.FontAwesome.Fa.Icon "fas fa-thermometer-empty"
            let inline ThermometerFull<'a> = Fable.FontAwesome.Fa.Icon "fas fa-thermometer-full"
            let inline ThermometerHalf<'a> = Fable.FontAwesome.Fa.Icon "fas fa-thermometer-half"
            let inline ThermometerQuarter<'a> = Fable.FontAwesome.Fa.Icon "fas fa-thermometer-quarter"
            let inline ThermometerThreeQuarters<'a> = Fable.FontAwesome.Fa.Icon "fas fa-thermometer-three-quarters"
            let inline ThumbsDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-thumbs-down"
            let inline ThumbsUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-thumbs-up"
            let inline Thumbtack<'a> = Fable.FontAwesome.Fa.Icon "fas fa-thumbtack"
            let inline TicketAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-ticket-alt"
            let inline Times<'a> = Fable.FontAwesome.Fa.Icon "fas fa-times"
            let inline TimesCircle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-times-circle"
            let inline Tint<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tint"
            let inline TintSlash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tint-slash"
            let inline Tired<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tired"
            let inline ToggleOff<'a> = Fable.FontAwesome.Fa.Icon "fas fa-toggle-off"
            let inline ToggleOn<'a> = Fable.FontAwesome.Fa.Icon "fas fa-toggle-on"
            let inline Toilet<'a> = Fable.FontAwesome.Fa.Icon "fas fa-toilet"
            let inline ToiletPaper<'a> = Fable.FontAwesome.Fa.Icon "fas fa-toilet-paper"
            let inline Toolbox<'a> = Fable.FontAwesome.Fa.Icon "fas fa-toolbox"
            let inline Tools<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tools"
            let inline Tooth<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tooth"
            let inline Torah<'a> = Fable.FontAwesome.Fa.Icon "fas fa-torah"
            let inline ToriiGate<'a> = Fable.FontAwesome.Fa.Icon "fas fa-torii-gate"
            let inline Tractor<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tractor"
            let inline Trademark<'a> = Fable.FontAwesome.Fa.Icon "fas fa-trademark"
            let inline TrafficLight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-traffic-light"
            let inline Trailer<'a> = Fable.FontAwesome.Fa.Icon "fas fa-trailer"
            let inline Train<'a> = Fable.FontAwesome.Fa.Icon "fas fa-train"
            let inline Tram<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tram"
            let inline Transgender<'a> = Fable.FontAwesome.Fa.Icon "fas fa-transgender"
            let inline TransgenderAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-transgender-alt"
            let inline Trash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-trash"
            let inline TrashAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-trash-alt"
            let inline TrashRestore<'a> = Fable.FontAwesome.Fa.Icon "fas fa-trash-restore"
            let inline TrashRestoreAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-trash-restore-alt"
            let inline Tree<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tree"
            let inline Trophy<'a> = Fable.FontAwesome.Fa.Icon "fas fa-trophy"
            let inline Truck<'a> = Fable.FontAwesome.Fa.Icon "fas fa-truck"
            let inline TruckLoading<'a> = Fable.FontAwesome.Fa.Icon "fas fa-truck-loading"
            let inline TruckMonster<'a> = Fable.FontAwesome.Fa.Icon "fas fa-truck-monster"
            let inline TruckMoving<'a> = Fable.FontAwesome.Fa.Icon "fas fa-truck-moving"
            let inline TruckPickup<'a> = Fable.FontAwesome.Fa.Icon "fas fa-truck-pickup"
            let inline Tshirt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tshirt"
            let inline Tty<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tty"
            let inline Tv<'a> = Fable.FontAwesome.Fa.Icon "fas fa-tv"
            let inline Umbrella<'a> = Fable.FontAwesome.Fa.Icon "fas fa-umbrella"
            let inline UmbrellaBeach<'a> = Fable.FontAwesome.Fa.Icon "fas fa-umbrella-beach"
            let inline Underline<'a> = Fable.FontAwesome.Fa.Icon "fas fa-underline"
            let inline Undo<'a> = Fable.FontAwesome.Fa.Icon "fas fa-undo"
            let inline UndoAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-undo-alt"
            let inline UniversalAccess<'a> = Fable.FontAwesome.Fa.Icon "fas fa-universal-access"
            let inline University<'a> = Fable.FontAwesome.Fa.Icon "fas fa-university"
            let inline Unlink<'a> = Fable.FontAwesome.Fa.Icon "fas fa-unlink"
            let inline Unlock<'a> = Fable.FontAwesome.Fa.Icon "fas fa-unlock"
            let inline UnlockAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-unlock-alt"
            let inline Upload<'a> = Fable.FontAwesome.Fa.Icon "fas fa-upload"
            let inline User<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user"
            let inline UserAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-alt"
            let inline UserAltSlash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-alt-slash"
            let inline UserAstronaut<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-astronaut"
            let inline UserCheck<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-check"
            let inline UserCircle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-circle"
            let inline UserClock<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-clock"
            let inline UserCog<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-cog"
            let inline UserEdit<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-edit"
            let inline UserFriends<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-friends"
            let inline UserGraduate<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-graduate"
            let inline UserInjured<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-injured"
            let inline UserLock<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-lock"
            let inline UserMd<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-md"
            let inline UserMinus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-minus"
            let inline UserNinja<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-ninja"
            let inline UserNurse<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-nurse"
            let inline UserPlus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-plus"
            let inline UserSecret<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-secret"
            let inline UserShield<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-shield"
            let inline UserSlash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-slash"
            let inline UserTag<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-tag"
            let inline UserTie<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-tie"
            let inline UserTimes<'a> = Fable.FontAwesome.Fa.Icon "fas fa-user-times"
            let inline Users<'a> = Fable.FontAwesome.Fa.Icon "fas fa-users"
            let inline UsersCog<'a> = Fable.FontAwesome.Fa.Icon "fas fa-users-cog"
            let inline UtensilSpoon<'a> = Fable.FontAwesome.Fa.Icon "fas fa-utensil-spoon"
            let inline Utensils<'a> = Fable.FontAwesome.Fa.Icon "fas fa-utensils"
            let inline VectorSquare<'a> = Fable.FontAwesome.Fa.Icon "fas fa-vector-square"
            let inline Venus<'a> = Fable.FontAwesome.Fa.Icon "fas fa-venus"
            let inline VenusDouble<'a> = Fable.FontAwesome.Fa.Icon "fas fa-venus-double"
            let inline VenusMars<'a> = Fable.FontAwesome.Fa.Icon "fas fa-venus-mars"
            let inline Vial<'a> = Fable.FontAwesome.Fa.Icon "fas fa-vial"
            let inline Vials<'a> = Fable.FontAwesome.Fa.Icon "fas fa-vials"
            let inline Video<'a> = Fable.FontAwesome.Fa.Icon "fas fa-video"
            let inline VideoSlash<'a> = Fable.FontAwesome.Fa.Icon "fas fa-video-slash"
            let inline Vihara<'a> = Fable.FontAwesome.Fa.Icon "fas fa-vihara"
            let inline Voicemail<'a> = Fable.FontAwesome.Fa.Icon "fas fa-voicemail"
            let inline VolleyballBall<'a> = Fable.FontAwesome.Fa.Icon "fas fa-volleyball-ball"
            let inline VolumeDown<'a> = Fable.FontAwesome.Fa.Icon "fas fa-volume-down"
            let inline VolumeMute<'a> = Fable.FontAwesome.Fa.Icon "fas fa-volume-mute"
            let inline VolumeOff<'a> = Fable.FontAwesome.Fa.Icon "fas fa-volume-off"
            let inline VolumeUp<'a> = Fable.FontAwesome.Fa.Icon "fas fa-volume-up"
            let inline VoteYea<'a> = Fable.FontAwesome.Fa.Icon "fas fa-vote-yea"
            let inline VrCardboard<'a> = Fable.FontAwesome.Fa.Icon "fas fa-vr-cardboard"
            let inline Walking<'a> = Fable.FontAwesome.Fa.Icon "fas fa-walking"
            let inline Wallet<'a> = Fable.FontAwesome.Fa.Icon "fas fa-wallet"
            let inline Warehouse<'a> = Fable.FontAwesome.Fa.Icon "fas fa-warehouse"
            let inline Water<'a> = Fable.FontAwesome.Fa.Icon "fas fa-water"
            let inline WaveSquare<'a> = Fable.FontAwesome.Fa.Icon "fas fa-wave-square"
            let inline Weight<'a> = Fable.FontAwesome.Fa.Icon "fas fa-weight"
            let inline WeightHanging<'a> = Fable.FontAwesome.Fa.Icon "fas fa-weight-hanging"
            let inline Wheelchair<'a> = Fable.FontAwesome.Fa.Icon "fas fa-wheelchair"
            let inline Wifi<'a> = Fable.FontAwesome.Fa.Icon "fas fa-wifi"
            let inline Wind<'a> = Fable.FontAwesome.Fa.Icon "fas fa-wind"
            let inline WindowClose<'a> = Fable.FontAwesome.Fa.Icon "fas fa-window-close"
            let inline WindowMaximize<'a> = Fable.FontAwesome.Fa.Icon "fas fa-window-maximize"
            let inline WindowMinimize<'a> = Fable.FontAwesome.Fa.Icon "fas fa-window-minimize"
            let inline WindowRestore<'a> = Fable.FontAwesome.Fa.Icon "fas fa-window-restore"
            let inline WineBottle<'a> = Fable.FontAwesome.Fa.Icon "fas fa-wine-bottle"
            let inline WineGlass<'a> = Fable.FontAwesome.Fa.Icon "fas fa-wine-glass"
            let inline WineGlassAlt<'a> = Fable.FontAwesome.Fa.Icon "fas fa-wine-glass-alt"
            let inline WonSign<'a> = Fable.FontAwesome.Fa.Icon "fas fa-won-sign"
            let inline Wrench<'a> = Fable.FontAwesome.Fa.Icon "fas fa-wrench"
            let inline XRay<'a> = Fable.FontAwesome.Fa.Icon "fas fa-x-ray"
            let inline YenSign<'a> = Fable.FontAwesome.Fa.Icon "fas fa-yen-sign"
            let inline YinYang<'a> = Fable.FontAwesome.Fa.Icon "fas fa-yin-yang"

        module Regular =
            let inline AddressBook<'a> = Fable.FontAwesome.Fa.Icon "far fa-address-book"
            let inline AddressCard<'a> = Fable.FontAwesome.Fa.Icon "far fa-address-card"
            let inline Angry<'a> = Fable.FontAwesome.Fa.Icon "far fa-angry"
            let inline ArrowAltCircleDown<'a> = Fable.FontAwesome.Fa.Icon "far fa-arrow-alt-circle-down"
            let inline ArrowAltCircleLeft<'a> = Fable.FontAwesome.Fa.Icon "far fa-arrow-alt-circle-left"
            let inline ArrowAltCircleRight<'a> = Fable.FontAwesome.Fa.Icon "far fa-arrow-alt-circle-right"
            let inline ArrowAltCircleUp<'a> = Fable.FontAwesome.Fa.Icon "far fa-arrow-alt-circle-up"
            let inline Bell<'a> = Fable.FontAwesome.Fa.Icon "far fa-bell"
            let inline BellSlash<'a> = Fable.FontAwesome.Fa.Icon "far fa-bell-slash"
            let inline Bookmark<'a> = Fable.FontAwesome.Fa.Icon "far fa-bookmark"
            let inline Building<'a> = Fable.FontAwesome.Fa.Icon "far fa-building"
            let inline Calendar<'a> = Fable.FontAwesome.Fa.Icon "far fa-calendar"
            let inline CalendarAlt<'a> = Fable.FontAwesome.Fa.Icon "far fa-calendar-alt"
            let inline CalendarCheck<'a> = Fable.FontAwesome.Fa.Icon "far fa-calendar-check"
            let inline CalendarMinus<'a> = Fable.FontAwesome.Fa.Icon "far fa-calendar-minus"
            let inline CalendarPlus<'a> = Fable.FontAwesome.Fa.Icon "far fa-calendar-plus"
            let inline CalendarTimes<'a> = Fable.FontAwesome.Fa.Icon "far fa-calendar-times"
            let inline CaretSquareDown<'a> = Fable.FontAwesome.Fa.Icon "far fa-caret-square-down"
            let inline CaretSquareLeft<'a> = Fable.FontAwesome.Fa.Icon "far fa-caret-square-left"
            let inline CaretSquareRight<'a> = Fable.FontAwesome.Fa.Icon "far fa-caret-square-right"
            let inline CaretSquareUp<'a> = Fable.FontAwesome.Fa.Icon "far fa-caret-square-up"
            let inline ChartBar<'a> = Fable.FontAwesome.Fa.Icon "far fa-chart-bar"
            let inline CheckCircle<'a> = Fable.FontAwesome.Fa.Icon "far fa-check-circle"
            let inline CheckSquare<'a> = Fable.FontAwesome.Fa.Icon "far fa-check-square"
            let inline Circle<'a> = Fable.FontAwesome.Fa.Icon "far fa-circle"
            let inline Clipboard<'a> = Fable.FontAwesome.Fa.Icon "far fa-clipboard"
            let inline Clock<'a> = Fable.FontAwesome.Fa.Icon "far fa-clock"
            let inline Clone<'a> = Fable.FontAwesome.Fa.Icon "far fa-clone"
            let inline ClosedCaptioning<'a> = Fable.FontAwesome.Fa.Icon "far fa-closed-captioning"
            let inline Comment<'a> = Fable.FontAwesome.Fa.Icon "far fa-comment"
            let inline CommentAlt<'a> = Fable.FontAwesome.Fa.Icon "far fa-comment-alt"
            let inline CommentDots<'a> = Fable.FontAwesome.Fa.Icon "far fa-comment-dots"
            let inline Comments<'a> = Fable.FontAwesome.Fa.Icon "far fa-comments"
            let inline Compass<'a> = Fable.FontAwesome.Fa.Icon "far fa-compass"
            let inline Copy<'a> = Fable.FontAwesome.Fa.Icon "far fa-copy"
            let inline Copyright<'a> = Fable.FontAwesome.Fa.Icon "far fa-copyright"
            let inline CreditCard<'a> = Fable.FontAwesome.Fa.Icon "far fa-credit-card"
            let inline Dizzy<'a> = Fable.FontAwesome.Fa.Icon "far fa-dizzy"
            let inline DotCircle<'a> = Fable.FontAwesome.Fa.Icon "far fa-dot-circle"
            let inline Edit<'a> = Fable.FontAwesome.Fa.Icon "far fa-edit"
            let inline Envelope<'a> = Fable.FontAwesome.Fa.Icon "far fa-envelope"
            let inline EnvelopeOpen<'a> = Fable.FontAwesome.Fa.Icon "far fa-envelope-open"
            let inline Eye<'a> = Fable.FontAwesome.Fa.Icon "far fa-eye"
            let inline EyeSlash<'a> = Fable.FontAwesome.Fa.Icon "far fa-eye-slash"
            let inline File<'a> = Fable.FontAwesome.Fa.Icon "far fa-file"
            let inline FileAlt<'a> = Fable.FontAwesome.Fa.Icon "far fa-file-alt"
            let inline FileArchive<'a> = Fable.FontAwesome.Fa.Icon "far fa-file-archive"
            let inline FileAudio<'a> = Fable.FontAwesome.Fa.Icon "far fa-file-audio"
            let inline FileCode<'a> = Fable.FontAwesome.Fa.Icon "far fa-file-code"
            let inline FileExcel<'a> = Fable.FontAwesome.Fa.Icon "far fa-file-excel"
            let inline FileImage<'a> = Fable.FontAwesome.Fa.Icon "far fa-file-image"
            let inline FilePdf<'a> = Fable.FontAwesome.Fa.Icon "far fa-file-pdf"
            let inline FilePowerpoint<'a> = Fable.FontAwesome.Fa.Icon "far fa-file-powerpoint"
            let inline FileVideo<'a> = Fable.FontAwesome.Fa.Icon "far fa-file-video"
            let inline FileWord<'a> = Fable.FontAwesome.Fa.Icon "far fa-file-word"
            let inline Flag<'a> = Fable.FontAwesome.Fa.Icon "far fa-flag"
            let inline Flushed<'a> = Fable.FontAwesome.Fa.Icon "far fa-flushed"
            let inline Folder<'a> = Fable.FontAwesome.Fa.Icon "far fa-folder"
            let inline FolderOpen<'a> = Fable.FontAwesome.Fa.Icon "far fa-folder-open"
            let inline Frown<'a> = Fable.FontAwesome.Fa.Icon "far fa-frown"
            let inline FrownOpen<'a> = Fable.FontAwesome.Fa.Icon "far fa-frown-open"
            let inline Futbol<'a> = Fable.FontAwesome.Fa.Icon "far fa-futbol"
            let inline Gem<'a> = Fable.FontAwesome.Fa.Icon "far fa-gem"
            let inline Grimace<'a> = Fable.FontAwesome.Fa.Icon "far fa-grimace"
            let inline Grin<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin"
            let inline GrinAlt<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin-alt"
            let inline GrinBeam<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin-beam"
            let inline GrinBeamSweat<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin-beam-sweat"
            let inline GrinHearts<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin-hearts"
            let inline GrinSquint<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin-squint"
            let inline GrinSquintTears<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin-squint-tears"
            let inline GrinStars<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin-stars"
            let inline GrinTears<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin-tears"
            let inline GrinTongue<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin-tongue"
            let inline GrinTongueSquint<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin-tongue-squint"
            let inline GrinTongueWink<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin-tongue-wink"
            let inline GrinWink<'a> = Fable.FontAwesome.Fa.Icon "far fa-grin-wink"
            let inline HandLizard<'a> = Fable.FontAwesome.Fa.Icon "far fa-hand-lizard"
            let inline HandPaper<'a> = Fable.FontAwesome.Fa.Icon "far fa-hand-paper"
            let inline HandPeace<'a> = Fable.FontAwesome.Fa.Icon "far fa-hand-peace"
            let inline HandPointDown<'a> = Fable.FontAwesome.Fa.Icon "far fa-hand-point-down"
            let inline HandPointLeft<'a> = Fable.FontAwesome.Fa.Icon "far fa-hand-point-left"
            let inline HandPointRight<'a> = Fable.FontAwesome.Fa.Icon "far fa-hand-point-right"
            let inline HandPointUp<'a> = Fable.FontAwesome.Fa.Icon "far fa-hand-point-up"
            let inline HandPointer<'a> = Fable.FontAwesome.Fa.Icon "far fa-hand-pointer"
            let inline HandRock<'a> = Fable.FontAwesome.Fa.Icon "far fa-hand-rock"
            let inline HandScissors<'a> = Fable.FontAwesome.Fa.Icon "far fa-hand-scissors"
            let inline HandSpock<'a> = Fable.FontAwesome.Fa.Icon "far fa-hand-spock"
            let inline Handshake<'a> = Fable.FontAwesome.Fa.Icon "far fa-handshake"
            let inline Hdd<'a> = Fable.FontAwesome.Fa.Icon "far fa-hdd"
            let inline Heart<'a> = Fable.FontAwesome.Fa.Icon "far fa-heart"
            let inline Hospital<'a> = Fable.FontAwesome.Fa.Icon "far fa-hospital"
            let inline Hourglass<'a> = Fable.FontAwesome.Fa.Icon "far fa-hourglass"
            let inline IdBadge<'a> = Fable.FontAwesome.Fa.Icon "far fa-id-badge"
            let inline IdCard<'a> = Fable.FontAwesome.Fa.Icon "far fa-id-card"
            let inline Image<'a> = Fable.FontAwesome.Fa.Icon "far fa-image"
            let inline Images<'a> = Fable.FontAwesome.Fa.Icon "far fa-images"
            let inline Keyboard<'a> = Fable.FontAwesome.Fa.Icon "far fa-keyboard"
            let inline Kiss<'a> = Fable.FontAwesome.Fa.Icon "far fa-kiss"
            let inline KissBeam<'a> = Fable.FontAwesome.Fa.Icon "far fa-kiss-beam"
            let inline KissWinkHeart<'a> = Fable.FontAwesome.Fa.Icon "far fa-kiss-wink-heart"
            let inline Laugh<'a> = Fable.FontAwesome.Fa.Icon "far fa-laugh"
            let inline LaughBeam<'a> = Fable.FontAwesome.Fa.Icon "far fa-laugh-beam"
            let inline LaughSquint<'a> = Fable.FontAwesome.Fa.Icon "far fa-laugh-squint"
            let inline LaughWink<'a> = Fable.FontAwesome.Fa.Icon "far fa-laugh-wink"
            let inline Lemon<'a> = Fable.FontAwesome.Fa.Icon "far fa-lemon"
            let inline LifeRing<'a> = Fable.FontAwesome.Fa.Icon "far fa-life-ring"
            let inline Lightbulb<'a> = Fable.FontAwesome.Fa.Icon "far fa-lightbulb"
            let inline ListAlt<'a> = Fable.FontAwesome.Fa.Icon "far fa-list-alt"
            let inline Map<'a> = Fable.FontAwesome.Fa.Icon "far fa-map"
            let inline Meh<'a> = Fable.FontAwesome.Fa.Icon "far fa-meh"
            let inline MehBlank<'a> = Fable.FontAwesome.Fa.Icon "far fa-meh-blank"
            let inline MehRollingEyes<'a> = Fable.FontAwesome.Fa.Icon "far fa-meh-rolling-eyes"
            let inline MinusSquare<'a> = Fable.FontAwesome.Fa.Icon "far fa-minus-square"
            let inline MoneyBillAlt<'a> = Fable.FontAwesome.Fa.Icon "far fa-money-bill-alt"
            let inline Moon<'a> = Fable.FontAwesome.Fa.Icon "far fa-moon"
            let inline Newspaper<'a> = Fable.FontAwesome.Fa.Icon "far fa-newspaper"
            let inline ObjectGroup<'a> = Fable.FontAwesome.Fa.Icon "far fa-object-group"
            let inline ObjectUngroup<'a> = Fable.FontAwesome.Fa.Icon "far fa-object-ungroup"
            let inline PaperPlane<'a> = Fable.FontAwesome.Fa.Icon "far fa-paper-plane"
            let inline PauseCircle<'a> = Fable.FontAwesome.Fa.Icon "far fa-pause-circle"
            let inline PlayCircle<'a> = Fable.FontAwesome.Fa.Icon "far fa-play-circle"
            let inline PlusSquare<'a> = Fable.FontAwesome.Fa.Icon "far fa-plus-square"
            let inline QuestionCircle<'a> = Fable.FontAwesome.Fa.Icon "far fa-question-circle"
            let inline Registered<'a> = Fable.FontAwesome.Fa.Icon "far fa-registered"
            let inline SadCry<'a> = Fable.FontAwesome.Fa.Icon "far fa-sad-cry"
            let inline SadTear<'a> = Fable.FontAwesome.Fa.Icon "far fa-sad-tear"
            let inline Save<'a> = Fable.FontAwesome.Fa.Icon "far fa-save"
            let inline ShareSquare<'a> = Fable.FontAwesome.Fa.Icon "far fa-share-square"
            let inline Smile<'a> = Fable.FontAwesome.Fa.Icon "far fa-smile"
            let inline SmileBeam<'a> = Fable.FontAwesome.Fa.Icon "far fa-smile-beam"
            let inline SmileWink<'a> = Fable.FontAwesome.Fa.Icon "far fa-smile-wink"
            let inline Snowflake<'a> = Fable.FontAwesome.Fa.Icon "far fa-snowflake"
            let inline Square<'a> = Fable.FontAwesome.Fa.Icon "far fa-square"
            let inline Star<'a> = Fable.FontAwesome.Fa.Icon "far fa-star"
            let inline StarHalf<'a> = Fable.FontAwesome.Fa.Icon "far fa-star-half"
            let inline StickyNote<'a> = Fable.FontAwesome.Fa.Icon "far fa-sticky-note"
            let inline StopCircle<'a> = Fable.FontAwesome.Fa.Icon "far fa-stop-circle"
            let inline Sun<'a> = Fable.FontAwesome.Fa.Icon "far fa-sun"
            let inline Surprise<'a> = Fable.FontAwesome.Fa.Icon "far fa-surprise"
            let inline ThumbsDown<'a> = Fable.FontAwesome.Fa.Icon "far fa-thumbs-down"
            let inline ThumbsUp<'a> = Fable.FontAwesome.Fa.Icon "far fa-thumbs-up"
            let inline TimesCircle<'a> = Fable.FontAwesome.Fa.Icon "far fa-times-circle"
            let inline Tired<'a> = Fable.FontAwesome.Fa.Icon "far fa-tired"
            let inline TrashAlt<'a> = Fable.FontAwesome.Fa.Icon "far fa-trash-alt"
            let inline User<'a> = Fable.FontAwesome.Fa.Icon "far fa-user"
            let inline UserCircle<'a> = Fable.FontAwesome.Fa.Icon "far fa-user-circle"
            let inline WindowClose<'a> = Fable.FontAwesome.Fa.Icon "far fa-window-close"
            let inline WindowMaximize<'a> = Fable.FontAwesome.Fa.Icon "far fa-window-maximize"
            let inline WindowMinimize<'a> = Fable.FontAwesome.Fa.Icon "far fa-window-minimize"
            let inline WindowRestore<'a> = Fable.FontAwesome.Fa.Icon "far fa-window-restore"

        module Brand =
            let inline ``500px``<'a> = Fable.FontAwesome.Fa.Icon "fab fa-500px"
            let inline AccessibleIcon<'a> = Fable.FontAwesome.Fa.Icon "fab fa-accessible-icon"
            let inline Accusoft<'a> = Fable.FontAwesome.Fa.Icon "fab fa-accusoft"
            let inline AcquisitionsIncorporated<'a> = Fable.FontAwesome.Fa.Icon "fab fa-acquisitions-incorporated"
            let inline Adn<'a> = Fable.FontAwesome.Fa.Icon "fab fa-adn"
            let inline Adobe<'a> = Fable.FontAwesome.Fa.Icon "fab fa-adobe"
            let inline Adversal<'a> = Fable.FontAwesome.Fa.Icon "fab fa-adversal"
            let inline Affiliatetheme<'a> = Fable.FontAwesome.Fa.Icon "fab fa-affiliatetheme"
            let inline Airbnb<'a> = Fable.FontAwesome.Fa.Icon "fab fa-airbnb"
            let inline Algolia<'a> = Fable.FontAwesome.Fa.Icon "fab fa-algolia"
            let inline Alipay<'a> = Fable.FontAwesome.Fa.Icon "fab fa-alipay"
            let inline Amazon<'a> = Fable.FontAwesome.Fa.Icon "fab fa-amazon"
            let inline AmazonPay<'a> = Fable.FontAwesome.Fa.Icon "fab fa-amazon-pay"
            let inline Amilia<'a> = Fable.FontAwesome.Fa.Icon "fab fa-amilia"
            let inline Android<'a> = Fable.FontAwesome.Fa.Icon "fab fa-android"
            let inline Angellist<'a> = Fable.FontAwesome.Fa.Icon "fab fa-angellist"
            let inline Angrycreative<'a> = Fable.FontAwesome.Fa.Icon "fab fa-angrycreative"
            let inline Angular<'a> = Fable.FontAwesome.Fa.Icon "fab fa-angular"
            let inline AppStore<'a> = Fable.FontAwesome.Fa.Icon "fab fa-app-store"
            let inline AppStoreIos<'a> = Fable.FontAwesome.Fa.Icon "fab fa-app-store-ios"
            let inline Apper<'a> = Fable.FontAwesome.Fa.Icon "fab fa-apper"
            let inline Apple<'a> = Fable.FontAwesome.Fa.Icon "fab fa-apple"
            let inline ApplePay<'a> = Fable.FontAwesome.Fa.Icon "fab fa-apple-pay"
            let inline Artstation<'a> = Fable.FontAwesome.Fa.Icon "fab fa-artstation"
            let inline Asymmetrik<'a> = Fable.FontAwesome.Fa.Icon "fab fa-asymmetrik"
            let inline Atlassian<'a> = Fable.FontAwesome.Fa.Icon "fab fa-atlassian"
            let inline Audible<'a> = Fable.FontAwesome.Fa.Icon "fab fa-audible"
            let inline Autoprefixer<'a> = Fable.FontAwesome.Fa.Icon "fab fa-autoprefixer"
            let inline Avianex<'a> = Fable.FontAwesome.Fa.Icon "fab fa-avianex"
            let inline Aviato<'a> = Fable.FontAwesome.Fa.Icon "fab fa-aviato"
            let inline Aws<'a> = Fable.FontAwesome.Fa.Icon "fab fa-aws"
            let inline Bandcamp<'a> = Fable.FontAwesome.Fa.Icon "fab fa-bandcamp"
            let inline BattleNet<'a> = Fable.FontAwesome.Fa.Icon "fab fa-battle-net"
            let inline Behance<'a> = Fable.FontAwesome.Fa.Icon "fab fa-behance"
            let inline BehanceSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-behance-square"
            let inline Bimobject<'a> = Fable.FontAwesome.Fa.Icon "fab fa-bimobject"
            let inline Bitbucket<'a> = Fable.FontAwesome.Fa.Icon "fab fa-bitbucket"
            let inline Bitcoin<'a> = Fable.FontAwesome.Fa.Icon "fab fa-bitcoin"
            let inline Bity<'a> = Fable.FontAwesome.Fa.Icon "fab fa-bity"
            let inline BlackTie<'a> = Fable.FontAwesome.Fa.Icon "fab fa-black-tie"
            let inline Blackberry<'a> = Fable.FontAwesome.Fa.Icon "fab fa-blackberry"
            let inline Blogger<'a> = Fable.FontAwesome.Fa.Icon "fab fa-blogger"
            let inline BloggerB<'a> = Fable.FontAwesome.Fa.Icon "fab fa-blogger-b"
            let inline Bluetooth<'a> = Fable.FontAwesome.Fa.Icon "fab fa-bluetooth"
            let inline BluetoothB<'a> = Fable.FontAwesome.Fa.Icon "fab fa-bluetooth-b"
            let inline Bootstrap<'a> = Fable.FontAwesome.Fa.Icon "fab fa-bootstrap"
            let inline Btc<'a> = Fable.FontAwesome.Fa.Icon "fab fa-btc"
            let inline Buffer<'a> = Fable.FontAwesome.Fa.Icon "fab fa-buffer"
            let inline Buromobelexperte<'a> = Fable.FontAwesome.Fa.Icon "fab fa-buromobelexperte"
            let inline BuyNLarge<'a> = Fable.FontAwesome.Fa.Icon "fab fa-buy-n-large"
            let inline Buysellads<'a> = Fable.FontAwesome.Fa.Icon "fab fa-buysellads"
            let inline CanadianMapleLeaf<'a> = Fable.FontAwesome.Fa.Icon "fab fa-canadian-maple-leaf"
            let inline CcAmazonPay<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cc-amazon-pay"
            let inline CcAmex<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cc-amex"
            let inline CcApplePay<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cc-apple-pay"
            let inline CcDinersClub<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cc-diners-club"
            let inline CcDiscover<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cc-discover"
            let inline CcJcb<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cc-jcb"
            let inline CcMastercard<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cc-mastercard"
            let inline CcPaypal<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cc-paypal"
            let inline CcStripe<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cc-stripe"
            let inline CcVisa<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cc-visa"
            let inline Centercode<'a> = Fable.FontAwesome.Fa.Icon "fab fa-centercode"
            let inline Centos<'a> = Fable.FontAwesome.Fa.Icon "fab fa-centos"
            let inline Chrome<'a> = Fable.FontAwesome.Fa.Icon "fab fa-chrome"
            let inline Chromecast<'a> = Fable.FontAwesome.Fa.Icon "fab fa-chromecast"
            let inline Cloudscale<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cloudscale"
            let inline Cloudsmith<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cloudsmith"
            let inline Cloudversify<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cloudversify"
            let inline Codepen<'a> = Fable.FontAwesome.Fa.Icon "fab fa-codepen"
            let inline Codiepie<'a> = Fable.FontAwesome.Fa.Icon "fab fa-codiepie"
            let inline Confluence<'a> = Fable.FontAwesome.Fa.Icon "fab fa-confluence"
            let inline Connectdevelop<'a> = Fable.FontAwesome.Fa.Icon "fab fa-connectdevelop"
            let inline Contao<'a> = Fable.FontAwesome.Fa.Icon "fab fa-contao"
            let inline CottonBureau<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cotton-bureau"
            let inline Cpanel<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cpanel"
            let inline CreativeCommons<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons"
            let inline CreativeCommonsBy<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-by"
            let inline CreativeCommonsNc<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-nc"
            let inline CreativeCommonsNcEu<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-nc-eu"
            let inline CreativeCommonsNcJp<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-nc-jp"
            let inline CreativeCommonsNd<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-nd"
            let inline CreativeCommonsPd<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-pd"
            let inline CreativeCommonsPdAlt<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-pd-alt"
            let inline CreativeCommonsRemix<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-remix"
            let inline CreativeCommonsSa<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-sa"
            let inline CreativeCommonsSampling<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-sampling"
            let inline CreativeCommonsSamplingPlus<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-sampling-plus"
            let inline CreativeCommonsShare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-share"
            let inline CreativeCommonsZero<'a> = Fable.FontAwesome.Fa.Icon "fab fa-creative-commons-zero"
            let inline CriticalRole<'a> = Fable.FontAwesome.Fa.Icon "fab fa-critical-role"
            let inline Css3<'a> = Fable.FontAwesome.Fa.Icon "fab fa-css3"
            let inline Css3Alt<'a> = Fable.FontAwesome.Fa.Icon "fab fa-css3-alt"
            let inline Cuttlefish<'a> = Fable.FontAwesome.Fa.Icon "fab fa-cuttlefish"
            let inline DAndD<'a> = Fable.FontAwesome.Fa.Icon "fab fa-d-and-d"
            let inline DAndDBeyond<'a> = Fable.FontAwesome.Fa.Icon "fab fa-d-and-d-beyond"
            let inline Dailymotion<'a> = Fable.FontAwesome.Fa.Icon "fab fa-dailymotion"
            let inline Dashcube<'a> = Fable.FontAwesome.Fa.Icon "fab fa-dashcube"
            let inline Delicious<'a> = Fable.FontAwesome.Fa.Icon "fab fa-delicious"
            let inline Deploydog<'a> = Fable.FontAwesome.Fa.Icon "fab fa-deploydog"
            let inline Deskpro<'a> = Fable.FontAwesome.Fa.Icon "fab fa-deskpro"
            let inline Dev<'a> = Fable.FontAwesome.Fa.Icon "fab fa-dev"
            let inline Deviantart<'a> = Fable.FontAwesome.Fa.Icon "fab fa-deviantart"
            let inline Dhl<'a> = Fable.FontAwesome.Fa.Icon "fab fa-dhl"
            let inline Diaspora<'a> = Fable.FontAwesome.Fa.Icon "fab fa-diaspora"
            let inline Digg<'a> = Fable.FontAwesome.Fa.Icon "fab fa-digg"
            let inline DigitalOcean<'a> = Fable.FontAwesome.Fa.Icon "fab fa-digital-ocean"
            let inline Discord<'a> = Fable.FontAwesome.Fa.Icon "fab fa-discord"
            let inline Discourse<'a> = Fable.FontAwesome.Fa.Icon "fab fa-discourse"
            let inline Dochub<'a> = Fable.FontAwesome.Fa.Icon "fab fa-dochub"
            let inline Docker<'a> = Fable.FontAwesome.Fa.Icon "fab fa-docker"
            let inline Draft2digital<'a> = Fable.FontAwesome.Fa.Icon "fab fa-draft2digital"
            let inline Dribbble<'a> = Fable.FontAwesome.Fa.Icon "fab fa-dribbble"
            let inline DribbbleSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-dribbble-square"
            let inline Dropbox<'a> = Fable.FontAwesome.Fa.Icon "fab fa-dropbox"
            let inline Drupal<'a> = Fable.FontAwesome.Fa.Icon "fab fa-drupal"
            let inline Dyalog<'a> = Fable.FontAwesome.Fa.Icon "fab fa-dyalog"
            let inline Earlybirds<'a> = Fable.FontAwesome.Fa.Icon "fab fa-earlybirds"
            let inline Ebay<'a> = Fable.FontAwesome.Fa.Icon "fab fa-ebay"
            let inline Edge<'a> = Fable.FontAwesome.Fa.Icon "fab fa-edge"
            let inline Elementor<'a> = Fable.FontAwesome.Fa.Icon "fab fa-elementor"
            let inline Ello<'a> = Fable.FontAwesome.Fa.Icon "fab fa-ello"
            let inline Ember<'a> = Fable.FontAwesome.Fa.Icon "fab fa-ember"
            let inline Empire<'a> = Fable.FontAwesome.Fa.Icon "fab fa-empire"
            let inline Envira<'a> = Fable.FontAwesome.Fa.Icon "fab fa-envira"
            let inline Erlang<'a> = Fable.FontAwesome.Fa.Icon "fab fa-erlang"
            let inline Ethereum<'a> = Fable.FontAwesome.Fa.Icon "fab fa-ethereum"
            let inline Etsy<'a> = Fable.FontAwesome.Fa.Icon "fab fa-etsy"
            let inline Evernote<'a> = Fable.FontAwesome.Fa.Icon "fab fa-evernote"
            let inline Expeditedssl<'a> = Fable.FontAwesome.Fa.Icon "fab fa-expeditedssl"
            let inline Facebook<'a> = Fable.FontAwesome.Fa.Icon "fab fa-facebook"
            let inline FacebookF<'a> = Fable.FontAwesome.Fa.Icon "fab fa-facebook-f"
            let inline FacebookMessenger<'a> = Fable.FontAwesome.Fa.Icon "fab fa-facebook-messenger"
            let inline FacebookSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-facebook-square"
            let inline FantasyFlightGames<'a> = Fable.FontAwesome.Fa.Icon "fab fa-fantasy-flight-games"
            let inline Fedex<'a> = Fable.FontAwesome.Fa.Icon "fab fa-fedex"
            let inline Fedora<'a> = Fable.FontAwesome.Fa.Icon "fab fa-fedora"
            let inline Figma<'a> = Fable.FontAwesome.Fa.Icon "fab fa-figma"
            let inline Firefox<'a> = Fable.FontAwesome.Fa.Icon "fab fa-firefox"
            let inline FirefoxBrowser<'a> = Fable.FontAwesome.Fa.Icon "fab fa-firefox-browser"
            let inline FirstOrder<'a> = Fable.FontAwesome.Fa.Icon "fab fa-first-order"
            let inline FirstOrderAlt<'a> = Fable.FontAwesome.Fa.Icon "fab fa-first-order-alt"
            let inline Firstdraft<'a> = Fable.FontAwesome.Fa.Icon "fab fa-firstdraft"
            let inline Flickr<'a> = Fable.FontAwesome.Fa.Icon "fab fa-flickr"
            let inline Flipboard<'a> = Fable.FontAwesome.Fa.Icon "fab fa-flipboard"
            let inline Fly<'a> = Fable.FontAwesome.Fa.Icon "fab fa-fly"
            let inline FontAwesome<'a> = Fable.FontAwesome.Fa.Icon "fab fa-font-awesome"
            let inline FontAwesomeAlt<'a> = Fable.FontAwesome.Fa.Icon "fab fa-font-awesome-alt"
            let inline FontAwesomeFlag<'a> = Fable.FontAwesome.Fa.Icon "fab fa-font-awesome-flag"
            let inline Fonticons<'a> = Fable.FontAwesome.Fa.Icon "fab fa-fonticons"
            let inline FonticonsFi<'a> = Fable.FontAwesome.Fa.Icon "fab fa-fonticons-fi"
            let inline FortAwesome<'a> = Fable.FontAwesome.Fa.Icon "fab fa-fort-awesome"
            let inline FortAwesomeAlt<'a> = Fable.FontAwesome.Fa.Icon "fab fa-fort-awesome-alt"
            let inline Forumbee<'a> = Fable.FontAwesome.Fa.Icon "fab fa-forumbee"
            let inline Foursquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-foursquare"
            let inline FreeCodeCamp<'a> = Fable.FontAwesome.Fa.Icon "fab fa-free-code-camp"
            let inline Freebsd<'a> = Fable.FontAwesome.Fa.Icon "fab fa-freebsd"
            let inline Fulcrum<'a> = Fable.FontAwesome.Fa.Icon "fab fa-fulcrum"
            let inline GalacticRepublic<'a> = Fable.FontAwesome.Fa.Icon "fab fa-galactic-republic"
            let inline GalacticSenate<'a> = Fable.FontAwesome.Fa.Icon "fab fa-galactic-senate"
            let inline GetPocket<'a> = Fable.FontAwesome.Fa.Icon "fab fa-get-pocket"
            let inline Gg<'a> = Fable.FontAwesome.Fa.Icon "fab fa-gg"
            let inline GgCircle<'a> = Fable.FontAwesome.Fa.Icon "fab fa-gg-circle"
            let inline Git<'a> = Fable.FontAwesome.Fa.Icon "fab fa-git"
            let inline GitAlt<'a> = Fable.FontAwesome.Fa.Icon "fab fa-git-alt"
            let inline GitSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-git-square"
            let inline Github<'a> = Fable.FontAwesome.Fa.Icon "fab fa-github"
            let inline GithubAlt<'a> = Fable.FontAwesome.Fa.Icon "fab fa-github-alt"
            let inline GithubSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-github-square"
            let inline Gitkraken<'a> = Fable.FontAwesome.Fa.Icon "fab fa-gitkraken"
            let inline Gitlab<'a> = Fable.FontAwesome.Fa.Icon "fab fa-gitlab"
            let inline Gitter<'a> = Fable.FontAwesome.Fa.Icon "fab fa-gitter"
            let inline Glide<'a> = Fable.FontAwesome.Fa.Icon "fab fa-glide"
            let inline GlideG<'a> = Fable.FontAwesome.Fa.Icon "fab fa-glide-g"
            let inline Gofore<'a> = Fable.FontAwesome.Fa.Icon "fab fa-gofore"
            let inline Goodreads<'a> = Fable.FontAwesome.Fa.Icon "fab fa-goodreads"
            let inline GoodreadsG<'a> = Fable.FontAwesome.Fa.Icon "fab fa-goodreads-g"
            let inline Google<'a> = Fable.FontAwesome.Fa.Icon "fab fa-google"
            let inline GoogleDrive<'a> = Fable.FontAwesome.Fa.Icon "fab fa-google-drive"
            let inline GooglePlay<'a> = Fable.FontAwesome.Fa.Icon "fab fa-google-play"
            let inline GooglePlus<'a> = Fable.FontAwesome.Fa.Icon "fab fa-google-plus"
            let inline GooglePlusG<'a> = Fable.FontAwesome.Fa.Icon "fab fa-google-plus-g"
            let inline GooglePlusSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-google-plus-square"
            let inline GoogleWallet<'a> = Fable.FontAwesome.Fa.Icon "fab fa-google-wallet"
            let inline Gratipay<'a> = Fable.FontAwesome.Fa.Icon "fab fa-gratipay"
            let inline Grav<'a> = Fable.FontAwesome.Fa.Icon "fab fa-grav"
            let inline Gripfire<'a> = Fable.FontAwesome.Fa.Icon "fab fa-gripfire"
            let inline Grunt<'a> = Fable.FontAwesome.Fa.Icon "fab fa-grunt"
            let inline Gulp<'a> = Fable.FontAwesome.Fa.Icon "fab fa-gulp"
            let inline HackerNews<'a> = Fable.FontAwesome.Fa.Icon "fab fa-hacker-news"
            let inline HackerNewsSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-hacker-news-square"
            let inline Hackerrank<'a> = Fable.FontAwesome.Fa.Icon "fab fa-hackerrank"
            let inline Hips<'a> = Fable.FontAwesome.Fa.Icon "fab fa-hips"
            let inline HireAHelper<'a> = Fable.FontAwesome.Fa.Icon "fab fa-hire-a-helper"
            let inline Hooli<'a> = Fable.FontAwesome.Fa.Icon "fab fa-hooli"
            let inline Hornbill<'a> = Fable.FontAwesome.Fa.Icon "fab fa-hornbill"
            let inline Hotjar<'a> = Fable.FontAwesome.Fa.Icon "fab fa-hotjar"
            let inline Houzz<'a> = Fable.FontAwesome.Fa.Icon "fab fa-houzz"
            let inline Html5<'a> = Fable.FontAwesome.Fa.Icon "fab fa-html5"
            let inline Hubspot<'a> = Fable.FontAwesome.Fa.Icon "fab fa-hubspot"
            let inline Ideal<'a> = Fable.FontAwesome.Fa.Icon "fab fa-ideal"
            let inline Imdb<'a> = Fable.FontAwesome.Fa.Icon "fab fa-imdb"
            let inline Instagram<'a> = Fable.FontAwesome.Fa.Icon "fab fa-instagram"
            let inline InstagramSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-instagram-square"
            let inline Intercom<'a> = Fable.FontAwesome.Fa.Icon "fab fa-intercom"
            let inline InternetExplorer<'a> = Fable.FontAwesome.Fa.Icon "fab fa-internet-explorer"
            let inline Invision<'a> = Fable.FontAwesome.Fa.Icon "fab fa-invision"
            let inline Ioxhost<'a> = Fable.FontAwesome.Fa.Icon "fab fa-ioxhost"
            let inline ItchIo<'a> = Fable.FontAwesome.Fa.Icon "fab fa-itch-io"
            let inline Itunes<'a> = Fable.FontAwesome.Fa.Icon "fab fa-itunes"
            let inline ItunesNote<'a> = Fable.FontAwesome.Fa.Icon "fab fa-itunes-note"
            let inline Java<'a> = Fable.FontAwesome.Fa.Icon "fab fa-java"
            let inline JediOrder<'a> = Fable.FontAwesome.Fa.Icon "fab fa-jedi-order"
            let inline Jenkins<'a> = Fable.FontAwesome.Fa.Icon "fab fa-jenkins"
            let inline Jira<'a> = Fable.FontAwesome.Fa.Icon "fab fa-jira"
            let inline Joget<'a> = Fable.FontAwesome.Fa.Icon "fab fa-joget"
            let inline Joomla<'a> = Fable.FontAwesome.Fa.Icon "fab fa-joomla"
            let inline Js<'a> = Fable.FontAwesome.Fa.Icon "fab fa-js"
            let inline JsSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-js-square"
            let inline Jsfiddle<'a> = Fable.FontAwesome.Fa.Icon "fab fa-jsfiddle"
            let inline Kaggle<'a> = Fable.FontAwesome.Fa.Icon "fab fa-kaggle"
            let inline Keybase<'a> = Fable.FontAwesome.Fa.Icon "fab fa-keybase"
            let inline Keycdn<'a> = Fable.FontAwesome.Fa.Icon "fab fa-keycdn"
            let inline Kickstarter<'a> = Fable.FontAwesome.Fa.Icon "fab fa-kickstarter"
            let inline KickstarterK<'a> = Fable.FontAwesome.Fa.Icon "fab fa-kickstarter-k"
            let inline Korvue<'a> = Fable.FontAwesome.Fa.Icon "fab fa-korvue"
            let inline Laravel<'a> = Fable.FontAwesome.Fa.Icon "fab fa-laravel"
            let inline Lastfm<'a> = Fable.FontAwesome.Fa.Icon "fab fa-lastfm"
            let inline LastfmSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-lastfm-square"
            let inline Leanpub<'a> = Fable.FontAwesome.Fa.Icon "fab fa-leanpub"
            let inline Less<'a> = Fable.FontAwesome.Fa.Icon "fab fa-less"
            let inline Line<'a> = Fable.FontAwesome.Fa.Icon "fab fa-line"
            let inline Linkedin<'a> = Fable.FontAwesome.Fa.Icon "fab fa-linkedin"
            let inline LinkedinIn<'a> = Fable.FontAwesome.Fa.Icon "fab fa-linkedin-in"
            let inline Linode<'a> = Fable.FontAwesome.Fa.Icon "fab fa-linode"
            let inline Linux<'a> = Fable.FontAwesome.Fa.Icon "fab fa-linux"
            let inline Lyft<'a> = Fable.FontAwesome.Fa.Icon "fab fa-lyft"
            let inline Magento<'a> = Fable.FontAwesome.Fa.Icon "fab fa-magento"
            let inline Mailchimp<'a> = Fable.FontAwesome.Fa.Icon "fab fa-mailchimp"
            let inline Mandalorian<'a> = Fable.FontAwesome.Fa.Icon "fab fa-mandalorian"
            let inline Markdown<'a> = Fable.FontAwesome.Fa.Icon "fab fa-markdown"
            let inline Mastodon<'a> = Fable.FontAwesome.Fa.Icon "fab fa-mastodon"
            let inline Maxcdn<'a> = Fable.FontAwesome.Fa.Icon "fab fa-maxcdn"
            let inline Mdb<'a> = Fable.FontAwesome.Fa.Icon "fab fa-mdb"
            let inline Medapps<'a> = Fable.FontAwesome.Fa.Icon "fab fa-medapps"
            let inline Medium<'a> = Fable.FontAwesome.Fa.Icon "fab fa-medium"
            let inline MediumM<'a> = Fable.FontAwesome.Fa.Icon "fab fa-medium-m"
            let inline Medrt<'a> = Fable.FontAwesome.Fa.Icon "fab fa-medrt"
            let inline Meetup<'a> = Fable.FontAwesome.Fa.Icon "fab fa-meetup"
            let inline Megaport<'a> = Fable.FontAwesome.Fa.Icon "fab fa-megaport"
            let inline Mendeley<'a> = Fable.FontAwesome.Fa.Icon "fab fa-mendeley"
            let inline Microblog<'a> = Fable.FontAwesome.Fa.Icon "fab fa-microblog"
            let inline Microsoft<'a> = Fable.FontAwesome.Fa.Icon "fab fa-microsoft"
            let inline Mix<'a> = Fable.FontAwesome.Fa.Icon "fab fa-mix"
            let inline Mixcloud<'a> = Fable.FontAwesome.Fa.Icon "fab fa-mixcloud"
            let inline Mixer<'a> = Fable.FontAwesome.Fa.Icon "fab fa-mixer"
            let inline Mizuni<'a> = Fable.FontAwesome.Fa.Icon "fab fa-mizuni"
            let inline Modx<'a> = Fable.FontAwesome.Fa.Icon "fab fa-modx"
            let inline Monero<'a> = Fable.FontAwesome.Fa.Icon "fab fa-monero"
            let inline Napster<'a> = Fable.FontAwesome.Fa.Icon "fab fa-napster"
            let inline Neos<'a> = Fable.FontAwesome.Fa.Icon "fab fa-neos"
            let inline Nimblr<'a> = Fable.FontAwesome.Fa.Icon "fab fa-nimblr"
            let inline Node<'a> = Fable.FontAwesome.Fa.Icon "fab fa-node"
            let inline NodeJs<'a> = Fable.FontAwesome.Fa.Icon "fab fa-node-js"
            let inline Npm<'a> = Fable.FontAwesome.Fa.Icon "fab fa-npm"
            let inline Ns8<'a> = Fable.FontAwesome.Fa.Icon "fab fa-ns8"
            let inline Nutritionix<'a> = Fable.FontAwesome.Fa.Icon "fab fa-nutritionix"
            let inline Odnoklassniki<'a> = Fable.FontAwesome.Fa.Icon "fab fa-odnoklassniki"
            let inline OdnoklassnikiSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-odnoklassniki-square"
            let inline OldRepublic<'a> = Fable.FontAwesome.Fa.Icon "fab fa-old-republic"
            let inline Opencart<'a> = Fable.FontAwesome.Fa.Icon "fab fa-opencart"
            let inline Openid<'a> = Fable.FontAwesome.Fa.Icon "fab fa-openid"
            let inline Opera<'a> = Fable.FontAwesome.Fa.Icon "fab fa-opera"
            let inline OptinMonster<'a> = Fable.FontAwesome.Fa.Icon "fab fa-optin-monster"
            let inline Orcid<'a> = Fable.FontAwesome.Fa.Icon "fab fa-orcid"
            let inline Osi<'a> = Fable.FontAwesome.Fa.Icon "fab fa-osi"
            let inline Page4<'a> = Fable.FontAwesome.Fa.Icon "fab fa-page4"
            let inline Pagelines<'a> = Fable.FontAwesome.Fa.Icon "fab fa-pagelines"
            let inline Palfed<'a> = Fable.FontAwesome.Fa.Icon "fab fa-palfed"
            let inline Patreon<'a> = Fable.FontAwesome.Fa.Icon "fab fa-patreon"
            let inline Paypal<'a> = Fable.FontAwesome.Fa.Icon "fab fa-paypal"
            let inline PennyArcade<'a> = Fable.FontAwesome.Fa.Icon "fab fa-penny-arcade"
            let inline Periscope<'a> = Fable.FontAwesome.Fa.Icon "fab fa-periscope"
            let inline Phabricator<'a> = Fable.FontAwesome.Fa.Icon "fab fa-phabricator"
            let inline PhoenixFramework<'a> = Fable.FontAwesome.Fa.Icon "fab fa-phoenix-framework"
            let inline PhoenixSquadron<'a> = Fable.FontAwesome.Fa.Icon "fab fa-phoenix-squadron"
            let inline Php<'a> = Fable.FontAwesome.Fa.Icon "fab fa-php"
            let inline PiedPiper<'a> = Fable.FontAwesome.Fa.Icon "fab fa-pied-piper"
            let inline PiedPiperAlt<'a> = Fable.FontAwesome.Fa.Icon "fab fa-pied-piper-alt"
            let inline PiedPiperHat<'a> = Fable.FontAwesome.Fa.Icon "fab fa-pied-piper-hat"
            let inline PiedPiperPp<'a> = Fable.FontAwesome.Fa.Icon "fab fa-pied-piper-pp"
            let inline PiedPiperSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-pied-piper-square"
            let inline Pinterest<'a> = Fable.FontAwesome.Fa.Icon "fab fa-pinterest"
            let inline PinterestP<'a> = Fable.FontAwesome.Fa.Icon "fab fa-pinterest-p"
            let inline PinterestSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-pinterest-square"
            let inline Playstation<'a> = Fable.FontAwesome.Fa.Icon "fab fa-playstation"
            let inline ProductHunt<'a> = Fable.FontAwesome.Fa.Icon "fab fa-product-hunt"
            let inline Pushed<'a> = Fable.FontAwesome.Fa.Icon "fab fa-pushed"
            let inline Python<'a> = Fable.FontAwesome.Fa.Icon "fab fa-python"
            let inline Qq<'a> = Fable.FontAwesome.Fa.Icon "fab fa-qq"
            let inline Quinscape<'a> = Fable.FontAwesome.Fa.Icon "fab fa-quinscape"
            let inline Quora<'a> = Fable.FontAwesome.Fa.Icon "fab fa-quora"
            let inline RProject<'a> = Fable.FontAwesome.Fa.Icon "fab fa-r-project"
            let inline RaspberryPi<'a> = Fable.FontAwesome.Fa.Icon "fab fa-raspberry-pi"
            let inline Ravelry<'a> = Fable.FontAwesome.Fa.Icon "fab fa-ravelry"
            let inline React<'a> = Fable.FontAwesome.Fa.Icon "fab fa-react"
            let inline Reacteurope<'a> = Fable.FontAwesome.Fa.Icon "fab fa-reacteurope"
            let inline Readme<'a> = Fable.FontAwesome.Fa.Icon "fab fa-readme"
            let inline Rebel<'a> = Fable.FontAwesome.Fa.Icon "fab fa-rebel"
            let inline RedRiver<'a> = Fable.FontAwesome.Fa.Icon "fab fa-red-river"
            let inline Reddit<'a> = Fable.FontAwesome.Fa.Icon "fab fa-reddit"
            let inline RedditAlien<'a> = Fable.FontAwesome.Fa.Icon "fab fa-reddit-alien"
            let inline RedditSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-reddit-square"
            let inline Redhat<'a> = Fable.FontAwesome.Fa.Icon "fab fa-redhat"
            let inline Renren<'a> = Fable.FontAwesome.Fa.Icon "fab fa-renren"
            let inline Replyd<'a> = Fable.FontAwesome.Fa.Icon "fab fa-replyd"
            let inline Researchgate<'a> = Fable.FontAwesome.Fa.Icon "fab fa-researchgate"
            let inline Resolving<'a> = Fable.FontAwesome.Fa.Icon "fab fa-resolving"
            let inline Rev<'a> = Fable.FontAwesome.Fa.Icon "fab fa-rev"
            let inline Rocketchat<'a> = Fable.FontAwesome.Fa.Icon "fab fa-rocketchat"
            let inline Rockrms<'a> = Fable.FontAwesome.Fa.Icon "fab fa-rockrms"
            let inline Safari<'a> = Fable.FontAwesome.Fa.Icon "fab fa-safari"
            let inline Salesforce<'a> = Fable.FontAwesome.Fa.Icon "fab fa-salesforce"
            let inline Sass<'a> = Fable.FontAwesome.Fa.Icon "fab fa-sass"
            let inline Schlix<'a> = Fable.FontAwesome.Fa.Icon "fab fa-schlix"
            let inline Scribd<'a> = Fable.FontAwesome.Fa.Icon "fab fa-scribd"
            let inline Searchengin<'a> = Fable.FontAwesome.Fa.Icon "fab fa-searchengin"
            let inline Sellcast<'a> = Fable.FontAwesome.Fa.Icon "fab fa-sellcast"
            let inline Sellsy<'a> = Fable.FontAwesome.Fa.Icon "fab fa-sellsy"
            let inline Servicestack<'a> = Fable.FontAwesome.Fa.Icon "fab fa-servicestack"
            let inline Shirtsinbulk<'a> = Fable.FontAwesome.Fa.Icon "fab fa-shirtsinbulk"
            let inline Shopify<'a> = Fable.FontAwesome.Fa.Icon "fab fa-shopify"
            let inline Shopware<'a> = Fable.FontAwesome.Fa.Icon "fab fa-shopware"
            let inline Simplybuilt<'a> = Fable.FontAwesome.Fa.Icon "fab fa-simplybuilt"
            let inline Sistrix<'a> = Fable.FontAwesome.Fa.Icon "fab fa-sistrix"
            let inline Sith<'a> = Fable.FontAwesome.Fa.Icon "fab fa-sith"
            let inline Sketch<'a> = Fable.FontAwesome.Fa.Icon "fab fa-sketch"
            let inline Skyatlas<'a> = Fable.FontAwesome.Fa.Icon "fab fa-skyatlas"
            let inline Skype<'a> = Fable.FontAwesome.Fa.Icon "fab fa-skype"
            let inline Slack<'a> = Fable.FontAwesome.Fa.Icon "fab fa-slack"
            let inline SlackHash<'a> = Fable.FontAwesome.Fa.Icon "fab fa-slack-hash"
            let inline Slideshare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-slideshare"
            let inline Snapchat<'a> = Fable.FontAwesome.Fa.Icon "fab fa-snapchat"
            let inline SnapchatGhost<'a> = Fable.FontAwesome.Fa.Icon "fab fa-snapchat-ghost"
            let inline SnapchatSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-snapchat-square"
            let inline Soundcloud<'a> = Fable.FontAwesome.Fa.Icon "fab fa-soundcloud"
            let inline Sourcetree<'a> = Fable.FontAwesome.Fa.Icon "fab fa-sourcetree"
            let inline Speakap<'a> = Fable.FontAwesome.Fa.Icon "fab fa-speakap"
            let inline SpeakerDeck<'a> = Fable.FontAwesome.Fa.Icon "fab fa-speaker-deck"
            let inline Spotify<'a> = Fable.FontAwesome.Fa.Icon "fab fa-spotify"
            let inline Squarespace<'a> = Fable.FontAwesome.Fa.Icon "fab fa-squarespace"
            let inline StackExchange<'a> = Fable.FontAwesome.Fa.Icon "fab fa-stack-exchange"
            let inline StackOverflow<'a> = Fable.FontAwesome.Fa.Icon "fab fa-stack-overflow"
            let inline Stackpath<'a> = Fable.FontAwesome.Fa.Icon "fab fa-stackpath"
            let inline Staylinked<'a> = Fable.FontAwesome.Fa.Icon "fab fa-staylinked"
            let inline Steam<'a> = Fable.FontAwesome.Fa.Icon "fab fa-steam"
            let inline SteamSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-steam-square"
            let inline SteamSymbol<'a> = Fable.FontAwesome.Fa.Icon "fab fa-steam-symbol"
            let inline StickerMule<'a> = Fable.FontAwesome.Fa.Icon "fab fa-sticker-mule"
            let inline Strava<'a> = Fable.FontAwesome.Fa.Icon "fab fa-strava"
            let inline Stripe<'a> = Fable.FontAwesome.Fa.Icon "fab fa-stripe"
            let inline StripeS<'a> = Fable.FontAwesome.Fa.Icon "fab fa-stripe-s"
            let inline Studiovinari<'a> = Fable.FontAwesome.Fa.Icon "fab fa-studiovinari"
            let inline Stumbleupon<'a> = Fable.FontAwesome.Fa.Icon "fab fa-stumbleupon"
            let inline StumbleuponCircle<'a> = Fable.FontAwesome.Fa.Icon "fab fa-stumbleupon-circle"
            let inline Superpowers<'a> = Fable.FontAwesome.Fa.Icon "fab fa-superpowers"
            let inline Supple<'a> = Fable.FontAwesome.Fa.Icon "fab fa-supple"
            let inline Suse<'a> = Fable.FontAwesome.Fa.Icon "fab fa-suse"
            let inline Swift<'a> = Fable.FontAwesome.Fa.Icon "fab fa-swift"
            let inline Symfony<'a> = Fable.FontAwesome.Fa.Icon "fab fa-symfony"
            let inline Teamspeak<'a> = Fable.FontAwesome.Fa.Icon "fab fa-teamspeak"
            let inline Telegram<'a> = Fable.FontAwesome.Fa.Icon "fab fa-telegram"
            let inline TelegramPlane<'a> = Fable.FontAwesome.Fa.Icon "fab fa-telegram-plane"
            let inline TencentWeibo<'a> = Fable.FontAwesome.Fa.Icon "fab fa-tencent-weibo"
            let inline TheRedYeti<'a> = Fable.FontAwesome.Fa.Icon "fab fa-the-red-yeti"
            let inline Themeco<'a> = Fable.FontAwesome.Fa.Icon "fab fa-themeco"
            let inline Themeisle<'a> = Fable.FontAwesome.Fa.Icon "fab fa-themeisle"
            let inline ThinkPeaks<'a> = Fable.FontAwesome.Fa.Icon "fab fa-think-peaks"
            let inline TradeFederation<'a> = Fable.FontAwesome.Fa.Icon "fab fa-trade-federation"
            let inline Trello<'a> = Fable.FontAwesome.Fa.Icon "fab fa-trello"
            let inline Tripadvisor<'a> = Fable.FontAwesome.Fa.Icon "fab fa-tripadvisor"
            let inline Tumblr<'a> = Fable.FontAwesome.Fa.Icon "fab fa-tumblr"
            let inline TumblrSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-tumblr-square"
            let inline Twitch<'a> = Fable.FontAwesome.Fa.Icon "fab fa-twitch"
            let inline Twitter<'a> = Fable.FontAwesome.Fa.Icon "fab fa-twitter"
            let inline TwitterSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-twitter-square"
            let inline Typo3<'a> = Fable.FontAwesome.Fa.Icon "fab fa-typo3"
            let inline Uber<'a> = Fable.FontAwesome.Fa.Icon "fab fa-uber"
            let inline Ubuntu<'a> = Fable.FontAwesome.Fa.Icon "fab fa-ubuntu"
            let inline Uikit<'a> = Fable.FontAwesome.Fa.Icon "fab fa-uikit"
            let inline Umbraco<'a> = Fable.FontAwesome.Fa.Icon "fab fa-umbraco"
            let inline Uniregistry<'a> = Fable.FontAwesome.Fa.Icon "fab fa-uniregistry"
            let inline Unity<'a> = Fable.FontAwesome.Fa.Icon "fab fa-unity"
            let inline Untappd<'a> = Fable.FontAwesome.Fa.Icon "fab fa-untappd"
            let inline Ups<'a> = Fable.FontAwesome.Fa.Icon "fab fa-ups"
            let inline Usb<'a> = Fable.FontAwesome.Fa.Icon "fab fa-usb"
            let inline Usps<'a> = Fable.FontAwesome.Fa.Icon "fab fa-usps"
            let inline Ussunnah<'a> = Fable.FontAwesome.Fa.Icon "fab fa-ussunnah"
            let inline Vaadin<'a> = Fable.FontAwesome.Fa.Icon "fab fa-vaadin"
            let inline Viacoin<'a> = Fable.FontAwesome.Fa.Icon "fab fa-viacoin"
            let inline Viadeo<'a> = Fable.FontAwesome.Fa.Icon "fab fa-viadeo"
            let inline ViadeoSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-viadeo-square"
            let inline Viber<'a> = Fable.FontAwesome.Fa.Icon "fab fa-viber"
            let inline Vimeo<'a> = Fable.FontAwesome.Fa.Icon "fab fa-vimeo"
            let inline VimeoSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-vimeo-square"
            let inline VimeoV<'a> = Fable.FontAwesome.Fa.Icon "fab fa-vimeo-v"
            let inline Vine<'a> = Fable.FontAwesome.Fa.Icon "fab fa-vine"
            let inline Vk<'a> = Fable.FontAwesome.Fa.Icon "fab fa-vk"
            let inline Vnv<'a> = Fable.FontAwesome.Fa.Icon "fab fa-vnv"
            let inline Vuejs<'a> = Fable.FontAwesome.Fa.Icon "fab fa-vuejs"
            let inline Waze<'a> = Fable.FontAwesome.Fa.Icon "fab fa-waze"
            let inline Weebly<'a> = Fable.FontAwesome.Fa.Icon "fab fa-weebly"
            let inline Weibo<'a> = Fable.FontAwesome.Fa.Icon "fab fa-weibo"
            let inline Weixin<'a> = Fable.FontAwesome.Fa.Icon "fab fa-weixin"
            let inline Whatsapp<'a> = Fable.FontAwesome.Fa.Icon "fab fa-whatsapp"
            let inline WhatsappSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-whatsapp-square"
            let inline Whmcs<'a> = Fable.FontAwesome.Fa.Icon "fab fa-whmcs"
            let inline WikipediaW<'a> = Fable.FontAwesome.Fa.Icon "fab fa-wikipedia-w"
            let inline Windows<'a> = Fable.FontAwesome.Fa.Icon "fab fa-windows"
            let inline Wix<'a> = Fable.FontAwesome.Fa.Icon "fab fa-wix"
            let inline WizardsOfTheCoast<'a> = Fable.FontAwesome.Fa.Icon "fab fa-wizards-of-the-coast"
            let inline WolfPackBattalion<'a> = Fable.FontAwesome.Fa.Icon "fab fa-wolf-pack-battalion"
            let inline Wordpress<'a> = Fable.FontAwesome.Fa.Icon "fab fa-wordpress"
            let inline WordpressSimple<'a> = Fable.FontAwesome.Fa.Icon "fab fa-wordpress-simple"
            let inline Wpbeginner<'a> = Fable.FontAwesome.Fa.Icon "fab fa-wpbeginner"
            let inline Wpexplorer<'a> = Fable.FontAwesome.Fa.Icon "fab fa-wpexplorer"
            let inline Wpforms<'a> = Fable.FontAwesome.Fa.Icon "fab fa-wpforms"
            let inline Wpressr<'a> = Fable.FontAwesome.Fa.Icon "fab fa-wpressr"
            let inline Xbox<'a> = Fable.FontAwesome.Fa.Icon "fab fa-xbox"
            let inline Xing<'a> = Fable.FontAwesome.Fa.Icon "fab fa-xing"
            let inline XingSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-xing-square"
            let inline YCombinator<'a> = Fable.FontAwesome.Fa.Icon "fab fa-y-combinator"
            let inline Yahoo<'a> = Fable.FontAwesome.Fa.Icon "fab fa-yahoo"
            let inline Yammer<'a> = Fable.FontAwesome.Fa.Icon "fab fa-yammer"
            let inline Yandex<'a> = Fable.FontAwesome.Fa.Icon "fab fa-yandex"
            let inline YandexInternational<'a> = Fable.FontAwesome.Fa.Icon "fab fa-yandex-international"
            let inline Yarn<'a> = Fable.FontAwesome.Fa.Icon "fab fa-yarn"
            let inline Yelp<'a> = Fable.FontAwesome.Fa.Icon "fab fa-yelp"
            let inline Yoast<'a> = Fable.FontAwesome.Fa.Icon "fab fa-yoast"
            let inline Youtube<'a> = Fable.FontAwesome.Fa.Icon "fab fa-youtube"
            let inline YoutubeSquare<'a> = Fable.FontAwesome.Fa.Icon "fab fa-youtube-square"
            let inline Zhihu<'a> = Fable.FontAwesome.Fa.Icon "fab fa-zhihu"

