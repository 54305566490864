module Login.View

open Types
open Fable.React.Props
open Fable.React

open ViewParts

// Wartungsmeldung über ganze Breite
let wartungsmeldungPart msg =
    div [
        Class "column is-12 image-column is-padded"
    ] [
        div [
            Class "content"
            DangerouslySetInnerHTML { __html = msg }
        ] []
    ]

let submitOnEnter dispatch =
    OnKeyDown(fun (e) ->
        if (e.key = "Enter") then
            dispatch (Submit None))

// rechts: sub-formular für Kdnr + Pw
let rightPartDefault model dispatch (navigateTo: AnyPage -> unit) =
    [
        p [ Class "info-block" ] [
            validatedTextBoxWithoutErrorMsg2
                [ submitOnEnter dispatch ]
                (dispatch << NameChanged)
                "Benutzerkennung:"
                "Benutzerkennung"
                "12345"
                model.Name
        ]
        p [ Class "info-block" ] [
            validatedPasswordBoxWithoutErrorMsg
                "is-tooltip-right"
                [ submitOnEnter dispatch ]
                (dispatch << PasswordChanged)
                "Passwort:"
                "Passwort"
                ""
                model.Password
        ]

        p [] [
            a [
                OnClick(fun _ -> (navigateTo Password.Types.Page.LostPassword))
            ] [ str "Passwort vergessen?" ]
        ]
    ]
    |> ofList

// rechts: sub-formular für OTP
let rightPartOtp model dispatch =
    [
        p [ Class "content" ] [
            str "Bitte geben Sie Ihr Einmalpasswort ein:"
        ]
        p [ Class "info-block" ] [
            validatedTextBoxWithoutErrorMsg2
                [
                    AutoComplete "off"
                    submitOnEnter dispatch
                ]
                (dispatch << OtpChanged)
                "Einmalpasswort:"
                "Otp"
                "123456"
                model.Otp
        ]
    ]
    |> ofList
// rechts: formular
let rightPart model dispatch navigateTo =
    let isLoading = (if model.Sending then " is-loading" else "")

    div [
        Class "column is-4 is-form-padded paragraphs form-column"
    ] [
        div [ Class "toppart" ] [
            h2 [] [ str "Service neu erleben!" ]
            p [] [
                str
                    "Im BFS-Kundenportal erhalten Sie exklusiven Zugriff auf wichtige Informationen, aktuelle News und Hilfe-Themen. Wir freuen uns auf Sie!"
            ]
        ]

        div [ Class "bottompart" ] [
            if model.OtpRequired then
                yield rightPartOtp model dispatch
            else
                yield rightPartDefault model dispatch navigateTo

            if model.ErrorMsg.IsSome then
                yield p [ Class "error-msg" ] [ str model.ErrorMsg.Value ]

            yield
                button [
                    Type "submit"
                    Class("bfsbutton wide " + isLoading)
                    Disabled(not (model.CanSubmit))
                    Props.OnClick(fun _ -> dispatch (Submit None))
                ] [ str "Einloggen" ]


        ]
    ]

let rightSpinner =
    div [
        Class "column is-4 is-form-padded form-column"
    ] [
        div [ Class "spacer" ] []
        spinnerwhite
    ]

let view model dispatch navigateTo =
    div [
        Class "loginform is-auto is-auth-form category-header"
    ] [
        div [ Class "columns" ] [
            match model.WartungMsg with
            | Some wartungsmeldung -> yield wartungsmeldungPart wartungsmeldung
            | None ->
                if not model.Loading then
                    yield rightPart model dispatch navigateTo
                else
                    yield rightSpinner
        ]
    ]
