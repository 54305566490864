module AdminNews.View

open Fable.React
open Types
open Http
open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.News
open Fable.React.Props
open ViewParts
open Fulma

let tableRow dispatch navigateTo (news: AdminNewsListItem) =
    let aktiv = if news.Aktiv then "" else "deaktiviert"

    tr [ Class aktiv ] [
        td [] [ str news.Titel ]
        td [] [ str news.AnzeigeConstraint ]
        td [] [
            str (news.Datum.ToString("dd.MM.yyyy"))
        ]
        td [] [
            a [
                OnClick(fun _ -> navigateTo (Page.NewsEdit(news.Id.ToString())))
                Title "bearbeiten"
            ] [ icon "fas fa-edit" ]
            (if news.Aktiv then
                 a [
                     OnClick(fun _ -> (DeactivateNews(news.Id.ToString())) |> dispatch)
                     Title "deaktivieren"
                 ] [ icon "fas fa-eye-slash" ]
             else
                 a [
                     OnClick(fun _ -> (ActivateNews(news.Id.ToString())) |> dispatch)
                     Title "aktivieren"
                 ] [ icon "fas fa-eye" ])
        ]
    ]

let table news dispatch navigateTo =
    table [
        Class "table table-striped responsive-table"
    ] [
        thead [] [
            tr [] [
                th [] [ str "Titel" ]
                th [] [ str "nur für" ]
                th [] [ str "Gültig ab" ]
                th [] [ str "Aktionen" ]
            ]
        ]
        tbody [] (news |> List.map (tableRow dispatch navigateTo))
    ]

let listPage (model: NewsListModel) dispatch navigateTo =
    let filterAlle, filterPflege, filterBfsPlus, filterTherapie, filterJugendhilfe, filterKunde =
        match model.Filter with
        | Alle -> "", "is-outlined", "is-outlined", "is-outlined", "is-outlined", "is-outlined"
        | Pflege -> "is-outlined", "", "is-outlined", "is-outlined", "is-outlined", "is-outlined"
        | BfsPlus -> "is-outlined", "is-outlined", "", "is-outlined", "is-outlined", "is-outlined"
        | Therapie -> "is-outlined", "is-outlined", "is-outlined", "", "is-outlined", "is-outlined"
        | Jugendhilfe -> "is-outlined", "is-outlined", "is-outlined", "is-outlined", "", "is-outlined"
        | Kundennummer _ -> "is-outlined", "is-outlined", "is-outlined", "is-outlined", "is-outlined", ""

    div [ Class "columns is-multiline" ] [
        div [ Class "column is-12" ] [
            yield
                div [
                    Class "flat-card profile-info-card is-auto"
                ] [
                    div [ Class "card-body" ] [
                        yield
                            button [
                                Class "button is-link is-pulled-right is-small"
                                OnClick(fun _ -> navigateTo Page.NewsNew)
                            ] [ str "Neu" ]

                        yield
                            div [ Class "field has-addons" ] [
                                p [ Class "control" ] [
                                    button [
                                        Class("button is-link is-small is-rounded is-info " + filterAlle)
                                        OnClick(fun _ -> FilterChanged Alle |> dispatch)
                                    ] [ str "alle" ]
                                ]
                                p [ Class "control" ] [
                                    button [
                                        Class("button is-link is-small is-rounded is-info " + filterPflege)
                                        OnClick(fun _ -> FilterChanged Pflege |> dispatch)
                                    ] [ str "nur Pflege" ]
                                ]
                                p [ Class "control" ] [
                                    button [
                                        Class(
                                            "button is-link is-small is-rounded is-info "
                                            + filterBfsPlus
                                        )
                                        OnClick(fun _ -> FilterChanged BfsPlus |> dispatch)
                                    ] [ str "nur BFS Plus" ]
                                ]
                                p [ Class "control" ] [
                                    button [
                                        Class(
                                            "button is-link is-small is-rounded is-info "
                                            + filterTherapie
                                        )
                                        OnClick(fun _ -> FilterChanged Therapie |> dispatch)
                                    ] [ str "nur Therapie" ]
                                ]
                                p [ Class "control" ] [
                                    button [
                                        Class(
                                            "button is-link is-small is-rounded is-info "
                                            + filterJugendhilfe
                                        )
                                        OnClick(fun _ -> FilterChanged Jugendhilfe |> dispatch)
                                    ] [ str "nur Jugendhilfe" ]
                                ]
                                div [ Class "control" ] [
                                    input [
                                        Class "input is-info is-small"
                                        Type "text"
                                        Placeholder "Kundennummer"
                                        Value model.KundennummernFilterText
                                        OnChange(fun ev -> KundennummernFilterTextChanged ev.Value |> dispatch)
                                        OnKeyDown(fun (e) ->
                                            if (e.key = "Enter") then
                                                FilterForKundennummer |> dispatch)
                                    ]
                                ]
                                div [ Class "control" ] [
                                    a [
                                        Class("button is-info is-small is-rounded " + filterKunde)
                                        OnClick(fun _ -> FilterForKundennummer |> dispatch)
                                        Disabled(not model.KundennummernFilterEnabeld)
                                    ] [ str "nur eine Kundennummer" ]
                                ]


                            ]

                        yield
                            match model.News with
                            | Empty -> div [] []
                            | Loading -> spinner
                            | LoadError s -> errorMsg "Fehler beim Laden der News!"
                            | Body x when x.Items = [] -> div [] [ str "Keine News vorhanden" ]
                            | Body x -> table x.Items dispatch navigateTo
                    ]
                ]
            match model.News with
            | Body x -> yield pagination x (fun page -> navigateTo (Page.NewsList page))
            | _ -> ()
        ]
    ]

let kundennummerTag dispatch kdnr =
    div [ Class "tags has-addons" ] [
        span [ Class "tag is-info" ] [ str (kdnr.ToString()) ]
        a [
            Class "tag is-delete is-danger"
            OnClick(fun _ -> RemoveKundennummer kdnr |> dispatch)
        ] []
    ]

let editBox (model: NewsModel) dispatch navigateTo =
    let isLoading = (if model.Sending then " is-loading" else "")
    Column.column [ Column.Width(Screen.All, Column.Is6) ] [
        div [
            Class "flat-card profile-info-card is-auto"
        ] [
            div [ Class "card-title" ] [
                h3 [] [ b [] [ str "News bearbeiten" ] ]
            ]
            div [ Class "card-body" ] [
                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block" ] [
                            validatedTextBox (dispatch << TitelChanged) "Titel" "Titel" "" model.Titel
                        ]
                    ]
                ]

                div [ Class "columns" ] [
                    div [ Class "column is-8" ] [
                        div [ Class "info-block" ] [
                            validatedDatePicker<Types.Msg> GueltigAbChanged "Gültig ab" model.GueltigAb dispatch
                        ]
                    ]

                    div [ Class "column is-4" ] [
                        div [ Class "info-block" ] [
                            div [ Class "field" ] [
                                label [ Class "label" ] [ str "Aktiv" ]
                                checkbox model.Aktiv (fun b -> b |> AktivChanged |> dispatch) ""
                            ]
                        ]
                    ]
                ]

                div [ Class "columns" ] [
                    div [ Class "column is-4" ] [
                        div [ Class "info-block" ] [
                            div [ Class "field" ] [
                                label [ Class "label" ] [ str "Kundentyp" ]
                                Select.select [ Select.Option.CustomClass "" ] [
                                    select [
                                        Class "select input is-multiline is-multiple"
                                        OnChange(fun ev ->
                                            let typ =
                                                match ev.Value with
                                                | "0" -> None
                                                | "1" -> Some KundenTyp.Pflege
                                                | "2" -> Some KundenTyp.Therapie
                                                | "4" -> Some KundenTyp.BfsPlus
                                                | "5" -> Some KundenTyp.Jugendhilfe
                                                | _ -> None
                                            KundenTypChanged typ |> dispatch)
                                    ] [
                                        option [
                                            Value "0"
                                            Selected(
                                                match model.KundenTyp with
                                                | None -> true
                                                | _ -> false
                                            )
                                        ] [ str "alle" ]
                                        option [
                                            Value "1"
                                            Selected(
                                                match model.KundenTyp with
                                                | Some KundenTyp.Pflege -> true
                                                | _ -> false
                                            )
                                        ] [ str "Pflege" ]
                                        option [
                                            Value "2"
                                            Selected(
                                                match model.KundenTyp with
                                                | Some KundenTyp.Therapie -> true
                                                | _ -> false
                                            )
                                        ] [ str "Therapie" ]
                                        option [
                                            Value "4"
                                            Selected(
                                                match model.KundenTyp with
                                                | Some KundenTyp.BfsPlus -> true
                                                | _ -> false
                                            )
                                        ] [ str "BFS Plus" ]
                                        option [
                                            Value "5"
                                            Selected(
                                                match model.KundenTyp with
                                                | Some KundenTyp.Jugendhilfe -> true
                                                | _ -> false
                                            )
                                        ] [ str "Jugendhilfe" ]
                                    ]
                                ]
                            ]
                        ]
                    ]

                    div [ Class "column is-8" ] [
                        div [ Class "info-block" ] [
                            div [ Class "field" ] [
                                label [ Class "label" ] [ str "Kundennummern" ]
                                div [ Class "field has-addons" ] [
                                    div [ Class "control" ] [
                                        input [
                                            Class "input"
                                            Type "text"
                                            Placeholder "hinzufügen"
                                            Value model.KundennummernAddText
                                            OnChange(fun ev -> KundennummernAddTextChanged ev.Value |> dispatch)
                                            OnKeyDown(fun (e) ->
                                                if (e.key = "Enter") then
                                                    AddKundennummer |> dispatch)
                                        ]
                                    ]
                                    div [ Class "control" ] [
                                        a [
                                            Class "button is-info"
                                            OnClick(fun _ -> AddKundennummer |> dispatch)
                                            Disabled(not model.KundennummernAddEnabeld)
                                        ] [ str "+" ]
                                    ]
                                ]
                                div [ Class "taglist" ] [
                                    model.Kundennummern
                                    |> List.map (kundennummerTag dispatch)
                                    |> ofList
                                ]
                            ]
                        ]
                    ]
                ]

                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block" ] [
                            validatedTextarea (dispatch << TextChanged) "Text" "Text" "" "is-big" model.Text
                        ]
                    ]
                ]

                div [ Class "columns" ] [
                    div [ Class "column is-12" ] [
                        div [ Class "info-block is-pulled-right" ] [
                            button [
                                Class("button is-link" + isLoading)
                                Disabled(not (model.CanSubmit))
                                Props.OnClick(fun _ -> dispatch (Save))
                            ] [ str "Speichern" ]
                        ]
                        div [ Class "info-block" ] [
                            button [
                                Class("button is-danger is-link")
                                Props.OnClick(fun _ -> navigateTo (Page.NewsList model.BackToPage))
                            ] [
                                str (if model.IsDirty then "Abbrechen" else "Zurück")
                            ]
                        ]
                    ]
                ]
            ]
        ]

        adminImageUploadBox model.Id model.HasImage imageUrl DeleteImage UploadImage dispatch
    ]

let previewBox (model: NewsModel) =
    Column.column [ Column.Width(Screen.All, Column.Is6) ] [
        div [
            Class "flat-card profile-info-card is-auto"
        ] [
            [
                div [ Class "card-title" ] [
                    h3 [] [ b [] [ str model.Titel.Value ] ]
                ]
                div [ Class "card-body" ] [
                    div [
                        Class "content"
                        DangerouslySetInnerHTML { __html = model.TextAsHtml }
                    ] []
                ]
            ]
            |> ofList
        ]
    ]

let editPage (model: NewsModel) dispatch navigateTo =
    div [ Class "columns is-multiline" ] [
        editBox model dispatch navigateTo
        previewBox model
    ]

let view (model: Types.Model) dispatch (navigateTo: AnyPage -> unit) =
    match model with
    | ListModel m -> listPage m dispatch navigateTo
    | ItemModel m -> editPage m dispatch navigateTo
