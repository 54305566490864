module Quickfinder.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> Header "BFS Quick Finder"

    BuildUrl =
        function
        | Page.RechnungQuickfinder nummer -> [ Urls.quickfinder; nummer ]

    ParseUrl =
        function
        | [ Urls.quickfinder; nummer ] -> Some(Page.RechnungQuickfinder nummer)
        | _ -> None
}
