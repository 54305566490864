module Bfs.Web.Data.Service.Contracts.KundenportalEncoders

#if FABLE_COMPILER
open Thoth.Json
#else
open Thoth.Json.Net
#endif

open Encoders

open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.Roles
open Bfs.Web.Data.Service.Contracts.Kundenportal.Help
open Bfs.Web.Data.Service.Contracts.Kundenportal.File

let addPortalExtra extraEncoders =
    extraEncoders
    |> Extra.withCustom (fun (x: KundenTyp) -> int x |> Encode.int) enumDecoder
    |> Extra.withCustom (fun (x: RoleType) -> int x |> Encode.int) enumDecoder
    |> Extra.withCustom (fun (x: FileType) -> int x |> Encode.int) enumDecoder
