[<RequireQualifiedAccess>]
module Template

open MenuDef
open Fable.React
open Fable.React.Props
open Fulma
open Types

let bfsFooterContent dispatch impressumPage datenschutzPage =
    [
        div [] [
            span [] [ str "© " ]
            a [
                Props.Href "https://www.bfs-abrechnung.de/"
            ] [ str "BFS Abrechnungs GmbH" ]
        ]

        div [] [
            a [
                Class "space-right"
                Props.OnClick(fun _ -> impressumPage |> NavigateTo |> dispatch)
            ] [ str "Impressum" ]
            a [
                Props.OnClick(fun _ -> datenschutzPage |> NavigateTo |> dispatch)
            ] [ str "Datenschutz" ]
        ]
    ]
    |> ofList

let bfsFooter dispatch impressumPage datenschutzPage =
    footer [] [
        div [ Class "container" ] [
            div [ Class "columns" ] [
                div [
                    Class "column is-offset-1 is-10 pagefooter"
                ] [
                    bfsFooterContent dispatch impressumPage datenschutzPage
                ]
            ]
        ]
    ]

let bfsLogo dispatch (model: Model) showBigCoverPicture =
    let loginpage = if model.User.IsNone then " loginpage " else ""
    let coverClass = if showBigCoverPicture then " logocontainerbigcover " else ""

    Container.container [
        Container.CustomClass("logocontainer" + loginpage + coverClass)
    ] [
        Columns.columns [] [
            Column.column [
                Column.Width(Screen.All, Column.Is10)
                Column.Offset(Screen.All, Column.Is1)
            ] [
                a [
                    Class("logoblock" + loginpage)
                    Props.OnClick(fun _ -> NavigateHome |> dispatch)
                ] [ div [ Class "logo" ] [] ]
            ]
        ]
    ]


let menuCaption (tab: menuItemDefinition) =
    match tab.Icon with
    | Some element -> element
    | None -> str tab.Title

let simpleMainMenuTab dispatch (tab: menuItemDefinition) =
    a [
        Class "main-menu-item"
        Props.OnClick(fun _ ->
            if tab.Page <> null then
                dispatch (NavigateTo tab.Page))
    ] [ div [] [ menuCaption tab ] ]
let submenuTab (model: Model) roles dispatch (tab: menuItemDefinition) =
    a [
        Class "main-menu-item dropdown is-hoverable"
    ] [
        div [ Class "dropdown-trigger" ] [ menuCaption tab ]
        div [ Class "dropdown-menu" ] [
            div [ Class "dropdown-content" ] [
                for sub in tab.Subitems do
                    if sub.isVisible roles then
                        let isactive = if (sub.IsActive model) then "is-active" else ""
                        yield
                            a [ //TODO Wirft einen Fehler in der Konsole, weil ein a in ein a geschachtelt ist
                                Class("dropdown-item submenu-item " + isactive)
                                Props.OnClick(fun _ ->
                                    if sub.Page <> null then
                                        dispatch (NavigateTo sub.Page))
                            ] [ menuCaption sub ]
            ]
        ]
    ]

let navigation dispatch model (menu: menuItemDefinition list) user =
    let roles = user.Roles
    div [ Class "container" ] [
        div [ Class "columns" ] [
            div [ Class "column is-offset-1 is-10" ] [
                Tabs.tabs [
                    Tabs.CustomClass "account-tabs has-controls is-hidden-mobile main-menu"
                ] [
                    for tab in menu do
                        if tab.isVisible roles then
                            yield
                                Tabs.tab [
                                    Tabs.Tab.IsActive(tab.IsActive model)
                                ] [
                                    yield
                                        match tab.Subitems with
                                        | [] -> tab |> simpleMainMenuTab dispatch
                                        | _ -> tab |> submenuTab model roles dispatch
                                ]
                ]
            ]
        ]
    ]

let rec mobileMenuItems dispatch model user prefix (items: menuItemDefinition list) =
    let prefix = prefix |> Option.defaultValue ""
    [
        for item in items do
            if item.isVisible user.Roles then
                if (item.Page <> null) then
                    let active = if (item.IsActive model) then "is-active" else ""
                    yield
                        a [
                            Class("dropdown-item " + active)
                            Props.OnClick(fun _ -> dispatch (NavigateTo item.Page))
                        ] [ str (prefix + item.Title) ]
                yield!
                    item.Subitems
                    |> mobileMenuItems dispatch model user (Some(item.Title + " - "))
    ]

let navigationMobileMenu dispatch model (menu: menuItemDefinition list) (userMenu: menuItemDefinition list) =
    let menuopen = if (model.IsBurgerMenuOpen) then "is-active" else ""

    match model.User with
    | Some user ->
        div [
            Class("navbar-menu is-hidden-tablet " + menuopen)
        ] [
            div [ Class "navbar-start" ] [
                yield! menu |> mobileMenuItems dispatch model model.User.Value None

                yield hr [ Class "dropdown-divider" ]

                yield!
                    userMenu
                    |> mobileMenuItems dispatch model model.User.Value None


                yield
                    a [
                        Class "dropdown-item"
                        Props.OnClick(fun _ -> Logoff |> GlobalMsg |> dispatch)
                    ] [ str "Abmelden" ]
            ]
        ]
    | None -> nothing

let buttonOnClick button dispatch =
    Props.OnClick(fun _ ->
        if button.Message.IsSome then
            WebPartMsg button.Message.Value |> dispatch
        if button.ClosesDialog then
            ClearMessageBox |> GlobalMsg |> dispatch)

let getButton buttonType buttonClass (buttonList: GlobalMessageBoxButton list) dispatch =
    let butt = buttonList |> List.tryFind (fun b -> b.Type = buttonType)
    match butt with
    | Some b ->
        button [
            buttonClass
            buttonOnClick b dispatch
        ] [ str b.Caption ]
    | None -> str ""


let messageBox dispatch (data: GlobalMessageBox) =
    let className =
        match data.Type with
        | ErrorBox -> "is-danger"
        | WarningBox -> "is-bfscolored"
        | InfoBox
        | SuccessBox -> "is-bfscolored"

    let buttonClass = Class("button " + className)

    div [ Class "modal is-active" ] [
        div [ Class "modal-background" ] []
        div [ Class "modal-content" ] [
            div [ Class("message " + className) ] [
                div [ Class "message-header" ] [
                    p [] [ str data.Title ]
                    button [
                        Class "delete"
                        OnClick(fun _ -> ClearMessageBox |> GlobalMsg |> dispatch)
                    ] []
                ]
                div [ Class "message-body" ] [
                    yield data.BodyElement

                    yield br []

                    yield
                        div [ Class "button-container" ] [
                            getButton GlobalMessageBoxButtonType.OkButton buttonClass data.Buttons dispatch
                            getButton GlobalMessageBoxButtonType.YesButton buttonClass data.Buttons dispatch
                            getButton GlobalMessageBoxButtonType.NoButton buttonClass data.Buttons dispatch
                            getButton GlobalMessageBoxButtonType.CancelButton buttonClass data.Buttons dispatch
                        ]
                ]
            ]
        ]
    ]

let messageBoxComponent boxType wider title body onClose buttons =
    let className =
        match boxType with
        | ErrorBox -> "is-danger"
        | WarningBox -> "is-bfscolored"
        | InfoBox
        | SuccessBox -> "is-bfscolored"

    div [ Class "modal is-active" ] [
        div [ Class "modal-background" ] []
        div [ Class ("modal-content" + if wider then " modal-wide" else "") ] [
            div [ Class("message " + className) ] [
                div [ Class "message-header" ] [
                    p [] [ str title ]
                    button [
                        Class "delete"
                        OnClick(fun _ -> onClose ())
                    ] []
                ]
                div [ Class "message-body" ] [
                    yield body

                    yield br []

                    yield div [ Class "button-container" ] buttons
                ]
            ]
        ]
    ]

let videoOverlay dispatch (VimeoId(vimeoId)) =
    div [ Class "modal is-active" ] [
        div [
            Class "modal-background"
            OnClick(fun _ -> ClearVideo |> GlobalMsg |> dispatch)
        ] []
        div [ Class "modal-content" ] [
            div [
                Style [ BackgroundColor "transparent" ]
            ] [
                iframe [
                    Src(sprintf "https://player.vimeo.com/video/%s?dnt=1&autoplay=1" vimeoId)
                    HTMLAttr.Width "640"
                    HTMLAttr.Height "564"
                    FrameBorder "0"
                    HTMLAttr.Custom("allow", "autoplay; fullscreen")
                    AllowFullScreen true
                    AutoPlay true
                ] []
            ]
        ]
    ]

let userIconMenu dispatch model (menu: menuItemDefinition list) (userMenu: menuItemDefinition list) usericonClass user =
    div [
        Class "userwelcome is-right is-hidden-mobile"
    ] [
        for tab in menu do
            if tab.isVisible user.Roles then
                let active = if tab.IsActive model then "is-active" else ""
                yield
                    a [
                        Class active
                        OnClick(fun _ -> tab.Page |> NavigateTo |> dispatch)
                    ] [ menuCaption tab ]

        yield
            div [
                Class "dropdown is-hoverable is-right"
            ] [
                div [ Class "dropdown-trigger" ] [
                    i [ Class usericonClass ] []
                    p [] [ str user.Name ]
                    i [ Class "fas fa-angle-down" ] []
                ]
                div [ Class "dropdown-menu" ] [
                    div [ Class "dropdown-content" ] [
                        // die mobileMenuItems passen hier auch!
                        yield!
                            userMenu
                            |> mobileMenuItems dispatch model model.User.Value None

                        if userMenu |> List.isEmpty |> not then
                            hr [ Class "dropdown-divider" ]
                        a [
                            Class "dropdown-item"
                            Props.OnClick(fun _ -> Logoff |> GlobalMsg |> dispatch)
                        ] [ str "Abmelden" ]
                    ]
                ]
            ]
    ]

let burgerMenuButton dispatch isOpen usericonClass user =
    let menuopen = if isOpen then "is-active" else ""

    div [
        Class(
            "userwelcome userwelcome-mobile dropdown is-right is-hidden-tablet "
            + menuopen
        )
        OnClick(fun _ -> ToggleBurgerMenu |> dispatch)
    ] [
        div [ Class "dropdown-trigger" ] [
            i [ Class usericonClass ] []
            p [] [ str user.Name ]
            i [ Class "fas fa-bars" ] []
        ]
    ]

let headerText header =
    div [ Class "category-header" ] [
        match header with
        | NoHeader -> nothing
        | Header header -> h2 [] [ str header ]
        | HeaderWithSubheader(header, sub) ->
            h2 [] [
                str header
                p [ Class "subheader" ] [ str sub ]
            ]
    ]

let main
    dispatch
    (model: Model)
    (pageHeader: PageHeader)
    (bigCoverPicture: ReactElement option)
    (usericonClass: string)
    (topMenu: menuItemDefinition list)
    (userMenu: menuItemDefinition list)
    (mainMenu: menuItemDefinition list)
    (mobileMenu: menuItemDefinition list)
    impressumPage
    datenschutzPage
    backgroundClass
    body
    =


    div [ Class backgroundClass ] [
        if model.User.IsSome then
            userIconMenu dispatch model topMenu userMenu usericonClass model.User.Value
            burgerMenuButton dispatch model.IsBurgerMenuOpen usericonClass model.User.Value

        div [ Props.Class "shop-wrapper" ] [
            header [] [
                yield bfsLogo dispatch model bigCoverPicture.IsSome
                if model.User.IsSome then
                    yield navigation dispatch model mainMenu model.User.Value

                    if bigCoverPicture.IsSome then
                        yield bigCoverPicture.Value
            ]

            Section.section [] [
                navigationMobileMenu dispatch model mobileMenu userMenu
                div [ Class "container" ] [
                    Columns.columns [] [
                        Column.column [
                            Column.Width(Screen.All, Column.Is10)
                            Column.Offset(Screen.All, Column.Is1)
                        ] [
                            headerText pageHeader

                            body
                        ]
                    ]
                ]
            ]
            bfsFooter dispatch impressumPage datenschutzPage
        ]

        if (model.VideoOverlayVimeoId.IsSome) then
            videoOverlay dispatch model.VideoOverlayVimeoId.Value

        if (model.MessageBox.IsSome) then
            messageBox dispatch model.MessageBox.Value
    ]
