namespace Profile

open Elmish
open Validation
open Types
open Http
open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.Account


module Http =
    let userDetails () = fetchAs<UserInfo> "/api/account/details"

    let activateOtp () = postEmpty2<OtpActivation> "/api/account/activateotp"
    let confirmOtp (data) = postRecord<OtpConfirmation> "/api/account/confirmotp" data
    let deactivateOtp () = postEmpty "/api/account/deactivateOtp"

    let updatePassword (data) = postRecord3<ChangePassword> "/api/account/password" data

    let updateNotifications (data) = postRecord3<UserNotifications> "/api/account/notifications" data


module State =
    let init page (user: UserSession option) lastModel =
        let name =
            user
            |> Option.map (fun u -> u.Name)
            |> Option.defaultValue ""

        let email =
            user
            |> Option.map (fun u -> u.Email)
            |> Option.defaultValue ""

        match page with
        | PasswordChange -> (Model.InitPassword name, Cmd.ofMsg LoadUserInfo)
        | Notifications ->
            let preisInfosAnzeigen =
                user.Value.Roles
                |> List.contains Roles.KundeMitPreisInfosAnzeigen
            let model = Model.InitNotifications name email
            let notificationModel =
                model.Notifications
                |> Option.map (fun n ->
                    { n with
                        PreisInfosAnzeigen = preisInfosAnzeigen
                    })
            ({ model with
                Notifications = notificationModel
             },
             Cmd.ofMsg LoadUserInfo)

    let updateOtp msg model =
        match msg with
        | DeactivateOtpCheckboxChanged value ->
            ({ model with
                Status = OtpActivated value
             },
             Cmd.none,
             Cmd.none)
        | OtpChanged value ->
            ({ model with
                OtpField = update model.OtpField value
             },
             Cmd.none,
             Cmd.none)

        | ActivateOtp ->
            let cmd = ActivationLoaded |> request Http.activateOtp ()
            (model, cmd, Cmd.none)
        | ActivationLoaded(Ok otp) ->
            ({ model with
                Status = OtpActivating
                Secret = otp.Secret
                QrCode = otp.QrDataUrl
                ErrorMsg = None
             },
             Cmd.none,
             Cmd.none)
        | ActivationLoaded(Error e) -> (model, Cmd.none, e |> errorCmd GlobalMsg "Aktivieren")
        | ConfirmOtp ->
            let data = {
                Secret = model.Secret
                Otp = model.OtpField.Value
            }
            let cmd = ConfirmationLoaded |> request Http.confirmOtp data
            (model, cmd, Cmd.none)
        | ConfirmationLoaded(Ok _) -> (model, Cmd.none, Cmd.ofMsg LoadUserInfo)
        | ConfirmationLoaded(Error e) ->
            ({ model with
                ErrorMsg = Some "Das Einmalpasswort ist falsch."
             },
             Cmd.none,
             Cmd.none)
        | DeactivateOtp ->
            let cmd = DeactivationLoaded |> request Http.deactivateOtp ()
            (model, cmd, Cmd.none)
        | DeactivationLoaded(Ok _) -> (model, Cmd.none, Cmd.ofMsg LoadUserInfo)
        | DeactivationLoaded(Error e) -> (model, Cmd.none, e |> errorCmd GlobalMsg "Deaktivieren")

    let updatePassword msg model =
        match msg with
        | OldPasswordChanged value ->
            ({ model with
                OldPassword = update model.OldPassword value
             },
             Cmd.none)
        | NewPassword1Changed value ->
            ({ model with
                NewPassword1 = update model.NewPassword1 value
             },
             Cmd.none)
        | NewPassword2Changed value ->
            ({ model with
                NewPassword2 = update model.NewPassword2 value
             },
             Cmd.none)

        | SubmitPassword ->
            let data = {
                Password = model.OldPassword.Value
                NewPassword1 = model.NewPassword1.Value
                NewPassword2 = model.NewPassword2.Value
            }

            let cmd = PasswordReqeustDone |> request Http.updatePassword data
            (model, cmd)

        | PasswordReqeustDone(Ok "") -> (PasswordModel.Init(Some "Ihr Passwort wurde geändert!"), Cmd.none)
        | PasswordReqeustDone(Ok error) -> ({ model with ErrorMsg = Some error }, Cmd.none)
        | PasswordReqeustDone(Error e) -> ({ model with ErrorMsg = Some e.Message }, Cmd.none)

    let updateNotifications msg model =

        match msg with
        | PostImPortalChanged b -> ({ model with PostImPortal = b }, Cmd.none, Cmd.none)
        | PreisInfosChanged b -> ({ model with PreisInfos = b }, Cmd.none, Cmd.none)
        | BelegeingangChanged b -> ({ model with Belegeingang = b }, Cmd.none, Cmd.none)
        | BelegpruefungAbgeschlossenChanged b ->
            ({ model with
                BelegpruefungAbgeschlossen = b
             },
             Cmd.none,
             Cmd.none)
        | AuszahlungGetaetigtChanged b -> ({ model with AuszahlungGetaetigt = b }, Cmd.none, Cmd.none)
        | TicketAntwortChanged b -> ({ model with TicketAntwort = b }, Cmd.none, Cmd.none)

        | SubmitNotifications ->
            let data = {
                NotificationForPostImPortal = model.PostImPortal
                NotificationForPreisInfos = model.PreisInfos
                NotificationForBelegeingang = model.Belegeingang
                NotificationForBelegpruefungAbgeschlossen = model.BelegpruefungAbgeschlossen
                NotificationForAuszahlungGetaetigt = model.AuszahlungGetaetigt
                NotificationForTicketAntwort = model.TicketAntwort
            }

            let cmd =
                NotificationsRequestDone
                |> request Http.updateNotifications data
            ({ model with Sending = true }, cmd, Cmd.none)

        | NotificationsRequestDone(Ok "") ->
            let cmd =
                GlobalMessageBox.Success
                    "Die Einstellungen wurden gespeichert. Wir werden Sie nun wie gewünscht per E-Mail informieren."
                |> ShowMessageBox
                |> GlobalMsg
                |> Cmd.ofMsg
            ({ model with Sending = false }, Cmd.none, cmd)
        | NotificationsRequestDone(Ok error) ->
            ({ model with Sending = false }, Cmd.none, error |> errorCmd2 GlobalMsg "Speichern")
        | NotificationsRequestDone(Error e) ->
            ({ model with Sending = false }, Cmd.none, e |> errorCmd GlobalMsg "Speichern")


    let update msg (model: Model) =
        match msg with
        | LoadUserInfo ->
            let cmd = UserInfoLoaded |> request Http.userDetails ()
            (model, cmd)
        | UserInfoLoaded x ->
            let status =
                match x with
                | Ok x -> if x.OtpEnabeld then OtpActivated false else OtpDeactivated
                | _ -> OtpDeactivated
            let otpModel =
                model.Otp
                |> Option.map (fun o -> { o with Status = status })

            let notificationModel =
                match x with
                | Ok x ->
                    model.Notifications
                    |> Option.map (fun n ->
                        { n with
                            PostImPortal = x.Notifications.NotificationForPostImPortal
                            PreisInfos = x.Notifications.NotificationForPreisInfos
                            Belegeingang = x.Notifications.NotificationForBelegeingang
                            BelegpruefungAbgeschlossen = x.Notifications.NotificationForBelegpruefungAbgeschlossen
                            AuszahlungGetaetigt = x.Notifications.NotificationForAuszahlungGetaetigt
                            TicketAntwort = x.Notifications.NotificationForTicketAntwort
                            Sending = false
                        })
                | _ -> model.Notifications

            ({ model with
                UserInfo = Loaded x
                Otp = otpModel
                Notifications = notificationModel
             },
             Cmd.none)

        | Otp otpmsg when model.Otp.IsSome ->
            let subModel, subCmd, globalMsg = updateOtp otpmsg model.Otp.Value
            let cmd = Cmd.batch [ Cmd.map Otp subCmd; globalMsg ]
            ({ model with Otp = Some subModel }, cmd)

        | Password pwmsg when model.Password.IsSome ->
            let subModel, subCmd = updatePassword pwmsg model.Password.Value
            ({ model with Password = Some subModel }, Cmd.map Password subCmd)

        | Notification nmsg when model.Notifications.IsSome ->
            let subModel, subCmd, globalCmd = updateNotifications nmsg model.Notifications.Value
            let cmd =
                Cmd.batch [
                    Cmd.map Notification subCmd
                    globalCmd
                ]
            ({ model with
                Notifications = Some subModel
             },
             cmd)

        | GlobalMsg _ -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!

        | _ -> (model, Cmd.none)
