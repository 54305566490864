module Dashboard.Melibo

open Bfs.Web.Data.Service.Contracts.Kundenportal.Dashboard
open Bfs.Web.Kundenportal.WebParts.User.Shared.Tile
open Fable
open Fable.React
open Fable.React.Props
open Feliz.Recharts
open Elmish

open Fulma
open Types
open Http
open ViewParts

open Dashboard.Shared

open Bfs.Web.Shared.Formating

let View =
    FunctionComponent.Of(fun (props: DashboardProps) ->
        let body =
            div [ Class "melibo" ] [
                div [ Class "text" ] [
                    div [ Class "large" ] [ str "KI-Assistent" ]
                    div [ Class "subtle" ] [
                        str "Sind meine Belege eingegangen? Wann kann ich mit dem Geldeingang rechnen?"
                    ]
                ]
                div [ Class "link emphasized" ] [
                    a [
                        OnClick(fun _ -> props.NavigateTo Melibo.Types.Page.Chat)
                    ] [
                        str "Stellen Sie hier Ihrem KI-Assistenten eine Frage"
                        navigationArrow
                    ]
                ]
            ]

        headlessTile body)
