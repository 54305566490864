﻿module Bfs.Web.Kundenportal.WebParts.User.Dashboard.EventsEaster

open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Bfs.Web.Kundenportal.WebParts.User.Shared.Tile

open Fable.React
open Fable.React.Props
open Elmish

open Types
open Http

open Dashboard.Shared

type Model = {
    Loading: bool
    DashboardFound: bool
    TicketFound: bool
    QuickfinderFound: bool
    Participated: bool
    GlobalDispatch: GlobalMsg -> unit
    ConfirmParticipationTrigger: int
}

type Msg =
    | LoadData
    | LoadDataCompleted of Result<EventSettings, exn>
    | ShowParticipationModal
    | ConfirmParticipation
    | ConfirmParticipationCompleted of Result<Fetch.Types.Response, exn>

module Http =
    let loadData () = fetchAs<EventSettings> "/api/events/settings"
    let confirmParticipation = "/api/events/confirm-participation"

module State =
    let init (props: DashboardProps) =
        {
            Loading = true
            DashboardFound = false
            TicketFound = false
            QuickfinderFound = false
            Participated = false
            GlobalDispatch = props.GlobalDispatch
            ConfirmParticipationTrigger = 0
        },
        LoadData |> Cmd.ofMsg

    let update (msg: Msg) (model: Model) : Model * Cmd<Msg> =
        match msg with

        | LoadData ->
            let cmd = LoadDataCompleted |> request Http.loadData ()
            { model with
                Loading = false
            },
            cmd

        | LoadDataCompleted(Ok data) ->
            { model with
                Loading = false
                DashboardFound = data.EasterEvent |> Option.map (fun x -> x.DashboardFound) |> Option.defaultValue false
                TicketFound = data.EasterEvent |> Option.map (fun x -> x.TicketFound) |> Option.defaultValue false
                QuickfinderFound = data.EasterEvent |> Option.map (fun x -> x.QuickfinderFound) |> Option.defaultValue false
                Participated = data.EasterEvent |> Option.map (fun x -> x.TeilgenommenAm.IsSome) |> Option.defaultValue false
            },
            Cmd.none

        | LoadDataCompleted(Error _) ->
            let msg =
                GlobalMessageBox.Error "Die Daten zum Gewinnspiel können aktuell nicht geladen werden."
                |> ShowMessageBox
            model.GlobalDispatch msg
            { model with Loading = false }, Cmd.none

        | ShowParticipationModal ->

            GlobalMessageBox.ConfirmationBox
                "Ostergewinnspiel"
                "Sie haben alle Ostereier gefunden! Möchten Sie am Gewinnspiel teilnehmen?"
                (Some (ConfirmParticipation :> AnyWebPartMsg |> Dashboard.Types.Callback :> AnyWebPartMsg))
            |> ShowMessageBox
            |> model.GlobalDispatch
            model, Cmd.none

        | ConfirmParticipation->
            let request () =
                Http.postEmpty Http.confirmParticipation
            let cmd = Http.request request () ConfirmParticipationCompleted
            model, cmd

        | ConfirmParticipationCompleted (Ok _) ->
            { model with Participated = true }, Cmd.none
        | ConfirmParticipationCompleted (Error _) ->
            GlobalMessageBox.Error "Die Teilnahme kann aktuell nicht gespeichert werden."
            |> ShowMessageBox
            |> model.GlobalDispatch

            { model with Participated = true }, Cmd.none

let imagePath found =
    if found then
        "events/easter/egg_BFS_target_active_issolved.svg"
    else
        "events/easter/egg_BFS_placeholder_box.svg"

let View =
    FunctionComponent.Of(fun (props: DashboardProps * int) ->
        let props, updateTrigger = props
        let model, dispatch = React.useElmish ((State.init props), State.update, [| box updateTrigger |])

        let isPristine = Hooks.useRef true
        Hooks.useEffect(
            (fun _ ->
                if isPristine.current then
                    isPristine.current <- false
                else
                    match props.MostRecentCallback with
                    | Some x when (x :? Msg) ->
                        let msg = (x :?> Msg)
                        dispatch msg
                    | _ -> ()

            ), [| box props.MostRecentCallback |])

        let totalEggCount = 3
        let foundEggCount = [ model.DashboardFound; model.TicketFound; model.QuickfinderFound ] |> List.filter id |> List.length
        let title = str "Mein Osternest"
        let body =
            div [ Class "events-easter" ] [
                div [ Class "subtle" ]
                    [ str "Wir haben drei BFS-Ostereier auf unserer Website versteckt: Finde sie und sammle sie per Mausklick ein!" ]

                div [ Class "found-eggs" ]
                    [
                        img [ Src (imagePath model.DashboardFound) ]
                        img [ Src (imagePath model.TicketFound) ]
                        img [ Src (imagePath model.QuickfinderFound) ]
                    ]

                div [ Class "emphasized has-text-centered" ]
                    [ (match foundEggCount with
                       | x when model.Participated -> str "Sie nehmen am Ostergewinnspiel teil!"
                       | x when x = totalEggCount -> a [ OnClick (fun _ -> ShowParticipationModal |> dispatch) ] [ str "Hier klicken um am Gewinnspiel teilzunehmen!" ]
                       | x when totalEggCount - x = 1 -> str "Noch ein Ei bis zum Gewinnspiel!"
                       | x -> str $"Noch {totalEggCount - x} Eier bis zum Gewinnspiel!"
                    ) ]
            ]

        bfsTile
            title
            None
            None
            body
            None
    )
