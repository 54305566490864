namespace Quickfinder

open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.Quickfinder
open System

module Http =
    let getRechnungDetails nummer = fetchAs<Information[]> (sprintf "/api/quickfinder/rechnung?nummer=%s" nummer)

    let downloadUrlDokumentAnfrage (id: Guid) = sprintf "/api/quickfinder/downloaddokumentanfrage?id=%O" id
    let downloadUrlSammelrechnung (id: Guid) = sprintf "/api/quickfinder/downloadsammelrechnung?id=%O" id

module State =
    let init page user lastModel =
        let setting = Storage.IsQuickFinderSortierungAufsteigend.get ()

        match page with
        | RechnungQuickfinder nummer ->
            let model = {
                Rechnungsnummer = nummer
                AufsteigendSortieren = setting
                Information = Loading

                OstergewinnspielActive = false
                EggFound = false
            }
            (model, [
                Cmd.ofMsg (LoadRechnungDeatils nummer)
                Cmd.ofMsg (LoadEventSettings)
            ] |> Cmd.batch)

    let update msg (model: Model) =
        match msg with
        | LoadRechnungDeatils nummer ->
            let cmd =
                RechnungDetailsLoaded
                |> request Http.getRechnungDetails nummer
            { model with Information = Loading }, cmd

        | RechnungDetailsLoaded x -> { model with Information = Loaded x }, Cmd.none

        | SortierungAendern ->
            let newValue = not model.AufsteigendSortieren
            Storage.IsQuickFinderSortierungAufsteigend.set newValue
            { model with
                AufsteigendSortieren = newValue
            },
            Cmd.none
        | LoadEventSettings ->
            let request () = fetchAs<EventSettings> "/api/events/settings"
            model,
            (
                LoadEventSettingsCompleted
                |> Http.request request ()
            )
        | LoadEventSettingsCompleted (Ok settings) ->
            { model with
                OstergewinnspielActive = settings.EasterEvent.IsSome
                EggFound = settings.EasterEvent |> Option.map (fun x -> x.QuickfinderFound) |> Option.defaultValue false }, Cmd.none
        | LoadEventSettingsCompleted _ ->
            Logger.error "Die Eventeinstellungen konnten nicht geladen werden"
            model, Cmd.none
        | GlobalMsg(_) -> (model, Cmd.none) // diese Message wird gar nicht hier behandelt, sondern im Dispatcher!
