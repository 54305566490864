module Dashboard.Webpart

open Bfs.Web.Kundenportal.WebParts.User.DashBoardPage
open Elmish
open WebPart
open Types

let (Part: WebPart<Msg, Model, DashboardPage>) = {
    Init = (fun _ session _ -> Model.Init session)
    Update = Model.Update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun _ -> NoHeader

    BuildUrl = fun _ -> [ Urls.dashboard ]

    ParseUrl =
        function
        | [ Urls.dashboard ] -> Some DashboardPage.Dashboard
        | _ -> None
}
