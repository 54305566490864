module AdminFaq.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg =
        function
        | GlobalMsg m -> Some m
        | _ -> None

    GetHeader = fun model -> Header "Administration: FAQs"

    BuildUrl = (fun _ -> [ Urls.admin; Urls.faq ])

    ParseUrl =
        function
        | [ Urls.admin; Urls.faq ] -> Some Page.Faq
        | _ -> None
}
