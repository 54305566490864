namespace Tickets

open System
open Bfs.Web.Data.Service.Contracts
open Bfs.Web.Data.Service.Contracts.Kundenportal.Events
open Elmish
open Types
open Http
open Bfs.Web.Data.Service.Contracts.Kundenportal
open Bfs.Web.Data.Service.Contracts.Kundenportal.Help
open Validation


module Http =
    let postNeuesTicket (ticket) = postRecord<NeuesTicket> "/api/ticket/tokontakt" (ticket)
    let getUserTicketList onlyMyTickets =
        fetchAs<UserTicketListItem list> (sprintf "api/ticket/usertickets/%b" onlyMyTickets)
    let postAttachment file = file |> uploadFile<UploadResult> $"/api/ticket/attachments/"
    let deleteAttachment (fileId: Guid) = delete<Guid> $"/api/ticket/attachments/{fileId}"

module State =

    let private resultToResultText (result: Result<Fetch.Types.Response, exn>) =
        match result with
        | Result.Ok _ -> Success
        | Result.Error e when e.Message.StartsWith("429") -> TooManyRequests
        | Result.Error e when e.Message.StartsWith("413") -> PayloadTooLarge
        | Result.Error _ -> Error

    let init page (user: UserSession option) lastModel =
        let model =
            match user, page with
            | Some user, Ticket -> Model.Init user.Name user.Email ""
            | Some user, PrefilledTicket betreff -> Model.Init user.Name user.Email betreff
            | None, _ -> Model.Init "" "" ""
        // eigentlich sollte das nicht vorkommen, da nur eingeloggte
        // Nutzer hierher kommen, aber schlimm wäre es auch nicht

        (model, [
            Cmd.ofMsg (LoadUserTicketList model.ShowOnlyMyTickets)
            Cmd.ofMsg (LoadEventSettings)
        ] |> Cmd.batch)

    let update msg (model: Model) =
        match msg with
        | LoadUserTicketList onlyMyTickets ->
            ({ model with
                ShowOnlyMyTickets = onlyMyTickets
                UserTickets = Loading
             },
             UserTicketListLoaded
             |> request Http.getUserTicketList onlyMyTickets)
        | UserTicketListLoaded userticketlist ->
            ({ model with
                UserTickets = Loaded userticketlist
             },
             Cmd.none)

        | BetreffChanged value ->
            ({ model with
                Betreff = update model.Betreff value
             },
             Cmd.none)
        | TextChanged value ->
            ({ model with
                Text = update model.Text value
             },
             Cmd.none)

        | UploadFiles files ->
            let size = files |> List.sumBy (fun f -> f.size)

            if (model.CurrentUploadSizeSum + size > sizeLimitMB * 1024 * 1024) then
                let cmd =
                    Cmd.ofMsg (
                        GlobalMsg(
                            ShowMessageBox(
                                GlobalMessageBox.Error
                                    $"Ticketanhänge dürfen insgesamt maximal {sizeLimitMB} MB groß sein."
                            )
                        )
                    )
                (model, cmd)
            else
                let cmd =
                    files
                    |> List.map (fun f -> FileUploaded |> request Http.postAttachment f)
                    |> Cmd.batch
                ({ model with
                    CurrentUploadCount = model.CurrentUploadCount + files.Length
                 },
                 cmd)
        | FileUploaded(Ok r) ->
            let model' =
                { model with
                    CurrentUploadCount = model.CurrentUploadCount - 1
                    CurrentUploadList = (List.append model.CurrentUploadList [ r ])
                    CurrentUploadSizeSum = model.CurrentUploadSizeSum + r.Size
                }
            (model', Cmd.none)
        | FileUploaded(Result.Error exn) ->
            let model' =
                { model with
                    CurrentUploadCount = model.CurrentUploadCount - 1
                }
            if (exn.Message.StartsWith("415")) then
                let cmd =
                    Cmd.ofMsg (
                        GlobalMsg(
                            ShowMessageBox(
                                GlobalMessageBox.Error
                                    $"Für Ticketanhänge sind nur pdf-, jpg-, jpeg- und png-Dateien zulässig."
                            )
                        )
                    )
                (model', cmd)
            else
                (model', exn |> errorCmd GlobalMsg "Upload")

        | DeleteUploadedFile file ->
            let cmd = UploadedFileDeleted |> request Http.deleteAttachment file.Id
            ({ model with Sending = true }, cmd)
        | UploadedFileDeleted(Ok id) ->
            let uploadList, uploadSize =
                model.CurrentUploadList
                |> List.tryFindIndex (fun f -> f.Id = id)
                |> function
                    | Some i ->
                        let deleted = model.CurrentUploadList[i]
                        model.CurrentUploadList |> List.removeAt i, model.CurrentUploadSizeSum - deleted.Size
                    | None -> model.CurrentUploadList, model.CurrentUploadSizeSum
            let model' =
                { model with
                    Sending = false
                    CurrentUploadList = uploadList
                    CurrentUploadSizeSum = uploadSize
                }
            (model', Cmd.none)
        | UploadedFileDeleted(Result.Error exn) -> ({ model with Sending = false }, exn |> errorCmd GlobalMsg "Löschen")

        | Submit ->
            let ids = model.CurrentUploadList |> List.map (fun f -> f.Id)
            let ticket = {
                Betreff = model.Betreff.Value
                Text = model.Text.Value
                Attachments = ids
            }
            let cmd = Submitted |> request Http.postNeuesTicket (ticket)
            ({ model with Sending = true }, cmd)
        | Submitted result ->
            let r = resultToResultText result
            let cmd = LoadUserTicketList model.ShowOnlyMyTickets |> Cmd.ofMsg
            ({ model with
                Sending = false
                SendingResult = Some r
             },
             cmd)

        | LoadEventSettings ->
            let request () = fetchAs<EventSettings> "/api/events/settings"
            model,
            (
                LoadEventSettingsCompleted
                |> Http.request request ()
            )
        | LoadEventSettingsCompleted (Ok settings) ->
            { model with
                OstergewinnspielActive = settings.EasterEvent.IsSome
                EggFound = settings.EasterEvent |> Option.map (fun x -> x.TicketFound) |> Option.defaultValue false }, Cmd.none
        | LoadEventSettingsCompleted _ ->
            Logger.error "Die Eventeinstellungen konnten nicht geladen werden"
            model, Cmd.none
        | GlobalMsg _ -> (model, Cmd.none) // wird hier sowieso nicht behandelt
