module StaticPages.WebPart

open WebPart
open Types

let Part = {
    Init = State.init
    Update = State.update
    View = View.view

    GetGlobalMsg = fun _ -> None

    GetHeader = fun model -> Header model.Headline

    BuildUrl =
        function
        | Page.Impressum -> [ Urls.impressum ]
        | Page.Datenschutz -> [ Urls.datenschutz ]

    ParseUrl =
        function
        | [ Urls.impressum ] -> Some StaticPages.Types.Page.Impressum
        | [ Urls.datenschutz ] -> Some StaticPages.Types.Page.Datenschutz
        | _ -> None
}
