module Verrechnungen.View

open System
open Fable.React
open Fulma

open Types
open Bfs.Web.Data.Service.Contracts.Kundenportal.Verrechnungen
open Bfs.Web.Kundenportal.WebParts.User.Events.Easter
open Bfs.Web.Shared.Formating
open Fable.React.Props
open ViewParts

open Bfs.Web.Portale.Collapsable


let private verrechnungenBox size navigateTo =
    Help.View.textBox
        size
        "Anstehende Verrechnungen"
        "bfs_verrechnung"
        Help.View.MediumDark
        "Hier finden Sie eine Übersicht über Ihre anstehenden Verrechnungen."
        None
        (Some(fun _ -> (navigateTo (Page.Verrechnungen None))))

let wideVerrechnungenBox = verrechnungenBox Help.View.Wide

let filialenRow selectedFilialeId dispatch (filialen: Filiale[]) =
    let selectedFilialeId = selectedFilialeId |> Option.defaultValue Guid.Empty

    Tabs.tabs [
        Tabs.CustomClass "is-hidden-touch account-tabs main-menu with-width-constraint"
    ] [
        filialen
        |> Array.sortBy (fun f -> (not f.IsZentrale, f.Name))
        |> Array.map (fun f ->
            Tabs.tab [
                Tabs.Tab.CustomClass "submenu-item"
                Tabs.Tab.IsActive(f.FilialeId = selectedFilialeId)
            ] [
                a [
                    OnClick(fun _ -> (SwitchFiliale f.FilialeId) |> dispatch)
                ] [ str f.Name ]
            ])
        |> ofArray
    ]

let filialenMobile selectedFilialeId dispatch (filialen: Filiale[]) =
    let selectedFilialeId = selectedFilialeId |> Option.defaultValue Guid.Empty

    div [ Class "is-hidden-desktop" ] [
        div [ Class "flat-card is-auto menu-card" ] [
            ul [ Class "wishlists" ] [
                filialen
                |> Array.sortBy (fun f -> (not f.IsZentrale, f.Name))
                |> Array.map (fun f ->
                    li [
                        Class(
                            "wishlist-item "
                            + (if f.FilialeId = selectedFilialeId then "is-active" else "")
                        )
                        OnClick(fun _ -> (SwitchFiliale f.FilialeId) |> dispatch)
                    ] [
                        div [ Class "item-wrapper" ] [ a [] [ str f.Name ] ]
                    ])
                |> ofArray
            ]
        ]
    ]

let verrechnungNichtMoeglichText (verrechnung: Verrechnung) =
    match verrechnung.Status with
    | Verschiebungsstatus.ForderungFaellig ->
        "Diese Forderung ist bereits fällig und kann daher nicht verschoben werden."
    | Verschiebungsstatus.ForderungTerminiert ->
        "Diese Forderung ist für die Verrechnung bereits unveränderbar terminiert und kann daher nicht verschoben werden."
    | Verschiebungsstatus.BereitsVerschoben ->
        "Die Fälligkeit dieser Forderung wurde bereits einmal verschoben und kann daher nicht erneut verschoben werden."
    | _ -> ""

let verrechnungCheckbox (verrechnung: Verrechnung) isSelected dispatch =
    if verrechnung.Status = Verschiebungsstatus.VerschiebungMoeglich then
        orangeCheckbox isSelected (fun _ ->
            SelectVerrechnung(verrechnung.ForderungId, not isSelected)
            |> dispatch)
    else
        let text = verrechnung |> verrechnungNichtMoeglichText
        disabeldCheckboxWithTooltip text (fun _ ->
            GlobalMessageBox.Error text
            |> ShowMessageBox
            |> GlobalMsg
            |> dispatch)


let ticketText (verrechnung: Verrechnung) = sprintf "Anstehende Verrechnung zur Rechnung %s" verrechnung.RechnugnsNr

let verrechnugnenMobile (verrechnungen: Verrechnung[]) model dispatch navigateTo withCheckboxes =
    div [ Class "dta-list is-hidden-desktop" ] [
        verrechnungen
        |> (Array.map (fun v ->
            let isExpanded = model.ExpandedVerrechnungen |> List.contains v.ForderungId
            let iconclass = if isExpanded then " upsidedown" else ""
            let active = if isExpanded then " is-active" else ""
            let isSelected = model.SelectedVerrechnugen |> List.contains v.ForderungId

            let expandedText =
                fragment [] [
                    p [] [ str v.Details ]
                    Icons.TicketErstellenLink(fun _ ->
                        navigateTo (Tickets.Types.Page.PrefilledTicket(ticketText v)))
                ]

            div [ Class "dta-item" ] [
                div [] [
                    div [ Class "dta-file" ] [
                        if withCheckboxes then
                            verrechnungCheckbox v isSelected dispatch

                        str (sprintf "Rechnung %s (%s, %s)" v.RechnugnsNr v.KlientNachname v.KlientVorname)
                    ]
                ]
                div [ Class "dta-status" ] [
                    div [] [
                        span [] [
                            v.Betrag |> asMoneySpan
                            str " Kürzung von "
                            v.Rechnungsbetrag |> asMoneySpan
                            str " Rechnungsbetrag "
                            str (sprintf "am %s" (v.Faelligkeitsdatum.ToString("dd.MM.yyyy")))
                        ]
                    ]

                    div [
                        Style [ MarginTop "10px" ]
                        OnClick(fun _ -> Toggle v.ForderungId |> dispatch)
                    ] [
                        div [
                            Style [ Display DisplayOptions.Flex ]
                        ] [
                            div [] [ str v.Absetzungsgrund ]
                            div [] [
                                a [] [
                                    linkicon ("fas fa-chevron-down" + iconclass)
                                ]
                            ]
                        ]
                    ]

                    div [ Class("beschreibung") ]
                        [ CollapsableElement (expandedText, isExpanded) ]
                ]
            ]))
        |> ofArray
    ]

let verrechnungZeile dispatch navigateTo model withCheckboxes (verrechnung: Verrechnung) =
    let isExpanded =
        model.ExpandedVerrechnungen
        |> List.contains verrechnung.ForderungId
    let iconclass = if isExpanded then " upsidedown" else ""
    let isSelected =
        model.SelectedVerrechnugen
        |> List.contains verrechnung.ForderungId

    let expandedText =
        fragment [] [
            p [] [ str verrechnung.Details ]
            Icons.TicketErstellenLink(fun _ ->
                navigateTo (Tickets.Types.Page.PrefilledTicket(ticketText verrechnung)))
        ]

    tr [] [
        if withCheckboxes then
            td [] [
                verrechnungCheckbox verrechnung isSelected dispatch
            ]
        td [
            Style [ Width "15%"; MinWidth "180px" ]
        ] [
            p [] [ str verrechnung.RechnugnsNr ]
            p [] [
                str (sprintf "%s, %s" verrechnung.KlientNachname verrechnung.KlientVorname)
            ]
        ]
        td [] [
            str (verrechnung.Faelligkeitsdatum.ToString("dd.MM.yyyy"))
        ]
        td [
            OnClick(fun _ -> Toggle verrechnung.ForderungId |> dispatch)
        ] [
            div [] [ str verrechnung.Absetzungsgrund ]
            div [ Class("beschreibung") ]
                [ CollapsableElement (expandedText, isExpanded) ]
        ]
        td [ Class "text-right" ] [
            verrechnung.Rechnungsbetrag |> asMoneySpan
        ]
        td [ Class "text-right" ] [ verrechnung.Betrag |> asMoneySpan ]
        td [
            OnClick(fun _ -> Toggle verrechnung.ForderungId |> dispatch)
        ] [
            a [] [
                linkicon ("fas fa-chevron-down" + iconclass)
            ]
        ]
    ]

let verrechnugnenTabelle (verrechnungen: Verrechnung[]) model dispatch navigateTo withCheckboxes =
    table [
        Class "table table-striped responsive-table is-hidden-touch"
    ] [
        thead [] [
            tr [] [
                if withCheckboxes then
                    th [] []
                th [] [ str "Rechnung" ]
                th [] [ str "Verrechnung am" ]
                th [
                    DangerouslySetInnerHTML { __html = "Absetzungs&shy;grund" }
                ] []
                th [
                    Class "text-right"
                    DangerouslySetInnerHTML { __html = "Rechnungs&shy;betrag" }
                ] []
                th [
                    Class "text-right"
                    DangerouslySetInnerHTML { __html = "Kürzungs&shy;summe" }
                ] []
                th [] []
            ]
        ] // klappicon
        tbody
            []
            (verrechnungen
             |> Array.map (verrechnungZeile dispatch navigateTo model withCheckboxes))
    ]

let emptyBox =
    div [ Class "columns" ] [
        div [ Class "column is-12" ] [
            div [
                Class "flat-card profile-info-card is-auto overflow-initial"
            ] [
                div [ Class "card-title" ] [
                    h3 [] [
                        b [] [
                            str "Keine anstehenden Verrechnungen"
                        ]
                    ]
                ]
                div [ Class "card-body" ] [
                    p [] [
                        str
                            "Derzeit gibt es keine Forderungen, zu denen es ein Abrechnungsproblem gibt und die zur Verrechnung anstehen."
                    ]
                ]
            ]
        ]
    ]

let monatBox showEasterEggs dispatch navigateTo (verrechnungen: Verrechnung[]) model =
    let summeKuerzungen = verrechnungen |> Array.sumBy (fun v -> v.Betrag)

    let monat = verrechnungen.[0].Faelligkeitsdatum

    let verschiebenMoeglich =
        model.UserDarfVerschieben
        && model.FilialeKannVerschieben
        && model.VerrechnungAuswaehlenFuerMonat.IsNone
    let istAmVerscheiben =
        model.VerrechnungAuswaehlenFuerMonat.IsSome
        && model.VerrechnungAuswaehlenFuerMonat.Value.Month = monat.Month
        && model.VerrechnungAuswaehlenFuerMonat.Value.Year = monat.Year

    let titel =
        if istAmVerscheiben then
            let summeGewaehlt =
                verrechnungen
                |> Array.filter (fun v -> (model.SelectedVerrechnugen |> List.contains v.ForderungId))
                |> Array.sumBy (fun v -> v.Betrag)
            sprintf
                "%s %i (%s von %s gewählt)"
                (monatName monat.Month)
                monat.Year
                (summeGewaehlt |> asHtmlMoney)
                (summeKuerzungen |> asHtmlMoney)
        else
            sprintf "%s %i (%s)" (monatName monat.Month) monat.Year (summeKuerzungen |> asHtmlMoney)


    div [ Class "columns verrechnungen" ] [
        div [ Class "column is-12" ] [
            div [
                Class "flat-card profile-info-card is-auto overflow-initial is-relative"
            ] [
                if model.OstergewinnspielActive && showEasterEggs then
                    (Egg Color.Blue Variant.Lines [ Top "20%"; ])
                    (Egg Color.Green Variant.Stripped [ Left "60%"; ])
                    (Egg Color.Yellow Variant.Stripped [ Left "100%"; Top "30%" ])
                    (Egg Color.Yellow Variant.Dotted [ Left "20%"; Top "100%" ])
                div [ Class "card-title" ] [
                    h3 [] [
                        b [
                            DangerouslySetInnerHTML { __html = titel }
                        ] []
                    //if showHinweis then
                    //    div [ Class "tag is-danger is-light" ] [str "Hohe Verrechnungsquote"] ]
                    ]

                    div [ Class "headerbuttons" ] [
                        if istAmVerscheiben then
                            a [
                                Class "bfsbutton gray"
                                OnClick(fun _ -> VerschiebenAbbrechen |> dispatch)
                            ] [ str "Abbrechen" ]

                        if verschiebenMoeglich then
                            a [
                                Class "bfsbutton"
                                OnClick(fun _ -> (VerschiebenStart monat) |> dispatch)
                            ] [ str "Verschieben" ]

                        if istAmVerscheiben then
                            a [
                                Class "bfsbutton"
                                Disabled(model.SelectedVerrechnugen.IsEmpty)
                                OnClick(fun _ ->
                                    if not model.SelectedVerrechnugen.IsEmpty then
                                        VerschiebenDurchfuehrenTry |> dispatch)
                            ] [ str "Jetzt verschieben" ]
                    ]
                ]

                div [ Class "card-body" ] [
                    verrechnugnenTabelle verrechnungen model dispatch navigateTo istAmVerscheiben
                    verrechnugnenMobile verrechnungen model dispatch navigateTo istAmVerscheiben
                ]
            ]
        ]
    ]

let monatBoxen dispatch navigateTo (verrechnugnen: Verrechnung[]) model =
    verrechnugnen
    |> Array.groupBy (fun v -> sprintf "%04i-%02i" v.Faelligkeitsdatum.Year v.Faelligkeitsdatum.Month)
    |> Array.sortBy (fun (key, _) -> key)
    |> Array.mapi (fun index (_, verrechnugnen) ->
        monatBox
            (index = 0)
            dispatch
            navigateTo
            (verrechnugnen
             |> Array.sortBy (fun v -> v.Faelligkeitsdatum, v.Absetzungsgrund, v.KlientNachname, v.KlientVorname))
            model)

let dtaReportText dispatch navigateTo model =
    div [ Class "verrechnungen plain-text" ] [
        str "Diese anstehenden Verrechnungen können Sie auch der Auswertung \"Offene Absetzungen\" entnehmen. "
        if not model.DtaReportAngefordert then
            str "Klicken Sie "
            a [
                OnClick(fun _ -> DtaReportAnfordern |> dispatch)
            ] [ str "hier" ]
            str " um diese anzufordern."
        else
            str "Die Auswertung wird in Kürze für Sie erzeugt und steht Ihnen unter "
            a [
                OnClick(fun _ -> navigateTo (Files.Types.Page.Files(1, Kundenportal.Types.Filter.NurNeue)))
            ] [ str "Dokumente" ]
            str " zur Verfügung."
    ]

let infoBox dispatch navigateTo =
    div [ Class "verrechnungen box" ] [
        div [ Class "container" ] [
            div [ Class "columns" ] [
                div [ Class "column is-4" ] [
                    img [ Src "help/bfs_epost_4.png" ]
                    p [] [
                        str
                            "Um eine Verrechnung zu verhindern, reichen Sie uns bis zur ausgewiesenen Frist Ihre Unterlagen zwecks Widerspruch ein."
                    ]
                ]
                div [ Class "column is-4" ] [
                    img [ Src "help/bfs_verrechnung_4.png" ]
                    p [] [
                        str
                            "Jetzt neu – verschieben Sie die Frist in den nächsten Monat. Alle Informationen hierzu finden Sie auf dieser Seite."
                    ]
                ]
                div [ Class "column is-4" ] [
                    img [ Src "help/bfs_ticketsystem_4.png" ]
                    p [] [
                        str "Bei weiteren Fragen nutzen Sie bitte unser Ticket System."
                    ]
                ]
            ]
        ]

        button [
            OnClick(fun _ -> HideInfoBox |> dispatch)
            Class "bfsdelete narrow"
            Title "schließen"
        ] []
    ]

let view (model: Types.Model) dispatch (navigateTo: AnyPage -> unit) =
    [
        if model.ShowInfoBox then
            yield infoBox dispatch navigateTo

        match model.Filialen with
        | Empty
        | Loading -> yield spinner
        | LoadError e -> yield errorMsg e
        | Body [||] -> ()
        | Body [| _ |] -> () // wenn es nur eine Fililae gibt, dann brauchen wir keinen umschalter
        | Body f ->
            yield f |> filialenRow model.SelectedFilialeId dispatch
            yield f |> filialenMobile model.SelectedFilialeId dispatch

        match model.Verrechnungen with
        | Empty
        | Body [||] -> yield emptyBox
        | Loading -> yield spinner
        | LoadError e -> yield errorMsg e
        | Body v -> yield! monatBoxen dispatch navigateTo v model

        yield dtaReportText dispatch navigateTo model

    ]
    |> ofList
